export const projectContextOptions = {
    light_type: ["natural_light", "artificial_light"],
    changing_background: [],
    distance: ["far_distance", "middle_distance", "near_distance"],
    is_general_object: [],
    light_condition: ["bright_light", "dimmed_light"],
    location: ["indoor", "outdoor"],
    perspective: ["birds_eye", "high_angle", "eye_level", "low_angle", "worms_eye"]
}

export const DATASET = "dataset";
export const INFERENCE = "inference";

export const DATUM_LABEL = 'DatumLabel';
export const BOUNDING_BOX = 'BoundingBox';

export const PREDICTION = 'PREDICTION';
export const GROUND_TRUTH = 'GROUND_TRUTH'

//TagViews
export const BACKGROUND = 'background';
export const UNTAGGED = 'untagged';
export const MINIMUM_IMAGES = 100;

export const newAiProject = {
    name: "",
    projectType: "",
    classes: [],
    context: {
        location: [],
        distance: [],
        light_type: [],
        light_condition: [],
        changing_background: null,
        is_general_object: null,
        perspective: []
    },
    aiWorkflowTemplates: [],
    teamIds: []
}

export const IMAGE_SIZE_SMALL = "small";
export const IMAGE_SIZE_MEDIUM = "medium";
export const IMAGE_SIZE_LARGE = "large";
export const IMAGE_SIZE_RAW = "raw";
export const imageSizeList = [IMAGE_SIZE_SMALL, IMAGE_SIZE_MEDIUM, IMAGE_SIZE_LARGE, IMAGE_SIZE_RAW];

export const brokenImage = "../assets/images/broken_image.png";

export const WELL_SET_TAB = 'well_set';
export const INFERENCE_PAGE_TAB = 'inference_page';


//CANVAS Configuration
export const PANNING_LIMIT_HORIZONTAL = 50;        // max distance of image to canvas border for panning
export const PANNING_LIMIT_VERTICAL = 50;          // max distance of image to canvas border for panning
export const GUIDE_LINECOLOR = '#ddd';             // color for guidelines
export const GUIDE_LINE_THICKNESS = 2;             // thickness for guide lines
export const HAS_ROTATING_POINT = false;           // bounding boxes rotateable?
export const IMAGE_BORDER_WIDTH = 1;
export const IMAGE_STROKE_COLOR = '#ddd';
export const BOUNDING_BOX_STROKE_WIDTH = 3;
export const DISTANCE_TO_GROUP_BORDER = 10;

export const datasetSortingSettings =  [{id: 'lastCreatedFirst', translation: 'lastCreatedFirst' },
                                        {id: 'lastCreatedLast', translation: 'lastCreatedLast' },
                                        {id: 'updatedLastFirst', translation: 'updatedLastFirst' },
                                        {id: 'updatedLastLast', translation: 'updatedLastLast' }];

export const trainingSortingSettings = [{id: 'newestCreatedFirst', translation: 'newestCreatedFirst' },
                                        {id: 'newestCreatedLast', translation: 'newestCreatedLast' },
                                        {id: 'highestPrecisionFirst', translation: 'highestPrecisionFirst' },
                                        {id: 'highestPrecisionLast', translation: 'highestPrecisionLast' }];

export interface LocaleViewModel {
    code?: string;
    displayName?: string;
    identifier?: string;
}
//Image Upload Constants
export const IMAGE_PREVIEW_SIZE = 15;

//TestData for Language Selection
export const supportedLocalesTEMP: LocaleViewModel[] = [
    {
        code: 'en_en',
        displayName: 'English',
        identifier: 'en_en',
    },
    {
        code: 'de_de',
        displayName: 'Deutsch',
        identifier: 'de_de',
    }
]

//ToDo: Testing Training
export const minImgAmountPerTag = 100;


//Ngx-skeleton-loader theme styles
export const ngxSkeletonTheme = {
    background: '#E1E1E1'
}

export const STATUS_PROJECT_INIT: string = 'project_init';
export const STATUS_PROJECT_LOADING:string = 'project_loading';
export const STATUS_PROJECT_LOADED:string = 'project_loaded';
export const STATUS_PROJECT_LOAD_FAILED:string = 'project_load_failed';
export const STATUS_NO_FREE_PROJECT:string = 'no_free_project';


export enum ModelTrainingStatus {
    READY_FOR_TRAINING = 'Ready for Training',
    TRAINED = 'Model Trained',
    TRAINING = 'Model in Training',
    CANCELED = 'Training Canceled',
    NOT_STARTED = 'Created',
    FAILED = 'Training Failed'
}

export enum OrderBy {
    ASC,
    DESC
}
