import {Component, Input, ViewEncapsulation} from "@angular/core";
import {AiProject, Model, ProjectType} from "@teamviewer/aistudioapi-common-angular";
import {DATASET, STATUS_PROJECT_LOAD_FAILED, STATUS_PROJECT_LOADED} from "utils/project-utils";
import {LoadingService} from "services/loading.service";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "services/notification.service";
import {UserService} from "services/aiStudio/user.service";
import {TagViewService} from "services/aiStudio/tag-view.service";
import {DatumService} from "services/aiStudio/datum.service";
import {Router} from "@angular/router";
import {Config} from "global/config";
import {DialogConfirm} from "dialogs/dialog-confirm";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {ProjectWizardComponent} from "components/modals/project-wizard/project-wizard.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DatasetService} from "services/aiStudio/dataset.service";
import {ProjectInfoComponent} from "components/modals/project-info/project-info.component";

@Component({
    selector: 'ai-project-card',
    templateUrl: 'ai-project-card.component.html',
    styleUrls: ['ai-project-card.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class AiProjectCardComponent {
    @Input() aiProject: AiProject | undefined;

    aiProjectPreviewDatumId: any | undefined;
    aiProjectTrainingStatus: any | undefined;
    publishedModel: Model | undefined;
    modelResultPrecision: number | undefined;
    imageCount: any | undefined;

    constructor(private loadingService: LoadingService,
                private aiProjectService: AiProjectService,
                public config: Config,
                private translateService: TranslateService,
                private notificationService: NotificationService,
                private userService: UserService,
                private tagViewService: TagViewService,
                private datumService: DatumService,
                private router: Router,
                private dialogConfirm: DialogConfirm,
                private aiStudioObservables: AiStudioObservables,
                private modalService: NgbModal,
                private datasetService: DatasetService
                ) {
    }

    async ngOnInit() {
        this.getAiProjectImages();
        this.getAiProjectTrainingStatus();
        this.publishedModel = await this.aiProject?.models?.filter((model: Model) => model.published)[0]
        if(this.publishedModel) {
            this.modelResultPrecision = this.publishedModel.trainingResult?.meanAveragePrecision;
        }
        this.imageCount = await this.datasetService.countPictures(this.aiProject?.projectUuid!);
    }

    //ToDo: Unlock implementation
    loadSelectedAiProject(projectUuid: string) {
        this.loadingService.show(this.translateService.instant('ai-studio.feedback.loading-project'));

        if(projectUuid === this.aiProjectService.getAiProject()?.projectUuid) {
            this.router.navigate([DATASET]);
        } else if (projectUuid) {

            // Load AI Project
            this.aiProjectService.getAiProjectByUuid(projectUuid).then(async (response: any) => {
                this.notificationService.info(this.translateService.instant('ai-studio.feedback.project-loaded', {projectName: this.aiProjectService.getAiProject().name}));

                this.userService.userPreferences.lastProject = projectUuid;
                this.userService.saveUserPreference();

                // Load AI project tag views depends on the project type.
                if (response.projectType === ProjectType.ObjectDetection) {// Object Detection
                    await this.tagViewService.fetchNotReadyTagViews();
                    await this.tagViewService.fetchWellSetTagViews();
                    await this.tagViewService.fetchInferenceTagViews();

                } else { // Image Classification
                    await this.tagViewService.fetchClassificationTagViews();
                    await this.tagViewService.fetchInferenceTagViews();
                }

                //Get initial well set datums for dataset page
                this.datumService.selectPage(1, false, "", true);

                this.aiProjectService.updateProjectStatus(STATUS_PROJECT_LOADED);

                this.aiStudioObservables.updateStatusPublishedModelHasChanged$(this.aiProjectService.getPublishedTrainingModel());

                //Change view to dataset tab
                this.router.navigate([DATASET])

            }).catch(() => {
                    this.aiProjectService.updateProjectStatus(STATUS_PROJECT_LOAD_FAILED);
                    console.error("Failed to load AI project '" + projectUuid + "'...");
                    this.loadingService.hide();
                }
            )
        }
    };

    getAiProjectImages() {
        //Get a datum to display a project image
        if(this.aiProject?.subDatasets !== undefined) {
            if(this.aiProject.subDatasets.length > 0) {
                this.datumService.getDatumImage(this.aiProject?.projectUuid).then((response: any) => {
                    this.aiProjectPreviewDatumId = response.content[0].id
                }).catch(() => {

                })
            }
        }
    }

    async getAiProjectTrainingStatus() {
         this.aiProjectTrainingStatus = await this.aiProjectService.getProjectTrainingStatus(this.aiProject!.projectUuid!,this.aiProject!.models!);
    }


    showDeleteProjectWarning(aiProject: AiProject | undefined) {
        if(aiProject){
            let projectName = aiProject.name;
            let message = this.translateService.instant('ai-studio.feedback.delete-project-question', {projectName: projectName});
            let primaryButton = this.translateService.instant('wf-editor.alert.yes');
            let secondaryButton = this.translateService.instant('wf-editor.alert.cancel');

            this.dialogConfirm.open(message, primaryButton, secondaryButton).then(() => {
                try {
                    let deleteMsg = this.translateService.instant('ai-studio.feedback.deleting-project', {projectName: projectName});
                    this.loadingService.show(deleteMsg);
                    console.info(deleteMsg);

                    this.aiProjectService.deleteSelectedAiProject(aiProject).then(() => {
                            let msg = this.translateService.instant('ai-studio.feedback.project-deleted', {projectName: projectName});
                            console.info(msg);
                            this.notificationService.info(msg);
                            this.loadingService.hide();
                            if(this.aiProjectService.getAiProject().projectUuid === aiProject.projectUuid) {
                                this.aiProjectService.setAiProject(null);
                            }
                            this.aiStudioObservables.updateStatusProjectDeleted$(aiProject.projectUuid!);
                        },
                        () =>  {
                            let msg = this.translateService.instant('ai-studio.feedback.project-deletion-failed', {projectName: projectName});
                            console.error(msg);
                            this.notificationService.info(msg);

                            this.loadingService.hide();
                        });
                } catch (e) {
                    console.log('Error while deleting AI project.');
                    console.log(e);
                }
            })
        }
    };

    editProjectInWizard() {
        let modalInstance = this.modalService.open(ProjectWizardComponent, {
            animation: true,
            size: "col-8",
            backdrop: 'static',
            keyboard: false,
        })
        modalInstance.componentInstance.fromToolbar = true
        modalInstance.componentInstance.aiProject = this.aiProject;
    };

    showInfo(){
        let modalInstance = this.modalService.open(ProjectInfoComponent, {
            animation: true,
            size: "col-6",
            backdrop: true,
        })
        modalInstance.componentInstance.aiProject = this.aiProject;
    }
}
