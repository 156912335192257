<!-- CONTEXT -->
<div class="tab-pane" id="context" [ngClass]="{'active' : currentWizardStep === 5}">
    <div class="row">
        <div class="col-sm-12">
            <!-- TODO: refactor -->
            <p *ngIf="model.classes.length === 1">
                <span *ngIf="currentContextStep === 1">{{ 'ai-studio.wizard.context.location' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 2">{{ 'ai-studio.wizard.context.distance' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 3">{{ 'ai-studio.wizard.context.background' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 4">{{ 'ai-studio.wizard.context.light-type' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 5">{{ 'ai-studio.wizard.context.light-condition' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 6">{{ 'ai-studio.wizard.context.general.title' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 7">{{ 'ai-studio.wizard.context.perspective' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <!-- ToDo: necessary? -->
                <!-- <span class="badge" [placement]="right"
                [ngbTooltip]="'ai-studio.wizard.context.number-of-steps.tooltip' | translate">
                        {{ numberOfSteps }} <i class="icon icon-times">&nbsp;</i><i
                        class="icon icon-image"></i>
                </span> -->
            </p>
            <p *ngIf="model.classes.length > 1">
                <span *ngIf="currentContextStep === 1">{{ 'ai-studio.wizard.context.location.pl' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 2">{{ 'ai-studio.wizard.context.distance.pl' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 3">{{ 'ai-studio.wizard.context.background.pl' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 4">{{ 'ai-studio.wizard.context.light-type.pl' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 5">{{ 'ai-studio.wizard.context.light-condition.pl' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 6">{{ 'ai-studio.wizard.context.general.title.pl' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                <span *ngIf="currentContextStep === 7">{{ 'ai-studio.wizard.context.perspective.pl' | translate }} ({{ currentContextStep }}/{{ wizardContextStepsCount }})</span>
                                <!-- ToDo: necessary? -->

                <!-- <span class="badge" [placement]="right"
                [ngbTooltip]="'ai-studio.wizard.context.number-of-steps.tooltip' | translate">
                        {{ numberOfSteps }} <i class="icon icon-times">&nbsp;</i><i
                        class="icon icon-image"></i>
                </span> -->
            </p>
        </div>

        <!-- LOCATION -->
        <div class="col-sm-12" *ngIf="currentContextStep === 1">
            <div class="row">
                <div class="col-sm-6" *ngFor="let location of projectContextOptions.location">
                    <div class="choice"
                            [ngClass]="{ 'active' : locationCheckBox[location] }"
                            role="presentation">
                        <label for="id_context_{{location}}" (click)="updateLocation(location)"
                                class="card card-checkboxes card-hover-effect">
                            <i class="selectionHelpIcon icon"
                                [ngClass]="{ 'icon-check-square-o' : locationCheckBox[location], 'icon-square' : !locationCheckBox[location] }"></i>
                            <i class="icon icon-ai-wizard-location-{{location}}"></i>
                            <p>{{ 'ai-studio.wizard.context.location.' + location |
                                translate }}</p>
                        </label>
                    </div>
                </div>
            </div>
        </div>

         <!-- DISTANCE -->
        <div class="col-sm-12" *ngIf="currentContextStep === 2">
            <div class="row">

                <div class="col-sm-4" *ngFor="let distance of projectContextOptions.distance; let index = index">

                    <div class="choice"
                            [ngClass]="{ 'active' : distanceCheckBox[distance] }"
                            role="presentation">
                        <label for="id_context_{{distance}}" (click)="updateDistance(distance)"
                                class="card card-checkboxes card-hover-effect">
                            <i class="selectionHelpIcon icon"
                                [ngClass]="{ 'icon-check-square-o' : distanceCheckBox[distance], 'icon-square' : !distanceCheckBox[distance] }"></i>
                            <i class="icon icon-ai-wizard-distance-{{index}}"></i>
                            <i class="icon icon-ai-wizard-distance-{{index + 1}}"
                                style="margin: -10px 0 20px 0;"></i>
                            <p>{{ 'ai-studio.wizard.context.distance.' + distance |
                                translate }}</p>
                        </label>
                        <p class="top-buffer">{{ 'ai-studio.wizard.context.distance.' +
                            distance
                            +'.help' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Background -->
        <div class="col-sm-12" *ngIf="currentContextStep === 3">
            <div class="row">
                <div class="col-sm-6">
                    <div class="choice" [ngClass]="{ 'active' : model.context.changing_background === true }" role="presentation">
                         <label for="context_changing_bg" (click)="model.context.changing_background = true"
                                class="card card-checkboxes card-hover-effect">
                            <i class="selectionHelpIcon icon"
                                [ngClass]="{ 'icon-radio-true' :model.context.changing_background === true, 'icon-radio-false' : model.context.changing_background !== true }"></i>
                            <i class="icon icon-ai-wizard-background-changing"></i>
                            <p>{{ 'ai-studio.wizard.context.background.different' |
                                translate
                                }}</p>
                        </label>
                        <p class="top-buffer"> {{
                            'ai-studio.wizard.context.background.different.desc' |
                            translate
                            }}</p>
                    </div>
                </div>

               <div class="col-sm-6">
                    <div class="choice"
                            [ngClass]="{ 'active' : model.context.changing_background === false }"
                            role="presentation">
                       <label for="context_same_bg" (click)="model.context.changing_background = false"
                                class="card card-checkboxes card-hover-effect">
                            <i class="selectionHelpIcon icon"
                                [ngClass]="{ 'icon-radio-true' : model.context.changing_background === false, 'icon-radio-false' : model.context.changing_background !== false }"></i>
                            <i class="icon icon-ai-wizard-background-same"></i>
                            <p>{{ 'ai-studio.wizard.context.background.same' | translate
                                }}</p>
                        </label>
                        <p class="top-buffer">{{
                            'ai-studio.wizard.context.background.same.desc' |
                            translate }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- LIGHT TYPE -->
        <div class="col-sm-12" *ngIf="currentContextStep === 4">
            <div class="row">
                <div class="col-sm-6" *ngFor="let lightType of projectContextOptions.light_type">
                    <div class="choice"
                           [ngClass]="{'active' : lightTypeCheckBox[lightType]}"
                            role="presentation">
                        <label for="id_context_{{lightType}}"  (click)="updateLightType(lightType)"
                                class="card card-checkboxes card-hover-effect">
                            <i class="selectionHelpIcon icon"
                               [ngClass]="{ 'icon-check-square-o' : lightTypeCheckBox[lightType], 'icon-square' : !lightTypeCheckBox[lightType] }"></i>
                            <i class="icon icon-ai-wizard-lighttype-{{lightType}}"></i>
                            <p>{{ 'ai-studio.wizard.context.light-type.' + lightType |
                                translate
                                }}</p>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- LIGHT CONDITION -->
         <div class="col-sm-12" *ngIf="currentContextStep === 5">
            <div class="row">
                <div class="col-sm-6"
                        *ngFor="let lightCondition of projectContextOptions.light_condition">
                    <div class="choice"
                           [ngClass]="{'active' : lightConditionCheckBox[lightCondition]}"
                            role="presentation">
                         <label for="id_context_{{lightCondition}}"
                         (click)="updateLightCondition(lightCondition)"
                                class="card card-checkboxes card-hover-effect">
                            <i class="selectionHelpIcon icon"
                               [ngClass]="{ 'icon-check-square-o' : lightConditionCheckBox[lightCondition], 'icon-square' : !lightConditionCheckBox[lightCondition] }"></i>
                            <i class="icon icon-ai-wizard-{{lightCondition}}"></i>
                            <p>{{ 'ai-studio.wizard.context.light-condition.' +
                                lightCondition |
                                translate }} </p>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- GENERAL / SPECIFIC -->
        <div class="col-sm-12" *ngIf="currentContextStep === 6">
            <div class="row">
                <div class="col-sm-6">
                    <div class="choice"
                           [ngClass]="{ 'active' : model.context.is_general_object === true}"
                            role="presentation">

                        <label class="card card-checkboxes card-hover-effect"
                        (click)="model.context.is_general_object = true"
                                for="context_general_object">
                            <i class="selectionHelpIcon icon"
                               [ngClass]="{ 'icon-radio-true' : model.context.is_general_object === true, 'icon-radio-false' : model.context.is_general_object !== true}"></i>
                            <i class="icon icon-ai-wizard-general"></i>
                            <p>{{ 'ai-studio.wizard.context.general' | translate }}</p>
                        </label>
                        <p class="top-buffer">
                            {{ 'ai-studio.wizard.context.general.desc' | translate }}
                        </p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="choice"
                           [ngClass]="{ 'active' : model.context.is_general_object === false}"
                            role="presentation">

                        <label class="card card-checkboxes card-hover-effect"
                        (click)="model.context.is_general_object = false"
                                for="context_specific_object">
                            <i class="selectionHelpIcon icon"
                               [ngClass]="{ 'icon-radio-true' : model.context.is_general_object === false, 'icon-radio-false' : model.context.is_general_object !== false }"></i>
                            <i class="icon icon-ai-wizard-specific"></i>
                            <p>{{ 'ai-studio.wizard.context.specific' | translate }}</p>
                        </label>
                        <p class="top-buffer">
                            {{ 'ai-studio.wizard.context.specific.desc' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- ANGLE -->
        <div class="col-sm-12" *ngIf="currentContextStep === 7">
            <div class="row">
                <div class="col-sm-6 example-image-left">
                    <img src="../../../../assets/images/perspective2.png" alt="">
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-sm-12"
                                *ngFor="let perspective of projectContextOptions.perspective">

                            <div class="perspective-selection choice"
                                   [ngClass]="{ 'active' : perspectiveCheckBox[perspective] }"
                                    role="presentation">
                                  <label for="id_context_{{perspective}}"
                                (click)="updatePerspective(perspective)"
                                        class="card card-checkboxes card-hover-effect">
                                     <i class="icon " style="margin: 0"
                                       [ngClass]="{ 'icon-check-square-o' : perspectiveCheckBox[perspective], 'icon-square' : ! perspectiveCheckBox[perspective] }"></i>
                                    {{ 'ai-studio.wizard.context.perspective.' + perspective
                                    |
                                    translate }}
                                </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
