<body class="theme-ai-studio">
<top-menu>
    <ai-toolbar></ai-toolbar>
</top-menu>
<main>
    <slide-out-app-menu></slide-out-app-menu>

    <div class="ui-view">
        <!-- Corrupted Screens -->
        <corrupted-screen></corrupted-screen>
        <init-screen></init-screen>
        <no-free-project-screen></no-free-project-screen>

        <router-outlet></router-outlet>
    </div>
    <!-- Guidance -->
    <ai-guidance></ai-guidance>
    <div id="highlighter" class="highlighter hide"></div>
</main>

</body>
