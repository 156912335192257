import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {AugmentationSettingsComponent} from 'components/modals/augmentation-settings/augmentation-settings.component';
import {StartTrainingComponent} from 'components/modals/start-training/start-training.component';
import {DialogConfirm} from 'dialogs/dialog-confirm';
import _ from 'lodash';
import {AiProjectService} from 'services/aiStudio/ai-project.service';
import {AiStudioObservables} from 'services/aiStudio/ai-studio.observables';
import {TagViewService} from 'services/aiStudio/tag-view.service';
import {
    BACKGROUND,
    GROUND_TRUTH,
    IMAGE_SIZE_RAW,
    ModelTrainingStatus,
    PREDICTION,
    trainingSortingSettings,
    UNTAGGED
} from "utils/project-utils";
import {Datum, DatumClass, MisclassifiedData, Model, TrainingStatus} from "@teamviewer/aistudioapi-common-angular";
import * as echarts from 'echarts';
import {TrainingService} from "services/aiStudio/training.service";
import {RenameTrainingComponent} from "components/modals/rename-training/rename-training.component";
import {TrainingOptionsInfoComponent} from "components/modals/training-options-info/training-options-info.component";
import {DatumService} from "services/aiStudio/datum.service";
import {LoadingService} from "services/loading.service";
import moment from "moment";
import {ObjectDetectionMisclassified} from "./object-detection-misclassified/object-detection-misclassified.component";
import {NotificationService} from "services/notification.service";
import {Config} from "global/config";
import {
    ClassificationMisclassifiedComponent
} from "./classification-misclassified/classification-misclassified.component";
import {HighlightingService} from "services/aiStudio/highlighting.service";
import {AuthenticationService} from "services/authentication.service";
import {Subscription} from "rxjs";
import {EventService} from "services/event.service";

@Component({
    selector: 'training',
    templateUrl: 'training.component.html',
    styleUrls: ['training.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class TrainingComponent implements OnInit, OnDestroy {
    @ViewChild('precisionChart', { static: false }) precisionChart!: ElementRef;
    @ViewChild('sourceChart', { static: false }) sourceChart!: ElementRef;
    @ViewChild('logChart', { static: false }) logChart!: ElementRef;

    showMissClassifiedImages = false;
    trainingSortingValue = '';

    trainingDays = 0;
    trainingHours = 0;
    trainingMinutes = 0;
    trainingSeconds = 0;

    misclassifiedImages: any;
    misclassificationFilters: any;
    loadingMisclassifies = false;
    selectedModel: Model | undefined;

    NOT_STARTED = TrainingStatus.NotStarted;
    TRAINING = TrainingStatus.Training;
    TRAINED = TrainingStatus.Trained;
    FAILED = TrainingStatus.Failed;

    baseUrl = '';

    IMAGE_SIZE_RAW = IMAGE_SIZE_RAW;

    SUCCESS_COLOR = 'rgb(154,205,50,0.7)';
    WARN_COLOR = 'rgb(240,173,78,0.7)';
    ERROR_COLOR = 'rgb(192,0,0,0.7)';

    imageCountLabels: any = [];
    imageCountValues: any = [];
    precisionLabels: any = [];
    precisionValues: any = [];
    private trainingLogs: any = {};

    allDatumViewWrappers: any;
    datumViewWrappers: any;

    BACKGROUND: string = BACKGROUND;

    trainingSortingSettings = trainingSortingSettings;
    selectedSortingSetting = 'newestCreatedFirst';

    hasFrontlineRole: boolean;

    private allObservables: Subscription[] = [];

    constructor(private aiStudioObservables: AiStudioObservables, private authenticationService: AuthenticationService, private translateService: TranslateService, private modalService: NgbModal, private tagViewService: TagViewService, private dialogConfirm: DialogConfirm, public aiProjectService: AiProjectService, public trainingService: TrainingService, private datumService: DatumService, private loadingService: LoadingService, private notificationService: NotificationService, public config: Config, private highlightingService: HighlightingService, private eventService: EventService) {
        this.hasFrontlineRole = this.authenticationService.hasRole(('Frontline_AI_User'));
    }

    ngAfterViewInit() {
        // Load charts
        this.loadCharts();
    }

    ngOnDestroy() {
        for (const subject of this.allObservables) {
            subject.unsubscribe();
        }
    }

    loadCharts() {
        let chartTitle;
        if (this.aiProjectService.isObjectDetectionProject()) {
            chartTitle = this.translateService.instant('ai-studio.model.tab.model.object-detection-precision');
        } else {
            chartTitle = this.translateService.instant('ai-studio.model.tab.model.image-classification-precision');
        }

        if (this.logChart && this.trainingLogs){
            const logChartElement: any = this.logChart?.nativeElement;
            const logChart: any = echarts.init(logChartElement);
            const logChartOption = this.generateLineChartOption(this.translateService.instant('ai-studio.model.tab.model.logs'), this.trainingLogs);
            logChart.setOption(logChartOption);
        }

        if (this.sourceChart){
            const sourceChartElement: any = this.sourceChart?.nativeElement;
            const sourceChart: any = echarts.init(sourceChartElement);
            const sourceChartOption = this.generateBarChartOption(this.translateService.instant('ai-studio.model.tab.dataset.quality'), this.imageCountValues, this.imageCountLabels);
            sourceChart.setOption(sourceChartOption);
        }

        if (this.precisionChart){
            const precisionChartElement: any =  this.precisionChart?.nativeElement;
            const precisionChart = echarts.init(precisionChartElement);

            const precisionChartOption: any = this.generateBarChartOption(chartTitle, this.precisionValues, this.precisionLabels);

            precisionChartOption.yAxis = {
                type: 'value',
                min: 0,
                max: 100,
                axisLabel: {
                    formatter: '{value}%'
                }
            };
            precisionChartOption.tooltip.formatter = (params: any) => {
                return params[0].name + ' : ' + params[0].value + '%';
            };
            precisionChart.setOption(precisionChartOption);
        }
    }

    ngOnInit() {
        if (this.aiProjectService.getTrainingModels().length > 0 && !this.selectedModel) {
            let targetModel: Model;
            const publishedModel: any = this.aiProjectService.getPublishedTrainingModel();
            if (publishedModel) {
                targetModel = publishedModel;
            } else {
                const models = this.aiProjectService.getAiProject()?.models || [{}];
                targetModel = models[0];
            }

            this.loadModel(targetModel);
            this.trainingSortingValue = 'startLast';
            this.updateTrainingSorting(this.trainingSortingValue);
        }
        this.baseUrl = this.config.baseUrl;


        this.allObservables.push(this.aiStudioObservables.STATUS_SELECTED_MODEL_HAS_CHANGED$.subscribe((currentModelUuid: string) => {
            if (currentModelUuid !== undefined || currentModelUuid === '') {
                const models = this.aiProjectService.getAiProject()?.models || [{}];

                const notifyModel = models.filter((model: Model) => model.modelUuid === currentModelUuid)[0];
                const translKey = notifyModel.trainingStatus === ModelTrainingStatus.TRAINED.toString() ? 'ai-studio.feedback.model-training-finished' :
                    notifyModel.trainingStatus === ModelTrainingStatus.FAILED.toString() ? 'ai-studio.feedback.model-training-failed' : '';
                if (translKey) {
                    this.notificationService.info(this.translateService.instant(translKey, {trainingName: notifyModel.name}));
                }

                if (this.selectedModel !== undefined && this.selectedModel.modelUuid === currentModelUuid) {
                    this.selectedModel = notifyModel;
                    this.selectModel(this.selectedModel);
                }
            }
        }));
    }

    // ToDo: test with more data
    updateTrainingSorting = (trainingSortingValue: string) => {
        let sortedModels;
        const models = this.aiProjectService.getAiProject().models;

        if (trainingSortingValue === 'newestCreatedFirst') {
            sortedModels = _.orderBy(models, 'createdAt', 'desc');
        } else if (trainingSortingValue === 'newestCreatedLast') {
            sortedModels = _.orderBy(models, 'createdAt');
        } else if (trainingSortingValue === 'highestPrecisionFirst') {
            sortedModels = _.orderBy(models, (m) => [m.trainingResult?.meanAveragePrecision, m.createdAt], 'desc');
        } else if (trainingSortingValue === 'highestPrecisionLast') {
            sortedModels = _.orderBy(models, (m) => [m.trainingResult?.meanAveragePrecision, m.createdAt]);
        }

        if (sortedModels) {
            this.aiProjectService.setTrainingModels(sortedModels);
        }
    }

    openTrainingModelWithAugmentationCheck() {

        this.highlightingService.activeHighlighting = false;
        this.highlightingService.hide();

        let hasTagBelowMinimumAmount = false;

        if (this.aiProjectService.isObjectDetectionProject() && this.tagViewService.getBelowMinimumAndWellSetTagViews().length === 0) {
            hasTagBelowMinimumAmount = true;
        } else if (!this.aiProjectService.isObjectDetectionProject() && this.tagViewService.getBelowMinimumAndTaggedTagViews().length === 0) {
            hasTagBelowMinimumAmount = true;
        }

        // Only when the augmentation settings are not set and all images are sufficient, the augmentation model will pop-up
        if (!this.aiProjectService.isAugmentationSettingsSet() && hasTagBelowMinimumAmount) {
            const message = this.translateService.instant('ai-studio.feedback.augmentation-not-set.desc');
            const okBtn = this.translateService.instant('wf-editor.alert.yes');
            const notOkBtn = this.translateService.instant('remoteSupportWizard.btn.skip');
            this.dialogConfirm.open(message, okBtn, notOkBtn).then(async () => {
                try {
                    const modalInstance = this.modalService.open(AugmentationSettingsComponent, {
                        animation: true,
                        size: 'col-5'
                    });
                    modalInstance.componentInstance.fromTraining = true;

                } catch (e) {
                    console.log('Error while opening augmentation settings.');
                    console.log(e);
                }
            }, () => {
                this.openTrainingModel();
            });
        } else {
            this.openTrainingModel();
        }
    }

    changeModelDetails = () => {
        this.trainingService.showTrainingDetails = !this.trainingService.showTrainingDetails;

        // Todo: implement user preferences
        if (this.trainingService.userPreference && this.trainingService.userPreference.aiStudioPreference) {
            this.trainingService.userPreference.aiStudioPreference.showTrainingDetails = this.trainingService.showTrainingDetails;
            // this.trainingService.saveUserPreference(this.trainingService.userPreference);
        }
    }

    selectModel(model: Model) {
        this.cleanModel();
        this.loadModel(model);
    }

    cleanModel() {
        // Clean charts
        this.precisionLabels = [];
        this.precisionValues = [];
        this.trainingLogs = {};
        this.imageCountLabels = [];
        this.imageCountValues = [];

        // Clean data
        this.showMissClassifiedImages = false;
        this.misclassificationFilters = [];

        this.trainingDays = 0;
        this.trainingHours = 0;
        this.trainingMinutes = 0;
        this.trainingSeconds = 0;
    }

    // Load model details and visualize them in the charts
    loadModel = (model: Model, forceRedraw?: boolean) => {
        if (!model) {
            console.error('Cannot load model, passed-in model is undefined or null...');
        } else {
            // Get the latest training status
            this.aiProjectService.updateTrainingModels();
            const isObjectDetection = this.aiProjectService.isObjectDetectionProject();


            if (isObjectDetection && model.trainingStatus === 'TRAINED') {
                if (model.trainingResult?.misClassifieds && model.trainingResult.misClassifieds.length > 0) {
                    // Render misclassified images for object detection object.
                    const projectClasses: any = this.aiProjectService.getAiProject().classes || [];
                    const externalDatumClasses = projectClasses.filter((aClass: DatumClass) => aClass.name !== 'background');

                    this.misclassificationFilters = this.prepareMisclassifiedFilters(externalDatumClasses, model);
                    this.showMissClassifiedImages = true;
                    this.fetchMisclassifiedDatumViewsAndDraw(model);
                }
            } else if (!isObjectDetection && model.trainingStatus === 'TRAINED' && model.trainingResult?.misClassifieds && model.trainingResult.misClassifieds.length > 0) {
                this.misclassifiedImages = this.trainingService.prepareMisclassifiedImages(model);
                // Render misclassified images for classification.
                this.showMissClassifiedImages = Object.keys(this.misclassifiedImages).length > 0;
            }

            const redraw = !this.selectedModel || this.selectedModel.id !== model.id || !_.isEqual(this.trainingLogs, model.trainLogs) || forceRedraw;

            if (redraw && model && model.modelMetadata) {
                this.selectedModel = model;
                this.trainingLogs = model.trainLogs || {};

                if (model.trainingResult?.trainingTime){
                    this.trainingDays = Math.trunc(model.trainingResult.trainingTime / 60 / 60 / 24);
                    this.trainingHours = Math.trunc(model.trainingResult.trainingTime / 60 / 60 % 24);
                    this.trainingMinutes = Math.trunc(model.trainingResult.trainingTime / 60 % 60);
                    this.trainingSeconds = Math.trunc(model.trainingResult.trainingTime % 60);
                }

                const trainingResult = model.trainingResult;

                // Setup image count chart
                if (model.modelMetadata && model.modelMetadata.datumsPerClasses && model.modelMetadata.datumsPerClasses.length > 0) {
                    // Flag to identify 'background' datum to push it at the end of listing
                    let isBackgroundDatum = false;
                    let backgroundImageCountLabel: any = '';
                    let backgroundImageCountValue: any = {};

                    if (forceRedraw) {
                        this.imageCountLabels = [];
                        this.imageCountValues = [];
                    }

                    for (const datumCount of model.modelMetadata.datumsPerClasses) {
                        if (datumCount.className !== UNTAGGED && datumCount.count) {
                            let datumName = datumCount.className;
                            if (datumName === BACKGROUND) {
                                datumName = this.translateService.instant('ai-studio.dataset.tab.background');
                                isBackgroundDatum = true;
                            }

                            const imageCountLabel = datumName!.toLowerCase();

                            const imageCountValue = {
                                    value: datumCount.count,
                                    name: datumName?.toLowerCase(),
                                    itemStyle: {
                                    color: this.determineBalanceChartBarColor(model.modelMetadata.minCount || 0, datumCount.count)
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: this.determineBalanceChartBarColor(model.modelMetadata.minCount || 0, datumCount.count)
                                    }
                                }
                            };

                            if (isBackgroundDatum) {
                                backgroundImageCountLabel = imageCountLabel;
                                backgroundImageCountValue = imageCountValue;
                                isBackgroundDatum = false;
                            } else {
                                this.imageCountLabels.push(imageCountLabel);
                                this.imageCountValues.push(imageCountValue);

                            }
                        }
                    }

                    // Sorts arrays into alphabetical order
                    this.imageCountLabels = this.imageCountLabels.sort();
                    this.imageCountValues = this.imageCountValues.sort( this.compareNames );
                    // Appends 'background' Datum to the end of the arrays
                    this.imageCountLabels.push(backgroundImageCountLabel);
                    this.imageCountValues.push(backgroundImageCountValue);
                    // Pops the last element out of the array
                    this.imageCountLabels.pop();
                    this.imageCountValues.pop();

                }

                // Setup precision count chart
                if (trainingResult?.classAccuracies && trainingResult?.classAccuracies.length > 0) {
                    // Flag to identify 'background' datum to push it at the end of listing
                    let isBackgroundDatum = false;
                    let backgroundPrecisionLabel: any = '';
                    let backgroundPrecisionValue: any = {};

                    if (forceRedraw) {
                        this.precisionValues = [];
                        this.precisionLabels = [];
                    }

                    for (const classAccuracy of trainingResult.classAccuracies) {
                        let datumName = classAccuracy.className || '';
                        if (datumName.toLowerCase() === BACKGROUND) {
                            if (this.aiProjectService.isObjectDetectionProject()) {
                                continue; // Skip 'background' in precision table in the object detection project
                            } else {
                                datumName = this.translateService.instant('ai-studio.dataset.tab.background');
                                isBackgroundDatum = true;
                            }
                        }

                        const precisionLabel =  datumName.toLowerCase();

                        const accuracyValue: any = classAccuracy.averagePrecision;

                        const precisionValue = {
                            value: Math.floor(accuracyValue * 100),
                            name: datumName.toLowerCase(),
                            itemStyle: {
                                color: this.determinePrecisionChartBarColor(accuracyValue)
                            },
                            emphasis: {
                                itemStyle: {
                                    color: this.determinePrecisionChartBarColor(accuracyValue)
                                }
                            }
                        };
                        if (isBackgroundDatum) {
                            backgroundPrecisionLabel = precisionLabel;
                            backgroundPrecisionValue = precisionValue;
                            isBackgroundDatum = false;
                        } else {
                            this.precisionValues.push(precisionValue);
                            this.precisionLabels.push(precisionLabel);
                        }
                    }
                    // Sorts arrays into alphabetical order
                    this.precisionLabels = this.precisionLabels.sort();
                    this.precisionValues = this.precisionValues.sort( this.compareNames );
                    // Appends 'background' Datum to the end of the arrays
                    if (backgroundPrecisionLabel !== '' && !_.isEmpty(backgroundPrecisionValue)){
                        this.precisionLabels.push(backgroundPrecisionLabel);
                        this.precisionValues.push(backgroundPrecisionValue);
                    }
                }
                this.loadCharts();
            }
        }
    }

    updateFilter = () => {
        this.fetchMisclassifiedDatumViewsAndDraw(this.selectedModel!);
    }

    openRenameTrainingModal = () => {
        if (this.selectedModel) {
            const modalInstance = this.modalService.open(RenameTrainingComponent,  {
                animation: true,
                size: 'col-5',
            });

            modalInstance.componentInstance.selectedModel = this.selectedModel;
        }
    }

    openOptionsInfoModal = () => {
        if (this.selectedModel) {
            const modalInstance = this.modalService.open(TrainingOptionsInfoComponent,  {
                animation: true,
                size: 'col-5',
            });

            modalInstance.componentInstance.selectedModel = this.selectedModel;
        }
    }

    fetchMisclassifiedDatumViewsAndDraw = (model: Model) => {
        this.loadingMisclassifies = true;

        const misClassifieds = model.trainingResult?.misClassifieds || [];
        const datums: any = [];

        // Filter misClassifieds by tags selected
        let selectedClasses = this.misclassificationFilters.filter((aClass: any) => aClass.selected);
        if (selectedClasses.length === 0){
            selectedClasses = this.misclassificationFilters;
        }

        const selectedClassesList = selectedClasses.map((aClass: any) => aClass.name);
        const filteredMisClassifieds = misClassifieds.filter((mis: any) =>  mis.misclassifiedDataList.some((ele: any) => selectedClassesList.includes(ele.label)));

        // Convert misclassified objects
        filteredMisClassifieds.map((item: any) => {
            const predictionBoxes = item.misclassifiedDataList.filter((type: any) => type.misclassifiedDataType === PREDICTION);
            const predictionClasses: any = [];

            for (const box of predictionBoxes){
                if (predictionClasses.filter((aClass: any) => aClass.name === box.label).length === 0 ){
                    const tag = this.misclassificationFilters.filter((aClass: any) => aClass.name === box.label)[0];
                    predictionClasses.push(tag);
                }
            }

            const groundTruthBoxes = item.misclassifiedDataList.filter((type: any) => type.misclassifiedDataType === GROUND_TRUTH);
            const groundTruthClasses: any = [];

            for (const box of groundTruthBoxes){

                if (groundTruthClasses.filter((aClass: any) => aClass.name === box.label).length === 0 ){
                    const tag = this.misclassificationFilters.filter((aClass: any) => aClass.name === box.label)[0];
                    groundTruthClasses.push(tag);
                }
            }

            const datum = {
                id: item.datumId,
                path: item.path,
                prediction:  predictionBoxes,
                predictionClasses,
                groundTruth: groundTruthBoxes,
                groundTruthClasses,
                trainingImgWidth: model.trainingResult?.trainingImgWidth,
                trainingImgHeight: model.trainingResult?.trainingImgHeight,
            };

            datums.push(datum);
        });

        this.allDatumViewWrappers = datums;

        // Only load 10 of them first (20 views)
        this.datumViewWrappers = this.allDatumViewWrappers.slice(0, 10);
        setTimeout(() => {
            this.datumService.renderMisclassifiedOnCanvas(this.datumViewWrappers, this.misclassificationFilters);

        }, 0);
        this.loadingMisclassifies = false;
    }

    prepareMisclassifiedFilters = (classes: DatumClass[], model: Model) => {
        const filters = [];
        const nameListOfClassInTraining = [];

        for (const aClassInTraining of model?.modelMetadata?.datumsPerClasses || []) {
            nameListOfClassInTraining.push(aClassInTraining.className);
        }

        const notAccurateClassList: Set<any> = new Set();

        for (const misClassified of model?.trainingResult?.misClassifieds || []) {
            misClassified?.misclassifiedDataList?.forEach((item: MisclassifiedData) => notAccurateClassList.add(item.label));
        }

        for (const aClass of classes) {
            if (nameListOfClassInTraining.includes(aClass.name) && notAccurateClassList.has(aClass.name)) {
                const aFilter: any = _.cloneDeep(aClass);
                aFilter.selected = false;
                filters.push(aFilter);
            }
        }

        return filters;
    }

    determineBalanceChartBarColor = (minCount: number, amount: number) => {
        if (amount < minCount) {
            return this.WARN_COLOR;
        } else {
            return this.SUCCESS_COLOR;
        }
    }

    determinePrecisionChartBarColor = (precision: number) => {
        if (precision <= 0.5) {
            return this.ERROR_COLOR;
        } else if (precision > 0.5 && precision <= 0.8) {
            return this.WARN_COLOR;
        } else {
            return this.SUCCESS_COLOR;
        }
    }

    publishModel = () => {
        this.highlightingService.hide();
        this.highlightingService.activeHighlighting = false;

        if (this.selectedModel) {
            if (this.selectedModel.trainingResult?.meanAveragePrecision! < 0.8) {
                const message = this.translateService.instant('ai-studio.feedback.publish-low-precision-model.desc', {
                    modelName: this.selectedModel.name,
                    percentage: (this.selectedModel.trainingResult?.meanAveragePrecision! * 100) + '%'
                });
                const primaryButton = this.translateService.instant('wf-editor.alert.yes');
                const secondaryButton = this.translateService.instant('wf-editor.alert.cancel');

                this.dialogConfirm.open(message, primaryButton, secondaryButton).then(async () => {
                    const publishedModel = this.trainingService.getPublishedModel();

                    if (publishedModel.length > 0) {
                        const message = this.translateService.instant('ai-studio.feedback.already-published-training.desc', {
                            oldModelName: publishedModel[0].name,
                            newModelName: this.selectedModel!.name
                        });
                        const primaryButton = this.translateService.instant('wf-editor.alert.yes');
                        const secondaryButton = this.translateService.instant('wf-editor.alert.cancel');

                        this.dialogConfirm.open(message, primaryButton, secondaryButton).then(async () => {
                            this.publishingModel();
                            this.aiStudioObservables.updateStatusPublishedModelHasChanged$(this.selectedModel!);
                        }, (error) => {
                            console.log(error);
                        });
                    } else {
                        this.publishingModel();
                        this.aiStudioObservables.updateStatusPublishedModelHasChanged$(this.selectedModel!);
                    }
                }, (error) => {
                    console.log(error);
                });
            } else {
                this.publishingModel();
                this.aiStudioObservables.updateStatusPublishedModelHasChanged$(this.selectedModel);
            }
        }
    }

    publishingModel = () => {
        let message = this.translateService.instant('ai-studio.feedback.sure-to-publish-training-public.desc');
        if (this.hasFrontlineRole) {
            message = this.translateService.instant('ai-studio.feedback.sure-to-publish-training-frontline.desc');
        }

        const primaryButton = this.translateService.instant('wf-editor.alert.yes');
        const secondaryButton = this.translateService.instant('wf-editor.alert.cancel');

        this.dialogConfirm.open(message, primaryButton, secondaryButton).then(async () => {
            try {
                const tmpCurrentlyPublished = this.aiProjectService.getPublishedTrainingModel();
                const eventModel = this.eventService.buildModel('Event,ai-training,initialize-publish-training', this.selectedModel!.aiProject?.projectUuid || '', this.selectedModel!.modelUuid || '');
                this.eventService.submitEvent(eventModel);
                this.trainingService.publishModel(this.selectedModel!).then(() => {
                    if (tmpCurrentlyPublished) {
                        tmpCurrentlyPublished.published = false;
                    }
                    this.selectedModel!.published = true;
                    this.aiStudioObservables.updateStatusPublishedModelHasChanged$(this.selectedModel!);
                    this.loadingService.hide();
                    if (this.hasFrontlineRole) {
                        const info = '<p class=\'magnify confirmation-message\'>' +  this.translateService.instant('ai-studio.feedback.publish-model.fcc-link.desc', {linkToOverview: this.authenticationService.getFrontlineBaseUrl() + '/#/admin/aistudio'}) + '</p>';
                        this.dialogConfirm.open('', 'Close', '', info);
                    }
                }, (err) => {
                    this.selectedModel!.published = false;
                    console.log(err);
                    const errorMsg = this.translateService.instant('ai-studio.feedback.training-publish-failed', {modelName: this.selectedModel!.name});
                    console.error(errorMsg);
                    this.loadingService.hide();
                    this.notificationService.info(errorMsg);
               });
            } catch (e) {
                this.loadingService.hide();
                console.log('Error while publishing model.');
                console.log(e);
            }
        }, (error) => {
            console.log(error);
        });
    }

    unpublishModel = () => {
        this.highlightingService.hide();
        this.highlightingService.activeHighlighting = false;
        if (this.selectedModel) {
            let message = this.translateService.instant('ai-studio.feedback.unpublish-question-public');
            if (this.hasFrontlineRole) {
                message = this.translateService.instant('ai-studio.feedback.unpublish-question-frontline');
            }
            const primaryButton = this.translateService.instant('wf-editor.alert.yes');
            const secondaryButton = this.translateService.instant('wf-editor.alert.cancel');

            this.dialogConfirm.open(message, primaryButton, secondaryButton).then(async () => {
                try {
                    this.trainingService.unpublishModel(this.selectedModel!).then(() => {
                        const eventModel = this.eventService.buildModel('Event,ai-training,unpublish-training', this.selectedModel!.aiProject?.projectUuid || '', this.selectedModel!.modelUuid || '');
                        this.eventService.submitEvent(eventModel);
                        this.aiStudioObservables.updateStatusPublishedModelHasChanged$(this.selectedModel!);
                        this.loadingService.hide();
                    }, (err) => {
                        console.log(err);
                        const errorMsg = this.translateService.instant('ai-studio.feedback.training-unpublish-failed', {modelName: this.selectedModel!.name});
                        this.selectedModel!.published = true;
                        console.error(errorMsg);
                        this.loadingService.hide();
                        this.notificationService.info(errorMsg);
                    });
                } catch (e) {
                    console.log('Error while unpublishing model.');
                    console.log(e);
                }
            }, (error) => {
                console.log(error);
            });
        }
    }

    openTrainingModel() {
        this.modalService.open(StartTrainingComponent, {
            animation: true,
            backdrop: 'static',
            size: 'col-5',
        });
    }

    openObjectDetectionMisclassifyModal = (datumViewWrapper: Datum) => {

        const modalInstance = this.modalService.open(ObjectDetectionMisclassified, {
            animation: true,
            size: 'col-12 no-padding',
            backdrop: 'static',
        });

        modalInstance.componentInstance.datumViewWrapper = datumViewWrapper;
        modalInstance.componentInstance.trainingImgHeight = this.selectedModel!.trainingResult?.trainingImgHeight;
        modalInstance.componentInstance.trainingImgWidth = this.selectedModel!.trainingResult?.trainingImgWidth;
        modalInstance.componentInstance.datumViewWrappers = this.datumViewWrappers;
    }

    openClassificationMisclassifyModal = (image: any) => {
        const modalInstance = this.modalService.open(ClassificationMisclassifiedComponent, {
            animation: true,
            size: 'col-12 no-padding',
        });

        modalInstance.componentInstance.image = image;
    }

    generateLineChartOption = (title: string, dataMap: any) => {
        const dat: any[] = [{data: [], type: 'line'}];
        const xAxes = dataMap.epoch || [];
        for (const key of Object.keys(dataMap)) {
            if (key !== 'epoch' && key !== '__jsogObjectId') {
                dat.push({type: 'line', name: key, data: dataMap[key]});
            }
        }
        // ataMap.
        return {
            title: {
                text: title,
                textStyle: {
                    fontSize: 14
                },
                x: 'center'
            },
            legend: {
                orient: 'vertical',
                x: 'right',
                top: '10%',
                borderWidth: '1',
                borderType: 'dashed',
                data: Object.keys(dataMap)
            },
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (serie: any) => {
                    return serie.seriesName + ' : ' + serie.value;
                }
            },
            xAxis: {
                type: 'category',
                data: xAxes
                // axisLabel: {
                //     rotate: 20,
                //     formatter: (value: any, index: number) => {
                //         if (value.length > 20) {
                //             value = value.substring(0, 20) + "...";
                //         }
                //         return value;
                //     }
                // }
            },
            yAxis: {
                type: 'value'
            },
            series: dat
        };
    }

    generateBarChartOption = (title: string, dataList: any, labelList: any) => {
        return {
            title: {
                text: title,
                textStyle: {
                    fontSize: 14
                },
                x: 'center'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params: any) => {
                    return params[0].name + ' : ' + params[0].value;
                }
            },
            xAxis: {
                type: 'category',
                data: labelList,
                axisLabel: {
                    rotate: 20,
                    formatter: (value: any, index: number) => {
                        if (value.length > 20) {
                            value = value.substring(0, 20) + '...';
                        }
                        return value;
                    }
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: dataList,
                type: 'bar'
            }]
        };
    }

    showUuid = () => {
        const message = this.translateService.instant('ai-studio.model.tab.show-uuid.desc', {
            modelUuid: this.selectedModel!.modelUuid
        });
        const primaryButton = this.translateService.instant('wf-editor.alert.ok');

        this.dialogConfirm.open(message, primaryButton, '').then(async () => {});
    }

    deleteTraining = () => {
        if (this.selectedModel) {
            const message = this.translateService.instant('ai-studio.model.training.delete');
            const primaryButton = this.translateService.instant('wf-editor.alert.yes');
            const secondaryButton = this.translateService.instant('wf-editor.alert.cancel');

            this.dialogConfirm.open(message, primaryButton, secondaryButton).then(async () => {
                try {
                    let eventAction = 'initialize-delete-training';
                    if (this.selectedModel!.trainingStatus! === 'TRAINING' || this.selectedModel!.trainingStatus! === 'NOT_STARTED') {
                        eventAction = 'initialize-cancel-training';
                    }
                    const eventModel = this.eventService.buildModel('Event,ai-training,' + eventAction, this.selectedModel!.aiProject?.projectUuid || '', this.selectedModel!.modelUuid || '');
                    this.eventService.submitEvent(eventModel);
                    this.trainingService.deleteTraining(this.selectedModel!).
                    then(() => {
                        const models = this.aiProjectService.getAiProject()?.models?.filter((model: Model) => model.id !== this.selectedModel!.id) || [];

                        this.aiProjectService.setTrainingModels(models);
                        if (models.length > 0) {
                            this.loadModel(models[0], true);
                        }
                    });
                } catch (e) {
                    console.log('Error while deleting training.');
                    console.log(e);
                }
            }, (error) => {
                console.log(error);
            });
        }
    }

    dateFormatter = (dateInMS: number) => {
        return moment(dateInMS).format('L LT');
    }

    hasRole(role: string): boolean {
        return this.authenticationService.hasRole(role);
    }


    hasTrainLogs(): boolean {
        return Object.keys(this.trainingLogs).length > 0;
    }

    compareNames( a: any, b: any ) {
        if (a.name === BACKGROUND || b.name === BACKGROUND) {
            console.log('compareNames', a.name, b.name);
            return 0;
        }
        if ( a.name < b.name ){
            return -1;
        }
        if ( a.name > b.name ){
            return 1;
        }
        return 0;
    }
}
