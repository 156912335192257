import {APP_BASE_HREF, CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {InitService} from './init.service';
import {AuthenticationService} from "services/authentication.service";
import {ComponentsModule} from '@ubimax/frontend-resources/components';
import {OAuthNoopResourceServerErrorHandler} from "global/oauth-error.service";
import {FormFieldInput} from 'directives/form-field-input.directive';
import {AgGridModule} from 'ag-grid-angular';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    NgbAccordionModule,
    NgbActiveModal,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbModule,
    NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {VirtualScrollerModule} from '@iharbeck/ngx-virtual-scroller';
import {TagInputModule} from 'ngx-chips';
import {Config} from './config';
import {DialogConfirm, DialogConfirmComponent} from 'dialogs/dialog-confirm';
import {DayJsService} from 'services/day-js.service';
import {NotificationService} from 'services/notification.service';
import {LoadingService} from 'services/loading.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TableScrollDirective} from 'directives/tablescroll.directive';
import {GridGlobalConfig} from 'global/grid.global.config';
import {LightboxComponent, LightboxDirective} from 'directives/lightbox.directive';
import {I18nService} from 'services/i18n.service';
import {OverlayModule} from '@angular/cdk/overlay';
import {ClickOutsideModule} from 'ng-click-outside';
import {SlideOutAppMenuComponent} from 'components/slide-out-menu/slide-out-app-menu.component';
import {TopMenuComponent} from 'components/top-menu/top-menu.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {OrderByPipe} from 'pipes/order-by.pipe';
import {TagViewComponent} from "components/tag-view/tag-view.component";
import {BoundingBoxHasClassPipe} from 'pipes/bounding-box-has-class.pipe';
import {JsogInterceptor} from "global/interceptors/JsogInterceptor";
import {HotkeyModule} from 'angular2-hotkeys';

import {OAuthModule, OAuthModuleConfig, OAuthResourceServerErrorHandler} from 'angular-oauth2-oidc';
import {UniqueValuesPipe} from 'pipes/unique-values';
import {SafeHtmlPipe} from 'pipes/safeHtml';
import {TrainingService} from 'services/aiStudio/training.service';
import {AnimateNumbersDirective} from 'directives/animate-numbers.directive';
import {UserProfileComponent} from "components/user-profile/user-profile.component";
import {UserService} from "services/aiStudio/user.service";
import {authConfigFactory} from "global/auth-config-factory";
import {EventDirective} from "directives/event.directive";
import {EventService} from "services/event.service";
import {CsrfInterceptor} from "global/interceptors/CsrfInterceptor";
import {AddAlphaPipe} from "pipes/addAlpha.pipe";
import {UploadDirective} from "directives/upload.directive";
import {RouterModule} from "@angular/router";
import {ValidationModule} from "validation/validation.module";
import {AiTagComponent} from "components/ai-tag/ai-tag.component";
import {ToastContainerModule, ToastrModule} from "ngx-toastr";

export function initFactory(initService: InitService): () => void {
    return () => initService.init();
}

function initTranslationServiceFactory(initService: InitService): () => void {
    return () => initService.initTranslationService();
}

function getBaseHREF(config: Config): string {
    return config.properties.contextPath;
}

function httpLoaderFactory(http: HttpClient, config: Config): TranslateHttpLoader {
    return new TranslateHttpLoader(http, `${config.baseUrl}/messageBundle/get?lang=`, '');
}

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        AgGridModule,
        BrowserAnimationsModule,
        ComponentsModule,
        FormsModule,
        NgbModalModule,
        NgbDatepickerModule,
        NgbModule,
        NgbAccordionModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        VirtualScrollerModule,
        TagInputModule,
        AngularMultiSelectModule,
        ClickOutsideModule,
        NgSelectModule,
        NgbDropdownModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient, Config]
            }
        }),
        ToastrModule.forRoot({
            timeOut: 3000,
            closeButton: true,
            progressBar: false,
            preventDuplicates: false,
            countDuplicates: false,
            resetTimeoutOnDuplicate: true,
        }),
        ToastContainerModule,
        HotkeyModule.forRoot(),
        OAuthModule.forRoot(),
        RouterModule,
        ValidationModule
    ],
    declarations: [
        FormFieldInput,
        DialogConfirmComponent,
        TableScrollDirective,
        LightboxDirective,
        LightboxComponent,
        SlideOutAppMenuComponent,
        TopMenuComponent,
        TagViewComponent,
        OrderByPipe,
        BoundingBoxHasClassPipe,
        UniqueValuesPipe,
        SafeHtmlPipe,
        AnimateNumbersDirective,
        UserProfileComponent,
        AddAlphaPipe,
        UploadDirective,
        EventDirective,
        AiTagComponent
    ],
    exports: [
        CommonModule,
        BrowserModule,
        TranslateModule,
        ComponentsModule,
        FormFieldInput,
        AgGridModule,
        FormsModule,
        NgbModalModule,
        NgbDatepickerModule,
        NgbModule,
        NgbAccordionModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        TableScrollDirective,
        LightboxDirective,
        OverlayModule,
        ClickOutsideModule,
        SlideOutAppMenuComponent,
        TopMenuComponent,
        TagViewComponent,
        NgSelectModule,
        OrderByPipe,
        BoundingBoxHasClassPipe,
        UniqueValuesPipe,
        SafeHtmlPipe,
        AnimateNumbersDirective,
        AddAlphaPipe,
        UploadDirective,
        EventDirective,
        RouterModule,
        ValidationModule,
        AiTagComponent
    ],
    providers: [
        InitService,
        AuthenticationService,
        UserService,
        DayJsService,
        NotificationService,
        LoadingService,
        I18nService,
        DialogConfirm,
        NgbActiveModal,
        Config,
        GridGlobalConfig,
        TrainingService,
        EventService,
        { provide: OAuthModuleConfig , useFactory: authConfigFactory, deps: [Config] },
        {
            provide: OAuthResourceServerErrorHandler,
            useClass: OAuthNoopResourceServerErrorHandler
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initFactory,
            deps: [InitService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initTranslationServiceFactory,
            deps: [InitService],
            multi: true
        },
        {
            provide: APP_BASE_HREF,
            useFactory: getBaseHREF,
            deps: [Config]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JsogInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CsrfInterceptor,
            multi: true,
        }
    ],
    entryComponents: [
        DialogConfirmComponent,
        LightboxComponent,
        UserProfileComponent
    ]
})
export class GlobalModule {
}
