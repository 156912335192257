import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {EventService} from "services/event.service";
import {EventModel} from "@teamviewer/aistudioapi-aistudio-angular";

@Directive({
    selector: '[event]'
})
export class EventDirective implements OnInit{

    @Input() event = '';
    @Input() projectuuid = '';
    @Input() modeluuid = '';
    constructor(private el: ElementRef, private eventService: EventService) {
    }

    ngOnInit(): void {
    }

    @HostListener('click')
    async onClick() {
        let model: EventModel = this.eventService.buildModel(this.event, this.projectuuid, this.modeluuid);
        await this.eventService.submitEvent(model);
    }



}
