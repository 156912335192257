import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {AiProject} from "@teamviewer/aistudioapi-common-angular";
import {ProjectWizardComponent} from "components/modals/project-wizard/project-wizard.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ngxSkeletonTheme, OrderBy} from "utils/project-utils";
import {NotificationService} from "services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";

@Component({
    selector: 'home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit{

    private aiProjects: AiProject[] = [];

    public gridSize = 4;
    searchExpression = '';

    aiProjectsFiltered: AiProject[] = [];

    isLoading = true;

    ngxSkeletonTheme = ngxSkeletonTheme;

    OrderBy = OrderBy

    constructor(private aiProjectService: AiProjectService, private modalService: NgbModal, private notificationService: NotificationService, private translateService: TranslateService, private aiStudioObservables: AiStudioObservables) {
        this.aiStudioObservables.STATUS_PROJECT_DELETED$.subscribe((projectUuid) => {
            this.aiProjects = this.aiProjects.filter((project: AiProject) => project.projectUuid !== projectUuid)
            this.filterProjects();
        });
    }

    async ngOnInit(){
        await this.aiProjectService.getAllAiProjects().then(result => {
            if(result && result.length > 0) {
                this.aiProjects = result;
            }
        }, () => {
            this.notificationService.info(this.translateService.instant('ai-studio.feedback.cannot-get-projects'));
        });

        this.filterProjects();
        this.isLoading = false;

    }

    createNewProjectWizard = (aiProject?:any) => {
        let modalInstance = this.modalService.open(ProjectWizardComponent,  {
            animation: true,
            size: 'col-8',
            backdrop: 'static',
            keyboard: false
        });

        modalInstance.componentInstance.aiProject = aiProject;
    };

    filterProjects() {
        if(this.searchExpression && this.searchExpression.length > 0) {
            this.aiProjectsFiltered = this.aiProjects.filter((project: AiProject) =>
                project.name?.toLowerCase().includes(this.searchExpression.toLowerCase()));
        } else {
            this.aiProjectsFiltered = this.aiProjects;
        }
    }

}
