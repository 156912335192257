<div id="tab-models" style="height: calc(100vh - 50px);">

    <div class="height100 row" style="margin: 0;"
    [style.display]="aiProjectService.getAiProject()?.models.length > 0 ? 'flex' : 'none'">
        <!-- Model List -->
        <div class="col-sm-4 col-md-3 height100 left-panel">
            <h2>{{ 'ai-studio.model.tab' | translate }}</h2>
            <div class="guidance-container">
                <button-primary
                    class="start-training-btn"
                    id="start-training-btn-training-tab"
                    text="{{ 'ai-studio.model.tab.new-training' | translate }}"
                    (click)="openTrainingModelWithAugmentationCheck();">
                </button-primary>
            </div>

            <div class="col-sm-12 propertyTitle  top-buffer">
                <div class="propertyPanelTitle">
                    <i class="icon icon-bar-chart" aria-hidden="true"></i>&nbsp;
                    {{ 'ai-studio.model.tab.trainings' | translate }}
                </div>

                <div>
                    <div>
                        <p>{{ 'ai-studio.model.sort-by' | translate }}</p>
                        <ng-select class="select"
                                   [(ngModel)]="selectedSortingSetting"
                                   (ngModelChange)="updateTrainingSorting(selectedSortingSetting)">
                            <ng-option *ngFor="let opt of trainingSortingSettings" [value]="opt.id">{{'ai-studio.model.sort-by.' + opt.translation  | translate }}</ng-option>
                        </ng-select>
<!--                        <div class="button-text-input-group button-text-input-group-small">
                            <div class="btn-group" ngbDropdown>
                                <button id="training-sorter" type="button" ngbDropdownToggle>
                                    <span class="btn-text">
                                        {{ 'ai-studio.model.sort-by.' + trainingSortingValue | translate }}
                                    </span>
                                </button>
                                <ul class="dropdown-menu" ngbDropdownMenu role="menu" aria-labelledby="training-sorter">
                                    <li
                                        ngbDropdownItem
                                        role="menuitem"
                                        (click)="trainingSortingValue = 'startLast'; updateTrainingSorting(trainingSortingValue)">
                                        <a>{{ 'ai-studio.model.sort-by.startLast' | translate }}</a>
                                    </li>
                                    <li
                                        ngbDropdownItem
                                        role="menuitem"
                                        (click)="trainingSortingValue = 'startFirst'; updateTrainingSorting(trainingSortingValue)">
                                        <a>{{ 'ai-studio.model.sort-by.startFirst' | translate }}</a>
                                    </li>
                                    <li
                                        ngbDropdownItem
                                        role="menuitem"
                                        (click)="trainingSortingValue = 'precisionHighestFirst'; updateTrainingSorting(trainingSortingValue)">
                                        <a>{{ 'ai-studio.model.sort-by.precisionHighestFirst' | translate }}</a>
                                    </li>
                                    <li
                                        ngbDropdownItem
                                        role="menuitem"
                                        (click)="trainingSortingValue = 'precisionLowestFirst'; updateTrainingSorting(trainingSortingValue)">
                                        <a>{{ 'ai-studio.model.sort-by.precisionLowestFirst' | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>-->
                    </div>
                    <div *ngIf="aiProjectService.getAiProject()" class="row top-buffer">
                        <div *ngFor="let model of aiProjectService.getAiProject().models" class="col col-sm-12" style="padding:0">
                            <div [ngClass]="{'iterationCardSelected': model?.id === selectedModel?.id}" class="iteration-card no-focus" (click)="selectModel(model)">
                                <div class="iteration-card-title row" style="margin: 0;">
                                    <div class="col-sm-10">
                                        {{model.name}}
                                        <span *ngIf="model.published === true" class="label label-success version-badge" style="top: -2px; position: inherit">
                                            {{ 'wf-editor.wf-panel.status.published' | translate }}
                                        </span>
                                    </div>
                                    <div class="col-sm-2" style="text-align: right;">
                                        <i class="icon icon-ellipsis-h pull-right"
                                           *ngIf="model.trainingStatus === NOT_STARTED"
                                           [ngbTooltip]="'ai-studio.model.not-started.icon-tooltip' | translate"  container="body" placement="top" >
                                        </i>

                                        <svg class="aistudio-loading-animation"
                                             *ngIf="model.trainingStatus === TRAINING"
                                             style="width: 18px; height: 18px;"
                                             [ngbTooltip]="'ai-studio.model.training.icon-tooltip' | translate" container="body" placement="top">
                                            <use xlink:href="#aistudio-loading-logo"></use>
                                        </svg>

                                        <i class="icon icon-times-circle pull-right text-danger"
                                           *ngIf="model.trainingStatus === FAILED"
                                            container="body"
                                            placement="top"
                                           [ngbTooltip]="'ai-studio.model.training-failed.icon-tooltip' | translate">
                                        </i>
                                        <i class="icon icon-check-circle pull-right"
                                           *ngIf="model.trainingStatus === TRAINED"
                                            container="body"
                                            placement="top"
                                           [ngbTooltip]="'ai-studio.model.trained.icon-tooltip' | translate">
                                        </i>
                                    </div>
                                </div>
                                <div class="iteration-card-details">
                                    <span *ngIf="model.trainingStatus === NOT_STARTED">
                                        {{ 'ai-studio.model.not-started.desc' | translate : {'createdAt' : dateFormatter(model.createdAt), 'amount': model.modelMetadata.totalCount} }}
                                    </span>
                                    <span *ngIf="model.trainingStatus === TRAINING">
                                        {{ 'ai-studio.model.training.desc' | translate : {'createdAt' : dateFormatter(model.createdAt), 'amount': model.modelMetadata.totalCount} }}
                                    </span>
                                    <span *ngIf="model.trainingStatus === FAILED">
                                        {{ 'ai-studio.model.training-failed.desc' | translate : {'modelId' : model.id} }}
                                    </span>
                                    <span *ngIf="model.trainingStatus === TRAINED">
                                        {{ 'ai-studio.model.trained.desc' | translate : {'createdAt' : dateFormatter(model.createdAt), 'amount': model.modelMetadata.totalCount, 'precision': (model.trainingResult?.meanAveragePrecision! * 100 | number:0)} }}
                                    </span>
                                    <br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Model Details View -->
        <div id="model-details" ngbDropdown class="col-sm-8 col-md-9 height100" *ngIf="selectedModel && selectedModel.hasOwnProperty('name')" style="padding-right: 0;">
            <!-- Controller Bar -->
            <div class="control-bar">
                <div class="guidance-container pull-left right-buffer">
                    <button-secondary
                        id="publish-training-btn"
                        icon="cloud-upload"
                        text="{{ 'ai-studio.model.tab.publish' | translate }}"
                        [disabled]="selectedModel.trainingStatus !== TRAINED"
                        *ngIf="!selectedModel.published"
                        (click)="publishModel()">
                    </button-secondary>
                </div>
                <button-secondary
                    id="unpublish-training-btn"
                    icon="cloud-x"
                    text="{{ 'ai-studio.model.tab.unpublish' | translate }}"
                    *ngIf="selectedModel.trainingStatus === TRAINED && selectedModel.published"
                    (click)="unpublishModel()">
                </button-secondary>
                <button-secondary
                    *ngIf="hasRole('Frontline_AI_User')"
                    text="{{ 'ai-studio.model.tab.show-uuid' | translate }}"
                    [disabled]="selectedModel.trainingStatus !== TRAINED"
                    (click)="showUuid()">
                </button-secondary>
                <button-secondary
                    icon="trash"
                    text="{{ 'ai-studio.model.tab.delete' | translate }}"
                    (click)="deleteTraining()">
                </button-secondary>
            </div>

            <!-- Model Details -->
            <div id="model-details-content">
                <h3 style="font-weight: bold; color: #7b7b7c;">
                    {{ selectedModel.name }}
                    <i class="icon icon-pencil" (click)="openRenameTrainingModal();" style="cursor: pointer;"></i>
                    <i *ngIf="(selectedModel.extraOptions | keyvalue)?.length" class="icon icon-info" (click)="openOptionsInfoModal();" style="cursor: pointer;"></i>
                </h3>
                <!-- Overall Status -->
                <div id="modal-status" class="row" *ngIf="selectedModel.trainingStatus === TRAINED" style="margin: 0;">
                    <!-- Precision -->
                    <div class="panel statscard" [ngClass]="{ 'panel-danger' : selectedModel.trainingResult?.meanAveragePrecision <= 0.5, 'panel-warning' :
                                                                                selectedModel.trainingResult?.meanAveragePrecision > 0.5 && selectedModel.trainingResult?.meanAveragePrecision < 0.8, 'panel-green' :
                                                                                 selectedModel.trainingResult?.meanAveragePrecision >= 0.8 }">
                        <div class="panel-heading">
                            <div class="row">
                                <div class="col-xs-3">
                                    <i class="icon icon-3x icon-dashboard"></i>
                                </div>
                                <div class="col-xs-9 text-right">
                                    <div class="medium">
                                        <span
                                            animate-numbers
                                            number="{{ selectedModel.trainingResult?.meanAveragePrecision! * 100 | number:0 }}"
                                            slowdown="false">
                                        </span> %
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 text-right">
                                    <div>
                                        {{ 'ai-studio.model.overall-precision' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Images -->
                    <div class="panel statscard panel-green">
                        <div class="panel-heading">
                            <div class="row">
                                <div class="col-xs-3">
                                    <i class="icon icon-3x icon-file-image-o"></i>
                                </div>
                                <div class="col-xs-9 text-right">
                                    <div class="medium">
                                        <span
                                            animate-numbers
                                            number="{{ selectedModel.modelMetadata.totalCount }}"
                                            slowdown="false">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 text-right">
                                    <div>{{ 'ai-studio.model.images' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Training Time -->
                    <div class="panel statscard panel-green">
                        <div class="panel-heading">
                            <div class="row">
                                <div class="col-xs-3">
                                    <i class="icon icon-3x icon-clock-o"></i>
                                </div>
                                <div class="col-xs-9 text-right">
                                    <div class="medium" *ngIf="trainingDays > 0">
                                        <span
                                            animate-numbers number="{{ trainingDays }}"
                                            slowdown="false">
                                        </span>
                                        &nbsp;{{ 'ai-studio.model.training.day' | translate }}
                                        <span
                                            animate-numbers number="{{ trainingHours }}"
                                            slowdown="false">
                                        </span>
                                        &nbsp;{{ 'ai-studio.model.training.hour' | translate }}
                                    </div>
                                    <div class="medium" *ngIf="trainingDays === 0 && trainingHours > 0">
                                        <span
                                            animate-numbers number="{{ trainingHours }}"
                                            slowdown="false">
                                        </span>
                                        &nbsp;{{ 'ai-studio.model.training.hour' | translate }}
                                        <span
                                            animate-numbers number="{{ trainingMinutes }}"
                                            slowdown="false">
                                        </span>
                                        &nbsp;{{ 'ai-studio.model.training.minute' | translate }}
                                    </div>
                                    <div class="medium" *ngIf="trainingDays === 0 && trainingHours === 0 && trainingMinutes > 0">
                                        <span
                                            animate-numbers number="{{ trainingMinutes }}"
                                            slowdown="false">
                                        </span>
                                        &nbsp;{{ 'ai-studio.model.training.minute' | translate }}
                                        <span
                                            animate-numbers number="{{ trainingSeconds }}"
                                            slowdown="false">
                                        </span>
                                        &nbsp;{{ 'ai-studio.model.training.second' | translate }}
                                    </div>
                                    <div class="medium" *ngIf="trainingDays === 0 && trainingHours === 0 && trainingMinutes == 0 && trainingSeconds > 0">
                                        <span
                                            animate-numbers number="{{ trainingSeconds }}"
                                            slowdown="false">
                                        </span>
                                        &nbsp;{{ 'ai-studio.model.training.second' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 text-right">
                                    <div>
                                        {{ 'ai-studio.model.training-time' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div *ngIf="selectedModel.trainingStatus === NOT_STARTED">
                        {{ 'ai-studio.model.training.waiting-in-queue' | translate }}
                    </div>

                    <div *ngIf="selectedModel.trainingStatus === FAILED">
                        {{ 'ai-studio.model.training.failed' | translate }}
                        <p>{{selectedModel.trainingError?.errorMessage}}</p>
                    </div>

                    <div *ngIf="selectedModel.trainingStatus === TRAINING">
                        {{ 'ai-studio.model.training.started' | translate }}
                    </div>

                    <div *ngIf="selectedModel.trainingStatus === TRAINED">
                        <!-- Bad Training Result -->
                        <span *ngIf="selectedModel.trainingResult?.meanAveragePrecision! < 0.8">
                            {{ 'ai-studio.model.training.finished.bad' | translate }}
                            <ul>
                                <li>{{ 'ai-studio.model.training.improvement.verify-context' | translate }}</li>
                                <li *ngIf="!aiProjectService.isObjectDetectionProject()">{{ 'ai-studio.model.training.improvement.verify-label' | translate }}</li>
                                <li *ngIf="aiProjectService.isObjectDetectionProject()">{{ 'ai-studio.model.training.improvement.verify-bounding-box' | translate }}</li>
                                <li *ngIf="!selectedModel.modelMetadata.balanced">{{ 'ai-studio.model.training.improvement.train-balancely' | translate }}</li>
                                <li>{{ 'ai-studio.model.training.improvement.add-more-images' | translate }}</li>
                                <li>{{ 'ai-studio.model.training.improvement.delete-blurry-images' | translate }}</li>
                            </ul>
                        </span>

                        <!-- Good Training Result -->
                        <span *ngIf="selectedModel.trainingResult?.meanAveragePrecision! >= 0.8 && hasFrontlineRole">
                            {{ 'ai-studio.model.training.finished.good-frontline' | translate }}
                        </span>
                        <span *ngIf="selectedModel.trainingResult?.meanAveragePrecision! >= 0.8 && !hasFrontlineRole">
                            {{ 'ai-studio.model.training.finished.good-free' | translate }}
                        </span>
                    </div>
                </div>

                <div class="top-buffer-20">
                    <a (click)="changeModelDetails()" style="cursor: pointer">
                        <span *ngIf="!trainingService.showTrainingDetails">
                            {{ 'ai-studio.model.show-details' | translate }}
                        </span>
                        <span *ngIf="trainingService.showTrainingDetails">
                            {{ 'ai-studio.model.hide-details' | translate }}
                        </span>
                    </a>
                    <div id="model-charts" class="top-buffer panel-collapse collapse in" [(ngbCollapse)]="!trainingService.showTrainingDetails">
                        <!-- Charts -->
                        <div #precisionChart class="model-chart"  [style.display]="selectedModel.trainingStatus === TRAINED ? 'inline-block' : 'none'" ></div>
                        <div #sourceChart class="model-chart"></div>
                        <div #logChart class="model-chart" [style.display]="hasTrainLogs() ? 'inline-block' : 'none'"></div>

                        <!-- Mis-classified Images -->
                        <div *ngIf="showMissClassifiedImages">
                            <div class="mis-classified top-buffer" style="padding-bottom: 10px;">
                                <p style="color: black; font-size: 15px;">
                                    <b>{{ 'ai-studio.model.wrong-predictions' | translate }}</b>
                                </p>
                                <span>{{ 'ai-studio.model.wrong-predictions.desc1' | translate }}</span>
                                <br>
                                <span>{{ 'ai-studio.model.wrong-predictions.desc2' | translate }}</span>
                            </div>

                            <!-- (Image Classification) -->
                            <div *ngIf="!aiProjectService.isObjectDetectionProject()">
                                <div *ngFor="let misclassified of misclassifiedImages | keyvalue" class="top-buffer" style="overflow: auto;">
                                    <div>
                                        <b>{{ 'ai-studio.model.identified-as' | translate : {'className' : misclassified.key} }}</b>
                                    </div>
                                    <div class="training-content-block">
                                        <div *ngFor="let image of misclassified.value | orderBy: 'groundTruth' : undefined" class="dataSetItemTile dataSetItemTile-2">
                                            <div class="dataSetItemTileInnerWrapper">
                                                <div class="imgContainer">
                                                    <img
                                                        [src]="config.baseUrl + '/aiStorage/' + image.url | authImage | async"
                                                        class="img-responsive lazyload"
                                                        (dblclick)="openClassificationMisclassifyModal(image)"/>
                                                </div>
                                                <div class="dataSetItemTileDetails">
                                                    <div class="tags">
                                                        <div class="wf-tag badge">
                                                            <span class="tagText">{{ image.groundTruth }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- (Object Detection) -->
                            <div *ngIf="aiProjectService.isObjectDetectionProject()">
                                <div class="form-check" *ngFor="let filter of misclassificationFilters | orderBy:'name'">
                                    <checkbox id="{{ 'obj-detection-filter-' + filter.name }}" (onChange)="filter.selected=$event; updateFilter()" [checked]="filter.selected"></checkbox>
                                    <label class="tag-input-section" for="{{ 'obj-detection-filter-' + filter.name }}" style="cursor: pointer">
                                        <ai-tag
                                            [name]="filter.name"
                                            [color]="filter.color">
                                        </ai-tag>
                                    </label>
                                </div>

                                <div class="text-center" *ngIf="loadingMisclassifies">
                                    <svg class="frontline-loading-animation"><use xlink:href="#frontline"></use></svg>
                                </div>

                               <div *ngIf="!loadingMisclassifies" class="misclassified-row" infinite-scroll="loadMoreMisclassifieds()" infinite-scroll-distance="1" infinite-scroll-container="'#model-details-content'">
                                    <div *ngFor="let datumViewWrapper of datumViewWrappers" class="dataSetItemTile dataSetItemTile-sm-5" (dblclick)="openObjectDetectionMisclassifyModal(datumViewWrapper)">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="dualImgContainerWrapper">
                                                <div class="dualImgContainer">
                                                    <canvas
                                                        id="canvas_prediction_{{ datumViewWrapper.id }}"
                                                        class="img-responsive lazyload">
                                                    </canvas>
                                                </div>
                                                <div class="dualImgContainer">
                                                    <canvas
                                                        id="canvas_groundtruth_{{ datumViewWrapper.id }}"
                                                        class="img-responsive lazyload">
                                                    </canvas>
                                                </div>
                                            </div>
                                            <div class="dualDataSetDetailsWrapper">
                                                <div class="dualDataSetTitleWrapper">
                                                    <div class="dualDataSetTitleRight">
                                                        <span>Prediction</span>
                                                    </div>
                                                    <div class="dualDataSetTitleLeft">
                                                        <span>Ground Truth</span>
                                                    </div>
                                                </div>
                                                <div class="dataSetItemTileDetailsWrapper">
                                                    <div class="dataSetItemTileDetails dualDataSetRight">
                                                        <div class="tags" *ngIf="datumViewWrapper.predictionClasses.length > 0">
                                                            <div *ngFor="let aClass of datumViewWrapper.predictionClasses">
                                                                <ai-tag
                                                                    style="padding-left: 10px;"
                                                                    [name]="aClass.name"
                                                                    [color]="aClass.color">
                                                                </ai-tag>
                                                            </div>
                                                        </div>
                                                        <span *ngIf="datumViewWrapper.predictionClasses.length === 0" style="padding-left: 5px;"> No object predicted. </span>
                                                    </div>
                                                    <div class="dataSetItemTileDetails dualDataSetLeft">
                                                        <div class="tags">
                                                            <div *ngFor="let aClass of datumViewWrapper.groundTruthClasses" >
                                                                <ai-tag
                                                                    style="padding-left: 10px;"
                                                                    [name]="aClass.name"
                                                                    [color]="aClass.color">
                                                                </ai-tag>
                                                            </div>
                                                            <div *ngIf="datumViewWrapper.groundTruthClasses.length === 0">
                                                                <ai-tag
                                                                    style="padding-left: 10px;"
                                                                    [name]="'ai-studio.dataset.tab.background' | translate">
                                                                </ai-tag>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="no-training-overlay" class="no-training-overlay" *ngIf="aiProjectService.getAiProject()?.models.length == 0">
        <div class="jumbotron text-center">
            <h3>{{ 'ai-studio.model.tab.no.training.found' | translate }}</h3>
            <!--<p>{{ 'ai-studio.model.tab.start.a.training.now' | translate }}</p>-->
            <button-primary
                text="{{ 'ai-studio.model.tab.new-training' | translate }}"
                (click)="openTrainingModelWithAugmentationCheck();">
            </button-primary>
        </div>
    </div>
</div>
