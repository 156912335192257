<div id="tab-inference" class="row overflowHidden" style="height: calc(100vh - 50px);">
    <div class="col-sm-4 col-md-2 left-panel height100">
        <h2>{{ 'ai-studio.inference.tab' | translate }}</h2>


        <!-- Sorting -->
        <div class="col-sm-12 propertyTitle top-buffer">
            <div class="propertyPanelTitle">
                {{ 'ai-studio.dataset.tab.sorting' | translate }}
            </div>
            <ng-select class="select"
                       [(ngModel)]="datumService.selectedSortingSetting"
                       (ngModelChange)="changeInferenceSorting(datumService.selectedSortingSetting)">
                <ng-option *ngFor="let opt of datasetSortingSettings" [value]="opt.id">{{'ai-studio.dataset.sort-by.' + opt.translation  | translate }}</ng-option>
            </ng-select>
        </div>

        <!-- Filtering -->
        <div class="col-sm-12 propertyTitle">
            <div class="propertyPanelTitle" (click)="filterCollapsed = !filterCollapsed">
                <i class="icon" [ngClass]="{'icon-angle-right': filterCollapsed, 'icon-angle-down': !filterCollapsed}"></i>
                {{ 'ai-studio.dataset.tab.filtering' | translate }}
            </div>
            <!--        Tag Selection        -->
            <div *ngIf="!filterCollapsed">
                <div class="top-buffer">
                    <div>
                        <tag-view
                            [backgroundTagView]="tagViewService.getBackgroundInferenceTagView()"
                            [positiveTagViews]="tagViewService.getPositiveInferenceTagViews()"
                            [showIcons]="false"
                            [tabSelected]="'inference'">
                        </tag-view>
                    </div>
                </div>
            </div>
        </div>


        <!-- Min Confidence Slider-->
        <div class="col-sm-12 propertyTitle">
            <div class="propertyPanelTitle" (click)="confidenceSliderCollapsed = !confidenceSliderCollapsed">
                <i class="icon" [ngClass]="{'icon-angle-right': confidenceSliderCollapsed, 'icon-angle-down': !confidenceSliderCollapsed}"></i>
                Min. Confidence Level per Bounding Box
            </div>
            <div *ngIf="!confidenceSliderCollapsed">
                <div class="top-buffer">
                    <div class="confidence-slider">
                        <dataset-slider
                            class="dataset-slider"
                            [min]="0"
                            [max]="100"
                            [step]="5"
                            [(value)]="confidenceLevel"
                            [(ngModel)]="datumService.confidenceLevel"
                            (onValueUpdate)="datumService.confidenceLevel = $event"
                            (change)="updateDatumViewsByMinConfidence()"
                            name="fieldName" ngDefaultControl
                            [showIcon]="true"
                            [grid]="false">
                        </dataset-slider>
                        <p>Current Confidence Level: {{datumService.confidenceLevel}}%</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-8 col-md-10 height100 p-0">
        <!-- Tag and selection manipulation -->
        <div class="control-bar">
            <span class="dropdown" role="navigation" id="inferenceDropdownMenu" ngbDropdown>
                <button-secondary
                    ngbDropdownToggle
                    id="add-image-dropdown"
                    icon="caret-down"
                    [disabled]="activeDatums.length === 0"
                    text="{{ ('ai-studio.inference.tab.add-to-dataset' | translate) }} {{ activeDatums.length > 0 ? ( '(' + activeDatums.length + ')' ) : ''}}">
                </button-secondary>
                <ul class="dropdown-menu" ngbDropdownMenu aria-label="inferenceAddDropdown">
                   <li ngbDropdownItem>
                        <a (click)="addToDataset();" [ngbTooltip]="'ai-studio.inference.tab.add-to-wellset.tooltip' | translate" [placement]="'right'" [container]="'body'">
                            {{ "ai-studio.inference.tab.add-to-wellset" | translate }}
                        </a>
                   </li>
                    <li role="separator" class="divider"></li>
                   <li ngbDropdownItem>
                        <a (click)="addToDatasetExcludeBoundingBoxes();" [ngbTooltip]="'ai-studio.inference.tab.add-to-dataset-exclude-tags.tooltip' | translate" [placement]="'right'" [container]="'body'">
                            {{ aiProjectService.isObjectDetectionProject() ?
                            ("ai-studio.inference.tab.add-to-dataset-exclude-bounding-boxes" | translate) :
                            ("ai-studio.inference.tab.add-to-dataset-exclude-tags" | translate) }}
                        </a>
                   </li>
                 </ul>
            </span>

            <button-secondary
                icon="check" (click)="selectAll();"
                text="{{ 'ai-studio.select-all' | translate }}"
                [disabled]="datumService.getInferenceDatums().length === 0"
                *ngIf="activeDatums.length === 0" >
            </button-secondary>

            <button-secondary
                icon="times"
                (click)="deselectAllActiveImages();"
                text="{{ 'ai-studio.dataset.tab.deselect-all' | translate }}"
                *ngIf="activeDatums.length > 0" >
            </button-secondary>

            <button-secondary icon="pencil"
                              text="{{ 'ai-studio.edit-all' | translate }}"
                              [hidden]="!aiProjectService.isObjectDetectionProject() || activeDatums.length > 0"
                              (click)="editAll();"
                              [disabled]="datumService.datums.length === 0">
            </button-secondary>
            <button-secondary icon="pencil"
                              text="{{ 'ai-studio.edit-selected' | translate }}"
                              *ngIf="aiProjectService.isObjectDetectionProject() && activeDatums.length > 0"
                              (click)="editSelected();">
            </button-secondary>

            <button-secondary
                icon="trash"
                (click)="removeSelected()"
                text="{{( 'delete' | translate ) + ( '(' + activeDatums.length + ')' )}}"
                [disabled]="activeDatums.length === 0" >
            </button-secondary>

            <dataset-slider
                *ngIf="datumService.getInferenceDatums().length > 0"
                class="dataset-slider"
                [min]="2"
                [max]="6"
                [step]="1"
                [(value)]="datumService.zoomLevel"
                [(ngModel)]="datumService.zoomLevel"
                [showIcon]="true"
                [grid]="false"
                (onValueUpdate)="datumService.zoomLevel = $event"
                name="fieldName" ngDefaultControl>
            </dataset-slider>
        </div>

        <!-- inference data sets -->
        <div id="main_block_with_scroll_inference" class="dataSets" (click)="deselectAllActiveImages();" tabindex="-1">
            <div>
                <!-- Dataset -->
                <div *ngIf="datumService.getInferenceDatums().length > 0" class="dataset-content-block" role="presentation" tabindex="-1">
                    <div class="dataSetItemTile dataSetItemTile-{{ datumService.zoomLevel }}"
                    *ngFor="let datum of datumService.inferenceDatums; let index = id" [ngClass]="{ selected : activeDatumsIds.includes(datum.id)}">
                        <div class="dataSetItemTileInnerWrapper" (dblclick)="openImage([datum])" (click)="$event.stopPropagation(); selectImage($event, datum);">
                            <div class="imgContainer">
                                <canvas id="canvas_{{ datum.id }}" class="img-responsive lazyload"></canvas>
                                <div class="deleteIcon" (click)="deleteImage(datum); $event.stopPropagation()" role="presentation" tabindex="-1">
                                    <i class="icon icon-trash pull-right"></i>
                                </div>
                            </div>
                            <div class="dataSetItemTileDetails">
                                <div class="tags">
                                    <div class="wf-tag-bg" *ngFor="let label of datum.recognitions | orderBy : 'confidence' | uniqueValues: 'datumClass.id' ">
                                        <ai-tag
                                            [name]="label.datumClass.name+formatConfidence(label.confidence)"
                                            [color]="label.datumClass.color">
                                        </ai-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-wrapper" *ngIf="datumService.inferenceDatums.length > 0">
                    <ngb-pagination id="inferencePagination" [collectionSize]="datumService.totalDatums" [pageSize]="datumService.pageSize" [(page)]="currentPage"  [maxSize]="3" [rotate]="true" aria-label="datum pagination" (pageChange)="onPagination($event)" ></ngb-pagination>
                    <div class="pagination-page-size">
                        <div class="page-size-select">
                            <p>{{ 'ai-studio.pagination.pagesize' | translate }}</p>
                            <ng-select
                                class="pagination-page-size-item"
                                [items]="datumService.paginationPageSize"
                                (change)="changePaginationSize($event)"
                                [clearable]=false
                                [(ngModel)]="datumService.pageSize">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
