import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from 'global/config';
import {EventModel, EventModelType} from "@teamviewer/aistudioapi-aistudio-angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class EventService {

    constructor(private http: HttpClient, private config: Config, private translateService: TranslateService) {
    }

    public submitEvent(event:EventModel): Promise<any> | null {
        const endpoint = `/metrics`;
        return this.http.post(`${this.config.baseUrl}${endpoint}`, event).toPromise();
    }

    public buildModelWithDuration(definition: string, projectuuid: string, modeluuid: string, duration: number) : EventModel {
        let model = this.buildModel(definition, projectuuid, modeluuid);
        model.duration = duration;
        return model;
    }

    public buildModel(definition: string, projectuuid: string, modeluuid: string) : EventModel {
        let model:EventModel = {};

        let parts: string[] =  definition.split(",");

        model.type = <EventModelType> parts[0] ?? "Event";
        model.category = parts[1] ?? "";
        model.action = parts[2] ?? "";
        model.value = parts[3] ?? "";

        model.projectuuid = projectuuid;
        model.modeluuid = modeluuid;

        model.language = this.translateService.currentLang ?? navigator.language ?? 'en_US';
        model.os = window.navigator.platform;
        model.datasource = "webapp";
        model.geo = navigator.language;
        model.useragent = window.navigator.userAgent;
        model.app = this.config.properties.version ?? "";

        return model;
    }
}
