<div class="card card-hover" (click)="loadSelectedAiProject(aiProject?.projectUuid)">
    <div class="image-wrapper aspect-ratio-16-9 image-cover">
        <div class="image image-click">
            <div class="optionsIcon" role="presentation" tabindex="-1" >
                <div class="leftOptionsIcon">
                    <i class="icon icon-info" (click)="showInfo();  $event.stopPropagation();"></i>
                </div>
                <div class="rightOptionsIcon">
                    <i class="icon icon-pen" (click)="editProjectInWizard(); $event.stopPropagation();" style="padding-right: 5px;"></i>
                    <i class="icon icon-trash" (click)="showDeleteProjectWarning(aiProject); $event.stopPropagation();"></i>
                </div>

            </div>
            <img *ngIf="aiProjectPreviewDatumId !== undefined" class="img-responsive lazyloaded"
                 [src]="(config.baseUrl + '/aiStorage/'+ aiProject?.projectUuid + '/datum/' + aiProjectPreviewDatumId+ '/image?size=small'|authImage|async) || ''">
            <div *ngIf="aiProjectPreviewDatumId !== undefined" class="inner-card-shadow"></div>
            <div class="top-right">
                <i *ngIf="aiProject?.projectType === 'OBJECT_DETECTION'" class="icon icon-object-detection-project"></i>
                <i *ngIf="aiProject?.projectType === 'IMAGE_CLASSIFICATION_SINGLE_LABEL'" class="icon icon-classification-project"></i>
            </div>
            <div class="bottom-left">
                <i class="icon icon-project-images" aria-hidden="true"></i>&nbsp;<span [innerHTML]="">{{imageCount}} {{ 'ai-studio.model.images' | translate}}</span><br>
                <!--<p [innerHtml]="'ai-studio.dataset.tab.open-frontline-workplace' | translate"></p>-->

                <i class="icon icon-project-classes" aria-hidden="true"></i>&nbsp;<span [innerHTML]="">{{aiProject?.classes.length}} {{ 'ai-studio.start-training.object' | translate}}</span>
                <!--<p [innerHtml]="'ai-studio.dataset.tab.open-frontline-workplace' | translate"></p>-->
            </div>
            <div class="bottom-right">
                    <span class="project-status-tag">{{aiProjectTrainingStatus}}</span>
            </div>
        </div>
    </div>
    <div class="card-content">
        <h6 class="card-title">{{aiProject?.name}}</h6>
        <div *ngIf="this.modelResultPrecision  !== undefined" class="training-accuracy-tag">
            <i class="icon icon-model-accuracy" aria-hidden="true"></i>&nbsp;
            <span>{{ this.modelResultPrecision * 100 }}%</span>
        </div>

    </div>
<!--    <div class="card-actions">


    </div>-->
</div>
