import {Component, ElementRef, ViewChild, ViewEncapsulation} from "@angular/core";
//import { UIRouter } from "@uirouter/angular";
import {Router} from "@angular/router"
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {HighlightingService} from "services/aiStudio/highlighting.service";
import {TagViewService} from "services/aiStudio/tag-view.service";
import {DATASET} from "utils/project-utils";
import {AuthenticationService} from "services/authentication.service";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";

@Component({
    selector: 'ai-guidance',
    templateUrl: 'ai-guidance.component.html',
    styleUrls: ['ai-guidance.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class AiGuidanceComponent {
    totalStep = 4;
    currentStep = 1;
    lastVerifiedStep = 0;
    progress = "0";
    @ViewChild('getStarted', { static: false }) getStarted!: ElementRef;

    progressWidth: any;
    guidanceCollapsed = true;
    collapsInfo = [true, true, true, true];

    interval: any

    linkFrontlineCreator: string = "";

    private hasFrontlineRole: boolean;

    constructor(private aiProjectService: AiProjectService, private authenticationService: AuthenticationService, private tagViewService: TagViewService, private highlightingService: HighlightingService, private router: Router, private aiStudioObservables: AiStudioObservables) {
        this.hasFrontlineRole = this.authenticationService.hasRole(("Frontline_AI_User"));
        if(this.hasFrontlineRole) {
            this.linkFrontlineCreator = this.authenticationService.getFrontlineBaseUrl()+ "/frontline-creator.html";
        }
    }

    ngAfterViewInit() {
        this.interval = setInterval(() => {
            this.expandInitialStep();
        }, 5000);
    }

    toggleCollapse() {
        this.guidanceCollapsed = !this.guidanceCollapsed;
        //ToDO: utilize user preferences here
        /* frontlineAiService.userPreference.aiStudioPreference.guidanceCollapsed = this.guidanceCollapsed; */
        /* frontlineAiService.saveUserPreference(frontlineAiService.userPreference); */
        this.hideHighlighting();
     };

    expandInitialStep = () => {
        this.updateGuidanceStep()

    }

    updateProgress = () => {
        this.progress = (this.lastVerifiedStep - 1) / this.totalStep * 100 + "%";
    };

    updateStep = (step: number) => {
        this.lastVerifiedStep = step;
        this.currentStep = step
        this.updateProgress();
        this.expandCurrentStep();
    };

    expandCurrentStep = () => {
        this.collapsInfo = [true, true, true, true];
        this.collapsInfo[this.lastVerifiedStep - 1] = false;
    };

    toggleStep = (stepIndex: number) => {
        clearInterval(this.interval)

        this.hideHighlighting();
        for (let i = 0; i < this.collapsInfo.length; i++) {
            if (i === stepIndex) {
                this.collapsInfo[i] = !this.collapsInfo[i];
            } else {
                this.collapsInfo[i] = true;
            }
        }
        this.currentStep = stepIndex + 1;
     };


    // Update steps status
    updateGuidanceStep = () => {
        let step = 1;

        let aiProject = this.aiProjectService.getAiProject();
         // If project is loaded, step 1 is passed
        if (aiProject) {
            step = 2;

            // If all dataset is above minimum required images and balanced, step 2 is passed
            if ((this.aiProjectService.isSingleLabelProject() && this.tagViewService.getBelowMinimumAndTaggedTagViews().length === 0) || this.aiProjectService.isObjectDetectionProject() && this.tagViewService.getBelowMinimumAndWellSetTagViews().length === 0) {
                step = 3;

                // If there is a trained model, step 3 is passed
                if (this.aiProjectService.getTrainedTrainingModel()) {
                    step = 4;

                    // If there is a published model, step 4 is passed
                    if (this.aiProjectService.getPublishedTrainingModel()) {
                        step = 5;
                    }
                }
            }
        }
         this.updateStep(step);
    };

    hideHighlighting = () => {
        this.highlightingService.activeHighlighting = false;
        this.highlightingService.hide();
    };

    findLocation() {
        return this.router.url
    }

    //Add Images Tip
    showOrHideAddImageTip= () => {
        if(!this.highlightingService.activeHighlighting) {
            //Change view to dataset tab
            this.router.navigate([DATASET])
            this.highlightingService.show("#add-image-btn");
            this.highlightingService.activeHighlighting = true;

        } else {
            this.hideHighlighting();
        }
    };

    //New Training Tip
    showOrHideNewTrainingTip = () => {
        const selectedTag = this.findLocation();

        if(!this.highlightingService.activeHighlighting) {
            const models = this.aiProjectService.getAiProject()?.models || [];

            if (models.length === 0 && selectedTag == 'training') {
                this.highlightingService.show("#no-training-overlay");
            } else if (selectedTag == DATASET) {
                this.highlightingService.show("#start-training-btn-dataset-tab");
            } else if (selectedTag == 'training') {
                this.highlightingService.show("#start-training-btn-training-tab");
            } else {
                //this.router.stateService.go(DATASET);
                this.router.navigate([DATASET]);
                this.highlightingService.show("#start-training-btn-dataset-tab");
            }
            this.highlightingService.activeHighlighting = true;
        } else {
            this.hideHighlighting();
        }
    };

    showOrHidePublishTrainingTip = () => {
        if(!this.highlightingService.activeHighlighting) {
            const models = this.aiProjectService.getAiProject()?.models || [];
            this.router.navigate(['training']).then(() => {
                if(models[0].published) {
                    this.highlightingService.show("#unpublish-training-btn");
                } else {
                    this.highlightingService.show("#publish-training-btn");
                }

                this.highlightingService.activeHighlighting = true;
            });
        } else {
            this.hideHighlighting();
        }
    };
}
