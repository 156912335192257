<div id="uploaderPage" class="ui-view-content container-fluid no-padding" tableScroll>
    <div class="col col-sm-12">
        <div class="row">
            <div class="col col-sm-12 p-0">
                <div class="dotted drop-area" [hidden]="!enableProgressBar">
                    <h6 *ngIf="!showUploadReport">{{ 'ai-studio.feedback.uploader.is-uploading' | translate }}<br></h6>
                    <h6 *ngIf="showUploadReport">{{ 'ai-studio.feedback.uploader.upload-completed' | translate }}<br></h6>
                    <li class="card-progress-bar" [hidden]="showUploadReport"  style="list-style: none">
                        <div class="progress-outer" [attr.data-label]="progressFilesUploadContent()">
                            <div class="progress-inner" [style.width.%]="getCompletedProgressPercentage()">
                            </div>
                        </div>
                    </li>
                    <div *ngIf="showUploadReport" class="upload-report">
                        <div class="row">
                            <div *ngIf="report.successes > 0" class="col-4 upload-successes">
                                <span>
                                    <i [ngbTooltip]="'ai-studio.feedback.uploader.uploaded-tooltip' | translate" class="icon icon-check"></i>{{report.successes}}
                                </span>
                            </div>
                            <div *ngIf="report.duplicates > 0" class="col-4 upload-duplicates">
                                <span>
                                    <i  [ngbTooltip]="'ai-studio.feedback.uploader.duplicate-detected-tooltip' | translate" class="icon icon-warning"></i>{{report.duplicates}}
                                </span>
                            </div>
                            <div *ngIf="report.maxSizeExceeded > 0" class="col-4 upload-fails">
                                <span>
                                    <i  [ngbTooltip]="'ai-studio.feedback.uploader.max-size-error-tooltip' | translate" class="icon icon-error"></i>{{report.maxSizeExceeded}}
                                </span>
                            </div>
                            <div *ngIf="report.annotationErrors > 0" class="col-4 annotation-fails">
                                <span>
                                    <i  [ngbTooltip]="'ai-studio.feedback.uploader.annotation-file-error' | translate" class="icon icon-file-alt"></i>{{report.annotationErrors}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dotted drop-area" [hidden]="enableProgressBar || showUploadReport || !isImageUpload" appUpload (click)="fileInput.click()" (onFileDropped)="selectFiles($event)">
                    <p>{{'app.xpick.images.drop-files' | translate }}</p>
                    <button-primary icon="upload" text="{{ 'ai-studio.import-dialog.select-images' | translate }}" (click)="selectFiles($event)">
                    </button-primary>
                    <p class="max-size-hint">{{'ai-studio.import-dialog.upload-images.max-file-size-hint' | translate }}</p>
                </div>
                <input hidden type="file" #fileInput multiple (change)="selectFiles($event)" (click)="resetFilePicker($event)" accept="video/*, image/*">
            </div>
            <div id="annotationUpload" [hidden]="enableProgressBar || showUploadReport || files.length === 0 || !hasFrontlineRole || !isImageUpload">
                <div class="dotted drop-area" appUpload (onFileDropped)="selectAnnotationFiles($event)">
                    <div class="annotationDropArea">
                        <div style="width: 40%">
                            <ng-select [items]="supportedAnnotationFormats"
                                       placeholder="{{'ai-studio.import-dialog.annotations.format-selection-placeholder' | translate }}"
                                       bindLabel="name"
                                       bindValue="id"
                                       [searchable]="false"
                                       [clearable]="false"
                                       (change)="clearAllAnnotations()"
                                       [(ngModel)]="selectedAnnotationFormat">
                            </ng-select>
                        </div>
                        <div class="dotted vSeparator"></div>
                        <div style="padding-left: 5px" (click)="annotationFileInput.click()">
                            <p>{{'ai-studio.import-dialog.annotations.drop-files' | translate }}</p>
                            <button-primary icon="upload" text="{{'ai-studio.import-dialog.annotations.select-files' | translate }}" (click)="selectAnnotationFiles($event)">
                            </button-primary>
                            <p class="max-size-hint">{{'ai-studio.import-dialog.annotations.format-hint' | translate }}</p>
                        </div>
                        <input hidden type="file" #annotationFileInput multiple (change)="selectAnnotationFiles($event)" accept=".json,.txt,.names">
                    </div>
                </div>
            </div>
        </div>
        <div class="row" [hidden]="files.length <= 0">
            <div class="col col-sm-9 p-0">
                <h2>{{ 'app.xpick.images.preview' | translate }}</h2>
            </div>
        </div>
        <div class="row" style="margin-bottom: 180px;">
            <div class="col" *ngFor="let anUploadItem of files; let $index = index;" [ngClass]="$index <= imagePreviewSize ? 'm-b-15' : 'm-b-0'" style="padding-right: 30px;">
                <image-card [hidden]="$index >= imagePreviewSize" #imageCardComponent [uploadItem]="anUploadItem" (removeEntry)="removeEntry($event)" (finishedUpload)="finishedUpload($event)"></image-card>
            </div>
            <button-primary class="align-button-center" [hidden]="files.length === 0 || imagePreviewSize > files.length" icon="angle-down" (click)="increaseImagePreviewSize()" text="{{ 'ai-studio.import-dialog.expand-more-previews' | translate }}"></button-primary>
            <button-primary class="align-button-center" [hidden]="files.length === 0 || !imagePreviewCheck" icon="angle-up" (click)="decreaseImagePreviewSize()" text="{{ 'ai-studio.import-dialog.collapse-previews' | translate }}"></button-primary>
        </div>
    </div>
</div>
