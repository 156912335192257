<div class="header" id="header" idle>
     <nav>
        <ng-content></ng-content>
        <ul class="nav-links">
            <li>
                <a id="user-profile-toggle" (click)="status === 'busy' || profileToggle()">
<!--                    <div class="avatar">
                        <img *ngIf="avatarUrl" [src]="avatarUrl" alt="avatar"/>
                    </div>-->
                     {{ username }}
                </a>
            </li>
        </ul>
     </nav>
    <!--(clickOutside)="showProfilePanel=false"-->
    <user-profile [hidden]="!showProfilePanel"  id="user-profile"></user-profile>

 </div>
 <!-- CO-Browsing on every page that has a menu (if enabled via server settings). -->
 <!--<co-browsing></co-browsing>-->
 <span id="CV_SESSION_ID"></span>
