<div id="rename-training-dialog-modal" class="modal-content">
    <div class="modal-head">
        <h2 class="modal-title">
            <span>{{ 'ai-studio.model.training.rename' | translate }}</span>
            <a class="float-right">
                <i class="icon icon-times" (click)="closeModal()"></i></a>
        </h2>
    </div>

    <div class="modal-body">
        <p>{{ 'ai-studio.model.training.rename.desc' | translate }}</p>
        <form-field-text-input
            [name]="'rename-training-input'"
            class="col col-sm-12"
            label="{{ 'ai-studio.start-training.training-name' | translate }}"
            [elementId]="'rename-training-input'"
            [(ngModel)]="trainingName">
        </form-field-text-input>
        <p *ngIf="showError" class="error-sign-color">{{ errorMsg }}</p>
    </div>

    <div class="modal-footer">
        <button-secondary
            text="{{ 'wf-editor.toolbar.open.cancel' | translate }}"
            style="float: left;"
            (click)="closeModal();">
        </button-secondary>
        <button-primary
            text="{{ 'rename' | translate}}"
            (click)="renameTraining();"
            event="Event,ai-training,rename-training"
            projectuuid="{{selectedModel.aiProject?.projectUuid}}"
            modeluuid="{{selectedModel.modelUuid}}">
        </button-primary>
    </div>
</div>
