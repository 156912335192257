<div class="upload-preview">
    <div class="upload-preview-content ">
        <img class="screen" [src]="uploadItem.content" style="">
        <div class="upload-preview-group">
            <p class="m-0">{{uploadItem.name}}
                <i [ngbTooltip]="'ai-studio.feedback.uploader.annotation-added-tooltip' | translate" class="icon icon-file-alt" [hidden]="!uploadItem.annotation || uploadItem.annotationError"></i>
            </p>
            <div class="upload-preview-actions">
                <li>
                    <i  [ngbTooltip]="'ai-studio.feedback.uploader.duplicate-detected-tooltip' | translate" class="icon icon-warning" [hidden]="!uploadItem.isDuplicate"></i>
                </li>
                <li>
                    <i  [ngbTooltip]="'ai-studio.feedback.uploader.max-size-error-tooltip' | translate" class="icon icon-error" [hidden]="!uploadItem.maxSizeExceeded"></i>
                </li>
                <li>
                    <i [ngbTooltip]="'ai-studio.feedback.uploader.uploaded-tooltip' | translate" class="icon icon-check" [hidden]="!uploadItem.isUploaded"></i>
                </li>
                <li>
                    <i [ngbTooltip]="'ai-studio.feedback.uploader.annotation-file-error' | translate" [style.color]="'red'" class="icon icon-file-alt" [hidden]="!uploadItem.annotationError"></i>
                </li>
                <li>
                    <button class="button-secondary" (click)="remove()"
                            [disabled]="isUploading">
                        <i class="icon icon-trash"></i>
                    </button>
                </li>
            </div>
        </div>
    </div>
</div>
