import {Component, ViewEncapsulation} from "@angular/core";
import {I18nService} from "services/i18n.service";
import {LocaleViewModel, supportedLocalesTEMP} from "utils/project-utils";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "services/authentication.service";
import DOMPurify from "dompurify";
import {UserService} from "services/aiStudio/user.service";
import {NotificationService} from "services/notification.service";
import {UserDeletionDialogComponent} from "dialogs/user-deletion/user-deletion-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Config} from "global/config";

@Component({
    selector: 'user-profile',
    templateUrl: 'user-profile.component.html',
    styleUrls: ['user-profile.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class UserProfileComponent {

    private i18nService: I18nService;
    //ToDo: Needed if its required
    // displayName: string;
    // domain: string;
    // lastlogin: string;
    // version: string;

    selectedLanguage: LocaleViewModel | undefined;
    supportedLocales: LocaleViewModel[] = supportedLocalesTEMP;
    username: string;

    hasFrontlineRole: boolean;
    hasInternalDevRole: boolean;

    appVersion: string = "";

    constructor(private I18nService: I18nService,
                private translateService: TranslateService,
                private authenticationService: AuthenticationService,
                private userService: UserService,
                private config: Config,
                private notificationService: NotificationService,
                private modalService: NgbModal) {
        this.hasFrontlineRole = this.authenticationService.hasRole(("Frontline_AI_User"))
        this.hasInternalDevRole = this.authenticationService.hasRole(("TV_INTERNAL_DEV"))
        this.i18nService = I18nService;
        //ToDo: Needed if its required
        //this.supportedLocales = [];

        this.username = DOMPurify.sanitize(authenticationService.getUserName());
        this.appVersion = this.config.properties.version
    }

    ngOnInit() {
        this.selectedLanguage = this.i18nService.getSelectedLanguage();
        if(this.selectedLanguage === undefined) {
            this.selectedLanguage = supportedLocalesTEMP[0];
        }

        //ToDo: Needed if its required
        //this.loadSupportedLocales();

    }
//ToDo: Needed if its required
/*    async loadSupportedLocales() {
        try {
            let localesRequest = await this.i18nService.getSupportedLocales();
            let locale = AuthenticationService.getLocale();
            this.supportedLocales = localesRequest.data;

            // Set the selected locale to the user
            this.supportedLocales.some(aLanguage => {
                if (aLanguage.code === locale) {
                    this.selectedLanguage = aLanguage;
                    return true;
                }
                return false;
            });
            // Load the supported locales to dayjs:
            loadGlobalDayjsLocale(this.selectedLanguage);

        } catch (e) {
            console.error("Could not request supported locales");
            console.error(e);
        }
    }*/

    /**
     * Changes the selected language
     * @param {*} language a Language object.
     */
    changeLanguage(language: LocaleViewModel) {
        if (language && language.code) {
            this.selectedLanguage = language;
            this.translateService.use(language.code);
            this.i18nService.saveSelectedLanguage(language);
            //ToDo: Needed if its required
            //loadGlobalDayjsLocale(language);
        } else {
            console.error("Could not change language: ");
            console.error(language);
        }
    }


    openDeleteAccountDialog() {

        this.modalService.open(UserDeletionDialogComponent, {size: 'col-4'})

    }

    editProfile() {
        this.authenticationService.updateProfile();
    }

    editPassword() {
        this.authenticationService.updatePassword();
    }

    logout() {
        this.authenticationService.logout();
    }
}
