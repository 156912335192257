<div id="object-detection-dialog-modal" class="modal-content" style="padding: 0;">
    <div class="modal-head">
        <h2 class="modal-title">
            <span>{{ 'ai-studio.object-detection.title' | translate }}</span>
            <a class="float-right" >
                <i class="icon icon-times" (click)="closeModal()"></i></a>
        </h2>
    </div>

    <div class="modal-body">
        <div class="row height100">
            <div class="col-sm-3 height100 leftPanel">
                <div class="bounding-box-tags">
                    <div class="propertyPanelTitle">{{ 'ai-studio.object-detection.draw-for' | translate : {'object' : ""} }}</div>
                    <!-- Group of default radios - option 1 -->
                    <div class="custom-control input-row classGrid">
                        <div class="gridRow" [ngClass]="{'disabled' : !aClass.visible || datumIsBackground(datum)}" *ngFor="let aClass of classes | orderBy: 'name' : OrderBy.ASC : 'name'">
                            <div class="gridCell" [ngClass]="{'selected' : aClass == selectedClass}">
                                <ai-tag
                                    [name]="aClass.name"
                                    [color]="aClass.color"
                                    (click)="selectClass(aClass)">
                                </ai-tag>
                            </div>
                            <div class="gridCell" (click)="selectClass(aClass);" [ngClass]="{'selected' : aClass == selectedClass}">
                                <span class="radio-hotkey" *ngIf="!datumIsBackground(datum)">{{aClass.hotKeyCombo}}</span>
                            </div>
                            <div class="gridCell" (click)="selectClass(aClass);" [ngClass]="{'selected' : aClass == selectedClass}">
                                <i class="icon icon-lg icon-keyboard-o" aria-hidden="true" *ngIf="!datumIsBackground(datum)"></i>
                            </div>
                            <div class="gridCell hideIcon" (click)="selectClass(aClass);" [ngClass]="{'selected' : aClass == selectedClass}">
                                <i class="icon icon-lg pull-right" aria-hidden="true" *ngIf="!datumIsBackground(datum)" (click)="aClass.visible = !aClass.visible; toggleBoundingBoxesVisibility(aClass);" [ngClass]="aClass.visible ? 'icon-eye' : 'icon-eye-slash'">
                                </i>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- IMAGE GRID -->
                <div class="propertyPanelTitle">
                    <span>
                        {{ 'ai-studio.object-detection.edit-status' | translate : {'index' : index + 1, 'total' : datums.length} }}
                    </span>
                    <span class="pull-right">
                        <i class="icon icon-exclamation-triangle"> </i> ({{datums.length - getNumberOfObjectDetectionReadyDatums().length}})
                        <i class="icon icon-check"> </i> ({{getNumberOfObjectDetectionReadyDatums().length}})
                    </span>
                </div>
                <div *ngIf="canvasRendered" class="datumViewOverViewGrid classGrid">
                    <div class="gridRow" *ngFor="let aDatum of datums" id="datum_{{aDatum.id}}">

                        <div class="gridCell thumbnailCell" (click)="setActiveImage(aDatum.id);" [ngClass]="{'selected' : aDatum.id == datum.id}">
                            <img class="img-responsive lazyloaded"
                                 [src]="config.baseUrl + '/aiStorage/'+ aiProjectService.getAiProject().projectUuid + '/datum/' + aDatum.id + '/image?size=small' | authImage | async " style="width: 50px">
                        </div>
                        <div class="gridCell" (click)="setActiveImage(aDatum.id);" [ngClass]="{'selected' : aDatum.id == datum.id}">
                            <span>{{aDatum.displayName}}</span>
                        </div>
                        <div class="gridCell" (click)="setActiveImage(aDatum.id);"
                                [ngClass]="{'selected' : aDatum.id == datum.id}">
                            <i class="icon"
                                [ngClass]="!aDatum.trainingReady ? 'icon-exclamation-triangle warning' : 'icon-check'"></i>
                        </div>
                    </div>
                </div>

                <div class="bottomPanel">
                     <!-- Status of current Image -->
                    <div class="statusPanel well well-lg">
                        <!-- Well set -->
                        <div *ngIf="datum && datum.trainingReady">
                            <p class="issuesText">
                                <i class="icon icon-2x icon-check pull-left"></i>
                                <span>{{ 'ai-studio.object-detection.is-well-set' | translate }}</span>
                            </p>
                        </div>

                        <!-- Not Well set -->

                        <div *ngIf="!datum.trainingReady">
                            <p class="issuesText" (click)="isCollapsed = !isCollapsed" style="cursor: pointer;">
                                <i class="icon icon-2x icon-exclamation-triangle pull-left" style="padding-right: 10px;"></i>
                                <span>{{ 'ai-studio.object-detection.has-issues' | translate : {'num' : numIssues} }}</span>
                            </p>

                            <div [(ngbCollapse)]="isCollapsed">
                                <!-- Image not Tagged -->
                                <p *ngIf="datum.datumLabels?.length === 0">
                                    {{ 'ai-studio.object-detection.image-not-tagged' | translate }}
                                    <i class="icon icon-exclamation-circle" [ngbTooltip]="'ai-studio.object-detection.label-action-choice' | translate"></i>
                                </p>
                                <!-- Image has Tags without BB -->
                                <div *ngFor="let aClass of datum?.datumLabels"
                                    class="datum-issue">
                                    <div *ngIf="(datum.datumLabels | boundingBoxHasClass: aClass.datumClass) == 0">
                                        <span [innerHtml]="'ai-studio.object-detection.no-bounding-box-found' | translate : {'className' : aClass.datumClass.name} | safeHtml"></span>
                                        <i class="icon icon-exclamation-circle" [ngbTooltip]="'ai-studio.object-detection.bounding-box-action-choice' | translate : {'className' : aClass.datumClass.name}"></i>
                                    </div>
                                </div>
                                <!-- Image has recognitions -->
                                <p *ngIf="openRecognitions">
                                    {{ 'ai-studio.object-detection.image-open-recognitions' | translate }}
                                    <i class="icon icon-exclamation-circle" [ngbTooltip]="'ai-studio.object-detection.tooltip-process-recognitions' | translate"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="buttonBar">
                        <button-primary
                            text="{{ 'ai-studio.object-detection.save-and-close' | translate }}"
                            class="pull-left"
                            [disabled]="showLoadingAnimation"
                            (click)="closeModal();"
                            [ngbTooltip]="'ai-studio.object-detection.save-and-close.tooltip' | translate">
                        </button-primary>
                        <div class="pull-right" role="group">
                            <button-secondary
                                class="deleteButton"
                                [disabled]="showLoadingAnimation"
                                icon="trash"
                                (click)="deleteImage(datum)"
                                [ngbTooltip]="'Delete Current Image from dataset'">
                            </button-secondary>
                            <button-secondary
                                [hidden]="datums.length <= 1"
                                [disabled]="showLoadingAnimation"
                                (click)="selectPreviousImage();"
                                icon="chevron-left"
                                [ngbTooltip]="'ai-studio.object-detection.pre-img' | translate">
                            </button-secondary>
                            <button-secondary
                                [hidden]="datums.length <= 1"
                                [disabled]="showLoadingAnimation"
                                (click)="selectNextImage(); "
                                icon="chevron-right"
                                [ngbTooltip]="'ai-studio.object-detection.next-img' | translate">
                            </button-secondary>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-9 height100 rightPanel">
                <div id="canvasContainer">
                    <div class="loadingAnimation" *ngIf="showLoadingAnimation">
                        <svg class="frontline-loading-animation"><use xlink:href="#frontline"></use></svg>
                    </div>
                    <div id="outerCanvasContainer height100">
                        <canvas id="imageRecognitionCanvas" width="400" height="400"></canvas>
                    </div>

                    <div class="canvasOverlay" *ngIf="datumIsBackground(datum)">
                        <div class="warningBackground">
                            <p>{{ 'ai-studio.object-detection.not-background.tooltip' | translate }}</p>
                            <button-primary
                                text="{{ 'ai-studio.object-detection.not-background' | translate }}"
                                class="button-small pull-right"
                                (click)="setDatumNotBackground()">
                            </button-primary>
                        </div>
                    </div>
                </div>
                <div class="tag-bar">
                    <!-- No Labels -->
                    <p *ngIf="datum.datumLabels?.length == 0" class="warning" style="margin-bottom: 20px;">
                        <i class="icon icon-exclamation-triangle"></i>&nbsp;
                        {{ 'ai-studio.object-detection.image-not-tagged.tooltip' | translate }}&nbsp;
                        <button-primary
                            class="button-small pull-right"
                            text="{{ 'ai-studio.object-detection.is-background' | translate }}"
                            (click)="setDatumBackground()">
                        </button-primary>
                    </p>

                    <div *ngFor="let label of datum.datumLabels | orderBy: 'id' | uniqueValues: 'datumClass.id'">
                        <ai-tag
                            style="margin-left: 10px;"
                            [name]="label.datumClass.name"
                            [color]="label.datumClass.color"
                            [boundingboxNum]="(datum.datumLabels | boundingBoxHasClass: label.datumClass)"
                            [isDeletable]="true"
                            (onDeletion)="removeDatumClass(label.datumClass)">
                        </ai-tag>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
