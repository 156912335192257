import {HttpResponse} from "@angular/common/http";
import {OAuthResourceServerErrorHandler} from "angular-oauth2-oidc";
import {Observable, throwError} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class OAuthNoopResourceServerErrorHandler implements OAuthResourceServerErrorHandler {

    handleError(err: HttpResponse<any>): Observable<any> {
        if(err.status===403) {
            console.log("Forbidden caught would redirect to error page telling you about missing permissions");
            return throwError(err);
        }else {
            return throwError(err);
        }
    }

}
