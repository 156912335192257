import {Component, HostBinding, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {TrainingService} from "services/aiStudio/training.service";
import {Model} from "@teamviewer/aistudioapi-common-angular";
import DOMPurify from "dompurify";

@Component({
    selector: 'rename-training',
    templateUrl: 'rename-training.component.html',
})

export class RenameTrainingComponent implements OnInit {
    @HostBinding('class') class = 'modal-content';
    @Input() selectedModel : Model = {};

    showError: boolean = false;
    errorMsg: string = "";
    trainingName: string = "";

    constructor(private aiProjectService: AiProjectService, private translateService: TranslateService, private activeModal: NgbActiveModal,private trainingService: TrainingService) {
    }

    ngOnInit(){
        this.trainingName = this.selectedModel?.name || "";
    }

    renameTraining = () => {
        this.trainingName = DOMPurify.sanitize(this.trainingName);
        if (this.trainingName === '') {
            this.showError = true;
            this.errorMsg = this.translateService.instant('ai-studio.start-training.training-name.empty-error-msg');
        } else if (this.aiProjectService.isTrainingNameExist(this.trainingName)) {
            this.showError = true;
            this.errorMsg = this.translateService.instant('ai-studio.start-training.training-name.exist-error-msg')
        } else {
            this.showError = false;
            this.trainingService.renameTraining(this.trainingName, this.selectedModel);
            this.closeModal();
        }
    };

    closeModal = () => {
        this.activeModal.close();
    };
}
