import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from 'global/config';
import {NullValidationHandler, OAuthService} from "angular-oauth2-oidc";
import jwt_decode from 'jwt-decode';
import {EventService} from "services/event.service";
import {LoadingService} from "services/loading.service";

@Injectable()
export class AuthenticationService {
    private roles:Array<string> | undefined;
    private username: string | undefined;
    private loginTimestamp: number | undefined;
    private frontlineBaseUrl: string | undefined;

    constructor(private http: HttpClient,
                private config: Config,
                private oauthService: OAuthService,
                private eventService: EventService,
                private loadingService: LoadingService
                ) { }

    async init (){
        this.oauthService.configure(JSON.parse(this.config.properties["authConfig"]));
        this.oauthService.redirectUri = window.location.origin + "/#/home";
        this.oauthService.tokenValidationHandler = new NullValidationHandler();

        window.onbeforeunload = () => {
            this.sendLogoutEvents()
        }
        return this.oauthService.loadDiscoveryDocumentAndTryLogin().then((_) => {
            if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
                return false;
            } else {
                this.oauthService.setupAutomaticSilentRefresh();
                let event = this.eventService.buildModel("Event,user-account,user-login","","");
                this.eventService.submitEvent(event);
                this.loginTimestamp = Date.now();
                return true;
            }
        });

    }

    updateProfile() {
        this.oauthService.initLoginFlow("", {"kc_action": "UPDATE_PROFILE"});
    }

    updatePassword() {
        this.oauthService.initLoginFlow("",{"kc_action":"UPDATE_PASSWORD"});
    }

    getToken() {
        return this.oauthService.getAccessToken();
    }

    logout() {
        this.sendLogoutEvents();
        this.loadingService.show("Logging out...");
        let customParameters: any = {};
        if(this.hasRole("Frontline_AI_User")) {
            customParameters["post_logout_redirect_uri"] = this.frontlineBaseUrl
        }
        this.oauthService.revokeTokenAndLogout(customParameters).then(() => {
            console.log("Logged out")
            this.loadingService.hide();
        }).catch(() => {
            console.log("Failed")})
    }

    sendLogoutEvents() {
        if(this.loginTimestamp) {
            let event = this.eventService.buildModel("Event,user-account,user-logout", "", "");
            this.eventService.submitEvent(event);
            event = this.eventService.buildModelWithDuration("Timing,user-account,user-session-duration", "", "", Date.now() - this.loginTimestamp);
            this.eventService.submitEvent(event);
        }
    }

    private loadJwt() {
        if(this.getToken()) {
            try {
                const decodedToken: any = jwt_decode(this.getToken() || "");
                this.username = this.hasRole("Frontline_AI_User") ? decodedToken.frontline_username : decodedToken.preferred_username;
                this.roles = decodedToken.realm_access?.roles;
                this.frontlineBaseUrl = decodedToken.frontline_url;
            } catch (e) {
                console.log("cannot decode token.");
            }
        }
    }

    hasRole(role:string): boolean {
        if (!this.roles) {
            this.loadJwt();
        }
        if (!this.roles || this.roles.length === 0){
            return false;
        }
        return this.roles.filter(aRole => aRole.toLowerCase() === role.toLowerCase()).length > 0;
    }

    getUserName(): string {
        if(!this.username) {
            this.loadJwt();
        }
        return this.username ? this.username : "unknown";
    }

    getFrontlineBaseUrl(): string {
        if(!this.username) {
            this.loadJwt();
        }
        if(!this.hasRole("Frontline_AI_User") || !this.frontlineBaseUrl) {
            return "";
        }
        return this.frontlineBaseUrl;
    }
}
