<div style="z-index: 10; position: absolute;">
    <div #getStarted class="get-started" (click)="toggleCollapse();">
        <span>{{ 'ai-studio.guide.badge-title' | translate }}</span>
    </div>

    <div class="progress-small" [ngStyle]="{'width.px': progressWidth}" *ngIf="guidanceCollapsed">
        <div class="project-progress">
          <div class="progress-bar" [ngStyle]="{ 'width' : progress, 'border-top-right-radius' : progress === '100%' ? '6px' : '0', 'border-bottom-right-radius' : progress === '100%' ? '6px' : '0' }"></div>
        </div>
    </div>

    <div class="progress-details" *ngIf="!guidanceCollapsed">
        <div class="details-card">
            <p>
                <b>{{ 'ai-studio.guide.title' | translate }}</b>
                <a class="pull-right show-hide" (click)="toggleCollapse()">{{ 'ai-studio.guide.hide' | translate }}</a>
            </p>
            <div class="project-progress">
                <div class="progress-bar" [ngStyle]="{ 'width' : progress, 'border-top-right-radius' : progress === '100%' ? '6px' : '0' ,'border-bottom-right-radius' : progress === '100%' ? '6px' : '0' }"></div>
            </div>
            <p>
                {{ 'ai-studio.guide.desc' | translate }}
            </p>
        </div>

        <div id="setup-steps" class="panel-group">
            <!-- Create Project -->
            <div class="details-card border-top panel panel-default" [ngClass]="{'active' : lastVerifiedStep === 1}">
                <a (click)="toggleStep(0);" aria-expanded="true" aria-controls="create-project" href="#create-project" class="check-list-item">
                    <div>
                        <span class="number-icon" *ngIf="lastVerifiedStep <= 1" [ngClass]="{'active' : lastVerifiedStep === 1}">1</span>
                        <i class="icon icon-lg icon-check-circle check-icon" aria-hidden="true" *ngIf="lastVerifiedStep > 1"></i>
                        <span class="panel-title panel-title-guidance">
                            <b>{{ 'ai-studio.guide.create-project' | translate }}</b>
                        </span>
                    </div>
                </a>

                <div [(ngbCollapse)]="collapsInfo[0]" id="create-project" class="panel-collapse collapse">
                    <p class="top-buffer">
                        {{ 'ai-studio.guide.create-project.desc' | translate }}
                    </p>
                    <p>
                        <b>{{ 'ai-studio.guide.create-project.tip' | translate }}</b>
                        <span [innerHtml]="'ai-studio.guide.create-project.tip.desc' | translate | safeHtml"></span>
                    </p>
                </div>
            </div>

            <!-- Add Images -->
            <div class="details-card border-top panel panel-default" [ngClass]="{'active' : lastVerifiedStep === 2}">
                <a href="#add-image" class="check-list-item" (click)="toggleStep(1)">
                    <div>
                        <span class="number-icon" *ngIf="lastVerifiedStep <= 2" [ngClass]="{'active' : lastVerifiedStep === 2}">2</span>
                        <i class="icon icon-check-circle check-icon" aria-hidden="true" *ngIf="lastVerifiedStep > 2"></i>
                        <span class="panel-title panel-title-guidance">
                            <b>{{ 'ai-studio.guide.add-images' | translate }}</b>
                        </span>
                    </div>
                </a>

                <div [(ngbCollapse)]="collapsInfo[1]" id="add-image" class="panel-collapse collapse">
                    <p class="top-buffer">{{ hasFrontlineRole ? ('ai-studio.guide.add-images.desc-frontline' | translate) : ('ai-studio.guide.add-images.desc-public' | translate) }}</p>
                    <a class="show-hide" (click)="showOrHideAddImageTip();">  {{ !highlightingService.activeHighlighting ? ('ai-studio.guide.show-me' | translate) : ( 'ai-studio.guide.hide' | translate) }}
                    </a>
                </div>
            </div>

            <!-- Train Your Project -->
            <div class="details-card border-top panel panel-default" [ngClass]="{'active' : lastVerifiedStep === 3}">
                <a href="#train-project" class="check-list-item" (click)="toggleStep(2)">
                    <div>
                        <span class="number-icon" *ngIf="lastVerifiedStep <= 3" [ngClass]="{'active' : lastVerifiedStep === 3}">3</span>
                        <i class="icon icon-check-circle check-icon" aria-hidden="true" *ngIf="lastVerifiedStep > 3"></i>
                        <span class="panel-title panel-title-guidance">
                            <b>{{ 'ai-studio.guide.train-project' | translate }}</b>
                        </span>
                    </div>
                </a>

                <div [(ngbCollapse)]="collapsInfo[2]" id="train-project" class="panel-collapse collapse">
                    <p class="top-buffer">{{ 'ai-studio.guide.train-project.desc' | translate }}</p>
                    <a class="show-hide" (click)="showOrHideNewTrainingTip();">{{ !highlightingService.activeHighlighting ? ('ai-studio.guide.show-me' | translate) : ('ai-studio.guide.hide' | translate) }}</a>
                </div>
            </div>

            <!-- Publish Trained Model -->
            <div class="details-card border-top panel panel-default" [ngClass]="{'active' : lastVerifiedStep === 4}">
                <a href="#publish-model" class="check-list-item"
                   (click)="toggleStep(3)">
                    <div>
                        <span class="number-icon" *ngIf="lastVerifiedStep <= 4" [ngClass]="{'active' : lastVerifiedStep === 4}">4</span>
                        <i class="icon icon-check-circle check-icon" aria-hidden="true" *ngIf="lastVerifiedStep > 4"></i>
                        <span class="panel-title panel-title-guidance">
                            <b>{{ 'ai-studio.guide.publish-model' | translate }}</b>
                        </span>
                    </div>
                </a>

                <div [(ngbCollapse)]="collapsInfo[3]" id="publish-model" class="panel-collapse collapse">
                    <p *ngIf="hasFrontlineRole" class="top-buffer" [innerHtml]="'ai-studio.guide.publish-model.desc-frontline' | translate : {'linkToCreator' : linkFrontlineCreator } | safeHtml"></p>
                    <p *ngIf="!hasFrontlineRole" class="top-buffer" [innerHtml]="'ai-studio.guide.publish-model.desc-public' | translate"></p>
                    <a class="show-hide" *ngIf="aiProjectService.getAiProject()?.models?.length > 0" (click)="showOrHidePublishTrainingTip();">{{ !highlightingService.activeHighlighting ? ('ai-studio.guide.show-me' | translate) : ('ai-studio.guide.hide' | translate) }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
