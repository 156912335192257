import {Component, OnInit} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {HotkeysService} from "angular2-hotkeys";
import {DialogConfirm} from "dialogs/dialog-confirm";
import _ from "lodash";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {DatumService} from "services/aiStudio/datum.service";
import {TagViewService} from "services/aiStudio/tag-view.service";
import {LoadingService} from "services/loading.service";
import {NotificationService} from "services/notification.service";
import {Datum} from "@teamviewer/aistudioapi-common-angular";
import {datasetSortingSettings, INFERENCE_PAGE_TAB} from "utils/project-utils";
import {ObjectDetectionComponent} from "apps/aiStudio/dataset/object-detection/object-detection.component";
import {ClassificationComponent} from "apps/aiStudio/dataset/classification/classification.component";

@Component({
    selector: 'inference',
    templateUrl: 'inference.component.html',
    styleUrls: ['inference.component.sass']
})

export class InferenceComponent implements OnInit {
    activeDatums: any = [];
    activeDatumsIds: any = [];
    lastSelectIndex: number = -1;
    currentPage: number = 1;

    hotkeys: any = null;
    datasetSortingSettings = datasetSortingSettings;
    filterCollapsed: boolean = false;
    confidenceSliderCollapsed: boolean = false;
    confidenceLevel: number = 75;

    constructor(private translateService: TranslateService, public aiProjectService: AiProjectService, public datumService : DatumService, private aiStudioObservables: AiStudioObservables, public tagViewService: TagViewService, private modalService: NgbModal, private dialogConfirm: DialogConfirm, private loadingService: LoadingService, private notificationService: NotificationService, private hotkeysService: HotkeysService) {
        this.hotkeys = hotkeysService;
        //HOTKEYS
        this.hotkeys.add({
            combo: 'ctrl+a',
            description: 'Select all images',
            callback: (e: any) => {
                e.preventDefault();
                this.selectAll()
            }
        });

        this.hotkeys.add({
            combo: 'esc',
            description: 'Deselect all images',
            callback: (e: any) => {
                e.preventDefault();
                this.deselectAllActiveImages();
            }
        });

        this.hotkeys.add({
            combo: 'del',
            description: 'Delete selected images',
            callback: () => {
                this.removeSelected();
            }
        });
    }

    ngOnInit() {
        if(this.aiProjectService.getAiProject()){
            this.datumService.selectPage(this.currentPage, true, this.datumService.selectedSortingSetting, false);
        }
        this.confidenceLevel = this.datumService.confidenceLevel;
    }

    onPagination(currentPage:any) {
        this.currentPage = currentPage;
        this.datumService.onPagination(currentPage, INFERENCE_PAGE_TAB);
    }

    changePaginationSize(pageSize: any) {
        this.datumService.onPagination(this.currentPage, INFERENCE_PAGE_TAB, pageSize);
    }

    zoomIn() {
        if (this.datumService.zoomLevel > this.datumService.minZoomLevel) {
            this.datumService.zoomLevel--;
        }
    }

    zoomOut() {
        if (this.datumService.zoomLevel < this.datumService.maxZoomLevel) {
            this.datumService.zoomLevel++;
        }
    }

    openImage = (datums: Datum[]) => {
        if (this.aiProjectService.isObjectDetectionProject()) {
            let modalInstance = this.modalService.open(ObjectDetectionComponent,  {
                animation: true,
                size: 'col-12 no-padding',
                backdrop: 'static',
            })

            modalInstance.componentInstance.datums = datums;
            modalInstance.result.then(() => {
                this.refreshInference(true, true)
            })

        } else {
            let modalInstance = this.modalService.open(ClassificationComponent,  {
                animation: true,
                size: 'col-6 no-padding',
                backdrop: 'static',
            })
            modalInstance.componentInstance.datum = datums[0];
            modalInstance.componentInstance.isInference = true;
        }
    };

    editAll = () => {
        let imagesInView = this.datumService.getDatums();
        this.openImage(imagesInView);
    };

    editSelected = () => {
        this.openImage(this.activeDatums);
    };

    // Remove a selected Image
    removeSelected = () => {
        if (this.activeDatums.length > 0) {
            let message = this.translateService.instant('ai-studio.alert.delete-image', {imageNum: this.activeDatums.length});
            let primaryButton = this.translateService.instant('wf-editor.alert.yes');
            let secondaryButton = this.translateService.instant('wf-editor.alert.cancel');

            this.dialogConfirm.open(message, primaryButton, secondaryButton).then(async () => {
                try {
                    this.loadingService.show(this.translateService.instant('ai-studio.feedback.deleting-image'));
                    let datumsToBeDeleted: Datum[] = [];

                    for (let datum of this.activeDatums) {
                        datumsToBeDeleted.push(datum);
                    }
                    this.datumService.deleteDatums(datumsToBeDeleted).then(() => {
                        this.refreshInference(true, true)
                    }).catch(() => {
                        this.loadingService.hide();
                    });
                } catch (e) {
                    console.log('Error while removing selected images.');
                    console.log(e);
                }

            }, (error: any) => {
                console.log(error);
            });
        }
    };

    deleteImage(datum: Datum) {
        this.datumService.deleteDatums([datum]).then(() => {
            this.refreshInference(false, true)
        }, () => {
            this.notificationService.info("Failed to delete images.");
        });
    }

    deselectAllActiveImages() {
        this.activeDatumsIds = [];
        this.activeDatums = [];
    }

    selectAll() {
        let inferenceDatums = this.datumService.getInferenceDatums();
        this.activeDatums = inferenceDatums;
        this.activeDatumsIds = inferenceDatums.map((datum: Datum) => datum.id)
    }

    changeInferenceSorting(sorting: any) {
        this.datumService.setSorting(sorting);
        this.refreshInference(false, false)
    }

    // Normal click: element is active
    selectImage(event: any, image: any) {
        if(image) {
            if (this.activeDatums.length === 0) {
                this.lastSelectIndex = -1;
            }

            //SHIFT KEY: Range Selection
            if (event.shiftKey && this.lastSelectIndex !== -1) {
                //prevents that the images are selected like browser text selection
                if(document){
                    document.getSelection()?.removeAllRanges();
                }

                let overallIndex = this.calculateOverallIndex(image);
                let allImages = this.datumService.getInferenceDatums();
                let min;
                let max;
                if (overallIndex < this.lastSelectIndex) {
                    min = overallIndex;
                    max = this.lastSelectIndex;
                } else {
                    min = this.lastSelectIndex;
                    max = overallIndex;
                }

                this.activeDatums = [];
                this.activeDatumsIds = [];

                for (let i = 0; i < allImages.length; i++) {
                    if (i >= min && i <= max) {
                        let activeImage = _.cloneDeep(allImages[i]);
                        this.activeDatums.push(allImages[i]);
                        this.activeDatumsIds.push(activeImage.id)
                    }
                }
            } else if (event.ctrlKey && this.lastSelectIndex !== -1) { // CTRL Key: Multiple Selection
                if (image.selected === true) {
                    for (let i = 0; i < this.activeDatums.length; i++) {
                        if (this.activeDatums[i] === image) {
                           this.activeDatums[i].selected = false;

                            this.activeDatums.splice(i, 1);
                            break;
                        }
                    }
                } else {
                    this.activeDatums.push(image);
                    this.activeDatumsIds.push(image.id)
                }
            } else { // NORMAL CLICK
                if (this.activeDatums.length > 0) {
                    for (let i = 0; i < this.activeDatums.length; i++) {
                        this.activeDatums[i].selected = false;
                    }

                }

                let activeImage = _.cloneDeep(image)
                this.activeDatums = [image];
                this.activeDatumsIds = [activeImage.id]

            }

            //Keep OverallIndex, when last Event is Shift Key
            if (!event.shiftKey && this.activeDatums.length > 0) {
                this.lastSelectIndex = this.calculateOverallIndex(image);
            }
        }

    }

    //Filters the label with the highest confidence. For Classification, just filters for one label
    filterRecognitionLabels(recognitions: any): any {
        if(recognitions.length <= 0) {
            return;
        } else if(this.aiProjectService.isObjectDetectionProject()) {
            let result_array = [];
            for(let rec in recognitions) {
                if(recognitions[rec].confidence >= this.datumService.confidenceLevel/100) {
                    result_array.push(recognitions[rec]);
                }
            }
            return result_array;
        } else if(this.aiProjectService.isSingleLabelProject()) {
            let result_array = [];
            result_array.push(recognitions.reduce((max: any, recognition: any) => max.confidence > recognition.confidence ? max : recognition));
             return result_array;
        }
    }

    addToDataset(){
        if (this.activeDatums.length > 0) {
            let message = this.translateService.instant('ai-studio.alert.adding-to-dataset-including-bounding-boxes', {imageNum: this.activeDatums.length});

            this.dialogConfirm.open(message, this.translateService.instant('wf-editor.alert.yes'), this.translateService.instant('wf-editor.alert.cancel')).then(async () => {
                try {
                    this.loadingService.show(this.translateService.instant('ai-studio.feedback.adding-to-dataset'));

                    let datums: Datum[] = [];

                    for (let datum of this.activeDatums) {
                        datum.inferenceImage = false;
                        datums.push(datum);
                    }

                    this.datumService.updateDatums(datums).then(() => {
                        this.refreshInference(true, true);
                        this.loadingService.hide();
                    }).catch(() => {
                        this.loadingService.hide();
                    });

                } catch (e) {
                    console.log('Error while adding selected images to the dataset (including Bouding Boxes).');
                    console.log(e);
                }
            }, (error: Error) => {
                console.log(error);
            });
        }
    }

    //Handles both Classification Projects and Object Detection Projects
    addToDatasetExcludeBoundingBoxes(){
        if (this.activeDatums.length > 0) {
            let message = this.aiProjectService.isObjectDetectionProject() ? this.translateService.instant('ai-studio.alert.adding-to-dataset-excluding-bounding-boxes', {imageNum: this.activeDatums.length}) : this.translateService.instant('ai-studio.inference.tab.add-to-dataset-exclude-tags', {imageNum: this.activeDatums.length});

            this.dialogConfirm.open(message, this.translateService.instant('wf-editor.alert.yes'), this.translateService.instant('wf-editor.alert.cancel')).then(async () => {
                try {
                    this.loadingService.show(this.translateService.instant('ai-studio.feedback.adding-to-dataset'));
                    let datums:Datum[] = [];

                    for (let datum of this.activeDatums) {
                        datum.recognitions = [];
                        datum.inferenceImage = false;
                        datums.push(datum);
                    }
                    this.datumService.updateDatums(datums).then(() => {
                        this.refreshInference(true, true)
                        this.loadingService.hide();
                    }).catch(() => {
                        this.loadingService.hide();
                    });

                } catch (e) {
                    console.log('Error while adding selected images to the dataset (excluding Bouding Boxes).');
                    console.log(e);
                }
            }, (error: Error) => {
                console.log(error);
            });
        }
    }

    // Calculates the image's index across all datasets
    calculateOverallIndex(image: any) {
        let overallIndex = 0;
        let imageFound = false;

        for (let i = 0; i < this.datumService.getInferenceDatums().length; i++){
            if(this.datumService.getInferenceDatums()[i].id === image.id){
               overallIndex += i
               imageFound = true
               break
           }
       }
        return overallIndex;
    }

    refreshInference(emptyActiveDatums?: boolean, fetchTags?: boolean) {
        if(emptyActiveDatums){
            this.activeDatums = [];
            this.activeDatumsIds = [];
        }

        if(fetchTags){
            this.tagViewService.fetchTagViews()
        }

        this.datumService.selectPage(this.currentPage,true, this.datumService.selectedSortingSetting, false);
        this.loadingService.hide();
    }

    updateDatumViewsByMinConfidence() {
        this.tagViewService.fetchInferenceTagViews(this.datumService.confidenceLevel);
        this.refreshInference(false, true);
    }

    formatConfidence(confidence: number) {
        return " ("+(100*(confidence || 0)).toFixed(2) +"%)"
    }
}
