<div id="project-info-dialog-modal" class="modal-content">
    <div class="modal-head info-detail-header">
        <h2 class="modal-title">
            <span>{{ 'ai-studio.project.info.title' | translate }}</span>
            <a class="float-right">
                <i class="icon icon-times" (click)="cancel()"></i></a>
        </h2>
    </div>

    <div class="modal-body">
        <div class="modal-body info-details-modal-body">
            <ul class="info-detail-group">
                <li>
                    <div class="row info-detail-group-item">
                        <div class="col col-sm-12">
                            <div class="row info-detail-group-item-rows">
                                <div class="col col-sm-4 info-detail-group-item-rows-text-headers"><p><b>{{ 'ai-studio.project.info.name' | translate }}</b></p></div>
                                <div class="col col-sm-8 info-detail-group-item-rows-text-values"><p>{{aiProject?.name }}</p></div>
                            </div>
                            <div class="row info-detail-group-item-rows">
                                <div class="col col-sm-4 info-detail-group-item-rows-text-headers"><p><b>{{ 'ai-studio.project.info.uuid' | translate }}</b></p></div>
                                <div class="col col-sm-8 info-detail-group-item-rows-text-values"><p>{{aiProject?.projectUuid }}</p></div>
                            </div>
                            <div class="row info-detail-group-item-rows">
                                <div class="col col-sm-4 info-detail-group-item-rows-text-headers"><p><b>{{ 'ai-studio.project.info.type' | translate }} </b></p></div>
                                <div class="col col-sm-8 info-detail-group-item-rows-text-values">{{ 'ai-studio.project.info.type.' + aiProject?.projectType | translate }}</div>
                            </div>
                            <div class="row info-detail-group-item-rows">
                                <div class="col col-sm-4 info-detail-group-item-rows-text-headers"><p><b>{{  'ai-studio.project.info.created_by' | translate }}</b></p></div>
                                <div class="col col-sm-8 info-detail-group-item-rows-text-values"><p>{{aiProject?.createdBy}}</p></div>
                            </div>
                            <div class="row info-detail-group-item-rows">
                                <div class="col col-sm-4 info-detail-group-item-rows-text-headers"><p><b>{{ 'ai-studio.project.info.created_on' | translate }} </b></p></div>
                                <div class="col col-sm-8 info-detail-group-item-rows-text-values"><p> {{dateTimeFormatter(aiProject?.createdAt) }}</p></div>
                            </div>
                            <div class="row info-detail-group-item-rows">
                                <div class="col col-sm-4 info-detail-group-item-rows-text-headers"><p><b>{{  'ai-studio.project.info.updated_by' | translate }}</b></p></div>
                                <div class="col col-sm-8 info-detail-group-item-rows-text-values"><p>{{aiProject?.updatedBy}}</p></div>
                            </div>
                            <div class="row info-detail-group-item-rows">
                                <div class="col col-sm-4 info-detail-group-item-rows-text-headers"><p><b>{{ 'ai-studio.project.info.updated_on' | translate }} </b></p></div>
                                <div class="col col-sm-8 info-detail-group-item-rows-text-values"><p> {{dateTimeFormatter(aiProject?.updatedAt) }}</p></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
