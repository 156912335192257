import {Component, ViewEncapsulation} from "@angular/core";
import {AuthenticationService} from "services/authentication.service";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {AiProjectService} from "services/aiStudio/ai-project.service";

@Component({
    selector: 'slide-out-app-menu',
    templateUrl: 'slide-out-app-menu.component.html',
    styleUrls: ['slide-out-app-menu.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class SlideOutAppMenuComponent {

    hasFrontlineRole: boolean;
    //ToDo: Create observable for loaded project.
    constructor(
        private authenticationService: AuthenticationService, private aiStudioObservables: AiStudioObservables, public aiProjectService: AiProjectService) {

        this.hasFrontlineRole = this.authenticationService.hasRole(("Frontline_AI_User"));

    }
}
