<div id="ai-toolbar"  >
    <div *ngIf="hasFrontlineRole" id="backtoFrontline" class="workflow-name" [ngbTooltip]="'ai-studio.feedback.back-to-frontline' | translate" [placement]="'right'" [container]="'body'"
         (mouseout)="showfrontlineIcon = true" (mouseover)="showfrontlineIcon = false">
        <a href='{{linkFrontline}}'>
            <i class="icon" [ngClass]="{'icon-aistudio-logo-fm': showfrontlineIcon, 'icon-frontline': !showfrontlineIcon}" style="font-size: 26px">
            </i>
        </a>
    </div>

    <div *ngIf="!hasFrontlineRole" id="aistudio-logo" class="workflow-name">
        <a>
            <i class="icon icon-aistudio-logo-fm" style="font-size: 26px">
            </i>
        </a>
    </div>

    <div id="wfName" class="workflow-name"> {{ aiProjectService.getAiProject()?.name }} </div>
    <span class="dropdown" role="navigation" id="headerMenu" ngbDropdown>

        <button *ngIf="aiProjectService.getAiProject() !== null" ngbDropdownToggle class="btn btn-default dropdown-toggle btnMenu" type="button" id="aiToolbarMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <i class="icon icon-settings" style="font-size: 18px;"></i>
        </button>
        <ul class="dropdown-menu" ngbDropdownMenu aria-label="aiToolbarDropdown">
            <!-- Edit Project -->
           <li ngbDropdownItem>
                <a (click)="editProjectInWizard();" [ngbTooltip]="'ai-studio.edit-project.tooltip' | translate" [placement]="'right'" [container]="'body'"
                   [ngClass]="{ 'menu-item-disabled': !STATUS_PROJECT_LOADED }">
                    {{ 'ai-studio.init.edit_project' | translate }}
                </a>
           </li>
            <li role="separator" class="divider"></li>
            <li ngbDropdownItem [disabled]="!STATUS_PROJECT_LOADED || !STATUS_DATUMS_IN_PROJECT">
                <a (click)="openAugmentationSettings();"  [ngbTooltip]="'ai-studio.data-optimization.tooltip' | translate" [placement]="'right'" [container]="'body'"
                   [ngClass]="{ 'menu-item-disabled': !STATUS_PROJECT_LOADED || !STATUS_DATUMS_IN_PROJECT }">
                    <i class="icon icon-database" aria-hidden="true"></i>
                    {{ 'ai-studio.data-optimization'| translate }}
                </a>
            </li>
            <li role="separator" class="divider"></li>
            <li ngbDropdownItem>
                <a (click)="exportPublishedModel();" [ngbTooltip]="'ai-studio.export-model.tooltip' | translate" [placement]="'right'" [container]="'body'"
                   [ngClass]="{ 'menu-item-disabled': !STATUS_PROJECT_LOADED}">
                    <i class="icon icon-sign-out"></i>
                    {{ 'ai-studio.export-published-model' | translate }}
                </a>
            </li>
            <li *ngIf="hasFrontlineRole" role="separator" class="divider">
            <!-- Link to Frontline Admin Panel -->
            <li *ngIf="hasFrontlineRole">
                <a href='{{linkFrontlineAiStudioOvewerview}}' [ngbTooltip]="'ai-studio.fcc-studio-overview.tooltip' | translate" [placement]="'right'" [container]="'body'"
                   target="_blank" rel="noopener noreferrer">
                    <i class="icon icon-frontline"></i>
                    {{ 'ai-studio.fcc-studio-overview' | translate }}
                </a>
            </li>
         </ul>
    </span>
</div>
