<div id="tab-home" style="height: calc(100vh - 50px);" >
    <div class="height100 row" style="margin: 0;">
        <div id="home-details-content">
            <div class="col-sm-12">
                <h1 style="margin-left: 15px; margin-top: 15px;">{{ 'ai-studio.home.project-overview' | translate }}</h1>
                <div class="row card-grid card-grid-4-cols image-upload-grid card-grid-same-height">
                    <div class="col m-t-30 m-b-30">
                        <div class="card card-hover create-new-project" (click)="createNewProjectWizard()">
                            <div class="card-content" style="padding: 70px; text-align: center;">
                                <i class="icon icon-add" aria-hidden="true"></i>
                                <h6 class="card-title">{{ 'ai-studio.home.create-new-project' | translate }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>

            <div class="col-sm-12">
                <div class="row m-0 justify-content-end">
                    <div class="col col-sm-2">
                        <dataset-slider
                            class="col col-sm-2"
                            [showIcon]="true"
                            [min]="2"
                            [max]="7"
                            [step]="1"
                            [(value)]="gridSize"
                            (onValueUpdate)="gridSize = $event">
                    </dataset-slider></div>
                    <div class="col col-sm-3 search-input">
                        <form-field-text-input
                            label="{{ 'xmake.search' | translate }}"
                            [elementId]="'filter-text-box'"
                            type="text"
                            [(ngModel)]="searchExpression"
                            (ngModelChange)="filterProjects()">
                        </form-field-text-input>
                    </div>
                </div>

                <div class="row card-grid card-grid-{{gridSize}}-cols image-upload-grid card-grid-same-height p-b-30">
                    <div class="col m-t-30" *ngFor="let aProject of aiProjectsFiltered | orderBy: 'updatedAt' : OrderBy.DESC" >
                        <ai-project-card [hidden]="isLoading" [aiProject]="aProject"></ai-project-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
