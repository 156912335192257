import {Injectable} from "@angular/core";
import {Config} from "global/config";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import Cookies from 'js-cookie';
import {tap} from "rxjs/operators";


@Injectable()
export class CsrfInterceptor implements HttpInterceptor{
    private tokenName = 'X-CSRF-TOKEN';

    constructor(private config:Config) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!req.url.startsWith(this.config.baseUrl))
        {
            return next.handle(req);
        }
        let xsrf = Cookies.get(this.tokenName);
        if(!xsrf){
            return next.handle(req);
        }
        req = req.clone({

            setHeaders: {
                'X-CSRF-TOKEN': xsrf
            }
        });

    return next.handle(req) .pipe(tap(() => {}));
    }
}
