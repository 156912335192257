import {Injectable} from "@angular/core";
import {Config} from "global/config";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

const JSOG = require('jsog/lib/JSOG');


@Injectable()
export class JsogInterceptor implements HttpInterceptor{
    constructor(private config:Config) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!req.url.startsWith(this.config.baseUrl))
        {
            return next.handle(req);
        }
        if (typeof req.body === 'object' && !(req.body instanceof FormData)) {
            req = req.clone({body: JSOG.encode(req.body) });
        }

        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if(event instanceof HttpResponse){
                    if (typeof event.body === 'object'  && !(event.body instanceof Blob)) {
                        event = event.clone({ body: JSOG.decode(event.body) });
                    }
                }
                return event;
            }));
    }
}
