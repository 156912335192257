import {ReplaySubject, Subject} from "rxjs";
import {Injectable} from "@angular/core";
import {Model} from "@teamviewer/aistudioapi-common-angular";

@Injectable()
export class AiStudioObservables {

    public selectProject$: ReplaySubject<string> = new ReplaySubject<string>(1);
    public guidanceStep$ : ReplaySubject<null> = new ReplaySubject<null>(1)
    public STATUS_PROJECT_DELETED$: ReplaySubject<string> = new ReplaySubject<string>(1);
    public STATUS_PROJECT_INIT$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public STATUS_PROJECT_LOADED$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public STATUS_PROJECT_LOAD_FAILED$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public STATUS_PROJECT_LOADING$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public STATUS_NO_FREE_PROJECT$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public SHOW_WIZARD$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public SHOW_OPEN_PROJECT_DIALOG$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public STATUS_DATUMS_IN_PROJECT$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public STATUS_SELECTED_MODEL_HAS_CHANGED$: Subject<string> = new Subject<string>();
    public STATUS_PUBLISHED_MODEL_HAS_CHANGED$: Subject<Model> = new Subject<Model>();

    constructor() {

    }


    updateSelectedProject$(selectedProject: string) {
        this.selectProject$.next(selectedProject)
    }

    updateGuidanceStep$() {
        this.guidanceStep$.next(null);
    }

    updateStatusProjectInit$(status: boolean) {
        this.STATUS_PROJECT_INIT$.next(status);
    }

    updateStatusProjectDeleted$(status: string) {
        this.STATUS_PROJECT_DELETED$.next(status);
    }

    updateStatusProjectLoaded$(status: boolean) {
        this.STATUS_PROJECT_LOADED$.next(status);
    }

    updateStatusProjectLoadFailed$(status: boolean) {
        this.STATUS_PROJECT_LOAD_FAILED$.next(status);
    }

    updateStatusProjectLoading$(status: boolean) {
        this.STATUS_PROJECT_LOADING$.next(status);
    }

    updateStatusNoFreeProject$(status: boolean) {
        this.STATUS_NO_FREE_PROJECT$.next(status);
    }

    updateShowWizard$(status: boolean) {
        this.SHOW_WIZARD$.next(status);
    }

    updateOpenProjectDialog$(status: boolean) {
        this.SHOW_OPEN_PROJECT_DIALOG$.next(status);
    }

    updateStatusDatumsInProject$(status: boolean) {
        this.STATUS_DATUMS_IN_PROJECT$.next(status);
    }

    updateStatusSelectedModelHasChanged$(modelUuid: string) {
        this.STATUS_SELECTED_MODEL_HAS_CHANGED$.next(modelUuid);
    }

    updateStatusPublishedModelHasChanged$(model: Model) {
        this.STATUS_PUBLISHED_MODEL_HAS_CHANGED$.next(model)
    }

}
