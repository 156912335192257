import {NgModule} from '@angular/core';
import {DatasetComponent} from './dataset/dataset.component';
import {InferenceComponent} from './inference/inference.component';
import {TrainingComponent} from './training/training.component';
import {HomeComponent} from "./home/home.component";
import {AuthGuard} from "global/auth-guard";
import {ProjectGuard} from "global/project-guard";
import {RouterModule, Routes} from "@angular/router";
import {ErrorComponent} from "apps/error/error.component";

export const aiStudioRoutes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dataset',
        component: DatasetComponent,
        canActivate: [AuthGuard, ProjectGuard]
    },
    {
        path: 'training',
        component: TrainingComponent,
        canActivate: [AuthGuard, ProjectGuard]
    },
    {
        path: 'inference',
        component: InferenceComponent,
        canActivate: [AuthGuard, ProjectGuard]
    },
    {
        path: 'error',
        component: ErrorComponent
    }
]

@NgModule({
    imports: [RouterModule.forRoot(aiStudioRoutes,{
        useHash: true
    })],
    exports: [
        RouterModule
    ]
})
export class AiStudioRoutingModule {}
