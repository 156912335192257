<div id="training-options-info-modal" class="modal-content">
    <div class="modal-head">
        <h2 class="modal-title">
            <span>{{ 'ai-studio.model.training.options-info' | translate }}</span>
            <a class="float-right">
                <i class="icon icon-times" (click)="closeModal()"></i></a>
        </h2>
    </div>

    <ul>
        <li *ngFor="let recipient of extraOptions | keyvalue">
            {{recipient.key}} --> {{stringify(recipient.value)}}
        </li>
    </ul>
</div>
