import {Component, HostBinding, Input, ViewEncapsulation} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Config} from "global/config";

@Component({
    selector: 'classification-misclassified',
    templateUrl: 'classification-misclassified.component.html',
    styleUrls: ['classification-misclassified.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class ClassificationMisclassifiedComponent {
    @Input() image: any;
    @HostBinding('class') class = 'modal-content';

    constructor(private activeModal: NgbActiveModal, public config: Config){}

    closeModal () {
        this.activeModal.close();
    };

}
