import {Pipe, PipeTransform} from '@angular/core';
import {BOUNDING_BOX} from 'utils/project-utils';
import {DatumLabel} from "@teamviewer/aistudioapi-common-angular";

@Pipe({
    name: 'boundingBoxHasClass',
})
export class BoundingBoxHasClassPipe implements PipeTransform {
    transform(datumLabels: any, datumClass: any) {
        let boundingBoxes = datumLabels.filter((label: DatumLabel) => label.type === BOUNDING_BOX)
        return boundingBoxes.filter((boundingBox: any) => boundingBox.datumClass.id === datumClass.id).length;
    }
}
