import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Config} from "global/config";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {Datum} from "@teamviewer/aistudioapi-common-angular";

@Component({
    selector: 'classification',
    templateUrl: 'classification.component.html',
    styleUrls: ['classification.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class ClassificationComponent implements OnInit {
    @Input() datum: Datum = {};
    @Input() isInference: boolean = false;
    imgQuality: string = "raw";
    title: string = "";

    baseUrl: string = "";
    projectUuid: string|undefined = "";

    constructor(private activeModal: NgbActiveModal, private config: Config, private aiProjectService: AiProjectService){}

    ngOnInit() {
        if(!this.isInference) {
            for (let aClass of this.datum?.datumLabels || []) {
                if (this.title === "") {
                    this.title += aClass.datumClass?.name;
                } else {
                    this.title += ", " + aClass.datumClass?.name;
                }
            }
        }else {
            for (let aRec of this.datum?.recognitions || []) {
                if (this.title === "") {
                    this.title += aRec.datumClass?.name + " ("+ (100*(aRec.confidence || 0)).toFixed(2) +"%)";
                } else {
                    this.title += ", " + aRec.datumClass?.name + " ("+ (100*(aRec.confidence || 0)).toFixed(2) +"%)";
                }
            }
        }
        this.baseUrl = this.config.baseUrl;
        this.projectUuid = this.aiProjectService.getAiProject().projectUuid;
    }

    closeModal () {
        this.activeModal.close();
    };

}
