import {Component} from "@angular/core";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'init-screen',
    templateUrl: 'init-screen.component.html'
})

export class InitScreenComponent {

    status_project_init = false;

    constructor(private aiStudioObservables: AiStudioObservables, private translateService: TranslateService) {

        this.aiStudioObservables.STATUS_PROJECT_INIT$.subscribe((init: boolean) => {
            this.status_project_init = init;
        });
    }

    showWizard() {
        this.aiStudioObservables.SHOW_WIZARD$.next(true);
    };



}
