import {Config} from "global/config";
import {OAuthModuleConfig} from "angular-oauth2-oidc";


export function authConfigFactory(service: Config): OAuthModuleConfig {
    console.log("Providing auth config with "+ service.baseUrl);
    return {
        resourceServer: {
            allowedUrls: [service.baseUrl],
            sendAccessToken: true,
        }
    };
}
