import {HttpClient} from "@angular/common/http";
import {Config} from "global/config";
import {
    AiProject,
    Datum,
    DatumClass,
    Model,
    ProjectType,
    TrainingStatus
} from "@teamviewer/aistudioapi-common-angular";
import {Injectable} from "@angular/core";
import {
    BACKGROUND,
    ModelTrainingStatus,
    STATUS_NO_FREE_PROJECT,
    STATUS_PROJECT_INIT,
    STATUS_PROJECT_LOAD_FAILED,
    STATUS_PROJECT_LOADED,
    STATUS_PROJECT_LOADING
} from "utils/project-utils";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {TagView, TagViewType} from "@teamviewer/aistudioapi-aistudio-angular";
import _ from "lodash";


@Injectable()
export class AiProjectService{
    private aiProject: any = null;

    constructor(private http: HttpClient, private config: Config, private aiStudioObservables: AiStudioObservables){
    }


    getAiProject(): AiProject {
        return this.aiProject;
    }

    setAiProject(aiProject: any) {
        this.aiProject = aiProject;
    }

    getFirstFreeAiProject(projects: AiProject[]) {
        const freeProjects = projects.filter((project: AiProject) => !project.editingBy);
        return freeProjects;
    }

    getTrainingModels(): Model[] {
        return this.aiProject?.models || [];
    }

    setTrainingModels(models: Model[]) {
        this.aiProject.models = models;
    }

    setProjectClasses(classes: DatumClass[]) {
        this.aiProject.classes = classes;
    }

    getAllDatumsFromProject() {
        let allDatums: Datum[] = [];
        this.aiProject?.subDatasets.map((subDataset: any) => allDatums = allDatums.concat(subDataset.datums));
        return allDatums;
    }

    getPublishedTrainingModel(): Model {
        return this.aiProject?.models.filter((model: Model) => model.published)[0];
    }

    getTrainedTrainingModel(): Model {
        return this.aiProject?.models.filter((model: Model)  => model.trainingStatus === TrainingStatus.Trained)[0];
    }

    getNotBackgroundClasses(): DatumClass[] {
        return this.aiProject.classes.filter((aClass: DatumClass) => aClass.name !== BACKGROUND);
    }

    getBackgroundClass(): DatumClass {
        return this.aiProject.classes.filter((aClass: DatumClass) => aClass.name === BACKGROUND)[0];
    }

    getAllAiProjects(): Promise<AiProject[] | undefined> {
        const endpoint = this.config.baseUrl + '/aiProject?projectTypes=' + [ProjectType.ObjectDetection, ProjectType.ImageClassificationSingleLabel].join(',');
        return this.http.get<AiProject[]>(endpoint).toPromise();
    }

    getAiProjectByUuid(uuid: string): Promise<AiProject | undefined >  {
        const endpoint = this.config.baseUrl + `/aiProject/${uuid}`;
        return this.http.get<AiProject>(endpoint).toPromise().then((result: AiProject | undefined) => {
            return this.aiProject = result;
        });
    }

    newAiProject(aiProject: AiProject): Promise<any> {
        const endpoint = this.config.baseUrl + '/aiProject';
        return this.http.post<AiProject>(endpoint, aiProject).toPromise();
    }

    updateAiProject(aiProject: AiProject): Promise<any> {
        const endpoint = this.config.baseUrl + '/aiProject/' + aiProject.projectUuid;
        return this.http.put(endpoint, aiProject).toPromise();
    }

    isObjectDetectionProject() {
        return this.getAiProject() && this.aiProject?.projectType === ProjectType.ObjectDetection;
    }

    isSingleLabelProject() {
        return this.getAiProject() && this.aiProject?.projectType === ProjectType.ImageClassificationSingleLabel;
    }

    isAugmentationSettingsSet() {
        for (const aClass of this.aiProject?.classes) {
            if (aClass.augmentationSettings && (aClass.augmentationSettings.verticalFlip === null || aClass.augmentationSettings.horizontalFlip === null)) {
                return false;
            }
        }
        return true;
    }

    isTrainingNameExist(name: string) {
        return this.aiProject?.models.filter((model: Model) => model.name === name).length > 0;
    }

    updateTrainingModels(model?: Model) {
        if (model) {
            const endpoint = this.config.baseUrl + '/aiTraining/model/' + model.modelUuid;
            this.http.get<Model>(endpoint).toPromise().then(model => {
                this.updateModel(model!);
            });
        } else {
            const models = this.getAiProject()?.models || [];
            for (const model of models) {
                if (model.trainingStatus !== TrainingStatus.Trained &&  model.trainingStatus !== TrainingStatus.Failed) {
                    const endpoint = this.config.baseUrl + '/aiTraining/model/' + model.modelUuid;
                    this.http.get<Model>(endpoint).toPromise().then(model => {
                        this.updateModel(model!);
                    });
                }
            }
        }
    }

    async checkForTrainingModelsUpdate(): Promise<void> {

        const changedModels: Model[] = [];
        const models = this.getAiProject()?.models || [];

        for (const model of models) {
            if (model.trainingStatus !== TrainingStatus.Trained && model.trainingStatus !== TrainingStatus.Failed) {
                const endpoint = this.config.baseUrl + '/aiTraining/model/' + model.modelUuid;
                const fetchedModel: Model = await this.http.get<Model>(endpoint).toPromise().then((fetchedModel) => {
                    return fetchedModel!;
                });

                if (model.trainingStatus !== fetchedModel.trainingStatus) {
                    console.log('Status changed');
                    changedModels.push(fetchedModel);
                    this.updateModel(fetchedModel);
                } else if (!_.isEqual(model.trainLogs, fetchedModel.trainLogs)) {
                    console.log('Logs changed');
                    changedModels.push(fetchedModel);
                    this.updateModel(fetchedModel);
                }
            }
        }

        if (changedModels?.length > 0) {
            for (const model of changedModels) {
                if (model !== undefined) {
                    this.aiStudioObservables.updateStatusSelectedModelHasChanged$(model.modelUuid!);
                }
            }
        }
    }




    updateModel(model: Model) {
        for (let i = 0; i < this.aiProject?.models.length; i++) {
            if (this.aiProject.models[i].id === model.id) {
                this.aiProject.models[i] = model;
                break;
            }
        }
    }

    getPublishedModelAsFile() {
        // TODO: Test
        const model = this.getPublishedTrainingModel();
        const endpoint = this.config.baseUrl + '/aiStorage/' + model.aiProject?.projectUuid + '/model/' + model.modelUuid + '/files';
        return this.http.get(endpoint, {responseType: 'blob', observe: 'response', headers: { Accept: 'application/octet-stream'}}).toPromise();
    }

    updateAugmentationSettings(datumClass: DatumClass) {
        const endpoint = this.config.baseUrl + '/aiProject/' + this.getAiProject().projectUuid + '/datumClass/' + datumClass.id;
        return this.http.put<DatumClass>(endpoint, datumClass).toPromise();
    }

    deleteAiProject() {
        const endpoint = this.config.baseUrl + '/aiProject/' + this.getAiProject().projectUuid;
        return this.http.delete(endpoint).toPromise();
    }

    deleteSelectedAiProject(aiProject: AiProject) {
        const endpoint = this.config.baseUrl + '/aiProject/' + aiProject.projectUuid;
        return this.http.delete(endpoint).toPromise();
    }

    updateProjectStatus(status: string) {

        this.aiStudioObservables.updateStatusProjectInit$(false);
        this.aiStudioObservables.updateStatusProjectLoaded$(false);
        this.aiStudioObservables.updateStatusProjectLoadFailed$(false);
        this.aiStudioObservables.updateStatusProjectLoaded$(false);
        this.aiStudioObservables.updateStatusNoFreeProject$(false);

        if (status === STATUS_PROJECT_LOADING) {
            this.aiStudioObservables.updateStatusProjectLoading$(true);
        } else if (status === STATUS_PROJECT_INIT) {
            this.aiStudioObservables.updateStatusProjectInit$(true);
        } else if (status === STATUS_PROJECT_LOADED) {
            this.aiStudioObservables.updateStatusProjectLoaded$(true);
        } else if (status === STATUS_PROJECT_LOAD_FAILED) {
            this.aiStudioObservables.updateStatusProjectLoadFailed$(true);
        } else if (status === STATUS_NO_FREE_PROJECT) {
            this.aiStudioObservables.updateStatusNoFreeProject$(true);
        }
    }

    /**
     * Fetches the tagView of a project for a specific view
     * @param {string} projectUuid Id of the project where the datumclass is updated
     * @param {TagViewType} tagView The view for which the tagView is fetched
     */

    getObjectsFromProject(projectUuid: string, tagView: TagViewType): Promise<any> {
        const endpoint = this.config.baseUrl + '/aiProject/' + projectUuid + '/tagView?tagView=' + tagView;
        return this.http.get(endpoint).toPromise();
    }

    /**
     * Checks if project is ready for Training. Every tag datum count needs to be aboveMinimumAmount
     * @param {string} projectUuid Id of the project
     * @param {TagViewType} tagView The view for which the tagView is fetched
     */

    async objectsTrainingReady(projectUuid: string, tagView?: TagView[]) {
        try {
            if (!tagView) {
                tagView =
                    (await this.getObjectsFromProject(projectUuid, TagViewType.Ready)) || [];
            }
            return (
                tagView!.length > 0 &&
                tagView!.filter((obj: TagView) => !obj.aboveMinimumAmount).length === 0
            );
        } catch (error) {
            console.warn('failed to check if project is ready for training: ', error);
            return false;
        }
    }

    /**
     * Returns project training status
     * @param {string} projectUuid Id of the project
     * @param {Model[]} model Project models
     */

    async getProjectTrainingStatus(projectUuid: string, models: Model[]): Promise<ModelTrainingStatus> {
        const trainingReady = await this.objectsTrainingReady(projectUuid || '');
        const allModels = _.orderBy(models, 'createdAt', 'desc');
        const publishedModel = allModels.filter((model: Model) => model.published);

        if (allModels.length > 0 && allModels[0].trainingStatus) {
            // There's a published model
            if (publishedModel.length > 0 && publishedModel[0].trainingStatus) {
                // If there's another model running
                if (this.trainingExists(models)) {
                    return ModelTrainingStatus.TRAINING;
                } else {
                    return ModelTrainingStatus[publishedModel[0].trainingStatus];
                }
            } else {
                // There's no published model, which means the one that exists is either starting or training
                if (trainingReady) {
                    return ModelTrainingStatus.READY_FOR_TRAINING;
                } else {
                    return ModelTrainingStatus[allModels[0].trainingStatus];
                }
            }
        } else {
            if (trainingReady) {
                return ModelTrainingStatus.READY_FOR_TRAINING;
            } else {
                // No models, not ready for training and NOT_STARTED
                return ModelTrainingStatus.NOT_STARTED;
            }
        }
    }

    trainingExists = (models: Model[]) => {
        // Check models for project.
        if (models.length > 0) {
            return models.some(
                (model: Model) =>
                    model.trainingStatus === TrainingStatus.NotStarted ||
                    model.trainingStatus === TrainingStatus.Training
            );
        } else { return false; }
    }
}
