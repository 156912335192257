import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {AiProjectService} from 'services/aiStudio/ai-project.service';
import {LoadingService} from 'services/loading.service';
import {NotificationService} from 'services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {DATASET, STATUS_PROJECT_LOAD_FAILED, STATUS_PROJECT_LOADED, STATUS_PROJECT_LOADING} from 'utils/project-utils';
import {Router} from '@angular/router'
import {TagViewService} from "services/aiStudio/tag-view.service";
import {DatumService} from 'services/aiStudio/datum.service';
import {UserService} from "services/aiStudio/user.service";
import {ProjectType, UserPreferences} from "@teamviewer/aistudioapi-common-angular";

@Component({
    selector: 'app-root',
    templateUrl: 'ai-studio.component.html',
    styleUrls: ['ai-studio.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class AiStudioComponent implements OnInit {

    //ToDo: Check if this timeouts are still needed
    slowUploadStatusTimeout:any;
    fastUploadStatusTimeout:any;
    interval: any;


    private userPreferences: UserPreferences = {};

    private updateInterval: any = null;

    constructor(
        private aiStudioObservables: AiStudioObservables,
        private aiProjectService: AiProjectService,
        private tagViewService: TagViewService,
        private loadingService: LoadingService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private router: Router,
        private datumService: DatumService,
        private userService: UserService
    ) {
        this.aiStudioObservables.selectProject$.subscribe((projectUuid: any) => {
            this.loadSelectedAiProject(projectUuid);
        });
    }

    ngOnInit() {
        this.aiProjectService.updateProjectStatus(STATUS_PROJECT_LOADING);
        this.preventImageDrop();

        this.aiStudioObservables.updateStatusProjectLoadFailed$(false);

        this.updateInterval = setInterval(() => {
            this.aiProjectService.checkForTrainingModelsUpdate();
        }, 30000);
    }


    // AI Studio allows user to drag and drop to upload images, however we want this to happen only if the user drop
    // the image in the droppale area, this function will disable the images from dropping in other areas and loads
    // the image.
    preventImageDrop() {
        window.addEventListener("dragover", function (e) {
            e = e || event;
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e = e || event;
            e.preventDefault();
        }, false);
    }

    //ToDo: Check if the Loading Behaviour is still wanted
    loadSelectedAiProject(projectUuid: string) {
        if(projectUuid !== this.aiProjectService.getAiProject()?.projectUuid) {
            this.loadingService.show(this.translateService.instant('ai-studio.feedback.loading-project'));
        } else {
            this.loadingService.show(this.translateService.instant('ai-studio.feedback.updating-project'));
        }
        // Unlock current workflow if there is any
        if (projectUuid) {

            // Load AI Project
            this.aiProjectService.getAiProjectByUuid(projectUuid).then(async (response: any) => {
                if(projectUuid !== this.aiProjectService.getAiProject().projectUuid) {
                    this.notificationService.info(this.translateService.instant('ai-studio.feedback.project-loaded', {projectName: this.aiProjectService.getAiProject().name}));
                }
                // Lock current workflow
                this.userService.userPreferences.lastProject = projectUuid;
                this.userService.saveUserPreference();

                // Load AI project tag views depends on the project type.
                if (response.projectType === ProjectType.ObjectDetection) {// Object Detection
                    await this.tagViewService.fetchNotReadyTagViews();
                    await this.tagViewService.fetchWellSetTagViews();
                    await this.tagViewService.fetchInferenceTagViews();

                } else { // Image Classification
                    await this.tagViewService.fetchClassificationTagViews();
                    await this.tagViewService.fetchInferenceTagViews();
                }

                //Get initial well set datums for dataset page
                this.datumService.selectPage(1, false, "", true);

                this.aiProjectService.updateProjectStatus(STATUS_PROJECT_LOADED);

                this.aiStudioObservables.updateStatusPublishedModelHasChanged$(this.aiProjectService.getPublishedTrainingModel());

                //Change view to dataset tab
                //this.router.stateService.go(DATASET);
                this.router.navigate([DATASET]);

            }).catch(() => {
                    this.aiProjectService.updateProjectStatus(STATUS_PROJECT_LOAD_FAILED);
                    console.error("Failed to load AI project '" + projectUuid + "'...");
                    this.loadingService.hide();
                }
            )
        }
    };

    //ToDo:
    setSlowUploadStatusInterval() {
        if (!this.slowUploadStatusTimeout) {
            if (this.fastUploadStatusTimeout) {
                this.interval.cancel(this.fastUploadStatusTimeout);
                this.fastUploadStatusTimeout = null;
            }
        }
    };
}
