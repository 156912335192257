import {Component, Input, OnDestroy, QueryList, ViewChildren, ViewEncapsulation} from '@angular/core';

import {UploadItem} from './upload.item';
import {ImageCardComponent} from '../image-card/image-card.component';
import {IMAGE_PREVIEW_SIZE} from "utils/project-utils";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "services/authentication.service";
import {
    loadAndSetAiStudioImageAnnotations,
    loadAndSetCOCOImageAnnotations,
    loadAndSetYOLOImageAnnotations,
    SUPPORTED_ANNOTATION_FORMATS
} from "utils/annotation-utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {VideoReviewComponent} from "apps/aiStudio/dataset/video-review/video-review.component";

@Component({
    selector: 'uploader',
    templateUrl: 'uploader.component.html',
    styleUrls: ['uploader.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class UploaderComponent implements OnDestroy {
    @ViewChildren('imageCardComponent') imageCards?: QueryList<ImageCardComponent>;
    @Input() isImageUpload: boolean = true;

    onUploadComplete: ((report: any) => void) | undefined;

    supportedAnnotationFormats = SUPPORTED_ANNOTATION_FORMATS;

    selectedAnnotationFormat: number = 1;

    public files: UploadItem[] = [];
    private cardsArray: ImageCardComponent[] = [];
    imagePreviewSize = IMAGE_PREVIEW_SIZE;
    imagePreviewCheck = false;
    enableProgressBar: boolean | undefined = false;

    showUploadReport = false;
    uploadCounter = 0;

    report = {
        successes: 0,
        errors: 0,
        duplicates: 0,
        maxSizeExceeded: 0,
        annotationErrors: 0,
    }

    hasFrontlineRole: boolean = false;

    constructor(private translateService: TranslateService,
                private modalService: NgbModal,
                private authenticationService: AuthenticationService) {
        this.hasFrontlineRole = this.authenticationService.hasRole(("Frontline_AI_User"));
    }

    increaseImagePreviewSize() {
        this.imagePreviewSize += IMAGE_PREVIEW_SIZE;
        this.imagePreviewCheck = true
    }

    decreaseImagePreviewSize() {
        this.imagePreviewSize = IMAGE_PREVIEW_SIZE;
        this.imagePreviewCheck = false
    }

    getImageContent(anUploadItem: UploadItem): void {
        const reader = new FileReader();
        reader.onload = () => {
            var img = new Image();
            img.onload = () => {
                anUploadItem.width = img.width;
                anUploadItem.height = img.height;
            };

            img.src = reader.result as string
            anUploadItem.content = reader.result as string;
        };
        if (anUploadItem.file) {
            reader.readAsDataURL(anUploadItem.file);
        }
    }

    clearAllAnnotations(): void {
        if(this.files.length > 0){
            this.files.forEach(uploadItem => uploadItem.annotation = null);
        }
    }

    selectAnnotationFiles(evt: any): void{
        this.clearAllAnnotations();

        if (evt?.target?.files) {
            const selectedFormat: number = SUPPORTED_ANNOTATION_FORMATS
                .find(format => format.id === this.selectedAnnotationFormat)?.id;
            const annotationFiles: File[] = Array.from(evt.target.files);

            if(selectedFormat && selectedFormat === 1){
                // AiStudio format. The user is either uploading a single annotation file for all images (annotations.json)
                // or a single file json file for each image. The name of image is included in the json file
                // For classification: the imageLabel is used to label the images
                // For Object detection: the objectName and bbox in the bboxes array is used to label the image. bbox: [xCenter,yCenter,width,height]
                loadAndSetAiStudioImageAnnotations(this.files, annotationFiles);
            } else if(selectedFormat && selectedFormat === 2){
                // COCO format. The user is uploading a single annotation.json file for all images.
                // The format of the file should follow the COCO format strictly https://docs.aws.amazon.com/rekognition/latest/customlabels-dg/md-coco-overview.html
                // bbox: [xMin,yMin,width,height]
                if(annotationFiles.length === 1 && annotationFiles[0].name.endsWith("json")){
                    loadAndSetCOCOImageAnnotations(this.files, annotationFiles[0]);
                } else {
                    console.error("Please provide a single file in .json format")
                }
            } else if (selectedFormat && selectedFormat === 3){
                // YOLO format. The user is uploading an annotation file per image and a .names file with the names of the objects
                // The format of the file should follow https://github.com/AlexeyAB/darknet#how-to-train-to-detect-your-custom-objects
                // bbox: [xCenter,yCenter,width,height] all in relative values 0 to 1
                const objectNames = annotationFiles.find(annFile => annFile.name.endsWith("names"));
                if(objectNames){
                    loadAndSetYOLOImageAnnotations(this.files, objectNames, annotationFiles);
                } else {
                    console.error("Please provide a .names file with the names of the objects");
                }
            }
        }
    }

    selectFiles(evt: any): void {
        this.files = [];
        if (evt) {
            if (evt.target?.files!) {
                if (evt.target?.files[0].type.startsWith("video")) {
                    let modalInstance = this.modalService.open(VideoReviewComponent, {
                        animation: true,
                        size: 'col-12 no-padding',
                        backdrop: 'static',
                    })

                    modalInstance.componentInstance.videofile = evt.target.files[0];
                    modalInstance.componentInstance.uploader = this;
                } else
                    for (let i = 0; i < evt.target.files.length; i++) {
                        if (evt.target?.files[i].type.startsWith("image")) {
                            const anUploadItem: UploadItem = {
                                file: evt.target.files[i],
                                name: evt.target.files[i].name,
                                blob: null,
                                upload: null,
                                hasError: false,
                                isUploaded: false,
                                isDuplicate: false,
                                maxSizeExceeded: false,
                                content: '',
                                width: 0,
                                height: 0,
                                annotation: null,
                                annotationError: false
                            };
                            this.files.push(anUploadItem);
                            this.getImageContent(anUploadItem);
                        } else {
                            console.log("Only accept images or mp4")
                        }
                    }
            } else if(evt.item) {
                if (evt.item(0).type.startsWith("video")) {
                    let modalInstance = this.modalService.open(VideoReviewComponent, {
                        animation: true,
                        size: 'col-12 no-padding',
                        backdrop: 'static',
                    })

                    modalInstance.componentInstance.videofile = evt.item(0);
                    modalInstance.componentInstance.uploader = this;
                } else {
                    for (let i = 0; i < evt.length; i++) {
                        if (evt.item(i).type.startsWith("image")) {
                            const anUploadItem: UploadItem = {
                                name: evt.item(i).name,
                                file: evt.item(i),
                                blob: null,
                                upload: null,
                                hasError: false,
                                isUploaded: false,
                                isDuplicate: false,
                                maxSizeExceeded: false,
                                content: '',
                                width: 0,
                                height: 0,
                                annotation: null,
                                annotationError: false
                            };
                            this.files.push(anUploadItem);
                            this.getImageContent(anUploadItem);
                        } else {
                            console.log("Only accept images or mp4")
                        }
                    }
                }
            }
        }
    }

    removeAllEntries(): void {
        this.showUploadReport = false;
        this.enableProgressBar = false;
        this.files = [];
    }

    removeEntry(uploadItem: UploadItem): void {
        this.files = this.files.filter(anUploadItem => anUploadItem !== uploadItem);
    }

    clearCompleted(): void {
        this.files = this.files.filter(anUploadItem => !anUploadItem.isUploaded);
    }

    finishedUpload($event: UploadItem) {
        if(this.uploadCounter<this.cardsArray.length) {
            this.cardsArray[this.uploadCounter].upload()
        }
        this.uploadCounter++
    }

    uploadAll(): void {
        if (this.imageCards) {
            this.cardsArray = this.imageCards.toArray();
            this.enableProgressBar = true;
            this.uploadCounter = 0;
            for(this.uploadCounter; this.uploadCounter<5 && this.uploadCounter<this.cardsArray.length; this.uploadCounter++) {
                this.cardsArray[this.uploadCounter].upload()
            }

            let checkInterval = setInterval(() => {
                if(this.files.length > 0 && this.hasAllCompleted()) {
                    // Delete all Images which are
                    clearInterval(checkInterval);
                    this.createUploadReport();
                    this.clearCompleted();
                }
            }, 1000);
        }
    }

    getCompletedProgressPercentage(): number {
        let overallProgressPercentage = 0;
        this.imageCards?.forEach(imageCard => {
            if (!imageCard.uploadItem.maxSizeExceeded) {
                overallProgressPercentage += imageCard.progressBarPercent
            } else {
                overallProgressPercentage += 100
            }
        })
        return Math.floor((overallProgressPercentage / this.files.length));
    }

    hasAllCompleted(): boolean {
        let result = true;
        this.files.forEach(anUploadItem => {
            if(!anUploadItem.isUploaded) {
                if(!(anUploadItem.isDuplicate || anUploadItem.maxSizeExceeded || anUploadItem.annotationError)) {
                    result = false;
                }
            }
        });
        return result;
    }

    progressFilesUploadContent(): string {
        let progressBarContent: string;
        if (this.getCompletedProgressPercentage() < 100) {
            progressBarContent = this.getCompletedProgressPercentage() + '%';
        }
        else {
            progressBarContent = this.translateService.instant('ai-studio.feedback.uploader.is-uploading');
        }
        return progressBarContent;
    }

    createUploadReport() {

        this.report.successes = 0;
        this.report.errors = 0;
        this.report.duplicates = 0;
        this.report.maxSizeExceeded = 0;
        this.report.annotationErrors = 0;

        this.files.forEach(anUploadItem => {
            if(anUploadItem.isUploaded) {
                this.report.successes += 1;
            }
            if(anUploadItem.hasError) {
                this.report.errors += 1;
            }
            if(anUploadItem.isDuplicate) {
                this.report.duplicates += 1;
            }
            if(anUploadItem.maxSizeExceeded) {
                this.report.maxSizeExceeded += 1;
            }
            if(anUploadItem.annotationError) {
                this.report.annotationErrors += 1;
            }
        });
        this.showUploadReport = true;

        if(this.onUploadComplete) {
            this.onUploadComplete(this.report);
        }
    }

    ngOnDestroy(): void {
        this.imagePreviewCheck = false
    }

    resetFilePicker(evt: any): void {
        if(evt) {
            evt.target.value = null;
        }
    }
}
