<div class="form-check p-0" *ngIf="backgroundTagView !== undefined">
    <checkbox id="{{'background-tag-check-box-' + tabSelected }}" (onChange)="backgroundTagView.selected=$event; updateViewImages(tabSelected)" [checked]="backgroundTagView.selected"></checkbox>
    <label class="tag-input-section" for="{{'background-tag-check-box-' + tabSelected }}">
        <ai-tag
            [hidden]="backgroundTagView.tagName !== BACKGROUND"
            [color]="backgroundTagView.datumClass?backgroundTagView.datumClass.color:''"
            [name]=" 'ai-studio.dataset.tab.background' | translate "
            [boundingboxNum]=" backgroundTagView.count">
        </ai-tag>
        <ai-tag
            [hidden]="backgroundTagView.tagName !== UNTAGGED"
            [name]="'Untagged'"
            [boundingboxNum]=" backgroundTagView.count">
        </ai-tag>
        <span class="extra-info-section" [hidden]="!showIcons && tabSelected != 'dataset'">
            <span>
                <i class="icon icon-info-circle" aria-hidden="true" container="body"
                   placement="right" [ngbTooltip]="'ai-studio.dataset.tab.background.tooltip' | translate"></i>
            </span>

            <span [hidden]="backgroundTagView.aboveMinimumAmount" class="error-sign-color" style="display: inline-block;">
                <i class="icon icon-exclamation-triangle" aria-hidden="true" container="body"
                   placement="right" [ngbTooltip]="'ai-studio.dataset.tab.background-minimum-image-warning' | translate : {'minimumImageAmount' : MINIMUM_IMAGES}"></i>
            </span>

            <span [hidden]="!backgroundTagView.aboveMinimumAmount || (backgroundTagView.aboveMinimumAmount && backgroundTagView.balanced)"
                  class="warning-sign-color" style="display: inline-block;">
                <i class="icon icon-exclamation-triangle" aria-hidden="true" container="body"
                   placement="right" [ngbTooltip]="'ai-studio.dataset.tab.background-unbalanced-dataset-warning' | translate"></i>
            </span>
        </span>
    </label>
</div>

<hr class="background-divider" [hidden]="!backgroundTagView && !positiveTagViews || positiveTagViews.length < 0">

<div class="form-check p-0 mb-1" *ngFor="let tag of positiveTagViews | orderBy : 'tagName' : OrderBy.ASC" [hidden]="!positiveTagViews">
    <checkbox id="{{ 'input-dom-id-' + tag.tagName + '-' + tabSelected}}" (onChange)="tag.selected=$event; updateViewImages(tabSelected)" [checked]="tag.selected"></checkbox>
    <label class="tag-input-section" for="{{ 'input-dom-id-' + tag.tagName + '-' + tabSelected }}">
        <ai-tag
            [name]="tag.tagName"
            [color]="tag.datumClass.color"
            [boundingboxNum]="tag.count">
        </ai-tag>
        <span class="extra-info-section" [hidden]="!showIcons && tabSelected != 'dataset'">
            <span [hidden]="tag.aboveMinimumAmount" class="error-sign-color" style="display: inline-block;">
                <i class="icon icon-exclamation-triangle" aria-hidden="true" container="body"
                   placement="right" [ngbTooltip]="'ai-studio.dataset.tab.minimum-image-warning' | translate : {'minimumImageAmount' : MINIMUM_IMAGES}"></i>
            </span>
            <span [hidden]="!tag.aboveMinimumAmount || (tag.aboveMinimumAmount && tag.balanced)" class="warning-sign-color" style="display: inline-block;">
                <i class="icon icon-exclamation-triangle" aria-hidden="true" container="body"
                   placement="right" [ngbTooltip]="'ai-studio.dataset.tab.unbalanced-dataset-warning' | translate"></i>
            </span>
        </span>
    </label>
</div>
