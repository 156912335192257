import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {Directive, Input, NgModule} from "@angular/core";
import {VALIDATION_MESSAGES} from "../../validation-messages";
import {DatumClass} from "@teamviewer/aistudioapi-common-angular";
import {BACKGROUND} from "../../../utils/project-utils";


@Directive({
    selector: '[noDuplicatedClassName]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: DuplicatedClassValidator,
            multi: true
        }
    ]
})

export class DuplicatedClassValidator implements Validator {

    @Input() noDuplicatedClassName: DatumClass[] = [];


    validate(control: AbstractControl): ValidationErrors | null {
        if(control.value !== null) {
            if((control.value.length <= 0 || control.value?.trim() === '') && (!control.parent?.value["val"] || control.parent?.value["val"].trim() === '')) {
                return {
                    noEmptyClassName: {},
                }
            }

            if (this.noDuplicatedClassName.length > 0) {
                for (let datumClass of this.noDuplicatedClassName) {
                    if (datumClass.name?.toLowerCase() == control.value.trim().toLowerCase() || control.value?.trim().toLowerCase() === BACKGROUND) {
                        //Checks if the input is not background
                        if(control.value?.trim().toLowerCase() === BACKGROUND) {
                            return {
                                noBackgroundClassName: {},
                            }

                        } else {
                            return {
                                noDuplicatedClassName: {},
                            };
                        }
                    }
                }
            }
        }
        return null;
    }
}

@NgModule({
    declarations: [DuplicatedClassValidator],
    exports: [DuplicatedClassValidator],
    providers: [
        {
            provide: VALIDATION_MESSAGES,
            useValue: {
                noDuplicatedClassName: () => 'ai-studio.feedback.duplicated-objects',
                noBackgroundClassName: () => 'ai-studio.feedback.reserve-object-name',
                noEmptyClassName: () => 'ai-studio.feedback.empty-object-name'
            },
            multi: true
        }
    ]
})
export class DuplicatedClassValidatorModule {}
