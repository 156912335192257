import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {Config} from "global/config";
import _ from "lodash";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {DatumService} from "services/aiStudio/datum.service";
import {LoadingService} from "services/loading.service";
import {NotificationService} from "services/notification.service";
import {Datum, DatumClass} from "@teamviewer/aistudioapi-common-angular";
import {StartTrainingComponent} from "../start-training/start-training.component";

interface ExampleDatumClass {
    exDatumClass?: any,
    exDatum?: any
}

@Component({
    selector: 'augmentation-settings',
    templateUrl: 'augmentation-settings.component.html',
    styleUrls: ['augmentation-settings.component.sass'],
    encapsulation: ViewEncapsulation.None
})

//TODO: fix bugs with icons
export class AugmentationSettingsComponent implements OnInit {
    @HostBinding('class') class = 'modal-content';

    @Input() fromTraining: any = false;
    currentWizardStep = 1;
    wizardStepsCount = 0;

    dataAugmentationSet = false;
    exampleImages = [];
    exDatumClasses: ExampleDatumClass[] = [];

    tmpDatumClasses: any[] = [];

    baseUrl: string = "";
    projectUuid: string|undefined = "";

    exampleImagesLoaded: boolean = false;

    constructor(private config: Config, private activeModal: NgbActiveModal, private aiProjectService: AiProjectService, private datumService: DatumService, private translateService: TranslateService, private loadingService: LoadingService, private modalService: NgbModal, private notificationService: NotificationService) {
    }

    ngOnInit(){
        this.baseUrl = this.config.baseUrl;
        this.projectUuid = this.aiProjectService.getAiProject().projectUuid;
        this.getNewExampleImages();
    }


    nextStep = () => {
        this.currentWizardStep = this.currentWizardStep + 1;
    };

    updateAugmentationSettings = () => {
        this.loadingService.show(this.translateService.instant('ai-studio.feedback.updating-augmentation'));

            // Clean up image property from the datums and update each class
            let updatedClasses: any[] = [];
            this.exDatumClasses.forEach((exDClass: ExampleDatumClass, index: number) => {
                updatedClasses.push(exDClass.exDatumClass);
            });

            let updatePromises: Promise<DatumClass | undefined>[] = [];
            for (let updatedClass of updatedClasses) {
                updatePromises.push(this.aiProjectService.updateAugmentationSettings(updatedClass))
            }
            Promise.all(updatePromises).then(() => {
                this.aiProjectService.setProjectClasses(updatedClasses);
                if (this.fromTraining) {
                    this.modalService.open(StartTrainingComponent, {
                        animation: true,
                        backdrop: 'static',
                        size: "col-5"
                    })
                }

                this.loadingService.hide();
                this.closeModal();

                let msg = this.translateService.instant('ai-studio.feedback.augmentation-updated');
                console.info(msg);
                this.notificationService.info(msg);

            }).catch(() => {
                let msg = this.translateService.instant('ai-studio.feedback.augmentation-update-failed');
                console.error(msg);
                this.notificationService.info(msg);
                this.loadingService.hide();
            });
    };
    getNewExampleImages() {
        //Get a datum representing each class on the project
        let exampleDatums: Datum[] = [];

        this.tmpDatumClasses = _.orderBy(this.aiProjectService.getAiProject().classes, "name");

        this.tmpDatumClasses.forEach((aClass: any, index: number) => {
            this.exDatumClasses[index] = {};
            this.exDatumClasses[index].exDatumClass = aClass;
        });

        if(this.exDatumClasses) {
            let fetchDatumPromises: Promise<any>[] = [];

            this.exDatumClasses.forEach((aClass: any, index: number) => {
                let fetchDatumPromise = this.datumService.getDatumWithClass(aClass.exDatumClass.name).then((response: any) => {
                    this.exDatumClasses[index].exDatum = response.content[0];
                    exampleDatums.push(response.content[0]);
                    this.wizardStepsCount = exampleDatums.length + 1;
                }).catch(() => {
                    console.error("Cannot get flip example images...");
                })
                fetchDatumPromises.push(fetchDatumPromise);

            })
            Promise.all(fetchDatumPromises).then(() => this.exampleImagesLoaded = true);
        }
    }

    nextStepBtnDisabled = (stepIndex: number) => {
        if (stepIndex > 1) {
            let horizontalFlipSet = this.exDatumClasses[stepIndex - 2].exDatumClass.augmentationSettings?.horizontalFlip;
            let verticalFlipSet = this.exDatumClasses[stepIndex - 2].exDatumClass.augmentationSettings?.verticalFlip;
            let rotateNinetyFlipSet = this.exDatumClasses[stepIndex - 2].exDatumClass.augmentationSettings?.rotateNinety;
            return !(horizontalFlipSet !== null && verticalFlipSet !== null && rotateNinetyFlipSet !== null);
        }
        return false;
    };


    closeModal = () => {
        this.activeModal.close();
    };


}
