<div id="open-dialog-modal" class="modal-content">
    <div class="modal-head">
        <h2 class="modal-title mb-2">
            <span>{{ 'ai-studio.start-training-dialog.title' | translate }}</span>
            <a class="float-right">
                <i class="icon icon-times" (click)="sendCancelEvent(); closeModal()"></i></a>
        </h2>
    </div>

    <div class="modal-body">
        <label for="trainingName">{{ 'ai-studio.start-training.check-list' | translate }}</label>
        <div *ngIf="(aiProjectService.isObjectDetectionProject() && numSelected>0) || numSelected>1" class="panel-group top-buffer bottom-buffer">
            <!-- Data Amount Check -->
            <div class="panel panel-default no-padding">
                <div *ngIf="dataAmountSufficient" class="panel-heading">
                    <h4 class="panel-title panel-title-ai">
                        <i class="icon icon-check ok-sign-color" aria-hidden="true"></i>
                        {{ 'ai-studio.start-training.amount-sufficient' | translate }}
                    </h4>
                </div>
                <div *ngIf="!dataAmountSufficient" class="panel-heading">
                    <h4 class="panel-title panel-title-ai">
                        <i class="icon icon-exclamation-triangle error-sign-color"
                            aria-hidden="true"></i>
                        {{ 'ai-studio.start-training.amount-not-sufficient' | translate }}
                    </h4>
                </div>

                <div id="amount-check">
                    <div *ngIf="!dataAmountSufficient" class="panel-body">
                        {{ 'ai-studio.start-training.object' | translate }}
                        <span *ngFor="let tagView of notSufficientTagViews; let index = index">
                                <b *ngIf="tagView.tagName === tagViewService.backgroundTag">{{ 'ai-studio.dataset.tab.background' | translate }}</b>
                                <b *ngIf="tagView.tagName !== tagViewService.backgroundTag">{{ tagView.tagName }}</b>
                            <span *ngIf="index < notSufficientTagViews.length - 2">, </span>
                            <span *ngIf="index == notSufficientTagViews.length - 2"> {{ 'ai-studio.start-training.and' | translate }} </span>
                        </span>
                        <b>&nbsp;</b>
                        <span [innerHtml]="'ai-studio.start-training.amount-not-sufficient.desc' | translate : {'minAmount' : minImgAmountPerTag} | safeHtml"></span>
                    </div>
                </div>
            </div>

            <!-- Data Balance Check -->
            <div class="panel panel-default no-padding">
                <div *ngIf="dataBalanced" class="panel-heading">
                    <h4 class="panel-title panel-title-ai">
                        <i class="icon icon-check ok-sign-color" aria-hidden="true"></i>
                        {{ 'ai-studio.start-training.dataset-balanced' | translate }}
                    </h4>
                </div>
                <div *ngIf="!dataBalanced" class="panel-heading">
                    <h4 class="panel-title panel-title-ai">
                        <i class="icon icon-exclamation-triangle warning-sign-color"
                            aria-hidden="true"></i>
                        {{ 'ai-studio.start-training.dataset-not-balanced' | translate }}
                    </h4>
                </div>

                <div id="balance-check">
                    <div *ngIf="!dataBalanced" class="panel-body">
                        <p>
                            {{ 'ai-studio.start-training.dataset-not-balanced.desc-1' | translate }}
                            <span *ngFor="let tagView of unbalancedTagViews; let index = index">
                                <b *ngIf="tagView.tagName === tagViewService.backgroundTag">{{ 'ai-studio.dataset.tab.background' | translate }}</b>
                                <b *ngIf="tagView.tagName !== tagViewService.backgroundTag">{{ tagView.tagName }}</b>
                                <span *ngIf="index < unbalancedTagViews.length - 2">, </span>
                                <span *ngIf="index == unbalancedTagViews.length - 2"> {{ 'ai-studio.start-training.and' | translate }} </span>
                            </span>.
                            <span *ngIf="hasFrontlineRole">{{ 'ai-studio.start-training.dataset-not-balanced.desc-2-frontline' | translate }}</span>
                            <span *ngIf="!hasFrontlineRole">{{ 'ai-studio.start-training.dataset-not-balanced.desc-2-public' | translate }}</span>

                        </p>
                    </div>
                </div>
            </div>

            <!-- Untagged Data Check -->
            <div class="panel panel-default no-padding">
                <div *ngIf="allDataTagged" class="panel-heading">
                    <h4 class="panel-title panel-title-ai">
                        <i class="icon icon-check ok-sign-color" aria-hidden="true"></i>
                        <span *ngIf="aiProjectService.isObjectDetectionProject()"> {{ 'ai-studio.start-training.all-well-set' | translate }}</span>
                        <span *ngIf="!aiProjectService.isObjectDetectionProject()"> {{ 'ai-studio.start-training.all-tagged' | translate }}</span>
                    </h4>
                </div>
                <div *ngIf="!allDataTagged" class="panel-heading">
                    <h4 class="panel-title panel-title-ai">
                        <i class="icon icon-exclamation-triangle warning-sign-color"
                            aria-hidden="true"></i>
                        {{ 'ai-studio.start-training.not-all-tagged' | translate }}
                    </h4>
                </div>

                <div id="untagged-data-check">
                    <div *ngIf="!allDataTagged && untaggedViewsAmount === 1" class="panel-body">
                        <span [innerHtml]="'ai-studio.start-training.not-all-tagged.desc-single' | translate : {'amount' : untaggedViewsAmount}| safeHtml"></span>
                    </div>
                    <div *ngIf="!allDataTagged && untaggedViewsAmount > 1" class="panel-body">
                        <span [innerHtml]="'ai-studio.start-training.not-all-tagged.desc-plural' | translate : {'amount' : untaggedViewsAmount}| safeHtml"></span>
                    </div>
                </div>
            </div>

            <!-- Only Background Tag Check -->
            <div class="panel panel-default no-padding">
                <div *ngIf="!backgroundOnly" class="panel-heading">
                    <h4 class="panel-title panel-title-ai">
                        <i class="icon icon-check ok-sign-color" aria-hidden="true"></i>
                        <span> {{ 'ai-studio.start-training.tags-ok' | translate }}</span>
                    </h4>
                </div>
                <div *ngIf="backgroundOnly" class="panel-heading">
                    <h4 class="panel-title panel-title-ai">
                        <i class="icon icon-exclamation-triangle error-sign-color"
                            aria-hidden="true"></i>
                        {{ 'ai-studio.start-training.tags-only-background' | translate }}
                    </h4>
                </div>
            </div>
        </div>

        <div *ngIf="(!aiProjectService.isObjectDetectionProject() && numSelected <= 1) || numSelected === 0" class="panel-group top-buffer bottom-buffer">
            <div class="panel panel-default no-padding">
                <div class="panel-heading">
                    <h4 class="panel-title panel-title-ai">
                        <i class="icon icon-exclamation-triangle error-sign-color"
                           aria-hidden="true"></i>
                        {{ 'ai-studio.start-training.more-tags-needed' | translate : {"amount": aiProjectService.isObjectDetectionProject()?1:2} }}
                    </h4>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <label >{{ 'ai-studio.start-training.select-objects' | translate }}</label>
            <div class="mt-2">
                <div class="form-check p-0 mb-1" *ngFor="let tag of positiveTagViews" [hidden]="!positiveTagViews">
                    <checkbox id="{{ 'input-dom-id-' + tag.tagName}}" (onChange)="tag.selected=$event; updateCheckList()" [checked]="tag.selected"></checkbox>
                    <label class="tag-input-section" for="{{ 'input-dom-id-' + tag.tagName}}">
                        <ai-tag
                            [name]="tag.tagName"
                            [color]="tag.datumClass.color"
                            [boundingboxNum]="tag.count">
                        </ai-tag>
                    </label>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <form-field-text-input
                class="col col-sm-12"
                label="{{ 'ai-studio.start-training.training-name' | translate }}"
                [elementId]="'training-name'"
                [(ngModel)]="trainingName"
                [disabled]="!allowTraining">
            </form-field-text-input>
            <div class="top-buffer text-danger" *ngIf="showTrainingNameEmptyError">
                {{ 'ai-studio.start-training.training-name.empty-error-msg' | translate }}
            </div>
            <div class="top-buffer text-danger" *ngIf="showTrainingNameExistError">
                {{ 'ai-studio.start-training.training-name.exist-error-msg' | translate }}
            </div>
        </div>

        <div class="top-buffer-20">
            <form-field-text-input
                columns="12"
                label="Extra Training Options"
                [elementId]="'extra-training-options'"
                [(ngModel)]="extraTrainingOptions"
                [disabled]="!allowTraining"
                *ngIf="hasDeveloperRole">
            </form-field-text-input>
        </div>
    </div>

    <div class="modal-footer">
        <button-secondary
            text="{{ 'wf-editor.toolbar.open.cancel' | translate }}"
            style="float: left;"
            (click)="closeModal();"
            event="Event,ai-training,cancel-start"
            projectuuid="{{project?.projectUuid}}">
        </button-secondary>
        <button-primary
            text="{{ 'ai-studio.start-training' | translate }}"
            [disabled]="!allowTraining"
            (click)="startTraining();"
            event="Event,ai-training,initialize-training,{{project?.projectType}}"
            projectuuid="{{project?.projectUuid}}">
        </button-primary>
    </div>
</div>
