<div id="video-review-dialog-modal" class="modal-content" style="padding: 0;">
    <div class="modal-head">
        <h2 class="modal-title">
            <span>{{ 'ai-studio.video-import.title' | translate }}</span>
            <a class="float-right" >
                <i class="icon icon-times" (click)="closeModal()"></i></a>
        </h2>
    </div>

    <div class="modal-body">
        <div class="row height100">
            <div class="col-sm-3 height100 leftPanel">
                <div class="bounding-box-tags" [hidden]="started">
                    <div class="confidence-slider">
                        <div class="propertyPanelTitle">{{ 'ai-studio.video-import.sample-rate' | translate }} {{samplePickRate}}</div>
                        <div class="range-slider-wrapper">
                            <i class="range-slider-icon-left icon icon-2x icon-minus-circle-solid"></i>
                            <div class="range-slider">
                                <input
                                       class="range-slider-range"
                                       type="range"
                                       [value]="samplePickRate"
                                       [(ngModel)]="samplePickRate"
                                       min="2"
                                       max="60"
                                       step="4"
                                       [disabled]=started>
                            </div>
                            <i class="range-slider-icon-right icon icon-2x icon-plus-circle-solid"></i>
                        </div>

                    </div>
                    <div style="margin-top:5%">
                        <button-primary
                            text="{{ 'ai-studio.video-import.start-sampling' | translate }}"
                            (click)="startSampling();">
                        </button-primary>
                    </div>
                </div>
                <div class="row height50 buttons-panel" role="group" *ngIf="!acceptAll" [hidden]="!started">
                    <div class="one-button" (click)="((acceptAll || showLoadingAnimation)? null: skipImage())">
                        <i class="icon icon-thumbs-up flipVertically text-black"></i>
                    </div>
                    <div class="other-button" (click)="((acceptAll || showLoadingAnimation)? null: addImage(undefined));">
                        <i class="icon icon-thumbs-up text-black"></i>
                    </div>
                </div>
                <div class="pull-right" style="margin-top:5%" *ngIf="!acceptAll" [hidden]="!started">
                    <button-primary
                        text="{{ 'ai-studio.video-import.accept-all' | translate }}"
                        (click)="triggerAcceptAll();">
                    </button-primary>
                    <button-primary
                        text="{{ 'ai-studio.video-import.continue' | translate }}"
                        (click)="closeModal();">
                    </button-primary>
                </div>
            </div>

            <div class="col-sm-9 height100 rightPanel">
                <div id="canvasContainer">
                    <div id="outerCanvasContainer height100">
                        <canvas class="img-canvas" id="videoImporterCanvas" width="400" height="400"></canvas>
                    </div>
                    <div class="loadingAnimation" *ngIf="showLoadingAnimation">
                        <svg class="frontline-loading-animation"><use xlink:href="#frontline"></use></svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
