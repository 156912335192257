import { Injectable } from "@angular/core";

@Injectable()
export class HighlightingService {
    
    activeHighlighting = false;
    interval: any;

    constructor(){}

    show(elementId: string) {
        let highlighter = document.getElementById("highlighter");
        let header = document.getElementById("header")

        if(highlighter){
            highlighter.classList.remove("hide");
        }

        if(header){
            header.classList.add("inBackground");
        }
        
        //add inForeground class to this element
        if(elementId){
            setTimeout(() => {
                let element =  document.querySelector(elementId)?.firstElementChild;
                element?.classList.add("inForeground");
            }, 0);
        }
    };

    hide() {
        let highlighter = document.getElementById("highlighter");
        let header = document.getElementById("header");

        if(highlighter){
            highlighter.classList.add("hide");
        }
        if(header){
            header.classList.remove("inBackground");
        }

        //remove inForeground classes
        let inForegroundElements = document.querySelector('.inForeground');
        inForegroundElements?.classList.remove('inForeground')
    };
     
}
