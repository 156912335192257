import {Component, HostBinding, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {Model} from "@teamviewer/aistudioapi-common-angular";

@Component({
    selector: 'training-options-info',
    templateUrl: 'training-options-info.component.html',
})

export class TrainingOptionsInfoComponent implements OnInit {
    @HostBinding('class') class = 'modal-content';
    @Input() selectedModel : Model = {};

    extraOptions: { [p: string]: object } | undefined;

    constructor(private translateService: TranslateService, private activeModal: NgbActiveModal) {
    }

    ngOnInit(){
        this.extraOptions = this.selectedModel.extraOptions
    }

    stringify(value: string) {
        return JSON.stringify(value)
    }

    closeModal = () => {
        this.activeModal.close();
    };
}
