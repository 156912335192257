import {Component, HostBinding, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import _ from "lodash";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {TagViewService} from "services/aiStudio/tag-view.service";
import {DialogConfirm} from "dialogs/dialog-confirm";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {newAiProject} from "utils/project-utils";
import {LoadingService} from "services/loading.service";
import {NotificationService} from "services/notification.service";
import {AiProject, Datum, DatumClass, ProjectType} from "@teamviewer/aistudioapi-common-angular";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {AuthenticationService} from "services/authentication.service";
import DOMPurify from "dompurify";
import {EventService} from "services/event.service";
import {NgForm} from "@angular/forms";
import {DatumService} from "services/aiStudio/datum.service";

@Component({
    selector: 'project-wizard',
    templateUrl: 'project-wizard.component.html',
    styleUrls: ['project-wizard.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class ProjectWizardComponent implements OnInit {
    @Input() fromToolbar: boolean = false;
    @Input() aiProject: AiProject | undefined;
    @HostBinding('class') class = 'modal-content';

    @ViewChild('datumClassForm', { static: false }) datumClassInput?: NgForm;

    // Depends on the mode the wizard will show different content
    EDIT_MODE = "edit";
    CREATE_MODE = "new";
    IMAGE_CLASSIFICATION_SINGLE_LABEL = ProjectType.ImageClassificationSingleLabel.toString();
    OBJECT_DETECTION = ProjectType.ObjectDetection.toString();
    mode: string = '';

    model: any = {};
    isModelEmpty: boolean = true;
    initProjectModel: any = {name: ""}; // This model is used for comparison, to check if there is modification.


    // Show counters
    wizardStepsCount = 5;
    wizardContextStepsCount = 7;
    currentWizardStep = 1;
    currentContextStep = 1;
    wizardStepsHeight: number = 100/this.wizardStepsCount;

    nameExist = false;
    objectError = false;
    objectErrorMsg = '';
    oldRenameObject: DatumClass = {};
    renameObject: DatumClass | null = null;
    currentClass = {val: '', desc: ''};
    progressBarHeight: string = '';

    savedClasses: any = [];
    newClasses: any = [];
    deletedClasses: any = [];
    savedSubDatasets: any;
    savedModels: any;

    hasFrontlineRole: boolean;
    private hasTVDevRole: boolean;
    //Flag to track if the update Class is enabled
    enableClassUpdate: boolean = false;
    //Saved Classes Exclude the one which will be updated
    savedClassesExcludeToBeUpdated: DatumClass = {}

    constructor(public tagViewService: TagViewService, private datumService: DatumService, private aiProjectService: AiProjectService, private authenticationService: AuthenticationService, private translateService: TranslateService, private dialogConfirm: DialogConfirm, private activeModal: NgbActiveModal, private loadingService: LoadingService, private notificationService: NotificationService, private aiStudioObservables: AiStudioObservables, private eventService: EventService) {
        this.hasFrontlineRole = this.authenticationService.hasRole("Frontline_AI_User");
        this.hasTVDevRole = this.authenticationService.hasRole("TV_INTERNAL_DEV");
    }

    ngOnInit(){
        if(!this.hasFrontlineRole) {
            this.wizardStepsCount = 4;
            this.wizardStepsHeight = 100/this.wizardStepsCount;
        }
        this.model = this.setupProjectWizard(this.aiProject);
        this.isModelEmpty = false
        this.updateprogressBarHeight();
    }

    updateprogressBarHeight = () => {
        this.progressBarHeight = (100 / this.wizardStepsCount * (this.currentWizardStep - 1)) + "%";
    };

    // If aiProject is null, then the wizard is creating a new project, otherwise is editing an existing one
    setupProjectWizard(aiProject?: AiProject){
        let model: any;

        //update mode variable
        if(this.fromToolbar){
            // For edit mode, skip intro step
            this.currentWizardStep = 2;
            this.mode = this.EDIT_MODE;

            if(aiProject !== undefined) {
                model = this.buildModelToEdit(aiProject)
            } else {
                model = this.buildModelToEdit()
            }

        } else {
            this.mode = this.CREATE_MODE;
            model = _.cloneDeep(newAiProject);
        }

        this.initProjectModel = _.cloneDeep(model);
        return model;
    }

    buildModelToEdit(aiProject?: AiProject) {
        let model: any;
        if(aiProject !== undefined) {
            model = _.cloneDeep(aiProject);
        } else {
            model = _.cloneDeep(this.aiProjectService.getAiProject());
        }
        let classesToEdit: any = [];

        //Not removing some properties causes infinite loop when detecting changes
        const {subDatasets , classes, models, ...modelToEdit} = model
        this.savedSubDatasets = subDatasets;
        this.savedModels = models;

        if(classes){
            this.savedClasses = classes;
            classes.forEach((aClass: any, index: number) => {
                classesToEdit[index] = {name: aClass.name, description: aClass.description, id: aClass.id}

            })
            modelToEdit.classes = classesToEdit;
        }

        if(model.context){
            try {
                modelToEdit.context = JSON.parse(model.context);
            } catch (e) {
                console.info("Tried to parse context as JSON but it is already JSON...");
            }
        }

        return modelToEdit;

    }

    // -------- Project Name
    addProjectNameWrapper(event: KeyboardEvent){
        if (event && event.key === "Enter") {
            this.goToWizardStep(3);
        }
        this.projectNameExist()
    };

    // -------- Project Class/Object
    confirmObjectInput(event: KeyboardEvent){
        if (this.datumClassInput?.valid) {
            if(this.renameObject) { // editing object name
                if (event && event.key === "Enter") {
                    if(this.currentClass.val.length > 25) {
                        this.objectError = true;
                        this.objectErrorMsg = this.translateService.instant('ai-studio.feedback.object-character-limit', {objectName: this.currentClass.val});
                    } else {
                        this.renameObject.name = this.currentClass.val;
                        this.renameObject.description = this.currentClass.desc;
                        this.updateObject();
                        this.renameObject = null;
                        this.currentClass.val = "";
                        this.currentClass.desc = "";
                        //Disable when update is completed
                        this.enableClassUpdate = false;
                    }
                } else if (event && event.key === "Escape") {
                    this.renameObject.name = this.oldRenameObject.name;
                    this.renameObject.description = this.oldRenameObject.description;
                    this.renameObject = null;
                    this.currentClass.val = "";
                    this.currentClass.desc = "";
                }
            } else { // creating object
                if (event && event.key === "Enter") {
                    this.addObject();
                }
            }
        }
    };

    addObject = () => {
        this.currentClass.val = DOMPurify.sanitize(this.currentClass.val)
        this.currentClass.desc = DOMPurify.sanitize(this.currentClass.desc)
        this.objectErrorMsg = this.translateService.instant('ai-studio.feedback.duplicated-objects', {objectName: this.currentClass.val});

        if (this.currentClass.val) {
            this.objectError = false;
            this.objectErrorMsg = "";

            this.model.classes.push({
                name: this.currentClass.val.trim().toLowerCase(),
                description: this.currentClass.desc.trim()
            });

            if(this.mode === this.EDIT_MODE){
                this.newClasses.push(
                    {name: this.currentClass.val.trim().toLowerCase(),
                        description: this.currentClass.desc.trim()}
                )
            }

            this.savedClasses.push({
                name: this.currentClass.val.trim().toLowerCase(),
                description: this.currentClass.desc.trim()
            });

            this.currentClass.val = '';
            this.currentClass.desc = "";
            this.nextContextBtnDisabled(this.currentWizardStep);
        }
    };

    updateObject = () => {
        let updateClass: DatumClass = this.savedClasses.filter((datumClass: DatumClass) => datumClass.id === this.renameObject?.id)[0]
        updateClass.name = this.renameObject?.name;
        updateClass.description = this.renameObject?.description;
    }

    enableObjectClassUpdate(datumClass: DatumClass) {
        this.oldRenameObject = { ...datumClass };
        this.renameObject = datumClass;
        this.enableClassUpdate = true;
        this.savedClassesExcludeToBeUpdated = this.savedClasses.filter((datumClass: DatumClass) => datumClass.name !== this.renameObject?.name);
        if(this.renameObject) {
            this.currentClass.val = this.renameObject.name ? this.renameObject.name: "";
            this.currentClass.desc = this.renameObject.description ? this.renameObject.description: "";
        }
    }

    nextStepBtnDisabled(stepIndex: number) {

          switch (stepIndex) {
            case 0:
            case 1: // Introduction
            case 5: // Project Context (already checked in nextContextBtnDisabled()
                return false;
            case 2: // Project Name
                if (this.model.name.length > 0 && !this.nameExist) {
                    return false;
                }
                break;
            case 3: // Detection Type
                if (this.model.projectType) return false;
                break;
            case 4: // Project Classes
                if (this.model.classes && this.model.classes.filter((aClass: any) => aClass.name !== this.tagViewService.backgroundTag).length > 0) return false;
                break;
            default:
                return true;
        }
        return true;
    };

    previousWizardStepOK = (stepNumber: number) => {
        let previousStepOK = true;

        for (let i = 0; i < stepNumber; i++) {
            if (i === 5) { // Project Context
                for (let j = 0; j <= this.wizardContextStepsCount; j++) {
                    if (this.nextContextBtnDisabled(j)) {
                        previousStepOK = false;
                        break;
                    }
                }

                if (!previousStepOK) break;
            } else {
                if (this.nextStepBtnDisabled(i)) {
                    previousStepOK = false;
                    break;
                }
            }
        }

        return previousStepOK;
    };


    nextContextBtnDisabled(contextIndex: number) {
        switch (contextIndex) {
            case 0:
            case 1: // Location
                if (this.model.context.location && this.model.context.location.length > 0) return false;
                break;
            case 2: // Distance
                if (this.model.context.distance && this.model.context.distance.length > 0) return false;
                break;
            case 3: // Changing Background
                if (this.model.context.changing_background !== null) return false;
                break;
            case 4: // Light Type
                if (this.model.context.light_type && this.model.context.light_type.length > 0) return false;
                break;
            case 5: // Lightning Condition
                if (this.model.context.light_condition && this.model.context.light_condition.length > 0) return false;
                break;
            case 6: // Is General Object
                if (this.model.context.is_general_object !== null) return false;
                break;
            case 7: // Perspective
                if (this.model.context.perspective && this.model.context.perspective.length > 0) return false;
                break;
            default:
                return true;
        }
        return true;
    };

    goToContextStep(contextIndex: number) {
        if (this.currentWizardStep === 5 && !this.nextContextBtnDisabled(contextIndex - 1)) {
            // context reaches the end, so go to next wizard step instead
            if ((contextIndex - 1) === this.wizardContextStepsCount) {
                this.goToWizardStep(this.currentWizardStep + 1);
            } else {
                this.currentContextStep = contextIndex;
            }
        }
    };


    // Checks if project name is exist
    projectNameExist(): boolean {

        this.aiProjectService.getAllAiProjects().then((projects: any) => {
            if ( projects && ((this.mode === this.CREATE_MODE && projects.filter((aiProject: AiProject) => aiProject.name === this.model.name).length === 0) ||
            (this.mode === this.EDIT_MODE && projects.filter((aiProject: AiProject) => aiProject.id !== this.model.id && aiProject.name === this.model.name).length === 0))) {
                this.nameExist = false;
                return false;
            } else {
                this.nameExist = true;
                return true;
            }
        });
        return false;
    };

    //checks if previous steps are completely done before going to next step
    goToWizardStep = (stepNumber: number) => {
        if (this.previousWizardStepOK(stepNumber)) {
            // Check if new object text field contains not submitted text
            if (this.currentWizardStep === 4) {
                // In case of update existing class
                if (this.renameObject !== null) {
                    this.objectError = true;
                    this.objectErrorMsg = this.translateService.instant('ai-studio.feedback.rename-name-not-finish');
                    return;
                }

                // In case of new class
                if (this.currentClass.val) {
                    this.objectError = true;
                    this.objectErrorMsg = this.translateService.instant('ai-studio.feedback.object-name-not-added', {object: this.currentClass.val});
                    return;
                }
            }

            this.currentWizardStep = stepNumber;
            this.updateprogressBarHeight();
        }
    };

    removeObjectClass = (aClass: DatumClass) => {
        let tagView = this.tagViewService.getTagView(aClass.name || "");

        //Check if there's datums associated with this tag
        if (tagView?.count && tagView?.count > 0) {
            let feedbackMsg;
            if (this.aiProjectService.isObjectDetectionProject()) {
                feedbackMsg = this.translateService.instant('ai-studio.feedback.remove-detection-object-warning', {amount: tagView.count})
            } else {
                feedbackMsg = this.translateService.instant('ai-studio.feedback.remove-object-warning', {amount: tagView.count})
            }

            this.dialogConfirm.open(feedbackMsg, this.translateService.instant('yes-sure'), this.translateService.instant('no-cancel')).then(async () => {
                try {
                    this.deletedClasses.push(aClass)
                    this.model.classes = this.model.classes.filter((datumClass: DatumClass) => aClass.name !== datumClass.name);
                } catch (e) {
                    console.log('Error while removing object class.');
                    console.log(e);
                }
            }, (error) => {
                console.log(error);
            });
        } else {
            this.deletedClasses.push(aClass)
            this.model.classes = this.model.classes.filter((datumClass: DatumClass) => aClass.name !== datumClass.name);
        }
    };

    finishSetup = () => {
        //check if new object text field contains not submitted text
        if (this.currentWizardStep === 4 && this.currentClass.val) {
            this.objectError = true;
            this.objectErrorMsg = this.translateService.instant('ai-studio.feedback.object-name-not-added', {object: this.currentClass.val});
            return;
        }

        if (this.mode === this.EDIT_MODE && this.renameObject !== null) {
            this.currentWizardStep = 4;
            this.objectError = true;
            this.objectErrorMsg = this.translateService.instant('ai-studio.feedback.rename-name-not-finish');
            return;
        }

        this.model.context = JSON.stringify(this.model.context);
        this.model.name = DOMPurify.sanitize(this.model.name.trim());

        if (this.mode === this.CREATE_MODE) {
            this.loadingService.show(this.translateService.instant('ai-studio.feedback.setting-up-project'));

            this.aiProjectService.newAiProject(this.model).then((aiProjectCreated) => {
                let event = this.eventService.buildModel(`Event,ai-project,created-project,${aiProjectCreated.classes?.length}`, aiProjectCreated.projectUuid || "", "");
                this.eventService.submitEvent(event);
                let msg = this.translateService.instant('ai-studio.feedback.project-created', {projectName: aiProjectCreated.name});
                console.info(msg);
                this.notificationService.info(msg);
                this.aiStudioObservables.updateSelectedProject$(aiProjectCreated?.projectUuid || "")

                this.closeModal(true);
            }, (response: any) => {
                if(response.headers.has("x-project-status")){
                    if (response.headers.get("x-project-status") === "project-name-exist") {
                        let msg = this.translateService.instant('ai-studio.feedback.project-creation-failed-name', {projectName: this.model.name})
                        this.notificationService.info(msg);
                        this.goToWizardStep(2);
                    } else if(response.headers.get("x-project-status") === "limit-exceeded") {
                        let msg = this.translateService.instant('ai-studio.feedback.project-creation-failed-project-count')
                        this.notificationService.info(msg)
                    } else if(response.headers.get("x-project-status").includes("class-limit-exceeded")) {
                        let count = response.headers.get("x-project-status").split(",").pop();
                        let msg = this.translateService.instant('ai-studio.feedback.project-creation-failed-datum-count', {maxCount: count})
                        this.notificationService.info(msg)
                        this.goToWizardStep(3)
                    }
                }
                 else {
                    let msg = this.translateService.instant('ai-studio.feedback.project-creation-failed');
                    console.error(msg);
                    this.notificationService.info(msg);
                }

                this.loadingService.hide();
            });
         }

         else if (this.mode === this.EDIT_MODE) {
            this.loadingService.show(this.translateService.instant('ai-studio.feedback.updating-project'));

            let updateAiProject = this.model;

            //Update Classes
            updateAiProject.classes = this.buildClasses()
            updateAiProject.subDatasets = this.savedSubDatasets;
            updateAiProject.models = this.savedModels;

            this.aiProjectService.updateAiProject(updateAiProject).then(() => {
                let msg = this.translateService.instant('ai-studio.feedback.project-updated', {projectName: this.model.name});
                console.info(msg);
                this.notificationService.info(msg);

                if(this.hasFrontlineRole){
                    let msg = this.translateService.instant('ai-studio.feedback.project-updated-frontline-wf');
                    this.notificationService.info(msg, 5000);
                }

                // Update the model of AI project on web
                this.aiStudioObservables.updateSelectedProject$(this.aiProject?.projectUuid || "");

                this.loadingService.hide();
                this.closeModal(true);
            }, () => {
                let msg = this.translateService.instant('ai-studio.feedback.project-update-failed', {projectName: this.model.name});
                console.error(msg);
                this.notificationService.info(msg);

                this.loadingService.hide();
            });
        }
    };

    buildClasses() {
        if(this.deletedClasses.length === 0 && this.newClasses.length === 0){
            //no changes
            return this.savedClasses
        } else {
            if(this.deletedClasses.length !== 0){
                let deletedNames = this.deletedClasses.map((deleted: any) => deleted.name)
                this.savedClasses = this.savedClasses.filter((saved: any) => !deletedNames.includes(saved.name))
            }
            //Check if a class is added and deleted in the same "project-wizard"-session
            this.newClasses = this.newClasses.filter((newClass: any) => this.deletedClasses.includes(newClass.name))
            if(this.newClasses.length !== 0 ) {
                this.savedClasses = this.savedClasses.concat(this.newClasses)
            }
            return this.savedClasses
        }
    }

    changesDetected() {
        return !_.isEqual(this.initProjectModel, this.model);
    }

    sendCancelEvent(currentMode: string) {
        let event = this.eventService.buildModel(`Event,ai-project,cancel-create`, '', '');
        if (currentMode === this.EDIT_MODE){
            event = this.eventService.buildModel(`Event,ai-project,cancel-update`, '', '');
        }
        this.eventService.submitEvent(event);
    }

    closeModal = (forceClosing?: boolean) => {
        if(!forceClosing && this.changesDetected()) {
            let feedbackMsg = "Changes are not yet saved, do you really want to quit?";
            let primaryButton = this.translateService.instant('yes');
            let secondaryButton = this.translateService.instant('cancel');

            this.dialogConfirm.open(feedbackMsg, primaryButton, secondaryButton).then(async () => this.activeModal.dismiss('cancel'))

        } else {
            this.activeModal.dismiss('cancel');
        }
    };

    hasRole(role: string): boolean {
        return this.authenticationService.hasRole(role);
    }

    refreshData(datums: Datum[]) {
        let index = this.datumService.datums.map((e: Datum) => {
            return e.id;
        }).indexOf(datums[0].id);

        if (index > -1) {
            setTimeout(() => {
                this.datumService.renderImagesOnCanvas(datums, false, false)
            }, 0)
        }
    }
}
