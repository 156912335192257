import 'global/assetsImports';
import {NgModule} from '@angular/core';
import {GlobalModule} from 'global/global.module';
import {AiStudioRoutingModule} from './ai-studio-routing.module';
import {AiStudioComponent} from './ai-studio.component';
import {DatasetComponent} from "apps/aiStudio/dataset/dataset.component";
import {TrainingComponent} from './training/training.component';
import {AiStudioObservables} from 'services/aiStudio/ai-studio.observables';
import {InferenceComponent} from './inference/inference.component';
import {AiToolbarComponent} from 'components/ai-toolbar/ai-toolbar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ProjectWizardComponent} from 'components/modals/project-wizard/project-wizard.component';
import {AiProjectService} from 'services/aiStudio/ai-project.service';
import {DatasetService} from 'services/aiStudio/dataset.service';
import {AugmentationSettingsComponent} from 'components/modals/augmentation-settings/augmentation-settings.component';
import {TagViewService} from 'services/aiStudio/tag-view.service';
import {ProjectContextComponent} from 'components/modals/project-wizard/project-context/project-context.component';
import {DatumService} from 'services/aiStudio/datum.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {ObjectDetectionComponent} from './dataset/object-detection/object-detection.component';
import {VideoReviewComponent} from './dataset/video-review/video-review.component';
import {ClassificationComponent} from './dataset/classification/classification.component';
import {ImportImagesComponent} from "components/modals/import-images/import-images.component";
import {ImportVideoComponent} from "components/modals/import-video/import-video.component";
import {StartTrainingComponent} from 'components/modals/start-training/start-training.component';
import {RenameTrainingComponent} from 'components/modals/rename-training/rename-training.component';
import {TrainingOptionsInfoComponent} from "components/modals/training-options-info/training-options-info.component";
import {
    ObjectDetectionMisclassified
} from './training/object-detection-misclassified/object-detection-misclassified.component';
import {UploaderComponent} from "components/uploader/uploader.component";
import {ImageCardComponent} from "components/image-card/image-card.component";
import {FileUploadService} from "services/file-upload.service";
import {
    ClassificationMisclassifiedComponent
} from './training/classification-misclassified/classification-misclassified.component';
import {ProjectInfoComponent} from 'components/modals/project-info/project-info.component';
import {AiGuidanceComponent} from 'components/ai-guidance/ai-guidance.component';
import {AiVideoIndicatorComponent} from 'components/ai-video-indicator/ai-video-indicator.component';
import {HighlightingService} from 'services/aiStudio/highlighting.service';
import {CorruptedScreenComponent} from "components/overlays/corrupted-screen/corrupted-screen.component";
import {
    NoFreeProjectScreenComponent
} from "components/overlays/no-free-project-screen/no-free-project-screen.component";
import {InitScreenComponent} from "components/overlays/init-screen/init-screen.component";
import {AuthImagePipe} from "pipes/authImage.pipe";
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {DisplayErrorComponent} from "validation/display-error/display-error.component";
import {HomeComponent} from "apps/aiStudio/home/home.component";
import {AiProjectCardComponent} from "components/ai-project-card/ai-project-card.component";
import {UserDeletionDialogComponent} from "dialogs/user-deletion/user-deletion-dialog.component";
import {ErrorModule} from "apps/error/error.module";

@NgModule({
    declarations: [
        AiStudioComponent,
        DatasetComponent,
        HomeComponent,
        TrainingComponent,
        InferenceComponent,
        AiProjectCardComponent,
        AiToolbarComponent,
        ProjectWizardComponent,
        AugmentationSettingsComponent,
        ProjectContextComponent,
        ObjectDetectionComponent,
        VideoReviewComponent,
        ClassificationComponent,
        ImportImagesComponent,
        ImportVideoComponent,
        StartTrainingComponent,
        RenameTrainingComponent,
        TrainingOptionsInfoComponent,
        ObjectDetectionMisclassified,
        UploaderComponent,
        ImageCardComponent,
        ClassificationMisclassifiedComponent,
        ProjectInfoComponent,
        AiGuidanceComponent,
        AiVideoIndicatorComponent,
        CorruptedScreenComponent,
        InitScreenComponent,
        NoFreeProjectScreenComponent,
        InitScreenComponent,
        AuthImagePipe,
        UserDeletionDialogComponent
    ],
    imports: [
        GlobalModule,
        AiStudioRoutingModule,
        NgbModule,
        NgSelectModule,
        NgxSkeletonLoaderModule.forRoot({animation: 'pulse', loadingText: 'This item is actually loading...'}),
        ErrorModule
    ],
    providers: [
        AiStudioObservables,
        DatumService,
        AiProjectService,
        DatasetService,
        TagViewService,
        FileUploadService,
        HighlightingService,
        AuthImagePipe
    ],
    bootstrap: [
        AiStudioComponent
    ],
    entryComponents: [
        ProjectWizardComponent,
        AugmentationSettingsComponent,
        ObjectDetectionComponent,
        VideoReviewComponent,
        ClassificationComponent,
        ImportImagesComponent,
        ImportVideoComponent,
        StartTrainingComponent,
        RenameTrainingComponent,
        TrainingOptionsInfoComponent,
        ObjectDetectionMisclassified,
        UploaderComponent,
        ImageCardComponent,
        ClassificationMisclassifiedComponent,
        ProjectInfoComponent,
        DisplayErrorComponent,
        UserDeletionDialogComponent
    ]
})
export class AiStudioModule {
}
