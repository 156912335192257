import {UploadItem} from "components/uploader/upload.item";
import {AiStudioBBoxAnnotation, AiStudioImageAnnotation} from "@teamviewer/aistudioapi-aistudio-angular";

export const SUPPORTED_ANNOTATION_FORMATS : any[] = [
    { id: 1, name: 'TeamViewer AiStudio' },
    { id: 2, name: 'COCO' },
    { id: 3, name: 'YOLO' },
    { id: 4, name: 'PASCAL VOC (Coming soon)', disabled: true }
]

export function loadAndSetYOLOImageAnnotations(uploads: UploadItem[], objectNames: File, annotationFiles: File[]): void {
    if(uploads.length > 0){
        const reader = new FileReader()
        reader.onload = () => {
            try {
                const objectsList: string[] = (reader.result as string).trim().split(/\r?\n/);
                if(objectsList && objectsList.length > 0){
                    setYOLOImageAnnotations(uploads, objectsList, annotationFiles);
                }
            } catch (e) {
                console.error("failed to read names file: " + objectNames.name)
                console.error(e)
            }
        }
        reader.readAsText(objectNames);
    }
}

export function setYOLOImageAnnotations(uploads: UploadItem[], objectsList: string[], annotationFiles: File[]): void{
    for(const upload of uploads){
        const annotationFileName = upload.file!.name.split('.').slice(0, -1).join('.') + ".txt";
        const annotationFile = annotationFiles.find(annFile => annFile.name === annotationFileName);
        if(annotationFile){
            const reader = new FileReader()
            reader.onload = () => {
                try {
                    const annotationsLines: string[] = (reader.result as string).trim().split(/\r?\n/);
                    if(annotationsLines && annotationsLines.length > 0){
                        let bboxes: AiStudioBBoxAnnotation[] = [];
                        for(const annotationLine of annotationsLines){
                            const detailedAnnotation = annotationLine.trim().split(' ');
                            if(detailedAnnotation.length == 5 && upload.width && upload.height){
                                const objectName: string = objectsList[parseInt(detailedAnnotation[0])];
                                const boxWidth = parseFloat(detailedAnnotation[3]) * upload.width;
                                const boxXCenter = parseFloat(detailedAnnotation[1]) * upload.width;
                                const boxHeight = parseFloat(detailedAnnotation[4]) * upload.height;
                                const boxYCenter = parseFloat(detailedAnnotation[2]) * upload.height;
                                bboxes.push({
                                    objectName: objectName,
                                    bbox: [boxXCenter, boxYCenter, boxWidth, boxHeight]
                                });
                            } else {
                                console.warn("Issue with annotation: " + JSON.stringify(detailedAnnotation));
                            }
                        }
                        // TODO: support classification here? how?
                        const aiStudioAnnotation: AiStudioImageAnnotation = {
                            fileName: upload.file!.name,
                            bboxes: bboxes
                        };
                        console.log("annotation " + upload.annotation);
                        upload.annotation = aiStudioAnnotation;
                    }
                } catch (e) {
                    console.error("failed to read yolo annotation from file: " + annotationFile.name)
                    console.error(e)
                }
            }
            reader.readAsText(annotationFile);
        }
    }
}

export function loadAndSetAiStudioImageAnnotations(uploads: UploadItem[], annotationFiles: File[]): void {
    const groupAnnotation = annotationFiles
        .find(fileInList => fileInList.name === 'annotations.json');

    if (groupAnnotation) {
        loadGroupedAnnotations(groupAnnotation, uploads);
    } else {
        loadAnnotations(annotationFiles, uploads);
    }
}

export function loadAndSetCOCOImageAnnotations(uploads: UploadItem[], annotationCOCO: File): void {
    if(uploads.length > 0){
        const reader = new FileReader();
        reader.onload = () => {
            try {
                const loadedAnnotations: any = JSON.parse(reader.result as string);
                if(loadedAnnotations &&
                    loadedAnnotations.hasOwnProperty('annotations') &&
                    loadedAnnotations.hasOwnProperty('categories') &&
                    loadedAnnotations.hasOwnProperty('images')){
                    const annotations: any[] = loadedAnnotations.annotations;
                    const categories: any[] = loadedAnnotations.categories;
                    const images: any[] = loadedAnnotations.images;

                    for(let uploadItem of uploads){
                        const fileName: string = uploadItem.file!.name
                        const image: any = images.find(image => image.hasOwnProperty('id') &&
                            image.hasOwnProperty('file_name') &&
                            image.file_name === fileName);
                        if(image){
                            // An image can have multiple annotations
                            const annotationsForImage = annotations.filter(annt => annt.hasOwnProperty('category_id') &&
                                annt.hasOwnProperty('bbox') && annt.hasOwnProperty('image_id') &&
                                annt.image_id === image.id);
                            if(annotationsForImage.length > 0){
                                let aiStudioBboxes: AiStudioBBoxAnnotation[] = [];
                                for(const singleAnnotation of annotationsForImage){
                                    const cat: any = categories.find(category => category.hasOwnProperty('name') &&
                                        category.hasOwnProperty('id') &&
                                        category.id === singleAnnotation.category_id);
                                    if(cat){
                                        aiStudioBboxes.push({
                                            bbox: convertCOCOToAiStudioAnnotation(singleAnnotation.bbox),
                                            objectName: cat.name
                                        })
                                    }
                                }
                                // TODO: support classification here? how?
                                const aiStudioAnnotation: AiStudioImageAnnotation = {
                                    fileName: fileName,
                                    bboxes: aiStudioBboxes
                                }
                                uploadItem.annotation = aiStudioAnnotation;
                            }
                        }
                    }
                }
            } catch (e) {
                console.error("Failed to load annotation file: " + annotationCOCO.name)
                console.error(e)
            }
        }
        reader.readAsText(annotationCOCO);
    }
}

export function convertCOCOToAiStudioAnnotation(bbox: number[]): number[] {
    //xMin, yMin, width, height -> xCenter, yCenter, width, height
    const xCenter: number = bbox[0] + (bbox[2] / 2);
    const yCenter: number = bbox[1] + (bbox[3] / 2);
    return [xCenter, yCenter, bbox[2], bbox[3]];
}

export function loadAnnotations(annotationFiles: File[], imageUploads: UploadItem[]): void{
    if(imageUploads.length > 0){
        for(const annotationFile of annotationFiles){
            const reader = new FileReader()
            reader.onload = () => {
                try {
                    const annotation: AiStudioImageAnnotation = JSON.parse(reader.result as string);
                    if(annotation.fileName){
                        const uploadItem = imageUploads.find(imageUpload => imageUpload.file!.name == annotation.fileName)
                        if(uploadItem){
                            uploadItem.annotation = annotation
                        }
                    }
                } catch (e) {
                    console.error("Failed to load annotations file: " + annotationFile!.name)
                    console.error(e)
                }
            }
            reader.readAsText(annotationFile);
        }
    }
}

export function loadGroupedAnnotations(annotationsFile: File, imageUploads: UploadItem[]): void{
    if(imageUploads.length > 0){
        const reader = new FileReader();
        reader.onload = () => {
            try {
                const loadedAnnotations: AiStudioImageAnnotation[] = JSON.parse(reader.result as string);
                findAnnotationInCollection(loadedAnnotations, imageUploads);
            } catch (e) {
                console.error("Failed to load annotation file: " + annotationsFile.name)
                console.error(e)
            }
        }
        reader.readAsText(annotationsFile);
    }
}
export function findAnnotationInCollection(annotations: AiStudioImageAnnotation[] | null, uploadItems: UploadItem[]): void {
    if(Array.isArray(annotations)){
        for(const uploadItem of uploadItems){
            const annotation = annotations.find(annotation => annotation.fileName === uploadItem.file!.name);
            if(annotation){
                uploadItem.annotation = annotation;
            }
        }
    }
}
