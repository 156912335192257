<div id="tab-dataset" class="row">
    <div class="col-sm-4 col-md-2 left-panel">
        <h2>{{ 'ai-studio.dataset.tab' | translate }}</h2>
        <button-primary
            id="start-training-btn-dataset-tab"
            (click)="openTrainingModelWithAugmentationCheck()"
            text="{{ 'ai-studio.model.tab.new-training' | translate }}">
        </button-primary>

        <!-- Sorting -->
        <div class="col-sm-12 propertyTitle top-buffer pb-4">
            <div class="propertyPanelTitle">
                {{ 'ai-studio.dataset.tab.sorting' | translate }}
            </div>
            <ng-select class="select"
               [(ngModel)]="datumService.selectedSortingSetting"
                (ngModelChange)="changeDatasetSorting(datumService.selectedSortingSetting)">
                <ng-option *ngFor="let opt of datasetSortingSettings" [value]="opt.id">{{'ai-studio.dataset.sort-by.' + opt.translation  | translate }}</ng-option>
            </ng-select>
        </div>
        <!-- Filtering -->
        <div class="col-sm-12 propertyTitle pb-5">
            <div class="propertyPanelTitle pb-2" (click)="filterCollapsed = !filterCollapsed">
                <i class="icon" [ngClass]="{'icon-angle-right': filterCollapsed, 'icon-angle-down': !filterCollapsed}"></i>
                {{ 'ai-studio.dataset.tab.filtering' | translate }}
            </div>
            <div [hidden]="filterCollapsed">
                <!--        TagTab for Classification Project        -->
                <div class="btn-group tag-group" role="group"
                     *ngIf="!aiProjectService.isObjectDetectionProject()">
                    <button type="button" class="tag-btn button-small"
                            (click)="showWellSetImages()"
                            [ngClass]="{ 'active': selectedTagTab === WELL_SET_TAB }">
                        {{ 'ai-studio.dataset.tab.tagged' | translate }}
                    </button>
                    <button type="button" class="tag-btn  button-small"
                            (click)="showNotReadyImages()"
                            [ngClass]="{ 'active': selectedTagTab === NOT_READY_TAB }">
                        {{ 'ai-studio.dataset.tab.untagged' | translate }}
                       <i class="icon icon-exclamation-triangle warning-sign-color"
                          *ngIf="tagViewService.getUntaggedTagView().count > 0"
                          aria-hidden="true" container="body"
                          placement="right"
                          [ngbTooltip]=" 'ai-studio.dataset.tab.untagged.tooltip' | translate">
                       </i>
                    </button>
                </div>
                <!--        TagTab for Object Detection Project        -->
                <div class="btn-group tag-group" role="group"
                     *ngIf="aiProjectService.isObjectDetectionProject()">
                    <button type="button" class="tag-btn button-small"
                            (click)="showWellSetImages()"
                            [ngClass]="{ 'active': selectedTagTab === WELL_SET_TAB}">
                            {{ 'ai-studio.dataset.tab.well-set' | translate }}
                    </button>
                    <button type="button" class="tag-btn button-small"
                            (click)="showNotReadyImages()"
                            [ngClass]="{ 'active': selectedTagTab === NOT_READY_TAB }">
                        {{ 'ai-studio.dataset.tab.not-ready' | translate }}
                        <i class="icon icon-exclamation-triangle warning-sign-color" aria-hidden="true"
                           container="body" *ngIf="tagViewService.getNotReadyTagViews().length > 0"
                           placement="right" [ngbTooltip]="'ai-studio.dataset.tab.not-ready.tooltip' | translate"></i>
                    </button>
                </div>
                <!--        Tag Selection        -->
                <div class="top-buffer" *ngIf="selectedTagTab === WELL_SET_TAB;">
                    <div *ngIf="!aiProjectService.isObjectDetectionProject()">
                        <tag-view
                            [backgroundTagView]="tagViewService.getBackgroundTagView()"
                            [positiveTagViews]="tagViewService.getPositiveTaggedTagViews()"
                            [showIcons]="true"
                            [tabSelected]="'dataset-wellset'">
                        </tag-view>
                    </div>

                    <div *ngIf="aiProjectService.isObjectDetectionProject()">
                        <tag-view
                            [backgroundTagView]=tagViewService.getBackgroundWellSetTagView()
                            [positiveTagViews]="tagViewService.getPositiveWellSetTagViews()"
                            [showIcons]="true"
                            [tabSelected]="'dataset-wellset'">
                        </tag-view>
                    </div>
                </div>
                <div class="top-buffer" *ngIf="selectedTagTab === NOT_READY_TAB;">
                    <div *ngIf="aiProjectService.isSingleLabelProject() && datumService.datums.length > 0">
                        <span [innerHTML]="'ai-studio.dataset.tab.untagged.desc' | translate : {'amount' : tagViewService.getUntaggedTagView().count}  | safeHtml"></span>
                    </div>

                    <div *ngIf="aiProjectService.isObjectDetectionProject()">
                        <div *ngIf="tagViewService.getNotReadyTagViews().length === 0">
                            {{ 'ai-studio.dataset.tab.all-images-ready' | translate }}
                        </div>

                        <div *ngIf="tagViewService.getNotReadyTagViews().length > 0">
                            <tag-view
                                [backgroundTagView]="tagViewService.getUntaggedNotReadyTagView()"
                                [positiveTagViews]="tagViewService.getTaggedNotReadyTagViews()"
                                [showIcons]="false"
                                [tabSelected]="'dataset-not-ready'">
                            </tag-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Begin Labeling Management -->
        <div class="col-sm-12 propertyTitle"
             [hidden]="aiProjectService.isObjectDetectionProject() || activeDatums.length <= 0">
            <div class="propertyPanelTitle">
                {{ 'ai-studio.dataset.tab.labeling' | translate }}
                <i class="icon icon-info-circle" aria-hidden="true" container="body"
                   placement="right" [ngbTooltip]="'ai-studio.dataset.tab.at-most-one-label' | translate"></i>
            </div>

            <div *ngIf="activeTags.length > 0">
                <p>{{ 'ai-studio.dataset.tab.existing-labels' | translate }}</p>
                <div class="tags">
                    <div class="wf-tag-bg" *ngFor="let tag of activeTags | orderBy: 'tagName'">
                        <div class="wf-tag badge" [style.background-color]="tag.datumClass.color | addAlpha">
                            <span class="tagText" [style.color]="tag.datumClass.color">{{ tag.tagName }} </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="top-buffer">
                <p class="m-0">{{ 'ai-studio.dataset.tab.label-as' | translate }}</p>

                <ng-select class="select" [(ngModel)]="targetTag">
                    <ng-option *ngFor="let tag of tagViewService.getTaggedTagViews() | orderBy: 'tagName'" [value]="tag.tagName">{{ tag.tagName }}</ng-option>
                </ng-select>

                <button-primary
                    text="{{ 'ai-studio.dataset.tab.update-label' | translate }}"
                    class="button-small top-buffer"
                    id="update-tag-button"
                    (click)="addTagToActiveDatums();">
                </button-primary>
            </div>
        </div>
        <!--End Labeling Management-->
    </div>

    <!-- *ngIf="datumService.getDatumViewPages().length > 0" -->
    <div class="col-sm-8 col-md-10 p-0 height100">
        <!-- Tag and selection manipulation -->
        <div class="control-bar">
            <button-secondary id="add-image-btn" icon="upload"
                            text="{{ 'ai-studio.import-dialog.title' | translate }}"
                            (click)="showImportDialog();">
            </button-secondary>
            <button-secondary icon="check"
                            text="{{ 'ai-studio.select-all' | translate }}"
                            [hidden]="activeDatums.length > 0 || datumService.datums.length === 0" (click)="selectAll();">
            </button-secondary>
            <button-secondary  icon="times"
                            text="{{ 'ai-studio.dataset.tab.deselect-all' | translate }}"
                            [hidden]="activeDatums.length === 0" (click)="deselectAllActiveImages();">
            </button-secondary>
            <button-secondary icon="pencil"
                            text="{{ 'ai-studio.edit-all' | translate }}"
                            [hidden]="!aiProjectService.isObjectDetectionProject() || activeDatums.length > 0"
                            (click)="editAll();"
                            [disabled]="datumService.datums.length === 0">
            </button-secondary>
            <button-secondary icon="pencil"
                            text="{{ 'ai-studio.edit-selected' | translate }}"
                            *ngIf="aiProjectService.isObjectDetectionProject() && activeDatums.length > 0"
                            (click)="editSelected();">
            </button-secondary>

            <button class="button button-secondary" (click)="removeSelected()" [disabled]="activeDatums.length === 0">
                <i class="icon icon-trash"></i>
                <span>{{ 'delete' | translate }}</span>
                <span *ngIf="activeDatums.length > 0">({{ activeDatums.length }})</span>
            </button>
            <!-- Zoomer -->
            <dataset-slider
                class="col col-sm-3"
                style="float: right;"
                [min]="2"
                [max]="6"
                [step]="1"
                [(value)]="datumService.zoomLevel"
                [(ngModel)]="datumService.zoomLevel"
                [showIcon]="true"
                [grid]="false"
                (onValueUpdate)="datumService.zoomLevel = $event"
                name="fieldName" ngDefaultControl>
            </dataset-slider>
        </div>

        <!-- data sets -->
        <div id="data-set-scroll-parent" class="dataSets" tabindex="-1">
            <!-- No Datums -->
            <div class="row" *ngIf="!datumService.loadingPaginatedDatums && datumService.getDatums().length === 0">
                <!-- Well Set Tab: If no datums are in the project-->
                <div *ngIf="selectedTagTab === WELL_SET_TAB && hasFrontlineRole && datumService.datums.length === 0"
                     class="jumbotron my-drop-zone text-center col-xs-12">
                    <h2>{{ 'ai-studio.dataset.tab.add-images' | translate }}</h2>
                    <p>{{ 'ai-studio.dataset.tab.add-images-desc' | translate }}</p>
                    <div class="col-sm-12 howToContainer">
                        <div class="row">
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon-smartglass"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.open-frontline-workplace' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon-angle-double-right"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.select-start-work' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon-qrcode"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.scan-your-login-card' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon-my-tasks"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.select-my-task' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon-check-square-o"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.select-workflow' | translate : {'workflowname' : aiProjectService.getAiProject()?.name + ' - Data Collection'}"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 uploadFromYourComputer">
                        <p>{{ 'ai-studio.dataset.tab.upload-from-your-computer.part1' |
                            translate}} <a (click)="showImportDialog();">{{
                                'ai-studio.dataset.tab.upload-from-your-computer.upload' |
                                translate}}</a> {{
                            'ai-studio.dataset.tab.upload-from-your-computer.part2' |
                            translate}}</p>
                    </div>
                </div>

                <!-- Well Set Tab AND Free Ai-Studio License: If no datums are in the project and the user as a free ai-studio license -->
                <div *ngIf="selectedTagTab === WELL_SET_TAB  && !hasFrontlineRole && datumService.datums.length === 0"
                     class="jumbotron my-drop-zone text-center col-xs-12 p-0 pt-4">
                    <h2>{{ 'ai-studio.dataset.tab.add-images-with-aistudio-app' | translate }}</h2>

                    <!-- Begin AiStudio User HowToContainer -->
                    <div *ngIf="!hasFrontlineRole" class="col-sm-12 howToContainer p-5">
                        <div class="row">
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon_aistudio_logo_24"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.open-aistudio-mobile-app' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon_fluent_board_24_filled"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.go-to-project' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon_fluent_search_visual_24_regular"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.select-project' | translate : {'projectname' : aiProjectService.getAiProject()?.name}" style="overflow: inherit"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon_fluent_image_add_24_regular"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.select-label' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon_fluent_camera_24_regular"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.start-taking-images' | translate"></p>
                            </div>
                        </div>
                    </div>
                    <!-- End AiStudio User HowToContainer -->
                    <!-- Begin Frontline User HowToContainer -->
                    <div *ngIf="hasFrontlineRole" class="col-sm-12 howToContainer">
                        <div class="row">
                            <div class="howToStep text-center">

                                <i class="icon-3x icon icon-smartglass"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.open-frontline-workplace' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon-angle-double-right"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.select-start-work' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon-qrcode"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.scan-your-login-card' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon-my-tasks"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.select-my-task' | translate"></p>
                                <i class="icon icon-chevron-right intro-arrow-icon" aria-hidden="true"></i>
                            </div>
                            <div class="howToStep text-center">
                                <i class="icon-3x icon icon-check-square-o"></i>
                                <p [innerHtml]="'ai-studio.dataset.tab.select-workflow' | translate : {'workflowname' : aiProjectService.getAiProject()?.name + ' - Data Collection'}"></p>
                            </div>
                        </div>
                    </div>
                    <!-- End Frontline User HowToContainer -->
                    <!-- Begin AiStudio App Promotion -->
                    <div *ngIf="!hasFrontlineRole" class="col-sm-12 aiStudioAppPromotion">
                        <div class="row">
                            <div class="col-sm-6 p-0" id="playStorePromotion">
                                <div class="row">
                                    <div class="col-sm-4 p-0 pt-4 pb-4 qrCodePromotion">
                                        <!-- Placeholder QR-Code -->
                                        <img src="../../../assets/images/badges/placeholder_qrcode.png" width="100px" height="100px">
                                        <!-- Placeholder QR-COde -->
                                    </div>
                                    <div class="col-sm-8 p-0 pt-4 pb-4 storeBadgesArea" >
                                        <h3 class="m-0">{{ 'ai-studio.dataset.tab.scan-qr-code-play-store' | translate }}</h3>
                                        <img class="storeBadges" src="../../../assets/images/badges/google-play-store-badge.svg">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 p-0" id="appStorePromotion">
                                <div class="row">
                                    <div class="col-sm-4 p-0 pt-4 pb-4 qrCodePromotion">
                                        <!-- Placeholder QR-Code -->
                                        <img src="../../../assets/images/badges/placeholder_qrcode.png" width="100px" height="100px">
                                        <!-- Placeholder QR-COde -->
                                    </div>
                                    <div class="col-sm-8 p-0 pt-4 pb-4 storeBadgesArea">
                                        <h3 class="m-0">{{ 'ai-studio.dataset.tab.scan-qr-code-app-store' | translate }}</h3>
                                        <img class="storeBadges" src="../../../assets/images/badges/apple-app-store-badge.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End AiStudio App Promotion -->
                    <div class="col-sm-12 uploadFromYourComputer">
                        <p>{{ 'ai-studio.dataset.tab.upload-from-your-computer.part1' |
                            translate}} <a (click)="showImportDialog();">{{
                            'ai-studio.dataset.tab.upload-from-your-computer.upload' |
                                translate}}</a> {{
                            'ai-studio.dataset.tab.upload-from-your-computer.part2' |
                                translate}}</p>
                    </div>
                </div>

                <!-- Not Ready Tab -->
                <div *ngIf="selectedTagTab=== NOT_READY_TAB"
                     class="jumbotron my-drop-zone text-center col-xs-12">
                    <h3 *ngIf="aiProjectService.isSingleLabelProject()">{{
                        'ai-studio.dataset.tab.all-images-tagged' | translate }}</h3>
                    <h3 *ngIf="aiProjectService.isObjectDetectionProject()">{{
                        'ai-studio.dataset.tab.all-images-ready' | translate }}</h3>
                    <p>{{ 'ai-studio.dataset.tab.all-images-tagged.desc' | translate }}</p>
                </div>
            </div>

            <!-- Dataset -->
            <div class="dataset-content-block" role="presentation" tabindex="-1" *ngIf="datumService.datums.length > 0">
                <div class="dataSetItemTile dataSetItemTile-{{ datumService.zoomLevel }}"
                     *ngFor="let datum of datumService.datums; let index = id"
                     [ngClass]="{ selected : activeDatumsIds.includes(datum.id)}">
                    <div class="dataSetItemTileInnerWrapper"
                         (dblclick)="openImage([datum])"
                         (click)="selectDatum($event, datum);">
                        <div class="imgContainer">
                            <canvas id="canvas_{{ datum.id }}" class="img-responsive lazyload" [ngClass]="{ 'skeleton-canvas': !datumService.imagesLoaded}"></canvas>
                            <div class="deleteIcon" (click)="deleteImage(datum); $event.stopPropagation();" role="presentation" tabindex="-1">
                                <i class="icon icon-trash pull-right"></i>
                            </div>
                        </div>
                        <div class="dataSetItemTileDetails">
                            <div class="tags">
                                <div class="wf-tag-bg" *ngFor="let label of datum.datumLabels | orderBy : 'datumClass.name' | uniqueValues: 'datumClass.id' ">
                                    <ai-tag
                                        [name]="label.datumClass.name"
                                        [color]="label.datumClass.color">
                                    </ai-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination-wrapper" *ngIf="datumService.datums.length > 0">
                <ngb-pagination id="datasetPagination" [collectionSize]="datumService.totalDatums" [pageSize]="datumService.pageSize" [(page)]="currentPage"  [maxSize]="3" [rotate]="true" aria-label="datum pagination" (pageChange)="onPagination($event)" ></ngb-pagination>
                <div class="pagination-page-size">
                    <div class="page-size-select">
                        <p>{{ 'ai-studio.pagination.pagesize' | translate }}</p>
                        <ng-select
                            class="pagination-page-size-item"
                            [items]="datumService.paginationPageSize"
                            (change)="changePaginationSize($event)"
                            [clearable]=false
                            [(ngModel)]="datumService.pageSize">
                        </ng-select>
                    </div>
                </div>
            </div>
        <ai-video-indicator></ai-video-indicator>
        </div>
    </div>
</div>

