<div class="modal-head">
    <h2 class="modal-title">
        <span>{{ 'ai-studio.review-video-dialog.title' | translate }}</span>
        <a class="float-right">
            <i class="icon icon-times" (click)="sendCancelEvent(); closeModal()"></i>
        </a>
    </h2>
</div>

<div class="modal-body" id="image-upload-page">
    <!-- When Image Classification Single Label -->
    <div  id="classification-tag-selection" *ngIf="aiProjectService.isSingleLabelProject()">
        <p>{{ 'ai-studio.review-video-dialog.review-frames' | translate }}</p>
        <ng-select
            class="col col-sm-12"
            [items]="tagViews"
            [bindLabel]="'tagName'"
            [bindValue]="'tagName'"
            [placeholder]="'ai-studio.import-dialog.tags-selection-label' | translate"
            (clear)="tagViewService.selectedTagViewsForUpload = []"
            [(ngModel)]="selectedTagViews"
            (ngModelChange)="changeTag()">
        </ng-select>
    </div>

    <!-- When Object Detection -->
    <div id="image-upload-tag-view" *ngIf="aiProjectService.isObjectDetectionProject()">
        <p>{{ 'ai-studio.review-video-dialog.review-frames' | translate }}</p>
        <ng-select
            class="col col-sm-12"
            [items]="tagViews"
            [bindLabel]="'tagName'"
            [bindValue]="'tagName'"
            [placeholder]="'ai-studio.import-dialog.tags-selection-label' | translate"
            [multiple]="true"
            (add)="checkForBackgroundTag($event)"
            (remove)="removeTag($event)"
            (clear)="tagViewService.selectedTagViewsForUpload = []"
            [(ngModel)]="selectedTagViews"
            >
            <!-- ng-value ng-option-tmp ng-label-tmp [ngStyle]="{'background-color': 'black !important'}" -->
            <ng-template ng-label-tmp let-item="item">
                {{item.tagName}}
            </ng-template>
        </ng-select>
    </div>
    <div *ngIf="tagViewService.selectedTagViewsForUpload?.length === 0" class="validation-error" style="">
        <p>{{'ai-studio.import-dialog.no-selected-tags' | translate }}</p>
    </div>

    <div *ngIf="aiProjectService.isObjectDetectionProject() && tagViewService.selectedTagViewsForUpload?.length !== 0 && ((MINIMUM_IMAGES - calculateMaximumUploadNumber()) >= 0) && (calculateMaximumUploadNumber() != 0) " class="upload-min-image-message" style="">
        <p>{{ 'ai-studio.import-dialog.max-required-images' | translate: { imageAmount: calculateMaximumUploadNumber() } }}</p>
    </div>

    <uploader [isImageUpload]=false></uploader>

</div>

<div class="modal-footer">
        <button-secondary data-dismiss="modal" (click)="closeModal()"  text="{{ 'ai-studio.import-dialog.cancel-upload' | translate }}" event="Event,ai-project,cancel-upload" projectuuid="{{aiProjectService.getAiProject().projectUuid}}"></button-secondary>
        <button-secondary data-dismiss="modal" (click)="skipVideo()"  text="{{ 'ai-studio.import-dialog.skip-video' | translate }}"></button-secondary>
        <div *ngIf="uploaderLoaded">
            <button-primary class="float-right" icon="upload" text="{{ 'app.xpick.images.upload-all' | translate }}({{uploader.files.length}})"
                            (click)="uploader.uploadAll()" [disabled]="uploader.files.length === 0 || uploader.showUploadReport"
                            event="Event,ai-project,initialize-upload-image,{{uploader.files.length}}"
                            projectuuid="{{aiProjectService.getAiProject().projectUuid}}">
            </button-primary>
            <button-secondary class="float-right" icon="trash" text="{{ 'app.xpick.images.clear' | translate }}({{uploader.files.length}})" (click)="uploader.removeAllEntries(); " [disabled]="uploader.files.length === 0">
            </button-secondary>
        </div>
</div>
