<div [hidden]="!status_project_load_failed" class="fullScreenOverlay">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <i class="icon icon-meh" aria-hidden="true" style="font-size: 15em"></i>&nbsp;
            </div>
            <div class="col-md-9">
                <h1>
                    {{ 'ai-studio.overlay.fail-to-load.title' | translate }}
                </h1>

                <p class="lead">
                    {{ 'ai-studio.overlay.fail-to-load.lead' | translate }}
                </p>

                <p>
                    <a href="mailto:frontline-service@teamviewer.com">
                        <i class="icon icon-envelope-o" aria-hidden="true"></i>&nbsp;<span>frontline-service@teamviewer.com</span>
                    </a>

                    &nbsp;|

                    <span id="footerPhone"><i class="icon icon-phone"></i>&nbsp;<span>+49 421 33558380</span></span>

                    &nbsp;|&nbsp;
                    <span id="feedbackspan">
                        <a id="feedbacktrigger">{{ 'footer.reportabug' | translate }}</a></span><br><br>
                </p>

                <div class="btn-row">
                    <button-primary text="{{ 'ai-studio.init.create_new_project' | translate }}"
                                    (click)="showWizard();">
                    </button-primary>
                    <button-primary text="{{ 'ai-studio.init.open_project' | translate }}"
                                    (click)="showOpenProjectDialog($event);">
                    </button-primary>
                </div>
            </div>
        </div>
    </div>
</div>
