import {Component, HostBinding, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {TagViewService} from "services/aiStudio/tag-view.service";
import {TrainingService} from "services/aiStudio/training.service";
import {AuthenticationService} from "services/authentication.service";
import {LoadingService} from "services/loading.service";
import {NotificationService} from "services/notification.service";
import {BACKGROUND, minImgAmountPerTag} from "utils/project-utils";
import {AiProject, Model} from "@teamviewer/aistudioapi-common-angular";
import DOMPurify from "dompurify";
import _ from "lodash";
import {EventService} from "services/event.service";
import {TagView} from "@teamviewer/aistudioapi-aistudio-angular";

@Component({
    selector: 'start-training',
    templateUrl: 'start-training.component.html',
})

export class StartTrainingComponent implements OnInit {
    @HostBinding('class') class = 'modal-content';

    dataAmountSufficient = true;
    allowTraining = true;
    dataBalanced = false;
    trainingName = '';
    extraTrainingOptions: any;
    allDataTagged: boolean = false;
    backgroundOnly: boolean = false;
    showTrainingNameEmptyError = false;
    showTrainingNameExistError = false;
    untaggedViewsAmount: any = 0;
    minImgAmountPerTag = minImgAmountPerTag
    project: AiProject | undefined;
    notSufficientTagViews: TagView[] = [];
    unbalancedTagViews: TagView[] = [];

    positiveTagViews: TagView[] = [];

    hasDeveloperRole: boolean
    hasFrontlineRole: boolean;
    numSelected: number = 0;

    constructor(public tagViewService: TagViewService, private authenticationService: AuthenticationService,
                public aiProjectService: AiProjectService, private loadingService: LoadingService,
                private translateService: TranslateService, private activeModal: NgbActiveModal,
                private trainingService: TrainingService, private notificationService: NotificationService,
                private eventService: EventService) {
        this.hasDeveloperRole = this.authenticationService.hasRole(("TV_INTERNAL_DEV"));
        this.hasFrontlineRole = this.authenticationService.hasRole(("Frontline_AI_User"));
    }

    ngOnInit(){
        if (this.aiProjectService.isObjectDetectionProject()) {
            this.positiveTagViews = _.cloneDeep(this.tagViewService.getPositiveWellSetTagViews());
            this.positiveTagViews.push(_.cloneDeep(this.tagViewService.getBackgroundWellSetTagView()));

            let notReadyTagViews: any = this.tagViewService.getNotReadyTagViews();
            let count = 0;
            for (let notReadyTagView of notReadyTagViews) {
                count += notReadyTagView?.count;
            }

            this.allDataTagged = count === 0;
            this.untaggedViewsAmount = count;
        } else {
            this.positiveTagViews = _.cloneDeep(this.tagViewService.getPositiveTaggedTagViews());
            this.positiveTagViews.push(_.cloneDeep(this.tagViewService.getBackgroundTagView()));

            this.allDataTagged = (this.tagViewService.getUntaggedTagView()?.count === 0 || this.tagViewService.getUntaggedTagView()?.count == undefined);
            this.untaggedViewsAmount = this.tagViewService.getUntaggedTagView()?.count || 0;
        }

        this.positiveTagViews.forEach((t) => t.selected=true);
        this.updateCheckList()
        this.project = this.aiProjectService.getAiProject()
    }

    startTraining = () => {
        this.showTrainingNameEmptyError = false;
        this.showTrainingNameExistError = false;

        this.trainingName = DOMPurify.sanitize(this.trainingName);

        if (this.trainingName.length === 0) {
            this.showTrainingNameEmptyError = true;
        } else if (this.aiProjectService.isTrainingNameExist(this.trainingName)) {
            this.showTrainingNameExistError = true;
        } else {

            this.loadingService.show(this.translateService.instant('ai-studio.feedback.creating-new-training'));

            let model: Model = {};
            model.modelMetadata = {};
            model.aiProject = this.aiProjectService.getAiProject()
            model.name = this.trainingName;
            model.extraOptions = this.extraTrainingOptions;
            model.modelMetadata.trainedClasses = this.positiveTagViews.filter((t) => t.selected)
                .map((t) => t.tagName || "")

            this.trainingService.startNewTraining(model).then((response: any) => {
                let projectModels = this.aiProjectService.getAiProject().models || []
                projectModels.push(response);
                //TODO
                /* $rootScope.$emit("switchTab", "model");
                $rootScope.$emit("newTrainingCreated", response);*/

                let msg = this.translateService.instant('ai-studio.feedback.create-new-training-successful', {trainingName: this.trainingName});
                console.info(msg);
                this.notificationService.info(msg);

                this.loadingService.hide();
                this.closeModal();
            }, (response: any) => {
                if (response.headers.get("x-training-status") === "project-limit-exceeded") {
                    let msg = this.translateService.instant('ai-studio.feedback.create-new-training-failed-project-limit');
                    this.notificationService.info(msg);
                } else if(response.headers.get("x-training-status") === "first-training-incomplete") {
                    let msg = this.translateService.instant('ai-studio.feedback.create-new-training-failed-initial-incomplete');
                    this.notificationService.info(msg)
                }  else if(response.headers.get("x-training-status") === "time-exceeded") {
                    let msg = this.translateService.instant('ai-studio.feedback.create-new-training-failed-time-limit');
                    this.notificationService.info(msg)
                } else {
                    let msg = this.translateService.instant('ai-studio.feedback.create-new-training-failed');
                    console.error(msg);
                    this.notificationService.info(msg);
                }
                this.loadingService.hide();
            });
       }
    };

    sendCancelEvent() {
        const projectUuid = this.aiProjectService.getAiProject().projectUuid;
        const event = this.eventService.buildModel(`Event,ai-training,cancel-start`, projectUuid || '', '');
        this.eventService.submitEvent(event);
    }

    closeModal = () => {
        this.activeModal.close();
    };

    hasRole(role: string): boolean {
        return this.authenticationService.hasRole(role);
    }

    updateCheckList() {
        this.unbalancedTagViews = []
        this.notSufficientTagViews = []
        this.dataAmountSufficient = true
        this.dataBalanced = true
        this.numSelected = 0
        let backgroundSelected = false
        for (let tag of this.positiveTagViews ) {
            if (tag.selected) {
                this.numSelected ++
                if(!tag.aboveMinimumAmount) {
                    this.notSufficientTagViews.push(tag)
                    this.dataAmountSufficient = false
                }
                if(!tag.balanced) {
                    this.unbalancedTagViews.push(tag)
                    this.dataBalanced = false
                }
                if(tag.tagName === BACKGROUND) {
                    backgroundSelected = true
                }
            }
        }
        this.backgroundOnly = backgroundSelected && this.numSelected === 1
        this.allowTraining = this.dataAmountSufficient && !this.backgroundOnly
            && (this.aiProjectService.isObjectDetectionProject() ? this.numSelected > 0 : this.numSelected > 1)
    }
}
