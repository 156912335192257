import { Directive, ElementRef, HostListener, Input, OnInit, Inject, AfterViewInit, OnChanges} from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
    selector: '[animate-numbers]'
})

export class AnimateNumbersDirective implements OnChanges {
    @Input() number: number = 0;
    @Input() slowdown = false;

    timeoutId: any = null;
    refreshInterval = 30;
    duration = 1000; //milliseconds
    lastNumberSlowCount = 3;
    steps = Math.ceil(this.duration / this.refreshInterval);

    step = 0;
    num = 0;
    percentCompleted = 0;
    
 
    constructor(private element: ElementRef) {}

    ngOnChanges() {
        this.updateNumber()
    }
     
    updateNumber(){         
        if(this.slowdown) {
            this.duration = 200;
            this.lastNumberSlowCount = 0;
        }
        
        if (this.number > this.lastNumberSlowCount) {
            this.number = this.number - this.lastNumberSlowCount;
        }

        this.counter();
    }

    counter() {
        this.timeoutId = setTimeout(() => {
            let  increment = this.number / this.steps;
            this.num += increment;
            this.percentCompleted = Math.round((this.num / this.number) * 100);

            if (this.percentCompleted > 60 && this.percentCompleted < 80) {
                this.refreshInterval = this.refreshInterval + 10;
            } else if (this.percentCompleted > 90) {
                this.refreshInterval = 200;
            }
            this.step++;

            if (this.step >= this.steps) {
                clearTimeout(this.timeoutId);
                this.num = this.number;
                this.element.nativeElement.textContent = this.number;
                if (this.number > this.lastNumberSlowCount) {
                    this.slowCounter();
                } 
            } else {
            this.element.nativeElement.textContent = Math.round(this.num); 
            this.counter();
            }
        }, this.refreshInterval);
    }

    slowCounter() {
        this.timeoutId = setTimeout(() => {
            this.lastNumberSlowCount--;
            if (this.lastNumberSlowCount < 0) {
                this.element.nativeElement.textContent = this.number;
                clearTimeout(this.timeoutId);
            } else {
                this.number++;
                this.element.nativeElement.textContent = this.number;
                this.slowCounter();
            }
        }, 500)
    }
}


 

