<div class="modal-body modal-confirm">
    <div class="modal-confirm-content">
        <p class="magnify confirmation-message">{{message}}</p>
        <p *ngIf="template" [innerHTML]="template"></p>
        <div class="button-bar">
            <button *ngIf="secondaryButton" class="button-secondary" (click)="dismiss()">{{secondaryButton}}</button>
            <button *ngIf="primaryButton" class="button-primary float-right" (click)="close()">{{primaryButton}}</button>
        </div>
    </div>
</div>

