import {Component, OnInit} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import _ from "lodash";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {DatumService} from "services/aiStudio/datum.service";
import {TagViewService} from "services/aiStudio/tag-view.service";
import {datasetSortingSettings, DATUM_LABEL, WELL_SET_TAB} from "utils/project-utils";
import {Datum, DatumClass, DatumLabel} from "@teamviewer/aistudioapi-common-angular";
import {TagView} from "@teamviewer/aistudioapi-aistudio-angular";
import {ObjectDetectionComponent} from "./object-detection/object-detection.component";
import {ClassificationComponent} from "./classification/classification.component";
import {ImportImagesComponent} from "components/modals/import-images/import-images.component";
import {DialogConfirm} from "dialogs/dialog-confirm";
import {LoadingService} from "services/loading.service";
import {NotificationService} from "services/notification.service";
import {AugmentationSettingsComponent} from "components/modals/augmentation-settings/augmentation-settings.component";
import {StartTrainingComponent} from "components/modals/start-training/start-training.component";
import {HighlightingService} from "services/aiStudio/highlighting.service";
import {HotkeysService} from "angular2-hotkeys";
import {AuthenticationService} from "services/authentication.service";
import {DatasetService} from "services/aiStudio/dataset.service";

@Component({
    selector: 'dataset',
    templateUrl: 'dataset.component.html',
    styleUrls: ['dataset.component.sass']
})
export class DatasetComponent implements OnInit {
    datasetSortingSettings = datasetSortingSettings;

    filterCollapsed: boolean = false;

    activeTags: TagView[] = [];

    WELL_SET_TAB: string = 'well_set';
    NOT_READY_TAB:string = 'not_ready';

    activeDatums: any[] = [];
    activeDatumsIds: any = [];
    lastSelectIndex = -1;

    selectedTagTab:string = WELL_SET_TAB;

    targetTag: string | undefined;

    paginationMaxSize: number = Math.floor(this.datumService.totalDatums /this.datumService.pageSize) ;

    classes: any = this.tagViewService.classes;

    hotkeys: any = null;
    currentPage: number = 1;

    private hasFrontlineRole: boolean;

    dataAmountSufficient = false;
    notSufficientTagViews: any = [];
    private openDCVideos: number = 0;

    constructor(private translateService: TranslateService,
                public aiProjectService: AiProjectService,
                private authenticationService: AuthenticationService,
                public datumService : DatumService,
                public datasetService: DatasetService,
                private aiStudioObservables: AiStudioObservables,
                public tagViewService: TagViewService,
                private modalService: NgbModal,
                private dialogConfirm: DialogConfirm,
                private loadingService: LoadingService,
                private notificationService: NotificationService,
                private highlightingService: HighlightingService,
                private hotkeysService: HotkeysService
                ) {
        this.hasFrontlineRole = this.authenticationService.hasRole(("Frontline_AI_User"));
        //HOTKEYS
        this.hotkeys = hotkeysService

        this.hotkeys.add({
            combo: 'ctrl+a',
            description: 'Select all images',
            callback: (e: any) => {
                e.preventDefault();
                this.selectAll();
            }
        });

        this.hotkeys.add({
            combo: 'esc',
            description: 'Deselect all images',
            callback: (e: any) => {
                e.preventDefault();
                this.deselectAllActiveImages();
            }
        });

        this.hotkeys.add({
            combo: 'del',
            description: 'Delete selected images',
            callback: (e: any) => {
                e.preventDefault();
                this.removeSelected();
            }
        });
    }

    ngOnInit(){
        this.refreshDataset(false,false);

        if (this.aiProjectService.isObjectDetectionProject()) {
            this.notSufficientTagViews = this.tagViewService.getBelowMinimumAndWellSetTagViews();
            this.dataAmountSufficient = this.notSufficientTagViews.length === 0;
        } else {
            this.notSufficientTagViews = this.tagViewService.getBelowMinimumAndTaggedTagViews();
            this.dataAmountSufficient = this.notSufficientTagViews.length === 0;
        }
    }

    onPagination(currentPage:any) {
        this.currentPage = currentPage;
        this.datumService.onPagination(currentPage, this.selectedTagTab);
    }

    changePaginationSize(pageSize: any) {
        this.datumService.onPagination(this.currentPage, this.selectedTagTab, pageSize);
    }

    // Normal click: element is active
    selectDatum(event: any, datum: Datum) {

        if (datum) {
            if (this.activeDatums.length === 0) {
                this.lastSelectIndex = -1;
            }

            //SHIFT KEY: Range Selection
            if (event.shiftKey && this.lastSelectIndex !== -1) {
                //prevents that the images are selected like browser text selection
                if(document){
                    document.getSelection()?.removeAllRanges();
                }

                let overallIndex = this.calculateOverallIndex(datum); //index across all datasets to enable cross dataset selection

                let allDatums: Datum[] = this.datumService.getDatums();
                let min;
                let max;
                if (overallIndex < this.lastSelectIndex) {
                    min = overallIndex;
                    max = this.lastSelectIndex;
                } else {
                    min = this.lastSelectIndex;
                    max = overallIndex;
                }

                this.activeDatums = [];
                this.activeDatumsIds = [];

                for (let i = 0; i < allDatums.length; i++) {
                    if (i >= min && i <= max) {
                        let activeDatum = _.cloneDeep(allDatums[i]);
                        this.activeDatums.push(activeDatum);
                        this.activeDatumsIds.push(activeDatum.id);
                    }
                }
            // CTRL Key: Multiple Selection
            } else if (event.ctrlKey && this.lastSelectIndex !== -1) {
                if(this.activeDatumsIds.includes(datum.id)) {
                    for (let i = 0; i < this.activeDatums.length; i++) {
                        if (this.activeDatums[i].id === datum.id) {
                            this.activeDatums[i].selected = false;
                            this.activeDatums.splice(i, 1);
                            this.activeDatumsIds.splice(i, 1);
                            break;
                        }
                    }
                } else {
                    this.activeDatums.push(datum);
                    this.activeDatumsIds.push(datum.id)
                }
            } else { // NORMAL CLICK

                if (this.activeDatums.length > 0) {
                    for (let i = 0; i < this.activeDatums.length; i++) {
                        this.activeDatums[i].selected = false;
                    }
                }

                let activeImage = _.cloneDeep(datum)
                this.activeDatums = [datum];
                this.activeDatumsIds = [activeImage.id]
            }

            //Keep OverallIndex, when last Event is Shift Key
            if (!event.shiftKey && this.activeDatums.length > 0) {
                this.lastSelectIndex = this.calculateOverallIndex(datum);
            }

            if (this.aiProjectService.isSingleLabelProject()) {
                this.updateTagsFromSelectedDatums();
            }
        }
    };

    editAll = () => {
        let imagesInView = this.datumService.getDatums();
        this.openImage(imagesInView);
    };

    editSelected = () => {
         this.openImage(this.activeDatums);
    };

    openImage = (datums: Datum[]) => {
        if (this.aiProjectService.isObjectDetectionProject()) {
            let modalInstance = this.modalService.open(ObjectDetectionComponent,  {
                animation: true,
                size: 'col-12 no-padding',
                backdrop: 'static',
            })

            modalInstance.componentInstance.datums = datums;
            modalInstance.result.then(() => {
                this.refreshDataset(true, true)
            })

        } else {
            let modalInstance = this.modalService.open(ClassificationComponent,  {
                animation: true,
                size: 'col-6 no-padding',
                backdrop: 'static',
            })
            modalInstance.componentInstance.datum = datums[0];
        }
    };

    // Calculates the image's index across all datasets
    calculateOverallIndex = (image: any) => {
        let overallIndex = 0;
        let imageFound = false;

        for (let i = 0; i < this.datumService.getDatums().length; i++){
             if(this.datumService.getDatums()[i].id === image.id){
                overallIndex += i
                imageFound = true
                break
            }
        }
        return overallIndex;
    };

    // Gets all the tags from the image selection
    updateTagsFromSelectedDatums() {
        let tags = [];
        let tagsTemp = [];
        // Get all the tags from all active images.
        for (let i = 0; i < this.activeDatums.length; i++) {
            let datumLabels = this.activeDatums[i].datumLabels;
            if(datumLabels !== undefined) {
                for (let j = 0; j < datumLabels.length; j++) {
                    tagsTemp.push(datumLabels[j].datumClass?.name);
                }
            }
        }

        // Filter the duplicated ones
        tagsTemp = tagsTemp.reduce(function (prev: any, next: any) {
            prev[next] = (prev[next] + 1) || 1;
            return prev;
        }, {});

        // Copy tag view from the whole tag view list to make an active tag list
        for (let i = 0; i < this.tagViewService.getTagViews().length; i++) {
            let tName: any = this.tagViewService.getTagViews()[i].tagName;
            if (tName in tagsTemp && tagsTemp[tName] > 0) {
                tags.push(_.cloneDeep(this.tagViewService.getTagViews()[i]));
            }
        }

        this.activeTags = tags;
        if(this.activeTags.length === 1) {
            this.targetTag = this.activeTags[0].tagName
        }else {
            this.targetTag = undefined
        }
    };



    // Well set images mean the images that are ready for training.
    showWellSetImages() {
        if(this.selectedTagTab !== WELL_SET_TAB) {
            this.selectedTagTab = WELL_SET_TAB;
            this.deselectAllActiveImages();
            for(let tag of this.tagViewService.getTagViews()) {
                tag.selected = false;
            }
            this.refreshDataset(true, true)
        }
    }

    // Not ready images mean the images require further actions to be ready for the training.
    showNotReadyImages() {
        if(this.selectedTagTab !== this.NOT_READY_TAB) {
            this.selectedTagTab = this.NOT_READY_TAB;
            this.deselectAllActiveImages();
            this.refreshDataset(true, true);
        }
    }

    showImportDialog() {
        this.highlightingService.hide();
        this.highlightingService.activeHighlighting = false;

        let selectedTagNames = [];
        let currentTagViews = [];

        if (this.aiProjectService.isObjectDetectionProject()) {
            if (this.selectedTagTab === WELL_SET_TAB) {
                currentTagViews = this.tagViewService.getWellSetTagViews();
            } else {
                currentTagViews = this.tagViewService.getNotReadyTagViews();
            }
        } else {
            currentTagViews = this.tagViewService.getTaggedTagViews();
        }

        // Select the first selected tag as the default selected tag for the image import dialog
        for (let i = 0; i < currentTagViews.length; i++) {
            if (currentTagViews[i].selected) {
                selectedTagNames.push(currentTagViews[i].tagName);
            }
        }

        let modalInstance = this.modalService.open(ImportImagesComponent,  {
            animation: true,
            size: 'col-6',
            backdrop: 'static',

        })

        modalInstance.result.then(() => {
            this.refreshDataset(true, true)
        })

    }

    //Start Training
    openTrainingModelWithAugmentationCheck() {
        this.highlightingService.activeHighlighting = false;
        this.highlightingService.hide();
        let hasTagBelowMinimumAmount = false;

        if (this.aiProjectService.isObjectDetectionProject() && this.tagViewService.getBelowMinimumAndWellSetTagViews().length === 0) {
            hasTagBelowMinimumAmount = true;
        } else if (!this.aiProjectService.isObjectDetectionProject() && this.tagViewService.getBelowMinimumAndTaggedTagViews().length === 0) {
            hasTagBelowMinimumAmount = true;
        }

        // Only when the augmentation settings are not set and all images are sufficient, the augmentation model will pop-up
        if (!this.aiProjectService.isAugmentationSettingsSet() && hasTagBelowMinimumAmount) {
            let message = this.translateService.instant('ai-studio.feedback.augmentation-not-set.desc');
            let okBtn = this.translateService.instant('wf-editor.alert.yes');
            let notOkBtn = this.translateService.instant('remoteSupportWizard.btn.skip');
            this.dialogConfirm.open(message, okBtn, notOkBtn).then(async () => {
                try {
                    let modalInstance = this.modalService.open(AugmentationSettingsComponent, {
                        animation: true,
                        size: "col-5"
                    })
                    modalInstance.componentInstance.fromTraining = true

                } catch (e) {
                    console.log('Error while opening augmentation settings.');
                    console.log(e);
                }
            }, () => {
                this.openTrainingModel();
            });
        } else {
            this.openTrainingModel();
        }
    };

    openTrainingModel() {
        this.modalService.open(StartTrainingComponent, {
            animation: true,
            backdrop: 'static',
            size: "col-5",
        });
    };


    selectAll() {
        let datums =  this.datumService.datums;
        this.activeDatums = datums;
        this.activeDatumsIds = datums.map((datum: Datum) => datum.id)
        this.updateTagsFromSelectedDatums();
    };

    deselectAllActiveImages() {
        this.activeDatumsIds = [];
        this.activeDatums = [];
    };

    changeDatasetSorting(sorting: any) {
        this.datumService.setSorting(sorting);
        this.refreshDataset(false, false)
    }

    // Remove a selected Image
    removeSelected = () => {
        if (this.activeDatums.length > 0) {
            let message = this.translateService.instant('ai-studio.alert.delete-image', {imageNum: this.activeDatums.length});
            let primaryButton = this.translateService.instant('wf-editor.alert.yes');
            let secondaryButton = this.translateService.instant('wf-editor.alert.cancel');

            this.dialogConfirm.open(message, primaryButton, secondaryButton).then(async () => {
                try {
                    this.loadingService.show(this.translateService.instant('ai-studio.feedback.deleting-image'));
                    let datumsToBeDeleted: Datum[] = [];

                    for (let datum of this.activeDatums) {
                        datumsToBeDeleted.push(datum);
                    }

                    this.datumService.deleteDatums(datumsToBeDeleted).then(() => {
                        this.updatePagination(datumsToBeDeleted.length);
                        this.refreshDataset(true, true);
                    }).catch(() => {
                        this.loadingService.hide();
                    });
                } catch (e) {
                    console.log('Error while removing selected images.');
                    console.log(e);
                }

            }, (error: any) => {
                console.log(error);
            });
        }
    };

    updatePagination(toDelete: number) {
        //Update page number before fetching datums
        let updatedTotalDatums = this.datumService.totalDatums - toDelete;

        if(updatedTotalDatums >= this.datumService.pageSize){
            this.currentPage = Math.floor(updatedTotalDatums/this.datumService.pageSize)
        }
    }

    deleteImage = (datum: Datum) => {
        let message = this.translateService.instant('ai-studio.alert.delete-single-image');
        let primaryButton = this.translateService.instant('wf-editor.alert.yes');
        let secondaryButton = this.translateService.instant('wf-editor.alert.cancel');

        this.dialogConfirm.open(message, primaryButton, secondaryButton).then(async () => {
            try {
                this.loadingService.show(this.translateService.instant('ai-studio.feedback.deleting-image'));
                this.datumService.deleteDatums([datum]).then(() => {
                    this.activeDatums = this.activeDatums.filter((active: Datum) => active.id !== datum.id);
                    this.activeDatumsIds = this.activeDatumsIds.filter((active: Datum) => active !== datum.id);
                    this.refreshDataset(false, true)
                }, () => {
                    this.notificationService.info("Failed to delete image.");
                });
            } catch (e) {
                console.log('Error while removing selected image.');
                console.log(e);
            }

        }, (error: any) => {
                console.log(error);
        });
    };

    //changes tags of selected images to value from input field
    addTagToActiveDatums() {
        this.loadingService.show()

        let datums: Datum[] = [];
        for (let i = 0; i < this.activeDatums.length; i++) {
            let updatedLabel: DatumLabel= this.getDatumLabelByName(this.targetTag);
            if (updatedLabel) {
                if (this.aiProjectService.isSingleLabelProject()) {
                    let datum: Datum = this.activeDatums[i];
                    if(datum.datumLabels) {
                        datum.datumLabels[0] = updatedLabel;
                    }
                }
            }else {
                // Label got removed
                this.activeDatums[i].datumLabels = []
            }
            datums.push(this.activeDatums[i]);

        }
        this.datumService.updateDatums(datums).then(() => {
            this.refreshDataset(true, true)
        }).finally(() => this.loadingService.hide());
        this.updateTagsFromSelectedDatums();
    };

    refreshDataset(emptyActiveDatums?: boolean, fetchTags?: boolean) {
        if(emptyActiveDatums){
            this.activeDatums = [];
            this.activeDatumsIds = [];
        }

        if(fetchTags){
            this.tagViewService.fetchTagViews();
        }
        this.datasetService.countDataCollectionVideos(this.aiProjectService.getAiProject().projectUuid || "").then((res) => this.openDCVideos = res)

        this.datumService.selectPage(this.currentPage,false, this.datumService.selectedSortingSetting, this.selectedTagTab === WELL_SET_TAB);
    }

    getDatumLabelByName(datumClassName: string | undefined): DatumLabel{
        let tmp: any[] = [];
        if(this.aiProjectService.getAiProject() !== undefined) {
            this.aiProjectService.getAiProject().classes?.forEach((datumClass: DatumClass) => {
                if(datumClass.name === datumClassName) {
                    tmp.push({datumClass, type: DATUM_LABEL});
                }
            })
        }
        return tmp[0];
    };
}
