import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {LocaleViewModel} from "../utils/project-utils";
import Cookies from 'js-cookie'

@Injectable()
export class I18nService {

    languageChange = new Subject<string>();

    private languageIdentifier: string

    constructor(

    ) {

        this.languageIdentifier = "lang";
    }
    //ToDo: Needed if an endpoint will be added
    async getSupportedLocales()/*: Promise<LocaleViewModel[]>*/ {
        // const endpoint = `/locales/supported`;
        // const request = {
        //     url: `${this._config.baseUrl}${endpoint}`,
        //     method: 'GET'
        // };
        //
        // return await this._$http(request);

    }

    saveSelectedLanguage(language: LocaleViewModel) {
        if(language.code) {
            Cookies.set('userlang', language.code, { path: '/' });
        }
        localStorage.setItem(this.languageIdentifier, JSON.stringify(language));
    }


    // @ts-ignore
    getSelectedLanguage(): LocaleViewModel | undefined {

        let selectedLanguage = localStorage.getItem(this.languageIdentifier);
        if ( selectedLanguage !== null ) {
            return JSON.parse(selectedLanguage);
        }
    }

}
