<div class="modal-head">
    <h2 class="modal-title">
        <span class="wizard-title" *ngIf="mode === CREATE_MODE">
            {{ 'ai-studio.wizard.create-title' | translate }} ({{currentWizardStep}}/{{wizardStepsCount}})
        </span>
        <span class="wizard-title" *ngIf="mode === EDIT_MODE">
            {{ 'ai-studio.wizard.edit-title' | translate }} ({{currentWizardStep}}/{{wizardStepsCount}})
        </span>
        <a class="float-right">
            <i class="icon icon-times" (click)="sendCancelEvent(mode); closeModal()"></i>
        </a>
    </h2>
</div>

<div class="modal-body">
    <div class="row height100">
        <div class="col-sm-3 wizard-left-panel">
            <div class="wizard-card height100" data-color="orange" id="wizardProfile">
                <div class="wizard-navigation">
                    <div class="progress-bar-wrapper height100">
                        <div class="progress-bar" [attr.aria-valuenow]="currentWizardStep"
                        [ngStyle]="{ 'height': progressBarHeight }"></div>
                    </div>

                    <div class="progress-pills">
                        <div [ngClass]="{'active' : currentWizardStep === 1, 'finished' : currentWizardStep > 1 }" class="nav-circle" [style.height.%]="wizardStepsHeight" (click)="goToWizardStep(1);" role="presentation" tabindex="-1">
                            <div class="icon icon-circle">
                                <i class="icon icon-check" *ngIf="currentWizardStep > 1"></i>
                            </div>

                            <a href="#">
                                <span *ngIf="currentWizardStep !== 1">
                                    {{ 'ai-studio.wizard.step-title.introduction' | translate }}
                                </span>
                                <b *ngIf="currentWizardStep === 1">
                                    {{ 'ai-studio.wizard.step-title.introduction' | translate }}
                                </b>
                            </a>
                        </div>

                        <div [ngClass]="{'active' : currentWizardStep === 2, 'finished' : currentWizardStep > 2 }"
                             class="nav-circle" [style.height.%]="wizardStepsHeight"
                             (click)="goToWizardStep(2);" role="presentation" tabindex="-1">
                            <div class="icon icon-circle">
                                <i class="icon icon-check" *ngIf="currentWizardStep > 2"></i>
                            </div>

                            <a href="#">
                                <span *ngIf="currentWizardStep !== 2">
                                    {{ 'ai-studio.wizard.step-title.project-name' | translate }}
                                </span>
                                <b *ngIf="currentWizardStep === 2">
                                    {{ 'ai-studio.wizard.step-title.project-name' | translate }}
                                </b>
                            </a>
                        </div>

<!--                        <div [ngClass]="{'active' : currentWizardStep === 3, 'finished' : currentWizardStep > 3 }"-->
<!--                             class="nav-circle" [style.height.%]="wizardStepsHeight"-->
<!--                             (click)="goToWizardStep(3);" role="presentation" tabindex="-1">-->
<!--                            <div class="icon icon-circle">-->
<!--                                <i class="icon icon-check" *ngIf="currentWizardStep > 2"></i>-->
<!--                            </div>-->

<!--                            <a href="#">-->
<!--                                <span *ngIf="currentWizardStep !== 3">-->
<!--                                    {{ 'ai-studio.wizard.step-title.select-category' | translate }}-->
<!--                                </span>-->
<!--                                <b *ngIf="currentWizardStep === 3">-->
<!--                                    {{ 'ai-studio.wizard.step-title.select-category' | translate }}-->
<!--                                </b>-->
<!--                            </a>-->
<!--                        </div>-->

                        <div [ngClass]="{'active' : currentWizardStep === 3, 'finished' : currentWizardStep > 3}"
                             class="nav-circle" [style.height.%]="wizardStepsHeight"
                             (click)="goToWizardStep(3);" role="presentation"
                             tabindex="-1">
                            <div class="icon icon-circle">
                                <i class="icon icon-check" *ngIf="currentWizardStep > 2"></i>
                            </div>

                            <a href="#">
                                <span *ngIf="currentWizardStep !== 3">
                                    {{ 'ai-studio.wizard.step-title.detection-type' | translate }}
                                </span>
                                <b *ngIf="currentWizardStep === 3">
                                    {{ 'ai-studio.wizard.step-title.detection-type' | translate }}
                                </b>
                            </a>
                        </div>

                        <div [ngClass]="{'active' : currentWizardStep === 4, 'finished' : currentWizardStep > 4 }"
                             class="nav-circle" [style.height.%]="wizardStepsHeight"
                             (click)="goToWizardStep(4);" role="presentation" tabindex="-1">
                            <div class="icon icon-circle">
                                <i class="icon icon-check" *ngIf="currentWizardStep > 3"></i>
                            </div>

                            <a href="#">
                                <span *ngIf="currentWizardStep !== 4">
                                    {{ 'ai-studio.wizard.step-title.define-objects' | translate }}
                                </span>
                                <b *ngIf="currentWizardStep === 4">
                                    {{ 'ai-studio.wizard.step-title.define-objects' | translate }}
                                </b>
                            </a>
                        </div>

                        <div *ngIf="hasFrontlineRole"
                             [ngClass]="{'active' : currentWizardStep === 5, 'finished' : currentWizardStep > 5 }"
                             class="nav-circle" [style.height.%]="wizardStepsHeight"
                             (click)="goToContextStep(1); goToWizardStep(5);" role="presentation"
                             tabindex="-1">
                            <div class="icon icon-circle">
                                <i class="icon icon-check" *ngIf="currentWizardStep > 2"></i>
                            </div>

                            <a href="#">
                                <span *ngIf="currentWizardStep !== 5">
                                    {{ 'ai-studio.wizard.step-title.define-context' | translate }}
                                </span>
                                <b *ngIf="currentWizardStep === 5">
                                    {{ 'ai-studio.wizard.step-title.define-context' | translate }}
                                </b>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-9 wizard-right-panel">
            <div class="wizard-container wizard-card height100">
                <div class="tab-content height100">
                    <!-- Introduction -->
                    <div class="tab-pane" id="introduction"
                         [ngClass]="{'active' : currentWizardStep === 1}">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: left;">
                                <p>
                                    {{ 'ai-studio.wizard.intro.introduction' | translate }}
                                </p>

                                <p class="top-buffer-20">
                                    {{ 'ai-studio.wizard.intro.imageheader' | translate }}
                                </p>

                                <div class="howToContainer" style="padding: 20px; font-size: 16px;">
                                    <div class="row">
                                        <div class="col-sm-3 text-center" style="position: relative;">
                                            <i class="icon icon-3x icon-file-text-o" aria-hidden="true"></i>
                                            <p>{{ 'ai-studio.wizard.intro.create' | translate }}</p>
                                            <i class="icon icon-chevron-right intro-arrow-icon"
                                               aria-hidden="true"></i>
                                        </div>
                                        <div class="col-sm-3 text-center" style="position: relative;">
                                            <i class="icon icon-3x icon-image" aria-hidden="true"></i>
                                            <p>{{ 'ai-studio.wizard.intro.addImages' | translate }}</p>
                                            <i class="icon icon-chevron-right intro-arrow-icon"
                                               aria-hidden="true"></i>
                                        </div>
                                        <div class="col-sm-3 text-center" style="position: relative;">
                                            <i class="icon icon-3x icon-cogs" aria-hidden="true"></i>
                                            <p>{{ 'ai-studio.wizard.intro.train' | translate }}</p>
                                            <i class="icon icon-chevron-right intro-arrow-icon"
                                               aria-hidden="true"></i>
                                        </div>
                                        <div class="col-sm-3 text-center" style="position: relative;">
                                            <i class="icon icon-3x icon-check-square-o"></i>
                                            <p>{{ 'ai-studio.wizard.intro.publish' | translate }}</p>
                                        </div>
                                    </div>
                                </div>

                                <p class="top-buffer-20">
                                    <span *ngIf="hasFrontlineRole" [innerHtml]="'ai-studio.wizard.intro.furtherIntro-frontline' | translate | safeHtml"></span>
                                    <span *ngIf="!hasFrontlineRole" [innerHtml]="'ai-studio.wizard.intro.furtherIntro-public' | translate | safeHtml"></span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- Project Name -->
                    <div class="tab-pane" id="projectName" style="padding: 0" [ngClass]="{'active' : currentWizardStep === 2}">
                        <div class="row">
                            <p>
                                {{ 'ai-studio.wizard.step-desc.project-name' | translate }}
                            </p>
                            <form-field-text-input
                                class="col col-sm-12"
                                type="text"
                                label="{{ 'ai-studio.wizard.step-title.project-name' | translate }}"
                                [elementId]="'project-name-input'"
                                (keyup)="addProjectNameWrapper($event);"
                                [(ngModel)]="model.name">
                            </form-field-text-input>
                            <p class="top-buffer text-danger" *ngIf="nameExist">
                                {{ 'ai-studio.feedback.project-name-exist' | translate }}
                            </p>
                        </div>
                    </div>

                    <!-- Project Category -->
<!--                    <div class="tab-pane" id="categories" [ngClass]="{'active' : currentWizardStep === 3}">-->
<!--                        <div class="row">-->
<!--                            <p>-->
<!--                                {{ 'ai-studio.wizard.step-desc.select-category' | translate }}-->
<!--                            </p>-->
<!--                            &lt;!&ndash; ToDo: style select component &ndash;&gt;-->
<!--                            <ng-select-->
<!--                                [items]="projectDomainOptions"-->
<!--                                [bindLabel]="'displayName'"-->
<!--                                [bindValue]="'value'"-->
<!--                                (change)="this.model.projectDomain = $event.value"-->
<!--                                placeholder="{{ domainDropdownTexts.buttonDefaultText }}"-->
<!--                                [(ngModel)]="this.model.projectDomain">-->
<!--                            </ng-select>-->
<!--                        </div>-->
<!--                    </div>-->

                    <!-- Detection Type -->
                    <div class="tab-pane" id="detection-type" [ngClass]="{'active' : currentWizardStep === 3}">
                        <div class="row">
                            <div class="col-sm-12">
                                <p>
                                    {{ 'ai-studio.wizard.step-desc.detection-type' | translate }}
                                </p>
                                <p *ngIf="mode === EDIT_MODE" class="error-sign-color">{{
                                    'ai-studio.wizard.step-desc.detection-type.forbidden-type-change' |
                                        translate }}
                                </p>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p><b>{{ 'ai-studio.wizard.step-desc.detection-type.single-labeling.label' | translate }}</b></p>
                                        <div class="choice" [ngClass]="{ 'active' : model.projectType === IMAGE_CLASSIFICATION_SINGLE_LABEL }" role="presentation">
                                            <input
                                                id="img-classification-single-labeling-option"
                                                class="wizard-radio-btn"
                                                name="projectType"
                                                [disabled]="mode === EDIT_MODE"
                                                type="radio"
                                                (change) = "this.model.projectType = IMAGE_CLASSIFICATION_SINGLE_LABEL"
                                                [ngModel] = "model.projectType"
                                                (click)="this.model.projectType = IMAGE_CLASSIFICATION_SINGLE_LABEL"

                                            />
                                            <label
                                                for="img-classification-single-labeling-option"
                                                class="card card-checkboxes card-hover-effect"
                                                [ngClass]="{ 'disabled-detection-type' : mode === EDIT_MODE }">
                                                <i class="selectionHelpIcon icon" [ngClass]="{ 'icon-radio-true' : model.projectType === IMAGE_CLASSIFICATION_SINGLE_LABEL, 'icon-radio-false' : model.projectType !== IMAGE_CLASSIFICATION_SINGLE_LABEL }"></i>

                                                <div class="detection-type-img center-block">
                                                    <div class="img-container">
                                                        <i class="icon icon-image-classification"
                                                           style="font-size: 100px !important; color: #7b7b7b;"></i>
                                                    </div>
                                                    <div class="tag-container">
                                                        <div class="wf-tag badge"
                                                             style="background-color: grey;">
                                                            <span class="tagText">stars and triangle</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                        <p class="top-buffer">{{'ai-studio.wizard.step-desc.detection-type.single-labeling' | translate }}</p>
                                    </div>

                                    <div class="col-sm-6">
                                        <p><b>{{ 'ai-studio.wizard.step-desc.detection-type.object-detection-label' | translate }}</b></p>
                                        <div class="choice" [ngClass]="{ 'active' : model.projectType === OBJECT_DETECTION }" role="presentation">
                                            <input
                                                id="object-detection-option"
                                                class="wizard-radio-btn"
                                                name="projectType"
                                                [disabled]="mode === EDIT_MODE"
                                                type="radio"
                                                (change) = "this.model.projectType = OBJECT_DETECTION"
                                                [ngModel]  = "model.projectType"
                                                (click)="this.model.projectType = OBJECT_DETECTION"
                                            />
                                            <label
                                                for="object-detection-option"
                                                class="card card-checkboxes card-hover-effect"
                                                [ngClass]="{ 'disabled-detection-type' : mode === EDIT_MODE }">
                                                <i class="selectionHelpIcon icon" [ngClass]="{ 'icon-radio-true' : model.projectType === OBJECT_DETECTION, 'icon-radio-false' : model.projectType !== OBJECT_DETECTION }"></i>
                                                <div class="detection-type-img center-block">
                                                    <div class="img-container">
                                                        <i class="icon icon-image-classification"
                                                           style="font-size: 100px !important; color: #7b7b7b;"></i>
                                                        <i class="icon icon-bounding-box-stars"
                                                           style="font-size: 100px !important;"></i>
                                                        <i class="icon icon-bounding-box-triangle"
                                                           style="font-size: 100px !important;"></i>
                                                    </div>
                                                    <div class="tag-container">
                                                        <div class="wf-tag badge"
                                                             style="background-color: #597eaa;">
                                                            <span class="tagText">star (2)</span>
                                                        </div>
                                                        <div class="wf-tag badge"
                                                             style="background-color: #53883c;">
                                                            <span class="tagText">triangle (1)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                        <p class="top-buffer">{{ 'ai-studio.wizard.step-desc.detection-type.object-detection' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Define Objects -->
                    <div class="tab-pane" id="classes" [ngClass]="{'active' : currentWizardStep === 4}">
                        <div class="row">
                            <div class="col-sm-12">
                                <p [innerHTML]="'ai-studio.wizard.step-desc.define-objects' | translate | safeHtml"></p>
                            </div>
                            <div class="col-sm-12">
                                <form #datumClassForm="ngForm">
                                    <form-field-text-input
                                        maxlength="25"
                                        minlength="1"
                                        type="text"
                                        label="{{ 'ai-studio.wizard.object-name' | translate }}"
                                        [name]="'val'"
                                        class="col col-sm-12"
                                        [elementId]="'object-name-wizard-input'"
                                        (keyup)="confirmObjectInput($event);"
                                        [(ngModel)]="currentClass.val"
                                        [noDuplicatedClassName]="enableClassUpdate ? savedClassesExcludeToBeUpdated : model.classes"
                                        style="padding: 0;">
                                    </form-field-text-input>
                                </form>

                                <form-field-text-input
                                    *ngIf="!hasFrontlineRole && model.projectType === IMAGE_CLASSIFICATION_SINGLE_LABEL"
                                    type="text" label="Object description"
                                    class="col col-sm-12"
                                    [elementId]="'object-desc-wizard-input'"
                                    (keyup)="confirmObjectInput($event);"
                                    [(ngModel)]="currentClass.desc"
                                    style="padding: 0;">
                                </form-field-text-input>

                                <p class="top-buffer text-danger" *ngIf="objectError">
                                    {{ objectErrorMsg }}
                                </p>
                                <div class="tag-wrapper">
                                    <div class="top-buffer" *ngFor="let aClass of model.classes | orderBy: '-name' : ''" >
                                        <div class="wf-tag-bg">
                                            <div class="wf-tag badge" [ngStyle]="mode === EDIT_MODE && aClass.id && aClass.id === renameObject?.id && {'opacity':.5}"
                                            *ngIf="aClass.name !== tagViewService.backgroundTag"
                                                style="padding: 4px 7px;" >
                                                <span class="tagText">
                                                    {{ aClass.name }}
                                                    <i *ngIf="mode === EDIT_MODE" class="icon icon-pencil"
                                                    (click)="enableObjectClassUpdate(aClass);"
                                                    title="{{ 'ai-studio.wizard.step-desc.rename-object' | translate }}"
                                                    style="cursor: pointer;"></i>
                                                    <i *ngIf="!renameObject" class="icon icon-times"
                                                    (click)="removeObjectClass(aClass);"
                                                    title="{{ 'ai-studio.wizard.step-desc.remove-object' | translate }}"
                                                    style="cursor: pointer;"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- CONTEXT -->
                  <project-context *ngIf="hasFrontlineRole && !isModelEmpty" [currentWizardStep]="currentWizardStep" [wizardContextStepsCount]="wizardContextStepsCount" [currentContextStep]="currentContextStep" [model]="model"></project-context>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal-footer">
    <div>
        <button-secondary
             text="{{ 'cancel' | translate }}"
            (click)="closeModal();"
             event="Event,ai-project,{{mode === CREATE_MODE?'cancel-create':'cancel-update'}}"
             projectuuid="{{model.projectUuid}}">
        </button-secondary>
    </div>
    <div>
        <button-secondary
            icon="angle-left"
            text="{{ 'ai-studio.wizard.previous' | translate }}"
            *ngIf="currentWizardStep > 1 && (currentWizardStep !== 5 || currentContextStep === 1)"
            (click)="goToWizardStep(currentWizardStep - 1);">
        </button-secondary>
        <button-secondary
            icon="angle-left"
            text="{{ 'ai-studio.wizard.previous' | translate }}"
            *ngIf="currentContextStep > 1 && currentWizardStep === 5"
            (click)="goToContextStep(currentContextStep - 1);">
        </button-secondary>
        <button-primary
            icon="angle-right"
            text="{{ 'ai-studio.wizard.next' | translate }}"
            [disabled]="nextStepBtnDisabled(currentWizardStep)"
            (click)="goToWizardStep(currentWizardStep + 1)"
            *ngIf="currentWizardStep < wizardStepsCount && currentWizardStep !== 5">
        </button-primary>
        <button-primary
            class="btn-min-width"
            icon="angle-right"
            text="{{ 'ai-studio.wizard.next' | translate }}"
            [disabled]="nextContextBtnDisabled(currentContextStep)"
            (click)="goToContextStep(currentContextStep + 1)"
            *ngIf="currentContextStep < wizardContextStepsCount && currentWizardStep === 5">
        </button-primary>
        <button-primary
            icon="check"
            text="{{ 'ai-studio.wizard.create' | translate }}"
            (click)="finishSetup()"
            [disabled]="(hasFrontlineRole && nextContextBtnDisabled(currentContextStep)) || (!hasFrontlineRole && nextStepBtnDisabled(currentWizardStep))"
            *ngIf="mode === CREATE_MODE && ((hasFrontlineRole && currentContextStep == wizardContextStepsCount) || (!hasFrontlineRole && currentWizardStep === wizardStepsCount))"
            event="Event,ai-project,initialize-create-project">
        </button-primary>
        <button-primary
            icon="check"
            text="{{ 'ai-studio.wizard.update' | translate }}"
            (click)="finishSetup()"
            [disabled]="!previousWizardStepOK(wizardStepsCount) || !previousWizardStepOK(wizardStepsCount) || (hasFrontlineRole && nextContextBtnDisabled(currentContextStep)) || (!hasFrontlineRole && nextStepBtnDisabled(currentWizardStep)) || !changesDetected()"
            *ngIf="mode === EDIT_MODE"
            event="Event,ai-project,initialize-update-project,{{model.classes.length}}"
            projectuuid="{{model.projectUuid}}">
        </button-primary>
    </div>
</div>
