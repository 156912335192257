import Cookies from 'js-cookie';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from './config';
import {AuthenticationService} from "services/authentication.service";
import {TranslateService} from '@ngx-translate/core';
import {I18nService} from 'services/i18n.service';
import {DayJsService} from 'services/day-js.service';
import {firstValueFrom} from "rxjs";
import {environment} from "../environments/environment";
import {DOCUMENT} from "@angular/common";
import {Router} from "@angular/router";

@Injectable()
export class InitService {
    window;
    body: HTMLBodyElement;

    constructor(
        private http: HttpClient,
        private config: Config,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private i18nService: I18nService,
        private dayJsService: DayJsService,
        private router: Router,
        @Inject(DOCUMENT) document: Document
    ) {
        this.window = document.defaultView;
        this.body = document.querySelector('body')!;
    }

    async init(): Promise<void> {
        try {
            await this.initServerSettings();
        }
        catch(e) {
            await this.router.navigate(['error'], { queryParams: { status: '-1' } });
            return;
        }

        await this.authenticationService.init();
    }

    initServerSettings() {
        return new Promise<void>(
            async (resolve, reject) => {
                this.body.classList.add('theme-ai-studio');
                let baseUrl = environment.APIHOSTPREFIX;
                if (environment.production && this.window) {
                }
                else {
                    console.log("---------------##DEBUG VERSION##---------------");
                }
                this.config.baseUrl = baseUrl;
                try {
                    const props = await firstValueFrom(
                        this.http.get<any[]>(`${baseUrl}/app/config/getServerSettings`)
                    );
                     this.config.properties = props;
                    // props.map((item: any) => {
                    //     this.config.properties[item.propertyName] = item.value;
                    // });
                    resolve();
                }
                catch(e) {
                    this.config.getServerSettingsFailed = true;
                    reject();
                }
            }
        );
    }

    async initTranslationService(): Promise<any> {
        let locale = Cookies.get('userlang');

        if(!locale) {
            locale = 'en_US';
        }
        return this.translateService.use(locale).toPromise().then(() => {

            this.dayJsService.changeLocale(locale || '');
            this.listenLanguageChange();
        });
    }

    private listenLanguageChange() {
        // listen to lang change. Cannot use browser.cookies.onChanged as it's not supported across all browsers
        let lastLang = Cookies.get('userlang');
        setInterval(() => {
            let lang = Cookies.get('userlang');
            if (lang && lang !== lastLang) {
                this.translateService.use(lang).toPromise().then(() => {
                    this.i18nService.languageChange.next(lang ||'en_US');
                });
                lastLang = lang;
            }
        }, 1000);
    }
}
