import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {UploadItem} from '../uploader/upload.item';
import {FileUploadService} from 'services/file-upload.service';
import {HttpRequest, HttpResponse} from "tus-js-client";

@Component({
    selector: 'image-card',
    templateUrl: 'image-card.component.html',
    styleUrls: ['image-card.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class ImageCardComponent {
    @Input() uploadItem: UploadItem = {
        name: '',
        content: '',
        file: null,
        blob: null,
        hasError: false,
        isUploaded: false,
        isDuplicate: false,
        maxSizeExceeded: false,
        upload: null,
        width: 0,
        height:0,
        annotation: null,
        annotationError: false
    };
    @Output() removeEntry = new EventEmitter<UploadItem>();
    @Output() finishedUpload = new EventEmitter<UploadItem>();

    isUploading = false;
    progressBarPercent = 0;

    constructor(private fileUploadService: FileUploadService) { }

    upload(): void {
        this.isUploading = true;
        this.fileUploadService.uploadFile(
            this.uploadItem,
            this.onComplete.bind(this),
            this.onError.bind(this),
            this.onProgress.bind(this),
            this.onAfterResponse.bind(this)
        );
    }

    onComplete(): void {
        this.uploadItem.isUploaded = true;
        this.uploadItem.hasError = false;
        this.uploadItem.upload = null;
        this.isUploading = false;
        this.finishedUpload.emit(this.uploadItem)
    }

    onError(): void {
        this.uploadItem.hasError = !this.uploadItem.maxSizeExceeded && !this.uploadItem.isDuplicate && !this.uploadItem.annotationError;
        this.uploadItem.isUploaded = false;
        this.isUploading = false;
        this.finishedUpload.emit(this.uploadItem)
    }

    onProgress(bytesSent: number, bytesTotal: number): void {
        this.progressBarPercent = Math.floor((bytesSent / bytesTotal) * 100);
    }

   onAfterResponse(req: HttpRequest, res: HttpResponse): void {
       if(res.getStatus() == 413) {
           this.uploadItem.maxSizeExceeded = true
       } else if(res.getHeader("x-storage-status") === "duplicate-image") {
           this.uploadItem.isDuplicate = true
       } else if(res.getHeader("x-storage-status") === "bad-annotations") {
           this.uploadItem.annotationError = true
       }
    }

    remove(): void {
        this.removeEntry.emit(this.uploadItem);
    }

}
