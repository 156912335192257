<div>
    <!-- Navigation -->
    <div id="sidebar-menu">
        <ul class="sidebar-menu-inner">
            <li [routerLink]="'/home'" [routerLinkActive]="'active'" [ngbTooltip]="'ai-studio.home.tab' | translate" [placement]="'right'" [container]="'body'">
                <i class="icon icon-ai-home-tab"></i>
            </li>
            <li [ngClass]="aiProjectService.getAiProject() === null ? 'disabled' : ''" [routerLink]="'/dataset'" [routerLinkActive]="'active'" [ngbTooltip]="'ai-studio.dataset.tab' | translate" [placement]="'right'" [container]="'body'">
                    <i class="icon icon-ai-dataset-tab"></i>
            </li>
            <li [ngClass]="aiProjectService.getAiProject() === null ? 'disabled' : ''" [routerLink]="'/training'" [routerLinkActive]="'active'" [ngbTooltip]="'ai-studio.model.tab' | translate" [placement]="'right'" [container]="'body'">
                <i class="icon icon-ai-trainings-tab"></i>
            </li>
            <li *ngIf="hasFrontlineRole" [ngClass]="aiProjectService.getAiProject() === null ? 'disabled' : ''" [routerLink]="'/inference'" [routerLinkActive]="'active'" [ngbTooltip]="'ai-studio.inference.tab' | translate" [placement]="'right'" [container]="'body'">
                <i class="icon icon-ai-inference-tab"></i>
            </li>
        </ul>
    </div>
 </div>
