import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Config} from "global/config";
import _ from "lodash";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {DatumService} from "services/aiStudio/datum.service";
import {TagViewService} from "services/aiStudio/tag-view.service";
import {
    BOUNDING_BOX,
    BOUNDING_BOX_STROKE_WIDTH,
    DATUM_LABEL,
    DISTANCE_TO_GROUP_BORDER,
    GUIDE_LINE_THICKNESS,
    GUIDE_LINECOLOR,
    HAS_ROTATING_POINT,
    IMAGE_BORDER_WIDTH,
    IMAGE_STROKE_COLOR,
    OrderBy,
    PANNING_LIMIT_HORIZONTAL,
    PANNING_LIMIT_VERTICAL,
    PREDICTION
} from "utils/project-utils";
import {fabric} from 'fabric'
import {TranslateService} from "@ngx-translate/core";
import {HotkeysService} from "angular2-hotkeys";
import {BoundingBox, Datum, DatumClass, DatumLabel, Recognition} from "@teamviewer/aistudioapi-common-angular";
import {IEvent} from "fabric/fabric-impl";
import {AuthImagePipe} from "pipes/authImage.pipe";

const JSOG = require('jsog/lib/JSOG');

interface BackgroundImage extends fabric.Image {
    scaleY: number,
    scaleX: number,
    height: number,
    width: number
}

interface FabricEvent extends IEvent {
    e: any
}

@Component({
    selector: 'object-detection',
    templateUrl: 'object-detection.component.html',
    styleUrls: ['object-detection.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class ObjectDetectionComponent implements OnInit {
    @Input() datums : Datum[] = [];
    @HostBinding('class') class = 'modal-content';
    datum: Datum = {};

    showLoadingAnimation = false;
    openRecognitions = false;

    canvas: any;                             // The Canvas
    availableHeight: number = 0;                    // available height = modal height - header and padding
    availableWidth: number = 0;                     // available width = modal width - padding and margins
    scalingFactor = 1;                  // image's scalingFactor to calculate the real pixels
    offsetLeft = 0;                     // Offset for calculation bounding box' position, if image is not as wide as canvas
    offsetTop = 0;                      // Offset for calculation bounding box' position if image is not as high as canvas
    ratioAvailableSpace: number = 0;                // aspect ratio of available space / canvas
    ratioImage: number = 0;                         // aspect ratio of image
    myImg: any;                              // the img / datum view

    rect: any;                               // the currently drawn rectangle
    origX: any;                              // current cursor position x
    origY: any;                              // current cursor position y
    guideHorizontal: any;
    guideVertical: any;
    currentClassHint: any;                   // Hint for current class next to the mouse cursor
    selectedObject: any;                     // the currently highlighted bounding box group

    //Status
    isDown: boolean = false;                             // true while mouse is down
    isMovingBBGroup = false;            // true while mouse is down on a highlighted bounding box group
    isScaling = false;                  // true while bounding box is in progress of scaling
    isHoveringDeleteButton = false;     // true while mouse is hovering over the delete buttton
    spaceKeyPressed = false;            // true while space key is pressed for panning the canvas
    duplicateKeyPressed = false;        // true while 'd' is pressed for duplicating an bounding box
    numIssues = 0;

    classes: any = this.aiProjectService.getNotBackgroundClasses();
    canvasRendered: boolean = false;
    pointer: any;
    index: number = -1;
    selectedClass: any = this.classes[0];

    lastStepsDatumViews: any = [];
    currentStepIndex = 0;
    hotkeys: any = null;

    OrderBy = OrderBy;

    constructor(public aiProjectService: AiProjectService, private tagViewService: TagViewService, public config: Config, private datumService: DatumService, private translateService: TranslateService, private hotkeysService: HotkeysService, private activeModal: NgbActiveModal, private authImagePipe: AuthImagePipe){
        this.hotkeys = hotkeysService;
        //HOTKEYS
        this.hotkeys.add({
            combo: 'del',
            description: 'Delete',
            callback: () => {
                let activeObject = this.canvas.getActiveObject();
                if (activeObject) {
                    let pointer: any= {};
                    pointer.x = this.origX;
                    pointer.y = this.origY;
                    this.deleteBoundingBox(activeObject.data);
                    let vpt = this.canvas.viewportTransform;

                    this.setGuideLines(vpt, pointer);
                    this.setCurrentClassHint(vpt, pointer);

                    this.canvas.defaultCursor = "default";
                    this.canvas.setCursor(this.canvas.defaultCursor);
                    this.canvas.renderAll();
                }
            }})
        this.hotkeys.add({
        combo: ['left', 'up', 'w', 'a'],
        description: this.translateService.instant('ai-studio.object-detection.pre-img.tooltip'),
        callback: (e: MouseEvent) => {
            e.preventDefault();
            this.selectPreviousImage();
        }})
        this.hotkeys.add({
        combo: ['right', 'down', 's', 'd'],
        description: this.translateService.instant('ai-studio.object-detection.next-img.tooltip'),
        callback:(e: MouseEvent) => {
            e.preventDefault();
            this.selectNextImage();
        }})
        this.hotkeys.add({
        combo: 'ctrl+z',
        description: 'Step Back',
        callback:() => {
            this.goStepBack();
        }})
        this.hotkeys.add({
        combo: 'ctrl+shift+z',
        description: 'Step Forward',
        callback:() => {
            this.goStepForward();
        }});

        /* Hotkeys with mouse up and down events */
        // event handler for alt key
        document.onkeydown = (event: KeyboardEvent) => {
            let vpt = this.canvas.viewportTransform;
            let pointer = {};
            // 32 = space key | Canvas Panning Mode
            if (event.code === 'Space') {
                if (!this.spaceKeyPressed) {
                    this.spaceKeyPressed = true;
                    this.canvas.defaultCursor = "grab";
                    this.canvas.setCursor(this.canvas.defaultCursor);
                    this.setGuideLines(vpt, pointer);
                    this.setCurrentClassHint(vpt, pointer);
                    this.canvas.discardActiveObject();
                    this.selectedObject = null;
                    this.isMovingBBGroup = false;
                    let objects = this.canvas.getObjects('bbGroup');

                    for (let object of objects) {
                        object.evented = false;
                    }

                    this.canvas.renderAll();
                }
            }

            //Todo: f | duplicating mode
            if (event.key === 'f') {
                this.duplicateKeyPressed = true;
            }

            // 17 = ctrl | draw on top of bounding boxes / sets all bounding box groups to unselectable
            if (event.key === '17') {
                this.canvas.forEachObject((object: any) => {
                    object.selectable = false;
                    object.evented = false;
                });
            }
        };

        document.onkeyup = (event: KeyboardEvent) => {
            let vpt = this.canvas.viewportTransform;
            let pointer = {};
            if (event.code === 'Space') {
                this.spaceKeyPressed = false;
                let objects = this.canvas.getObjects('bbGroup');
                for (let object of objects) {
                    object.evented = true;
                }
                this.canvas.defaultCursor = "default";
                this.canvas.setCursor(this.canvas.defaultCursor);
                this.setGuideLines(vpt, pointer);
                this.setCurrentClassHint(vpt, pointer);
                this.canvas.renderAll();
            }
            if (event.key === 'f') {
                this.duplicateKeyPressed = false;
            }

            // 17 = ctrl | draw on top of bounding boxes / sets all bounding box groups to unselectable
            if (event.keyCode === 17) {
                this.canvas.forEachObject((object: any) => {
                    if (object.get('type') === 'bbGroup') {
                        object.selectable = true;
                        object.evented = true;
                    }
                });
            }
        };
    }

    ngOnInit(){
        this.datum = this.datums[0];
        this.openRecognitions = (this.datum.recognitions?.filter((rec: Recognition) => rec?.datumClass?.name !== this.tagViewService.backgroundTag).length||0) > 0
        this.numIssues = this.getNumberOfIssues()
        this.modalRendered();
    }

    isObjectDetectionReady(datum: Datum){
        this.numIssues = this.getNumberOfIssues() // Whenever training readiness is checked we should update number of issues
        return this.datumService.datumIsObjectDetectionReady(datum) && (datum.recognitions?.length===0)
    }

    modalRendered() {
        this.canvas = new fabric.Canvas('imageRecognitionCanvas', {uniformScaling: false, containerClass: "centered-canvas-container"});
        this.canvas.selection = false;
        this.canvas.stateful = true;
        window.addEventListener('resize', () => this.resizeCanvas, false);

        // Set visibility of all classes' bounding boxes to true
        for (let aClass of this.classes) {
            aClass.visible = true;
        }

        this.canvas.uniScaleTransform = true;

        this.guideHorizontal = new fabric.Line([0, 0, 0, 0], {left: 0, top: 0, selectable: false, evented: false});
        this.guideVertical = new fabric.Line([0, 0, 0, 0], {left: 0, top: 0, selectable: false, evented: false});

        if (!this.datumIsBackground(this.datum)) {
            this.setEventListeners();
            this.generateHotKeys();
        }

        this.addCurrentClassHint();
        this.initCanvas();
    }

    setEventListeners() {
        //set EventListeners
        this.canvas.on('object:modified', (opt: FabricEvent) => this.updateBoundingBox(opt));
        this.canvas.on('mouse:down', (opt: FabricEvent) => this.mouseDown(opt));
        this.canvas.on('mouse:move', (opt: FabricEvent) => this.mouseMove(opt));
        this.canvas.on('mouse:up', (opt: FabricEvent) => this.mouseUp(opt));
        this.canvas.on('mouse:wheel', (opt: FabricEvent) => this.mouseWheel(opt));
        this.canvas.on('mouse:out', (opt: FabricEvent) => this.mouseOut(opt));
        this.canvas.on('mouse:over', (opt: FabricEvent) => this.mouseOver(opt));
        this.canvas.on('object:scaling', (opt: FabricEvent) =>this.objectScaling(opt));
        this.canvas.on('before:selection:cleared',(opt: FabricEvent) =>this.beforeSelectionCleared(opt));
        this.canvas.on('selection:updated', (opt: FabricEvent) =>this.beforeSelectionCleared(opt));
    }

    setBackgroundImage(myImg: BackgroundImage) {
        //Check if Portrait or Landscape Mode, scale and center horizontally or vertically on canvas
        let vpt = this.canvas.viewportTransform;

        // TODO: Should provide error case if image is not there.
        if (!this.ratioImage || !this.ratioAvailableSpace) {
            return;
        }

        if(myImg){
            myImg.stroke = IMAGE_STROKE_COLOR;
            myImg.strokeWidth = IMAGE_BORDER_WIDTH / vpt[0];

            if (this.ratioImage > this.ratioAvailableSpace) {
                myImg.scaleToWidth(this.availableWidth - (2 * IMAGE_BORDER_WIDTH));
                myImg.top = (this.canvas.height - myImg.height * myImg.scaleY) / 2;
                myImg.left = 0;
                this.offsetTop = myImg.top;
                this.offsetLeft = 0;
                this.scalingFactor = myImg.scaleY;
            } else {
                myImg.scaleToHeight(this.availableHeight - (2 * IMAGE_BORDER_WIDTH));
                myImg.top = 0;
                myImg.left = (this.canvas.width - myImg.width * myImg.scaleX) / 2;
                this.offsetTop = 0;
                this.offsetLeft = myImg.left;
                this.scalingFactor = myImg.scaleX;
            }
            this.canvas.setBackgroundImage(myImg)
            this.canvas.renderAll();
        }
    }

    initCanvas() {
        this.authImagePipe.transform(this.config.baseUrl +'/aiStorage/'+  this.aiProjectService.getAiProject().projectUuid +'/datum/'+ this.datum.id + '/image?size=raw').then(
            url => {

                this.canvas.setZoom(1);
                this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
                this.removeAllBoundingBoxesFromCanvas();
                this.canvas.backgroundImage = false;
                this.showLoadingAnimation = true;
                this.loadBackgroundImage(url);
            }
        );
        this.lastStepsDatumViews = [];
        this.index = this.datums.map((datum: Datum) => {
            return datum.id;
        }).indexOf(this.datum.id);
        for (let datum of this.datums){
            datum.trainingReady = this.isObjectDetectionReady(datum)
        }

    };

    loadBackgroundImage(url?: string) {
        if(url){
            fabric.util.loadImage(url, (img: HTMLImageElement) => {
                if (img) {
                    this.showLoadingAnimation = false;
                    this.canvas.clear();
                    this.myImg = new fabric.Image(img);
                    this.ratioImage = img.width / img.height;
                    this.setCanvasDimensions();
                    this.setBackgroundImage(this.myImg);
                    this.drawBoundingBoxes(this.datum.datumLabels?.map((value: DatumLabel) => value as BoundingBox)||[]);
                    this.drawBoundingBoxesFromRecognitions(this.datum.recognitions)
                    this.canvasRendered = true;
                } else {
                    console.log("Failed to load image, please check!");
                }
            });
        }
    }

    setCanvasDimensions() {
        if(this.canvas) {
            let vpt = this.canvas.viewportTransform;
            let canvasContainer = document.getElementById('canvasContainer');

            if(canvasContainer) {
                this.availableHeight = canvasContainer.clientHeight;
                this.availableWidth = canvasContainer.clientWidth - 30;
                this.ratioAvailableSpace = this.availableWidth / this.availableHeight;

                this.canvas.setHeight(this.availableHeight);
                this.canvas.setWidth(this.availableWidth);
            }

            this.guideHorizontal.set({
                width: this.canvas.width * vpt[0],
                stroke: GUIDE_LINECOLOR,
                strokeWidth: GUIDE_LINE_THICKNESS,
                left: 0,
                top: -1 * vpt[5]
            });

            this.guideVertical.set({
                height: this.canvas.height * vpt[0],
                top: 0,
                left: -1 * vpt[4],
                stroke: GUIDE_LINECOLOR,
                strokeWidth: GUIDE_LINE_THICKNESS
            });
        }
    }

    resizeCanvas() {
        this.setCanvasDimensions();
        this.setBackgroundImage(this.myImg);
        this.removeAllBoundingBoxesFromCanvas();
        this.drawBoundingBoxes(this.datum.datumLabels?.map(value => value as BoundingBox)||[]);
        this.drawBoundingBoxesFromRecognitions(this.datum.recognitions)
        this.canvas.renderAll();
    }

    addGuideLines() {
        if (this.selectedClass) {
            this.canvas.add(this.guideHorizontal);
            this.canvas.add(this.guideVertical);
        }
    }

    drawBoundingBoxes(boundingBoxes: BoundingBox[], isRecognition: boolean = false) {
        let visibleClasses = [];
        for (let aClass of this.classes) {
            if (aClass.visible) {
                visibleClasses.push(aClass.name);
            }
        }

        for (let boundingBox of boundingBoxes) {
            if (visibleClasses.indexOf(boundingBox?.datumClass?.name) >= 0) {
                this.drawBoundingBox(boundingBox, this.scalingFactor, isRecognition);
            }
        }
    }

    drawBoundingBox(boundingBox: BoundingBox, scalingFactor: number, isRecognition: boolean, selectAfterDrawing?: boolean) {
        let vpt = this.canvas.viewportTransform;
        if(boundingBox.width && boundingBox.height && boundingBox.centerPosX && boundingBox.centerPosY && boundingBox.datumClass) {
            let rec = new fabric.Rect({
                width: boundingBox.width * scalingFactor - BOUNDING_BOX_STROKE_WIDTH / vpt[0],
                height: boundingBox.height * scalingFactor - BOUNDING_BOX_STROKE_WIDTH / vpt[0],
                left: boundingBox.centerPosX * scalingFactor + this.offsetLeft - (boundingBox.width / 2 * scalingFactor),
                top: boundingBox.centerPosY * scalingFactor + this.offsetTop - (boundingBox.height / 2 * scalingFactor),
                //fill: boundingBox.datumClass.color,
                fill: 'rgba(0,0,0,0)',
                opacity: 1,
                lockUniScaling: true,
                lockScalingX: true,
                lockScalingY: true,
                stroke: boundingBox.datumClass.color,
                strokeWidth: BOUNDING_BOX_STROKE_WIDTH / vpt[0],
                selectable: false,
                strokeDashArray: isRecognition?[10]:[]
            });

            let g = new fabric.Group([rec], {
                subTargetCheck: true,
                data: boundingBox,
                transparentCorners: false,
                cornerColor: boundingBox.datumClass.color,
                borderColor: boundingBox.datumClass.color,
                borderScaleFactor: 0,
                cornerSize: 10,
                padding: 0,
                selectable: false,
                hasControls: false,
                lockMovementX: true,
                lockMovementY: true,
                type: 'bbGroup'
            });

            g.controls = {
                ...fabric.Group.prototype.controls,
                mtr: new fabric.Control({ visible: false })
            }

            g.on('mouseout', () => {
                if (!this.isDown) {
                    this.canvas.discardActiveObject();
                }
                this.removeDeleteButtonFromBoundingBox(g);
            });

            this.canvas.add(g);

            if (selectAfterDrawing) {
                this.canvas.setActiveObject(g);
                this.selectedObject = g;
            }
        }else {
            console.warn("Won't display invalid bounding box")
        }
    }

    //highlights Boundgin Box Groups within a distance of 5 pixels to the border.
    activateNearBBGroup() {
        let vpt = this.canvas.viewportTransform;
        let allBBGroups = this.canvas.getObjects('bbGroup');
        let pointer = this.canvas.getPointer();
        let d = DISTANCE_TO_GROUP_BORDER / vpt[0]; //offset

        if (allBBGroups.length > 0) {
            for (let bbGroup of allBBGroups) {
                if
                (!this.isScaling && ((pointer.x > bbGroup.aCoords.tl.x - d && pointer.x < bbGroup.aCoords.tl.x + d && pointer.y > bbGroup.aCoords.tl.y - d && pointer.y < bbGroup.aCoords.bl.y + d) || // pointer is close to rectangle's left border and not on the resize handlers
                    (pointer.x < bbGroup.aCoords.tr.x + d && pointer.x > bbGroup.aCoords.tr.x - d && pointer.y > bbGroup.aCoords.tl.y - d && pointer.y < bbGroup.aCoords.bl.y + d) ||                 // pointer is close to rectangle's left border and not on the resize handlers
                    (pointer.y > bbGroup.aCoords.tl.y - d && pointer.y < bbGroup.aCoords.tl.y + d && pointer.x > bbGroup.aCoords.tl.x - d && pointer.x < bbGroup.aCoords.tr.x + d) ||                 // pointer is close to rectangle's top border and not on the resize handlers
                    (pointer.y < bbGroup.aCoords.bl.y + d && pointer.y > bbGroup.aCoords.bl.y - d && pointer.x > bbGroup.aCoords.bl.x - d && pointer.x < bbGroup.aCoords.br.x + d) ||                 // pointer is close to rectangle's bottom border and not on the resize handlers
                    (pointer.y < bbGroup.aCoords.tr.y + 20 / vpt[0] && pointer.y > bbGroup.aCoords.tr.y && pointer.x < bbGroup.aCoords.tr.x && pointer.x > bbGroup.aCoords.tr.x - 20 / vpt[0]))) {             // pointer is on the delete Button.

                    bbGroup.selectable = true;
                    bbGroup.hasControls = true;
                    this.canvas.hoverCursor = 'move';
                    this.canvas.defaultCursor = 'move';
                    this.selectedObject = bbGroup;
                    this.canvas.setActiveObject(this.selectedObject);
                    this.isHoveringDeleteButton = false;

                    // pointer is on the delete Button.
                    if (pointer.y < bbGroup.aCoords.tr.y + 20 / vpt[0] && pointer.y > bbGroup.aCoords.tr.y && pointer.x < bbGroup.aCoords.tr.x && pointer.x > bbGroup.aCoords.tr.x - 20 / vpt[0]) {
                        bbGroup.selectable = false;
                        this.canvas.hoverCursor = 'pointer';
                        this.canvas.defaultCursor = 'pointer';
                        this.selectedObject = null;
                        this.addDeleteButtonToBoundingBox(bbGroup);
                        this.isHoveringDeleteButton = true;
                    }
                    break;
                } else {
                    bbGroup.hasControls = false;
                    bbGroup.selectable = false;
                    this.canvas.defaultCursor = 'default';
                    this.canvas.hoverCursor = 'default';
                    this.canvas.discardActiveObject();
                    this.selectedObject = null;
                    this.isHoveringDeleteButton = false;
                }
            }
        } else {
            this.canvas.defaultCursor = 'default';
            this.canvas.hoverCursor = 'default';
            this.selectedObject = null;
        }
    }

    removeAllBoundingBoxesFromCanvas() {
        let objects = this.canvas.getObjects('bbGroup');
        for (let bbGroup of objects) {
            this.canvas.remove(bbGroup);
        }
    }

    saveNewBoundingBox(rectangle: any, datumClass: DatumClass) { //class is optional, for cloning an object
        let rec = this.removeOffsetOfRectangle(rectangle);
        let boxBigEnough = this.isBoundingBoxBigEnough(rec);
        if (boxBigEnough) {
            let bbWidth = rec.width / this.scalingFactor;
            let bbHeight = rec.height / this.scalingFactor;
            let bbCenterPosX = (rec.getCenterPoint().x - this.offsetLeft) / this.scalingFactor;
            let bbCenterPosY = (rec.getCenterPoint().y - this.offsetTop) / this.scalingFactor;

            let newBoundingBox = {} as BoundingBox;
            newBoundingBox.width = bbWidth;
            newBoundingBox.height = bbHeight;
            newBoundingBox.centerPosX = bbCenterPosX;
            newBoundingBox.centerPosY = bbCenterPosY;
            newBoundingBox.datumClass = datumClass;
            newBoundingBox.type = BOUNDING_BOX;
            this.drawBoundingBox(newBoundingBox, this.scalingFactor, false,true)

            this.datum.datumLabels = this.datum.datumLabels?.concat([newBoundingBox]);
            //If there's a class remove it
            this.datum.datumLabels = this.datum.datumLabels?.filter((aClass: any) => !(aClass.datumClass.name === datumClass.name && aClass.type === DATUM_LABEL))
            this.datum.recognitions = this.datum.recognitions?.filter((aClass: any) => !(aClass.datumClass.name === this.tagViewService.backgroundTag))
            this.datum.trainingReady = this.isObjectDetectionReady(this.datum)
            this.replaceDatumInDatums(this.datum);
            this.addStepToQueue();
        }
    }

    removeOffsetOfRectangle(rectangle: any) {
        if (rectangle) {
            let rec = rectangle;

            //moves the rectangle to image's left border
            if (rec.left < this.offsetLeft) {
                let widthInOffsetLeft = this.offsetLeft - rec.left;
                rec.left = this.offsetLeft;
                rec.width = rec.width - widthInOffsetLeft;
            }
            //moves rectangle to the image's top border
            if (rec.top < this.offsetTop) {
                let widthInOffsetTop = this.offsetTop - rec.top;
                rec.top = this.offsetTop;
                rec.height = rec.height - widthInOffsetTop;
            }
            // moves rectangle to the image's right border
            if (rec.left + rec.width > this.offsetLeft + this.myImg.width * this.myImg.scaleX) {
                let widthInOffsetRight = rec.left + rec.width - (this.myImg.width * this.myImg.scaleX + this.offsetLeft);
                rec.width = rec.width - widthInOffsetRight;
                rec.left = this.offsetLeft + this.myImg.width * this.myImg.scaleX - rec.width;
            }

            // moves rectangle to the image's right border
            if (rec.top + rec.height > this.offsetTop + this.myImg.height * this.myImg.scaleY) {
                let heightInOffsetBottom = rec.top + rec.height - (this.myImg.height * this.myImg.scaleY + this.offsetTop);
                rec.height = rec.height - heightInOffsetBottom;
                rec.top = this.offsetTop + this.myImg.height * this.myImg.scaleY - rec.height;
            }

            return rec;
        }
    }

    updateBoundingBox(e: any) {
        //Only update if we are scaling or moving - for moving this function might be called twice
        if(this.isScaling || this.isMovingBBGroup) {
            this.isScaling = false;
            this.isMovingBBGroup = false;
            let updatedBoundingBox = e.target || e;
            let vpt = this.canvas.viewportTransform;

            if (updatedBoundingBox.data) {
                let boundingBox = updatedBoundingBox.data;
                boundingBox.centerPosX = (updatedBoundingBox.getCenterPoint().x - this.offsetLeft) / this.scalingFactor;
                boundingBox.centerPosY = (updatedBoundingBox.getCenterPoint().y - this.offsetTop) / this.scalingFactor;
                boundingBox.width = updatedBoundingBox.width * updatedBoundingBox.scaleX / this.scalingFactor;
                boundingBox.height = updatedBoundingBox.height * updatedBoundingBox.scaleY / this.scalingFactor;

                let brNew = updatedBoundingBox.getBoundingRect();
                updatedBoundingBox.setCoords();

                let newWidth = brNew.width / vpt[0];
                let newHeight = brNew.height / vpt[0];

                //crop BB when scaled in left offset
                if (updatedBoundingBox.aCoords.tl.x < this.offsetLeft) {
                    let widthInOffsetLeft = this.offsetLeft - updatedBoundingBox.aCoords.tl.x;
                    boundingBox.centerPosX = (newWidth - widthInOffsetLeft) / 2 / this.scalingFactor;
                    boundingBox.width = (newWidth - widthInOffsetLeft) / this.scalingFactor;
                }

                //crop BB when scaled in top offset
                if (updatedBoundingBox.aCoords.tl.y < this.offsetTop) {
                    let heightInOffsetTop = this.offsetTop - updatedBoundingBox.aCoords.tl.y;
                    boundingBox.centerPosY = (newHeight - heightInOffsetTop) / 2 / this.scalingFactor;
                    boundingBox.height = (newHeight - heightInOffsetTop) / this.scalingFactor;
                }

                //crop BB when scaled in right offset
                if (updatedBoundingBox.aCoords.tr.x > this.offsetLeft + this.myImg.width * this.myImg.scaleX) {
                    let widthInOffsetRight = updatedBoundingBox.aCoords.tr.x - (this.myImg.width * this.myImg.scaleX + this.offsetLeft);
                    boundingBox.width = (newWidth - widthInOffsetRight) / this.scalingFactor;
                    boundingBox.centerPosX = ((updatedBoundingBox.aCoords.tl.x + (newWidth - widthInOffsetRight) / 2) - this.offsetLeft) / this.scalingFactor;
                }

                //crop BB when scaled in bottom offset
                if (updatedBoundingBox.aCoords.bl.y > this.offsetTop + this.myImg.height * this.myImg.scaleY) {
                    let widthInOffsetBottom = updatedBoundingBox.aCoords.bl.y - (this.myImg.height * this.myImg.scaleY + this.offsetTop);
                    boundingBox.height = (newHeight - widthInOffsetBottom) / this.scalingFactor;
                    boundingBox.centerPosY = ((updatedBoundingBox.aCoords.tl.y + (newHeight - widthInOffsetBottom) / 2) - this.offsetTop) / this.scalingFactor;
                }

                if (updatedBoundingBox.data.type === PREDICTION) {
                    // Got update on recognition, making it to label
                    let rect = new fabric.Rect({
                        left: (boundingBox.centerPosX - boundingBox.width / 2) * this.scalingFactor + this.offsetLeft,
                        top: (boundingBox.centerPosY - boundingBox.height / 2) * this.scalingFactor + this.offsetTop,
                        width: (boundingBox.width) * this.scalingFactor,
                        height: (boundingBox.height) * this.scalingFactor
                    })
                    this.deleteBoundingBox(boundingBox)
                    this.saveNewBoundingBox(rect, boundingBox.datumClass)
                    this.openRecognitions = (this.datum.recognitions?.filter((rec: Recognition) => rec?.datumClass?.name !== this.tagViewService.backgroundTag).length || 0) > 0
                } else {
                    this.drawBoundingBox(boundingBox, this.scalingFactor, false, true);
                }

                this.addStepToQueue();
                this.canvas.remove(updatedBoundingBox);
            }
        }

    }

    addDeleteButtonToBoundingBox(group: any) {
        let vpt = this.canvas.viewportTransform;
        let boundingBox = group.data;
        let deleteButton = group.getObjects('deleteButton');

        if (deleteButton.length > 0) {
            return;
        }

        let text = new fabric.Text('✘', {
            left: boundingBox.centerPosX * this.scalingFactor + this.offsetLeft - (boundingBox.width / 2 * this.scalingFactor) + boundingBox.width * this.scalingFactor - 25 / vpt[0],
            top: boundingBox.centerPosY * this.scalingFactor + this.offsetTop - (boundingBox.height / 2 * this.scalingFactor - 5 / vpt[0]),
            fontSize: 20 / vpt[0],
            fill: 'white',
            hoverCursor: 'pointer',
            selectable: false,
            type: 'deleteButton'
        });

        text.on('mouseup', (e: any) => {
            if (!this.isDown) {
                this.deleteBoundingBox(e.target.data);
                this.isHoveringDeleteButton = false;

                let pointer = this.canvas.getPointer();
                let vpt = this.canvas.viewportTransform;

                this.setGuideLines(vpt, pointer);
                this.setCurrentClassHint(vpt, pointer);

                this.canvas.defaultCursor = "default";
                this.canvas.setCursor(this.canvas.defaultCursor);
                //canvas.renderAll();
            }
        });

        group.addWithUpdate(text);
        group.add(text);
    }

    removeDeleteButtonFromBoundingBox(group: any) {
        let deleteButtons = group.getObjects('deleteButton');
        for (let deleteButton of deleteButtons) {
            group.remove(deleteButton);
        }
    }

    /* Mousedown */
    mouseDown(opt: any) {
        // User is not able to draw any bounding box if the image is not fully loaded.
        if (this.showLoadingAnimation) {
            return;
        }
        let vpt = this.canvas.viewportTransform;
        let evt = opt.e;

        if (opt.target && opt.target.get('type') === 'bbGroup') {
            //duplicating selected bounding box
            if (this.duplicateKeyPressed) {
                let rectangle = opt.target.getObjects('rect');
                let duplicatedRectangle = fabric.util.object.clone(rectangle[0]);
                duplicatedRectangle.set({
                    left: opt.target.left,
                    top: opt.target.top,
                });
                this.saveNewBoundingBox(duplicatedRectangle, opt.target.data.datumClass);
            }
        }

        if (this.selectedObject != null && !this.isMouseOnScalingHandler() && !this.isHoveringDeleteButton) {  //object is selected and cursor is not on resize handler
            this.isMovingBBGroup = true;
            this.canvas.lastPosX = evt.clientX;
            this.canvas.lastPosY = evt.clientY;
        }

        //Dragging canvas mode
        if (this.spaceKeyPressed) {
            this.canvas.isDragging = true;
            this.canvas.lastPosX = evt.clientX;
            this.canvas.lastPosY = evt.clientY;
        } else if (!this.isMovingBBGroup && this.selectedClass !== null && this.selectedClass.visible && !this.isMouseOnScalingHandler() && !this.isHoveringDeleteButton) { //draw rectangle / bounding box
            this.isDown = true;

            let pointer = this.canvas.getPointer();
            this.origX = pointer.x;
            this.origY = pointer.y;
            this.rect = new fabric.Rect({
                left: this.origX,
                top: this.origY,
                width: pointer.x - this.origX,
                height: pointer.y - this.origY,
                fill: 'rgba(0,0,0,0)',
                opacity: 1,
                stroke: this.selectedClass.color,
                type: 'rect',
                strokeWidth: BOUNDING_BOX_STROKE_WIDTH / vpt[0],
                lockUniScaling: true,
                lockRotation: HAS_ROTATING_POINT,
                evented: false,
                selectable: false,
                hasBorders: false,
                hasControls: false
            });
            this.canvas.add(this.rect);
            this.canvas.setActiveObject(this.rect);
        }
    }

    /* Mousemove */
    mouseMove(opt: any) {
        let e = opt.e;
        let pointer = this.canvas.getPointer();
        this.pointer = pointer;

        if (!this.isDown) {
            this.origY = pointer.y;
            this.origX = pointer.x;
        }

        let vpt = this.canvas.viewportTransform;

        this.setGuideLines(vpt, pointer);
        if (!this.isMovingBBGroup && !this.canvas.isDragging && !this.isDown && !this.spaceKeyPressed) {
            this.activateNearBBGroup();
            this.setCurrentClassHint(vpt, pointer);
        }
        if (this.isMovingBBGroup) {
            let allBBGroups = this.canvas.getObjects('bbGroup');

            for (let group of allBBGroups) {
                group.set({
                    selectable: false,
                    hasControls: false
                });
            }

            this.selectedObject.set({
                selectable: true,
                hasControls: true
            });

            let pixelsToMoveX = (e.clientX - this.canvas.lastPosX) / vpt[0];
            let pixelsToMoveY = (e.clientY - this.canvas.lastPosY) / vpt[0];

            if (this.selectedObject.left + pixelsToMoveX >= this.offsetLeft &&                                                         //prevent moving bounding box outside of image's left border
                this.selectedObject.top + pixelsToMoveY >= this.offsetTop &&                                                          //prevent moving bounding box outside of image's top border
                this.selectedObject.left + this.selectedObject.width + pixelsToMoveX <= this.offsetLeft + this.myImg.width * this.myImg.scaleX &&    //prevent moving bounding box outside of image's right border
                this.selectedObject.top + this.selectedObject.height + pixelsToMoveY <= this.offsetTop + this.myImg.height * this.myImg.scaleY)     //prevent moving bounding box outside of image's bottom border
            {
                this.selectedObject.left = this.selectedObject.left + pixelsToMoveX;
                this.selectedObject.top = this.selectedObject.top + pixelsToMoveY;
                this.canvas.setActiveObject(this.selectedObject);
            }
            this.canvas.lastPosX = e.clientX;
            this.canvas.lastPosY = e.clientY;
        }

        // panning the canvas
        if (this.canvas.isDragging) {
            let factorHorizontal = 1;
            let factorVertical = 1;

            if (vpt[0] === 1 && this.ratioImage < this.ratioAvailableSpace) {
                factorHorizontal = -1;
            } else if (vpt[0] === 1 && this.ratioImage > this.ratioAvailableSpace) {
                factorVertical = -1;
            }

            // panning limit to left and right
            if (((vpt[4] + e.clientX - this.canvas.lastPosX) > factorHorizontal * (((this.canvas.width * vpt[0] - this.canvas.width) * -1) + this.offsetLeft * vpt[0] - PANNING_LIMIT_HORIZONTAL)) && (vpt[4] + e.clientX - this.canvas.lastPosX) < factorHorizontal * (-1 * (this.offsetLeft * vpt[0] - PANNING_LIMIT_HORIZONTAL))) {
                vpt[4] += e.clientX - this.canvas.lastPosX;
            }

            // panning limit to top and bottom
            if (((vpt[5] + e.clientY - this.canvas.lastPosY) > factorVertical * (((this.canvas.height * vpt[0] - this.canvas.height) * -1) + this.offsetTop * vpt[0] - PANNING_LIMIT_VERTICAL)) && (vpt[5] + e.clientY - this.canvas.lastPosY) < factorVertical * (-1 * (this.offsetTop * vpt[0] - PANNING_LIMIT_VERTICAL))) {
                vpt[5] += e.clientY - this.canvas.lastPosY;
            }

            //canvas.requestRenderAll();
            this.canvas.lastPosX = e.clientX;
            this.canvas.lastPosY = e.clientY;
        }

        // draw a rectangle
        if (this.isDown) {
            if (this.origX > pointer.x && pointer.x > 0) {
                this.rect.set({left: Math.abs(pointer.x)});
            }
            if (this.origY > pointer.y && pointer.y > 0) {
                this.rect.set({top: Math.abs(pointer.y)});
            }

            if (pointer.x < 0) {
                this.rect.set({width: Math.abs(this.origX)});
                this.rect.set({left: 0});
            } else {
                this.rect.set({width: Math.abs(this.origX - pointer.x)});
            }

            if (pointer.y < 0) {
                this.rect.set({height: Math.abs(this.origY)});
                this.rect.set({top: 0});
            } else {
                this.rect.set({height: Math.abs(this.origY - pointer.y)});
            }
        }
        //canvas.renderAll();
    }

    /* Mouseup */
    mouseUp(e: any) {
        if (this.canvas.isDragging) {
            this.canvas.isDragging = false;
            this.canvas.renderAll();

            let objects = this.canvas.getObjects('rect');
            for (let i in objects) {
                objects[i].setCoords();
            }
        }
        // mouse cursor is max 5px outside of the BB
        if (this.isMovingBBGroup) {
            this.selectedObject.data.centerPosX = (this.selectedObject.getCenterPoint().x - this.offsetLeft) / this.scalingFactor;
            this.selectedObject.data.centerPosY = (this.selectedObject.getCenterPoint().y - this.offsetTop) / this.scalingFactor;
            this.updateBoundingBox(this.selectedObject)

            this.removeAllBoundingBoxesFromCanvas();
            this.drawBoundingBoxes(this.datum.datumLabels?.map(value => value as BoundingBox)||[]);
            this.drawBoundingBoxesFromRecognitions(this.datum.recognitions)
            this.isMovingBBGroup = false;
            this.selectedObject = null;

            if (e.target == null) { //avoid duplicated update from updateBoundingBox();
                this.addStepToQueue();
            }
        }

        if (this.isDown) {
            this.isDown = false;
            let square = this.canvas.getActiveObject();

            if (!square) {
                console.error("Cannot get active bounding box, abort drawing...");
            } else {
                let boundingBoxOutOfImage = this.isBoundingBoxOutOfImage(square);

                if (!boundingBoxOutOfImage) {
                    if (square.left < 0) {
                        square.left = 0;
                    }
                    if (square.top < 0) {
                        square.top = 0;
                    }
                    if (square.left > this.offsetLeft + this.myImg.width * this.myImg.scaleX) {
                        square.left = this.offsetLeft + this.myImg.width * this.myImg.scaleX;
                    }
                    if (square.top > this.offsetTop + this.myImg.height * this.myImg.scaleY) {
                        square.left = this.offsetTop + this.myImg.height * this.myImg.scaleY;
                    }
                     this.saveNewBoundingBox(square, this.selectedClass);
                }
                this.canvas.remove(square);
            }
        }
    }

    mouseWheel(opt: FabricEvent) {
        let delta = opt.e.deltaY;
        let zoom = this.canvas.getZoom();
        zoom = zoom + delta / 200;
        if (zoom > 20) zoom = 20;
        if (zoom < 1) zoom = 1;
        if (zoom === 1) {
            //canvas.zoomToPoint({ x: 0, y: 0}, zoom);
            this.canvas.zoomToPoint(new fabric.Point(this.canvas.width / 2, this.canvas.height / 2), zoom);
        } else {
            this.canvas.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom);
        }
        opt.e.preventDefault();
        opt.e.stopPropagation();
        let vpt = this.canvas.viewportTransform;
        if (zoom < 400 / 1000) {
            vpt[4] = 200 - 1000 * zoom / 2;
            vpt[5] = 200 - 1000 * zoom / 2;
        } else {
            if (vpt[4] >= 0) {
                vpt[4] = 0;
            } else if (vpt[4] < this.canvas.getWidth() - 1000 * zoom) {
                vpt[4] = this.canvas.getWidth() - 1000 * zoom;
            }
            if (vpt[5] >= 0) {
                vpt[5] = 0;
            } else if (vpt[5] < this.canvas.getHeight() - 1000 * zoom) {
                vpt[5] = this.canvas.getHeight() - 1000 * zoom;
            }
        }

        if (zoom === 1) {
            vpt[5] = 0;
            vpt[4] = 0;
        }

        this.removeAllBoundingBoxesFromCanvas();
        this.drawBoundingBoxes(this.datum.datumLabels?.map(value => value as BoundingBox)||[]);
        this.drawBoundingBoxesFromRecognitions(this.datum.recognitions)

        this.canvas.remove(this.guideHorizontal);
        this.canvas.remove(this.guideVertical);
        this.addGuideLines();

        let pointer = this.canvas.getPointer();

        this.setGuideLines(vpt, pointer);
        this.setCurrentClassHint(vpt, pointer);
    }

    mouseOut(opt: any) {
        if (!opt.target) {
            let objects = this.canvas.getObjects('line');
            for (let i in objects) {
                this.canvas.remove(objects[i]);
            }
            this.canvas.remove(this.currentClassHint);

            this.canvas.renderAll();
        }
    }

    mouseOver(opt: any) {
        if (!opt.target && !this.spaceKeyPressed) {
            //this.canvas.add(currentClassHint);
            this.addCurrentClassHint();
            this.addGuideLines();
            this.canvas.renderAll();
        }
    }

    // Param boundingBox = canvas.getActiveObject
    isBoundingBoxOutOfImage = (boundingBox: any) => {
        return (boundingBox.left + boundingBox.width) <= this.offsetLeft ||
            boundingBox.left > (this.offsetLeft + this.myImg.width * this.myImg.scaleX) ||
            (boundingBox.top + boundingBox.height) <= this.offsetTop ||
            boundingBox.top > (this.offsetTop + this.myImg.height * this.myImg.scaleY);
    };

    // If a bounding box's width and height are both bigger than 10px, then return true, otherwise return false.
    // Param boundingBox = canvas.getActiveObject
    isBoundingBoxBigEnough = (boundingBox: any) => {
        if (boundingBox) {
            return Math.abs(boundingBox.width) > 10 && Math.abs(boundingBox.height) > 10;
        } else {
            console.error("Cannot check if bounding box is big enough or not, the passed-in bounding box is not qualified.");
        }
        return false;
    };

    objectScaling(opt: any) {
        this.isScaling = true;
        if (opt.target.get('type') === 'bbGroup') {
            let vpt = this.canvas.viewportTransform;
            opt.target.item(0).set({
                strokeWidth: BOUNDING_BOX_STROKE_WIDTH / vpt[0]
            });
        }
    }

    beforeSelectionCleared(opt: any) {
        if (opt.deselected && !!this.duplicateKeyPressed) {
            for (let group of opt.deselected) {
                this.removeDeleteButtonFromBoundingBox(group);
            }

        } else if (opt.target?.get('type') === 'bbGroup' && !this.duplicateKeyPressed) {
            this.removeDeleteButtonFromBoundingBox(opt.target);
        }
    }

    deleteBoundingBox(boundingBox: any, ignoreStepInProtocol?: boolean) {   // ignoreStepInProtocol --> true: deletion will be ignored in step backward /forward protocoll
        if (boundingBox) {
            if(boundingBox.type === PREDICTION) {
                this.datum.recognitions = this.datum.recognitions?.filter((aRec: Recognition) => aRec.id !== boundingBox.id)
                this.openRecognitions = (this.datum.recognitions?.filter((rec: Recognition) => rec?.datumClass?.name !== this.tagViewService.backgroundTag).length||0) > 0
            }else {
                //If theres only one bb for that class, remove it but add class
                if ((this.datum.datumLabels?.filter((label: DatumLabel) => label?.datumClass?.id === boundingBox?.datumClass?.id).length || 0) <= 1) {
                    this.datum.datumLabels = this.datum.datumLabels?.concat({
                        datumClass: boundingBox.datumClass,
                        type: DATUM_LABEL
                    })
                }
                this.datum.datumLabels = this.datum.datumLabels?.filter((aBoundingBox: BoundingBox) => aBoundingBox !== boundingBox);
            }
            this.replaceDatumInDatums(this.datum);
            if (!ignoreStepInProtocol) {
                this.addStepToQueue();
            }
            let rect = this.getItemByBoundingBox(boundingBox);
            if (rect) {
                this.canvas.remove(rect);
            }
            this.selectedObject = null;
            this.datum.trainingReady = this.isObjectDetectionReady(this.datum)
        }
    }

    setActiveImage = (id: string) => {
        this.updateDatum(this.datum);

        if (typeof this.datum !== 'undefined' || this.datum !== null) {
            this.datum = this.datums.filter((datum: Datum) => {
                return datum.id === id
            })[0];
            this.initCanvas();

            if (!this.datumIsBackground(this.datum)) {
                this.setEventListeners();
                if (!this.selectedClass) {
                    this.selectedClass = this.classes[0];
                }
            }
        }
    };

    toggleBoundingBoxesVisibility = (datumClass: DatumClass) => {
        this.generateHotKeys();
        if (datumClass === this.selectedClass) {
             this.selectedClass = null;
        }

        this.removeAllBoundingBoxesFromCanvas();
        this.drawBoundingBoxes(this.datum.datumLabels?.map(value => value as BoundingBox)||[]);
        this.drawBoundingBoxesFromRecognitions(this.datum.recognitions)
    };


    datumIsBackground = (datum: Datum) => {
        if (datum.datumLabels && datum.datumLabels.filter((label: DatumLabel) => label?.datumClass?.name === this.tagViewService.backgroundTag).length > 0){
            this.selectedClass = null;
            return true;
        }
        return false;
    };

    replaceDatumInDatums = (datum: Datum) => {
        let id = datum.id;
        let index = this.datums.findIndex((item: Datum) => item.id === id);
        this.datums.splice(index, 1, datum);
    };

    isMouseOnScalingHandler = () => {
        return (this.canvas.upperCanvasEl.style.cursor.indexOf('-resize') > 0);
    };

    removeDatumClass = (datumClass: DatumClass, ignoreStepInProtocol?: boolean) => {
        if (datumClass) {
            //Remove BoundingBoxes
            let boundingBoxesWithClass = this.datum.datumLabels?.filter((label: DatumLabel) => label?.datumClass?.id === datumClass.id) || [];
            for (let boundingBox of boundingBoxesWithClass) {
                this.deleteBoundingBox(boundingBox, true);
            }

            //Remove class
            this.datum.datumLabels = this.datum.datumLabels?.filter((aClass:any) => !(aClass.datumClass.id === datumClass?.id && aClass.type === DATUM_LABEL))
            this.datum.trainingReady = this.isObjectDetectionReady(this.datum);
            this.replaceDatumInDatums(this.datum);

            if (!ignoreStepInProtocol) {
                this.addStepToQueue();
            }
        }
    };

    updateDatum = (datum: Datum) => {
        let datums: Datum[] = [];
        datum.inferenceImage = (datum.recognitions?.length||0)>0
        datums.push(datum);
        this.datumService.updateDatums(datums).then((response: any) => {
            let index = this.datums.map((d: Datum) => {
                return d.id;
            }).indexOf(response[0].id);
            if (index > -1) {
                this.setDatum(response[0], index)
            }
        }, function error() {
            console.error("Failed to update datum");
        });
    };

    setDatum(datum: any, index: number) {
        this.datums[index] = datum;
        this.datums[index].trainingReady = this.isObjectDetectionReady(this.datums[index])
    }

    setDatumNotBackground = () => {
        if(this.aiProjectService.getBackgroundClass()){
            this.datum.datumLabels = [];
            this.selectedClass = this.classes[0];
            this.datum.trainingReady = this.isObjectDetectionReady(this.datum);
            this.replaceDatumInDatums(this.datum);
            this.addStepToQueue();
            this.setEventListeners();
            this.generateHotKeys();
    };}

    setDatumBackground = () => {
        for (let boundingBox of this.datum.datumLabels?.filter((label: DatumLabel) => label.type === BOUNDING_BOX)||[]) {
             this.deleteBoundingBox(boundingBox, true);
        }
        for (let recognition of this.datum.recognitions||[]) {
            this.deleteRecognitionBox(recognition);
            // Empty recognitions to also remove background recognitions
            this.datum.recognitions = [];
        }

        let backgroundClass: DatumClass = this.aiProjectService.getBackgroundClass();
        this.datum.datumLabels?.push({
            datumClass: backgroundClass,
            type: DATUM_LABEL
        });
        this.datum.trainingReady = this.isObjectDetectionReady(this.datum);
        this.replaceDatumInDatums(this.datum);

        this.addStepToQueue();
    };

    setGuideLines(vpt: any, pointer: any) {
        if (!this.guideHorizontal && !this.guideVertical) {
            this.addGuideLines();
        }

        if (this.isMovingBBGroup || this.isScaling || this.selectedObject || this.isHoveringDeleteButton || this.spaceKeyPressed || this.isDown) {
            this.guideVertical.opacity = 0;
            this.guideHorizontal.opacity = 0;
        } else {
            this.guideVertical.opacity = 1;
            this.guideHorizontal.opacity = 1;
        }

        this.guideVertical.height = this.canvas.height * vpt[0] + 2 * PANNING_LIMIT_VERTICAL;
        this.guideHorizontal.width = this.canvas.width * vpt[0] + 2 * PANNING_LIMIT_HORIZONTAL;

        this.guideVertical.top = -PANNING_LIMIT_VERTICAL;
        this.guideHorizontal.left = -PANNING_LIMIT_HORIZONTAL;

        this.guideVertical.left = pointer.x;
        this.guideHorizontal.top = pointer.y;

        this.guideVertical.strokeWidth = GUIDE_LINE_THICKNESS / vpt[0];
        this.guideHorizontal.strokeWidth = GUIDE_LINE_THICKNESS / vpt[0];

        this.canvas.bringToFront(this.guideHorizontal);
        this.canvas.bringToFront(this.guideVertical);
        this.guideHorizontal.setCoords();
        this.guideVertical.setCoords();
    }

    addCurrentClassHint() {
        if (this.currentClassHint) {
            this.canvas.remove(this.currentClassHint);
        }
        if (this.selectedClass) {
            this.currentClassHint = new fabric.Text(this.selectedClass.name, {
                fontFamily: 'Roboto',
                fill: 'white',
                type: 'currentClassName',
                selectable: false,
                evented: false,
                opacity: 0
            });
            this.canvas.add(this.currentClassHint);
        }
    }

    setCurrentClassHint(vpt: any, pointer: any) {
        if (!this.currentClassHint) {
            this.addCurrentClassHint();
        }

        if (this.isMovingBBGroup || this.isScaling || this.isMouseOnScalingHandler() || this.selectedObject || this.isHoveringDeleteButton || this.spaceKeyPressed) {
            this.currentClassHint.opacity = 0;
        } else {
            this.currentClassHint.opacity = 1;
            this.canvas.bringToFront(this.currentClassHint);
        }

        if (!this.isDown && this.selectedClass) {
            this.currentClassHint.set({
                left: pointer.x,
                top: pointer.y - 20 / vpt[0],
                fontSize: 14 / vpt[0],
                backgroundColor: this.selectedClass.color,
                padding: 5 / vpt[0]
            });
        }
    }

    selectClass = (datumClass: any) => {
        if (datumClass.visible) {
            this.selectedClass = datumClass;
        }
    };

    selectPreviousImage = () => {
        this.index = this.datums.map((datum: Datum) => {
            return datum.id;
        }).indexOf(this.datum.id);

        // Update current editing image
        this.updateDatum(this.datum);

        // Update index
        if (this.index > 0) {
            this.datum = this.datums[this.index - 1];
        } else {
            this.datum = this.datums[this.datums.length - 1];
        }

        // Move to previous
        this.initCanvas();
        this.scrollToListItem(this.datum);
        this.activeDatumViewIfNotBackground();
    };

    selectNextImage(){
        this.index = this.datums.map((datum: Datum) => {
            return datum.id;
        }).indexOf(this.datum.id);

        // Update current editing image
        this.updateDatum(this.datum);

        // Update index
        if (this.index < (this.datums.length - 1)) {
            this.datum = this.datums[this.index + 1];
        } else {
            this.datum = this.datums[0]
        }

        // Move to next
        this.initCanvas();
        this.scrollToListItem(this.datum);
        this.activeDatumViewIfNotBackground();
    }

    //TODO
    scrollToListItem(datum: Datum) {

    }

    activeDatumViewIfNotBackground(){
        if (!this.datumIsBackground(this.datum)) {
            this.setEventListeners();
            this.generateHotKeys();
            if (!this.selectedClass) {
                this.selectedClass = this.classes[0];
            }
        }
    }

    deleteImage = (aDatum: Datum) => {
        if (aDatum) {
            let currentDatum = aDatum;
            let index = this.datums.map((datum: Datum) => {
                return datum.id;
            }).indexOf(this.datum.id);

            this.datumService.deleteDatums([currentDatum]).then(() => {
                this.datums = this.datums.filter((datum: Datum) => datum.id !== currentDatum.id);
                if (this.datums.length === 0) {
                    this.closeModal();
                }
                if (index < this.datums.length) {
                    this.datum = this.datums[index];
                } else {
                    this.datum = this.datums[index - 1];
                }
                if (this.datum) {
                    this.initCanvas();
                }
            });
        }
    };

    refreshData(datums: Datum[]) {
        let index = this.datumService.datums.map((e: Datum) => {
            return e.id;
        }).indexOf(datums[0].id);
        if (index > -1) {
            setTimeout(() => {
            this.datumService.renderImagesOnCanvas(datums, false, false)
            }, 0)
        }
    }

    addStepToQueue() {
        if (this.currentStepIndex < this.lastStepsDatumViews.length - 1) {
            this.lastStepsDatumViews = this.lastStepsDatumViews.slice(0, this.currentStepIndex + 1);
        }
        this.lastStepsDatumViews.push(_.cloneDeep(this.datum));
        this.currentStepIndex = this.lastStepsDatumViews.length - 1;
    }

    goStepBack() {
        if (this.currentStepIndex > 0) {
            this.datum = _.cloneDeep(this.lastStepsDatumViews[this.currentStepIndex - 1]);
            this.currentStepIndex = this.currentStepIndex - 1;
            this.removeAllBoundingBoxesFromCanvas();
            this.drawBoundingBoxes(this.datum.datumLabels?.map(value => value as BoundingBox)||[]);
            this.drawBoundingBoxesFromRecognitions(this.datum.recognitions)
            if (this.selectedClass == null) {
                this.selectedClass = this.classes[0];
            }
        }
    }

    goStepForward() {
        if (this.currentStepIndex < this.lastStepsDatumViews.length - 1) {
            this.datum = _.cloneDeep(this.lastStepsDatumViews[this.currentStepIndex + 1]);
            this.currentStepIndex = this.currentStepIndex + 1;
            this.removeAllBoundingBoxesFromCanvas();
            this.drawBoundingBoxes(this.datum.datumLabels?.map(value => value as BoundingBox)||[]);
            this.drawBoundingBoxesFromRecognitions(this.datum.recognitions)
        }
    }

    //generates Hot Keys for the first 10 Classes. Other Classes won't get a Hot Key.
    generateHotKeys() {
        if (typeof this.classes !== 'undefined' || this.classes !== null) {

            for (let i = 0; i < this.classes.length && i < 9; i++) {
                let key = (i + 1).toString();
                this.classes[i].hotKeyCombo = key
                if (this.classes[i].visible) {
                    this.hotkeys
                        .add({
                            combo: key,
                            description: 'Hotkey for ' + this.classes[i].name,
                            callback: () => {
                                if (!this.isDown) {
                                     this.selectedClass = this.classes[i];
                                    this.currentClassHint.set('text', this.selectedClass.name);
                                    this.currentClassHint.set('backgroundColor', this.selectedClass.color);
                                    this.canvas.renderAll();
                                }
                            }
                        })
                } else {
                    this.hotkeys.remove(key);
                }
            }
        }
    }

    getNumberOfObjectDetectionReadyDatums = () => {
        return this.datums.filter((datum: Datum) => datum.trainingReady);
    };

    getNumberOfIssues() {
        let datumLabels = this.datum.datumLabels
        let numIssues = 0
        if (datumLabels?.length === 0) {
            numIssues += 1;
        } else {
            numIssues += datumLabels?.filter((label: DatumLabel) => label.type === DATUM_LABEL && label.datumClass?.name !== this.tagViewService.backgroundTag).length||0
        }
        if(this.datum.recognitions && this.datum.recognitions.filter((rec: Recognition) => rec?.datumClass?.name !== this.tagViewService.backgroundTag).length > 0) {
            numIssues += 1;
        }
        return numIssues
    };

    closeModal() {
        if (this.datums.length > 0) {
            if (this.datum) {
                this.showLoadingAnimation = true;
                this.datums.forEach(datum => datum.inferenceImage ? datum.inferenceImage = (datum.recognitions?.length||0) > 0 : "")
                this.datumService.updateDatums(this.datums).then((response: any) => {
                    this.activeModal.close();
                     this.refreshData(response)

                        }, function error() {
                            console.error("Failed to update datum ");
                        });
            }
        } else {
            this.activeModal.close();
        }
    };

    getItemByBoundingBox = (boundingBox: any) => {
        let object = null,
        objects = this.canvas.getObjects();
        for (let i = 0; i < objects.length; i++) {

            if (objects[i].data  && (objects[i].data === boundingBox || (objects[i].data.id && objects[i].data.id === boundingBox.id))) {
                object = objects[i];
                break;
            }
        }
        return object;
    };

    private drawBoundingBoxesFromRecognitions(recognitions: Recognition[] | undefined) {
        if(recognitions) {
            let boxes: BoundingBox[] = []
            for (let rec of recognitions) {
                if(rec.datumClass?.name !== this.tagViewService.backgroundTag) {
                    let recBox = JSOG.parse(rec.recognitionInfo) as BoundingBox
                    recBox.id = rec.id
                    recBox.type = PREDICTION
                    recBox.datumClass = rec.datumClass
                    boxes.push(recBox)
                }
            }
            this.drawBoundingBoxes(boxes, true)
        }
    }

    private deleteRecognitionBox(boundingBox: any) {
        if(boundingBox && boundingBox.datumClass?.name !== this.tagViewService.backgroundTag) {
            let recBox = JSOG.parse(boundingBox.recognitionInfo) as BoundingBox
            recBox.id = boundingBox.id
            recBox.type = PREDICTION
            recBox.datumClass = boundingBox.datumClass
            this.deleteBoundingBox(recBox, true)
        }
    }
}



