import {Injectable} from '@angular/core';
import DOMPurify from 'dompurify';
import {TranslateService} from "@ngx-translate/core";
import {IndividualConfig, ToastrService} from 'ngx-toastr';

@Injectable()
export class NotificationService {
    constructor(private toastrService: ToastrService,
                private translateService: TranslateService) {
    }

    infoWithTitle(title: string, message: string, timeOut = 2000) {
        return this.toast('info', message, { timeOut }, title);
    }

    info(message: string, timeOut = 2000) {
        return this.toast('info', message, { timeOut }, this.translateService.instant("notification-service.info"));
    }

    warnWithTitle(title: string, message: string, timeOut = 2000) {
        return this.toast('warning', message, { timeOut }, title);
    }

    warn(message: string, timeOut = 3000) {
        return this.toast('warning', message, { timeOut }, this.translateService.instant("notification-service.warn"));
    }

    errorWithTitle(title: string, message: string, timeOut = 2000) {
        return this.toast('error', message, { timeOut }, title);
    }

    error(message: string, timeOut = 4000) {
        return this.toast('error', message, { timeOut }, this.translateService.instant("notification-service.error"));
    }

    clearAll() {
        this.toastrService.clear();
    }

    private toast(type: 'info' | 'warning' | 'error' | 'show', message: string, options: Partial<IndividualConfig> = {}, title?: string) {
        message = DOMPurify.sanitize(message, { ALLOWED_TAGS: [] });
        title = title ? DOMPurify.sanitize(title, { ALLOWED_TAGS: [] }) : undefined;
        return this.toastrService[type](message, title, options);
    }


}
