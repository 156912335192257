<div id="augmentation-settings-modal" class="augmentation-wizard modal-content">
    <div class="modal-head">
        <h2 class="modal-title">
            <span>{{ 'ai-studio.data-optimization' | translate }} ({{currentWizardStep}}/{{wizardStepsCount}})</span>
            <a class="float-right">
                <i class="icon icon-times" (click)="closeModal()"></i>
            </a>
        </h2>
    </div>

    <div class="modal-body">
        <div class="wizard-container">
            <div id="wizardProfile" class="wizardcard wizard-card" data-color="orange">
                <div class="tab-content">
                    <!-- TODO: icons not working -->
                    <div class="tab-pane" [ngClass]="{'active' : currentWizardStep === 1}">
                        <div class="top-buffer">
                            <h3 [innerHTML]="'ai-studio.data-optimization.intro' | translate | safeHtml"></h3>
                            <h3 [innerHTML]="'ai-studio.data-optimization.flipping-intro' | translate | safeHtml"></h3>
                        </div>
                        <!-- Flipping: WILL NOT effect Example -->
                        <div class="top-buffer">
                            <p [innerHTML]="'ai-studio.data-optimization.flip-ok' | translate | safeHtml"></p>
                            <div class="example-row">
                                <div class="col-sm">
                                    <div class="dataSetItemTile dataSetItemTile-small float-left">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">

                                                <div class="wf-tag badge">
                                                    <span class="tagText">{{ 'ai-studio.data-optimization.thumbs' | translate }}</span>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="float-left">
                                        <div class="flip-icon">
                                            <i class="icon icon-flip-horizontally" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                    <div class="dataSetItemTile dataSetItemTile-small float-left">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up flipHorizontally"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="tags">
                                                    <div class="wf-tag badge">
                                                        <span class="tagText">{{ 'ai-studio.data-optimization.thumbs' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <div class="dataSetItemTile dataSetItemTile-small float-right">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up flipVertically"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="wf-tag badge">
                                                    <span class="tagText">{{ 'ai-studio.data-optimization.thumbs' | translate }}</span>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="float-right">
                                        <div class="flip-icon">
                                            <i class="icon icon-flip-vertically" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="dataSetItemTile dataSetItemTile-small float-right">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="tags">
                                                    <div class="wf-tag badge">
                                                        <span class="tagText">{{ 'ai-studio.data-optimization.thumbs' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Flipping: WILL effect Example -->
                        <div class="top-buffer-20">
                            <p [innerHTML]="'ai-studio.data-optimization.flip-not-ok' | translate | safeHtml"></p>
                            <div class="example-row">
                                 <div class="col-sm">
                                    <div class="dataSetItemTile dataSetItemTile-small float-left">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="wf-tag badge">
                                                    <span class="tagText">{{ 'ai-studio.data-optimization.right-thumbs-up' | translate }}</span>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="float-left">
                                        <div class="flip-icon">
                                            <i class="icon icon-flip-horizontally" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                    <div class="dataSetItemTile dataSetItemTile-small float-left">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up flipHorizontally"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="tags">
                                                    <div class="wf-tag badge">
                                                        <span class="tagText">{{ 'ai-studio.data-optimization.right-thumbs-up' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-times example-cross" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <div class="dataSetItemTile dataSetItemTile-small float-right">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up flipVertically"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="wf-tag badge">
                                                    <span class="tagText">{{ 'ai-studio.data-optimization.right-thumbs-up' | translate }}</span>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-times example-cross" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="float-right">
                                        <div class="flip-icon">
                                            <i class="icon icon-flip-vertically" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                    <div class="dataSetItemTile dataSetItemTile-small float-right">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="tags">
                                                    <div class="wf-tag badge">
                                                        <span class="tagText">{{ 'ai-studio.data-optimization.right-thumbs-up' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="top-buffer">
                            <h3 [innerHTML]="'ai-studio.data-optimization.rotation-intro' | translate | safeHtml"></h3>
                        </div>
                        <!-- Rotation Ninety Degrees: Will NOT -->
                        <div class="top-buffer-20">
                            <p [innerHTML]="'ai-studio.data-optimization.rotation-ninety-ok' | translate | safeHtml"></p>
                            <div class="example-row">
                                <div class="col-sm">
                                    <div class="dataSetItemTile dataSetItemTile-small float-left">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="wf-tag badge">
                                                    <span class="tagText">{{ 'ai-studio.data-optimization.thumbs' | translate }}</span>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="float-left">
                                        <div class="flip-icon">
                                            <i class="icon icon-rotate-ninety" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                    <div class="dataSetItemTile dataSetItemTile-small float-left">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up rotateNinety"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="tags">
                                                    <div class="wf-tag badge">
                                                        <span class="tagText">{{ 'ai-studio.data-optimization.thumbs' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <div class="dataSetItemTile dataSetItemTile-small float-right">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up rotateMinusNinety"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="wf-tag badge">
                                                    <span class="tagText">{{ 'ai-studio.data-optimization.thumbs' | translate }}</span>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="float-right">
                                        <div class="flip-icon">
                                            <i class="icon icon-rotate-minus-ninety" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                    <div class="dataSetItemTile dataSetItemTile-small float-right">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="tags">
                                                    <div class="wf-tag badge">
                                                        <span class="tagText">{{ 'ai-studio.data-optimization.thumbs' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Rotation Ninety Degrees: Will -->
                        <div class="top-buffer-20">
                            <p [innerHTML]="'ai-studio.data-optimization.rotation-ninety-not-ok' | translate | safeHtml"></p>
                            <div class="example-row">
                                <div class="col-sm">
                                    <div class="dataSetItemTile dataSetItemTile-small float-left">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="wf-tag badge">
                                                    <span class="tagText">{{ 'ai-studio.data-optimization.right-thumbs-up' | translate }}</span>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="float-left">
                                        <div class="flip-icon">
                                            <i class="icon icon-rotate-ninety" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                    <div class="dataSetItemTile dataSetItemTile-small float-left">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up rotateNinety"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="tags">
                                                    <div class="wf-tag badge">
                                                        <span class="tagText">{{ 'ai-studio.data-optimization.right-thumbs-up' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-times example-cross" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <div class="dataSetItemTile dataSetItemTile-small float-right">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up rotateMinusNinety"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="wf-tag badge">
                                                    <span class="tagText">{{ 'ai-studio.data-optimization.right-thumbs-up' | translate }}</span>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-times example-cross" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="float-right">
                                        <div class="flip-icon">
                                            <i class="icon icon-rotate-minus-ninety" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                    <div class="dataSetItemTile dataSetItemTile-small float-right">
                                        <div class="dataSetItemTileInnerWrapper">
                                            <div class="imgContainer">
                                                <i class="icon icon-thumbs-up"></i>
                                            </div>
                                            <div class="dataSetItemTileDetails-small">
                                                <div class="tags">
                                                    <div class="wf-tag badge">
                                                        <span class="tagText">{{ 'ai-studio.data-optimization.right-thumbs-up' | translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="example-icon-small">
                                                <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- Top Pane Begin -->
                    <div class="tab-pane" [ngClass]="{'active' : currentWizardStep === index + 2}" *ngFor="let datumClass of exDatumClasses; let index = index">
                        <div class="top-buffer">
                            <p class="optimization-text lead" [innerHTML]="'ai-studio.data-optimization.flip-question' | translate : {'datumClassName' : datumClass.exDatumClass.name}"></p>
                        </div>
                        <!-- FLIP -->
                        <div class="top-buffer" *ngFor="let m of [0,1]">
                            <div class="example-row">
                                <div *ngFor="let n of [0,1]">

                                        <div class="dataSetItemTile dataSetItemTile-medium">
                                            <div class="dataSetItemTileInnerWrapper">
                                                <div class="imgContainer" [ngClass]="{ 'skeleton-canvas': !exampleImagesLoaded}">
                                                    <img *ngIf="this.exampleImagesLoaded"
                                                        class="example-img img-responsive lazyloaded"
                                                        [src]="baseUrl+'/aiStorage/'+ projectUuid +'/datum/'+ datumClass.exDatum?.id + '/image?size=small' | authImage | async"
                                                        alt="augmentation settings"
                                                        [ngClass]="{'flipHorizontally' : m == 0 && n == 1, 'flipVertically' : m == 1 && n == 1}">
                                                </div>
                                                <!-- CLASS TAG -->
                                                <div class="dataSetItemTileDetails-medium">
                                                    <div class="wf-tag badge">
                                                        <span class="tagText">{{datumClass.exDatumClass.name}}</span>
                                                    </div>
                                                </div>
                                                <!-- Original Image -->
                                                <div class="example-icon-medium" *ngIf="(m == 0 && n == 0) || (m == 1 && n == 0)">
                                                    <!--only show on the right image -->
                                                    <i class="icon icon-check example-tick"  aria-hidden="true"></i>
                                                </div>

                                                <!-- Image flipped horizontally-->
                                                <div class="select-group" *ngIf="(m == 0 && n == 1)">
                                                    <!-- Flip Yes-->
                                                    <label for="horizontal-flip-yes-{{datumClass.exDatumClass.name}}" style="padding-left: 15px;" (click)="datumClass.exDatumClass.augmentationSettings.horizontalFlip=true">
                                                        <span class="left-icon example-icon-medium" [ngClass]="{ 'active' : datumClass.exDatumClass.augmentationSettings.horizontalFlip }">
                                                            <i class="icon icon-check" aria-hidden="true" [ngClass]="{ 'example-tick' : datumClass.exDatumClass.augmentationSettings.horizontalFlip }"></i>
                                                        </span>
                                                    </label>
                                                    <!-- Flip No-->
                                                    <label for="horizontal-flip-no-{{datumClass.exDatumClass.name}}" style="padding-left: 15px;" (click)="datumClass.exDatumClass.augmentationSettings.horizontalFlip=false">
                                                        <span class="right-icon example-icon-medium" [ngClass]="{ 'active' : datumClass.exDatumClass.augmentationSettings.horizontalFlip == false}">
                                                            <i class="icon icon-times"  aria-hidden="true"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                                <!-- Image flipped horizontally-->
                                                <div class="select-group" *ngIf="(m == 1 && n == 1)">
                                                     <!-- Flip Yes-->
                                                    <label for="vertical-flip-yes-{{datumClass.exDatumClass.name}}" style="padding-left: 15px;" (click)="datumClass.exDatumClass.augmentationSettings.verticalFlip=true">
                                                        <span class="left-icon example-icon-medium" [ngClass]="{ 'active' : datumClass.exDatumClass.augmentationSettings.verticalFlip }">
                                                            <i class="icon icon-check" aria-hidden="true" [ngClass]="{ 'example-tick' : datumClass.exDatumClass.augmentationSettings.verticalFlip }"></i>
                                                        </span>
                                                    </label>
                                                    <!-- Flip No-->
                                                    <label for="vertical-flip-no-{{datumClass.exDatumClass.name}}" style="padding-left: 15px;" (click)="datumClass.exDatumClass.augmentationSettings.verticalFlip=false">
                                                        <span class="right-icon example-icon-medium" [ngClass]="{ 'active' : datumClass.exDatumClass.augmentationSettings.verticalFlip==false}">
                                                            <i class="icon icon-times" aria-hidden="true"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    <!-- TODO -->
                                    <div class="flip-icon" *ngIf="n == 0">
                                        <i class="fa icon" [ngClass]="{'icon-flip-horizontally' : m == 0 && n == 0, 'icon-flip-vertically' : m == 1 && n == 0}" aria-hidden="true">
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="rotation-ninety-wrapper">
                            <div class="top-buffer">
                                <p class="optimization-text lead" [innerHTML]="'ai-studio.data-optimization.rotation-question' | translate : {'datumClassName' : datumClass.exDatumClass.name} | safeHtml"></p>
                            </div>
                            <!-- ROTATION -->
                            <div class="top-buffer" *ngFor="let m of [0,1] ">
                                <div class="example-row">
                                    <div class="col-sm-12 augmentation-rotation" >
                                        <div *ngFor="let n of [0,1]" >
                                            <div class="dataSetItemTile dataSetItemTile-medium p-0">
                                                <div class="dataSetItemTileInnerWrapper">

                                                    <div class="imgContainer" [ngClass]="{ 'skeleton-canvas': !exampleImagesLoaded}">
                                                        <img *ngIf="this.exampleImagesLoaded"
                                                            class="example-img img-responsive lazyloaded"
                                                            [src]="baseUrl+'/aiStorage/'+projectUuid+'/datum/'+ datumClass.exDatum?.id +'/image?size=small' | authImage | async "
                                                            alt="augmentation settings"
                                                            [ngClass]="{'rotateNinety' : m == 0 && n == 1,
                                                            'rotateMinusNinety' : m == 1 && n == 1}">
                                                    </div>
                                                    <!-- CLASS TAG -->
                                                    <div class="dataSetItemTileDetails-medium">
                                                        <div class="wf-tag badge">
                                                            <span class="tagText">{{datumClass.exDatumClass.name}}</span>
                                                        </div>
                                                    </div>
                                                    <!-- Original Image -->
                                                    <div class="example-icon-medium" *ngIf="(m == 0 && n == 0) || (m == 1 && n == 0)">
                                                        <i class="icon icon-check example-tick" aria-hidden="true"></i>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="flip-icon" *ngIf="n == 0">
                                                <i class="fa icon" [ngClass]="{'icon-rotate-ninety' : m == 0 && n == 0, 'icon-rotate-minus-ninety' : m == 1 && n == 0}" aria-hidden="true">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="select-group rotation-ninety">
                                <label for="rotate-ninety-yes-{{datumClass.exDatumClass.name}}" style="padding-left: 15px;" (click)="datumClass.exDatumClass.augmentationSettings.rotateNinety=true">
                                    <span class="left-icon example-icon-medium" [ngClass]="{ 'active' : datumClass.exDatumClass.augmentationSettings.rotateNinety }">
                                        <i class="icon icon-check" aria-hidden="true" [ngClass]="{ 'example-tick' : datumClass.exDatumClass.augmentationSettings.rotateNinety }"></i>
                                    </span>
                                </label>
                                <label for="rotate-ninety-no-{{datumClass.exDatumClass.name}}" style="padding-left: 15px;" (click)="datumClass.exDatumClass.augmentationSettings.rotateNinety=false">
                                    <span class="right-icon example-icon-medium" [ngClass]="{ 'active' : datumClass.exDatumClass.augmentationSettings.rotateNinety==false }">
                                        <i class="icon icon-times" aria-hidden="true"></i>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- Top Pane End -->
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button-secondary
            text="{{ 'cancel' | translate }}"
            style="float: left;"
            (click)="closeModal();">
        </button-secondary>

        <button-secondary
            style="margin-left: auto"
            icon="angle-left"
            text="{{ 'ai-studio.wizard.previous' | translate }}"
            *ngIf="currentWizardStep > 1"
            (click)="currentWizardStep = currentWizardStep - 1">
        </button-secondary>
        <!-- Todo: bug fix: button is disabled but working when clicking icon -->
        <button-primary
            icon="angle-right"
            text="{{ 'ai-studio.wizard.next' | translate }}"
            (click)="nextStep();"
            *ngIf="currentWizardStep < wizardStepsCount"
            [disabled]="nextStepBtnDisabled(currentWizardStep) ">
        </button-primary>

        <button-primary
            icon="check"
            text="{{ 'ai-studio.wizard.update' | translate }}"
            (click)="updateAugmentationSettings()"
            [disabled]="nextStepBtnDisabled(currentWizardStep)"
            *ngIf="wizardStepsCount == currentWizardStep">
        </button-primary>
    </div>
</div>
