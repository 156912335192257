import {Component} from "@angular/core";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";

@Component({
    selector: 'no-free-project-screen',
    templateUrl: 'no-free-project-screen.component.html'
})

export class NoFreeProjectScreenComponent {

    status_no_free_project = false;

    constructor(private aiStudioObservables: AiStudioObservables) {

        this.aiStudioObservables.STATUS_NO_FREE_PROJECT$.subscribe((status: boolean) => {
            this.status_no_free_project = status;
        });

    }

    showWizard() {
        this.aiStudioObservables.SHOW_WIZARD$.next(true);
    };
}
