import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from 'services/authentication.service';
import {OAuthService} from "angular-oauth2-oidc";
import {LoadingService} from "services/loading.service";

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {

    constructor(private authenticationService: AuthenticationService,
                private  oauthService: OAuthService,
                private loadingService: LoadingService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let login_hint = route.queryParams.login_hint;
        if(!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
            this.loadingService.show("Redirecting");
            this.oauthService.initLoginFlow("", {"kc_idp_hint": login_hint});
            return false;
        }else {
            return true;
        }
    }
}
