import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";

@Component({
    selector: 'ai-tag',
    templateUrl: 'ai-tag.component.html',
    styleUrls: ['ai-tag.component.sass'],
    encapsulation: ViewEncapsulation.None
})


export class AiTagComponent {
    @Input() public boundingboxNum: number | undefined;
    @Input() public isDeletable: boolean = false;

    @Input() public name: string | undefined;
    @Input() public color: string | undefined;



    @Output() onDeletion : EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    darken(color: string | undefined) {
        return color?"#"+((Number(color.replace("#","0x")) & 0xfefefe) >> 1).toString(16):color
    }

}
