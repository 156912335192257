import {Pipe, PipeTransform} from '@angular/core';
import {OrderBy} from "../utils/project-utils";

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {


    transform(array: any, field: string, orderBy?: OrderBy, fieldProperty?: string): any[]{

        if(!Array.isArray(array)) {
            return [];
        }
        array.sort((a: any, b: any) => {

            if(fieldProperty !== undefined && fieldProperty.length > 0) {
                if(a[field][fieldProperty] < b[field][fieldProperty]) {
                    return -1;
                } else if (a[field][fieldProperty] > b[field][fieldProperty]) {
                    return 1;
                }
                else {
                    return 0;
                }
            } else if(a[field] < b[field]) {
                    return -1;
            } else if(a[field] > b[field]) {
                    return 1;
            } else {
                return 0;
            }

        });
        if(OrderBy.DESC) {
            array.reverse();
        }
            return array;
    }

}
