import {Injectable} from '@angular/core';
import $ from 'jquery';

@Injectable()
export class LoadingService {
    loadingHtml = `
        <div id="loading-service-overlay" class="loading-overlay hide">
            <svg id="loading-animation" class="aistudio-loading-animation">
                <use xlink:href="#aistudio-loading-logo"></use>
            </svg>
            <p id="loading-message" class="loading-message"></p>
            <div class="progress">
                <div id="progress-bar" class="progress-bar" role="progressbar" style=""></div>
            </div>
            <p id="progress-percentage" class="loading-message"></p>
            <p id="calculating-message" class="calculating-message"></p>
            <div class="remaining-time-countdown">
                <span class="minutes" id="remaining-time"></span>
            </div>
        </div>`;
    parentElement: any;
    angularTemplate = $(this.loadingHtml);
    isIntervalInProgress = false;
    countdown: any;
    loadingOverlay: any;
    loadingMessage: any;
    progressBar: any;
    loadingAnimation: any;
    progressPercentage: any;
    calculatingMessage: any;
    remainingTime: any;
    remainingTimeCountdown: any;

    constructor() {
        $(document.body).append(this.angularTemplate);
        this.loadingOverlay = $('#loading-service-overlay');
        this.loadingMessage = $('#loading-message');
        this.progressBar = $('#progress-bar');
        this.loadingAnimation = $('#loading-animation');
        this.progressPercentage = $('#progress-percentage');
        this.calculatingMessage = $('#calculating-message');
        this.remainingTime = $('#remaining-time');
        this.remainingTimeCountdown = $('.remaining-time-countdown');
    }

    show(text?: string, element?: HTMLElement, inTransparent?: boolean, progress?: number, time?: number): void {
        let overlay: any;

        this.hideAllBeforeInit();

        this.loadingMessage.html(" ");
        this.loadingMessage.html(text);

        if(inTransparent) {
            this.loadingOverlay.addClass('intransparent');
            this.loadingOverlay.removeClass('transparent');
        }
        else {
            this.loadingOverlay.addClass('transparent');
            this.loadingOverlay.removeClass('intransparent');
        }

        if(element) {
            overlay = this.angularTemplate.detach();
            this.loadingMessage.addClass('loading-message-dark');
            this.loadingMessage.removeClass('loading-message-bright');
            this.progressPercentage.addClass('loading-message-dark');
            this.progressPercentage.removeClass('loading-message-bright');
            this.parentElement = $(element);
            this.parentElement.append(overlay);
        }
        else {
            this.loadingMessage.addClass('loading-message-bright');
            this.loadingMessage.removeClass('loading-message-dark');
            overlay = this.angularTemplate.detach();
            this.parentElement = $(document.body);
            this.parentElement.append(overlay);
        }

        if(progress) {
            if(progress <= 0) {
                progress = 0;
                this.calculatingMessage.html('Estimating upload time...');
                this.calculatingMessage.removeClass('hide');
            }
            this.progressBar.parent().removeClass('hide');
            this.progressBar.width(progress + '%');
            this.loadingAnimation.addClass('hide');
            this.progressPercentage.removeClass('hide');
            this.progressPercentage.html(progress + "% " + 'uploaded');
        }

        if(time) {
            this.calculatingMessage.addClass('hide');
            this.remainingTimeCountdown.removeClass('hide');

            clearInterval(this.countdown);
            if(this.isIntervalInProgress) {
                clearInterval(this.countdown);
                this.countdown = 0;
                this.isIntervalInProgress = false;
            }
            else {
                this.countdown = setInterval(() => {
                    if(time) {
                        this.isIntervalInProgress = true;
                        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
                        const seconds = Math.floor((time % (1000 * 60)) / 1000);

                        if(minutes < 0 || seconds < 0) {
                            clearInterval(this.countdown);
                            this.remainingTime.html('Please wait a moment...');
                        } else {
                            this.remainingTime.html(`Estimated remaining time ${minutes} min ${seconds} sec`);

                        }
                        time -=1000;
                    }
                }, 1000);
            }
        }

        this.loadingOverlay.removeClass('hide');
    }

    hide(): void {
        this.loadingOverlay.addClass('hide');
        this.loadingMessage.html('');
    }

    private hideAllBeforeInit(): void {
        this.progressBar.parent().addClass('hide');
        this.progressPercentage.addClass('hide');
        this.loadingAnimation.removeClass('hide');
        this.remainingTimeCountdown.addClass('hide');
    }

}
