import {NgModule} from '@angular/core';
import {GlobalModule} from 'global/global.module';
import {ErrorComponent} from './error.component';

@NgModule({
    declarations: [
        ErrorComponent
    ],
    imports: [
        GlobalModule,
    ],
})
export class ErrorModule { }
