import {Component, Input, ViewEncapsulation} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import dayjs from "dayjs";
import {AiProject} from "@teamviewer/aistudioapi-common-angular";


@Component({
    selector: 'project-info',
    templateUrl: 'project-info.component.html',
    styleUrls: ['project-info.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class ProjectInfoComponent {
    @Input() aiProject: AiProject | undefined;

    constructor(private activeModal: NgbActiveModal) {
    }

    dateTimeFormatter(data: number): string {
        if (data) {
            let time = dayjs(data);
            return time.format('LLL');
        } else {
            console.error("Can't format the date time, the data is empty.")
            return "";
        }
    }

    cancel() {
       this.activeModal.dismiss('cancel');
    };
}
