import {Component, ViewEncapsulation} from "@angular/core";
import {AuthenticationService} from "services/authentication.service";
import DOMPurify from "dompurify";


@Component({
    selector: 'top-menu',
    templateUrl: 'top-menu.component.html',
    styleUrls: ['top-menu.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class TopMenuComponent {

    appName = "admin";
    displayName = 'user' /* this.authenticationService.getDisplayName() */;

    showContactsPanel = false;
    showProfilePanel = false;
    avatarUrl = '';
    status = 'available';

    username: string;

    constructor(private authenticationService: AuthenticationService) {
        this.username = DOMPurify.sanitize(authenticationService.getUserName());
    }

    profileToggle() {
        if (this.showContactsPanel) {
            this.showContactsPanel = false;
        }
        //this.setAppLauncherVisibility(false);
        this.showProfilePanel = !this.showProfilePanel;
        console.log("showProfilePanel", this.showProfilePanel)
    }
}
