import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Config} from "global/config";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {DatumService} from "services/aiStudio/datum.service";
import {IMAGE_SIZE_RAW} from "utils/project-utils";
import {HotkeysService} from "angular2-hotkeys";
import {Datum, DatumClass} from "@teamviewer/aistudioapi-common-angular";
import {AuthImagePipe} from "pipes/authImage.pipe";

@Component({
    selector: 'object-detection-misclassified',
    templateUrl: 'object-detection-misclassified.component.html',
    styleUrls: ['object-detection-misclassified.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class ObjectDetectionMisclassified implements OnInit {
    @Input() datumViewWrapper : any = {};
    @Input() datumViewWrappers : Datum[] = [];
    @HostBinding('class') class = 'modal-content';

    // By default shows prediction image
    predictionSelected = true;
    groundTruthSelected = false;
    currentDatumClasses: any = [];

    predictionDatumClasses: any;
    groundTruthClasses: any;
    allDatumClasses: any;

    // By default show all tags
    allTagsSelected = true;
    noTagSelected = false;

    // By default do not show error
    showError = false;

    imageLoading = true;
    imageBroken = true;

    imageCanvasInfo = null;

    index = 0
    hotkeys: any = null;

    imgCtx: any;
    imageObj: any;

    posInfo:any;
    scaleX:any;
    scaleY: any;

    trainingImgHeight: number = 0;
    trainingImgWidth: number = 0;

    constructor(private aiProjectService: AiProjectService, private config: Config, private datumService: DatumService, private hotkeysService: HotkeysService, private activeModal: NgbActiveModal, private authImagePipe: AuthImagePipe){
        this.hotkeys = hotkeysService;
        this.hotkeys.add({
                combo: 'right',
                description: 'Hotkey for previous image',
                callback: () => {
                    this.previousImage();
                }});

        this.hotkeys.add({
            combo: 'left',
            description: 'Hotkey for next image',
            callback: () => {
                this.nextImage();
            }})
    }

    ngOnInit(){
        this.prepareDatumClassAndRenderImage();
    }


    //Draw image
    drawImageOnCanvas = () => {
        this.imageLoading = true;

        let domId = this.datumViewWrapper.id + "_modal";

        const imgCanvas: any = document.getElementById('canvas_' + domId);
        this.imgCtx = imgCanvas.getContext('2d');

        this.datumService.fitCanvasToContainer(imgCanvas);

        this.imageObj = new Image();
        this.authImagePipe.transform(this.config.baseUrl +'/aiStorage/'+ this.aiProjectService.getAiProject().projectUuid +'/datum/'+ this.datumViewWrapper.id + '/image?size=' + IMAGE_SIZE_RAW).then(
            url => { this.imageObj.src = url;}
        );

        // If image failed to load, show broken image instead.
        this.imageObj.onerror = () => {
            this.imageLoading = false;
            this.imageBroken = true;
        };

        this.imageObj.onload = () => {
            this.imageLoading = false;
            this.imageBroken = false;
            this.posInfo = this.datumService.getImagePosInfoInCanvas(this.imageObj, imgCanvas, true);
            this.scaleX =  (this.posInfo.imgWidth/this.trainingImgWidth);
            this.scaleY = (this.posInfo.imgHeight/this.trainingImgHeight);
            this.imgCtx.drawImage(
                this.imageObj,
                this.posInfo.xOffset,
                this.posInfo.yOffset,
                this.posInfo.imgWidth,
                this.posInfo.imgHeight
            );

            this.prepareBoxes()
        };
    };

    resetImage(){
        //Reset Image
        this.imgCtx.clearRect(0, 0, this.imgCtx.canvas.width, this.imgCtx.canvas.height);
        this.imgCtx.drawImage(
            this.imageObj,
            this.posInfo.xOffset,
            this.posInfo.yOffset,
            this.posInfo.imgWidth,
            this.posInfo.imgHeight
        );
    }

    filteredBoxes(boxes: any) {
        let filteredBoxes = boxes.filter((bb:any) => {
            if(this.currentDatumClasses.some((aClass:any) => bb.label === aClass.name && aClass.selected))
            return bb
        })
        return filteredBoxes;
    }

    drawBoxes(boxes: any, classes: any, dashed: boolean, error?:boolean) {
        let errorColor = "#ff0000";;
        for (let boundingBoxObject of boxes) {

            let color = !error ? this.getClassColor(boundingBoxObject) : errorColor;
            this.imgCtx.strokeStyle = this.datumService.hexToRgba(color);
            this.imgCtx.setLineDash(dashed ? [10, 5] : []);
            this.imgCtx.lineWidth = 4;

            // Scale the bounding box according to the original size of the image
            // each box [0,1,2,3] -> [x1, y1, x2, y2]
            let width = boundingBoxObject.box[2] - boundingBoxObject.box[0];
            let boxScaledWidth = width * this.scaleX;
            let height = boundingBoxObject.box[3] - boundingBoxObject.box[1];
            let boxScaledHeight = height * this.scaleY;

            // Draw bounding box with X, Y offset.
            let x = boundingBoxObject.box[0] * this.scaleX + this.posInfo.xOffset;
            let y = boundingBoxObject.box[1] * this.scaleY + this.posInfo.yOffset;

            if(error){
                this.imgCtx.fillStyle = this.datumService.hexToRgba(errorColor, 0.4);
                this.imgCtx.fillRect(x, y, boxScaledWidth, boxScaledHeight);
            } else {
                  this.imgCtx.strokeRect(x, y, boxScaledWidth, boxScaledHeight);
            }

        }
    }


    getClassColor(boundingBox: any) {
        return this.allDatumClasses.filter((aClass: DatumClass) => aClass.name === boundingBox.label)[0].color
    }

    prepareBoxes() {
        this.resetImage();

        if(this.predictionSelected){
            let predictionBoundingBoxes = this.filteredBoxes(this.datumViewWrapper.prediction)
            let predictionClasses = this.datumViewWrapper.predictionClasses;

            if (predictionBoundingBoxes.length > 0) {
                this.drawBoxes(predictionBoundingBoxes, predictionClasses, false);
            }
        }

        if(this.groundTruthSelected){
            let groundTruthBoundingBoxes = this.filteredBoxes(this.datumViewWrapper.groundTruth);
            let groundTruthClasses = this.datumViewWrapper.groundTruthClasses;

            if (groundTruthBoundingBoxes.length > 0) {
                this.drawBoxes(groundTruthBoundingBoxes, groundTruthClasses, true);
            }
        }

        if(this.showError){
            let errorBoxes: any = this.getErrorBoxes()
            if (errorBoxes.length > 0) {
                this.drawBoxes(errorBoxes, this.allDatumClasses, false, true);
            }
        }
    }



    // The way to get wrong error is to loop through the ground truth and the prediction, compare the central point
    // of both bounding boxes (with range of 20px):
    // 1. If no bounding box found, the ground truth is counted as error.
    // 2. If more than one matched prediction is found, from the 2nd bounding box on are counted as error. Each
    // matched prediction should be removed from the list.
    // 3. At the end anyone left from the prediction list are also counted as error.
    getErrorBoxes = () => {
        let range = 0.25;

        let predictionBoundingBoxes = this.filteredBoxes(this.datumViewWrapper.prediction)
        let groundTruthBoundingBoxes = this.filteredBoxes(this.datumViewWrapper.groundTruth);

        let errorBoundingBoxList: any = [];

        groundTruthBoundingBoxes.map((gt: any) => {
            let matchFound = false;

            predictionBoundingBoxes.map((prediction: any) => {
                let pBox = prediction.box;
                let gTBox = gt.box

                let widthRange = gTBox[0] > pBox[0] ? pBox[0] * range : gTBox[0] * range;
                let heightRange = gTBox[1] > pBox[1] ? pBox[1] * range : gTBox[1] * range;

                if(prediction.label === gt.label && Math.abs(gTBox[2] - pBox[2]) < widthRange &&
                Math.abs(gTBox[3] - pBox[3]) < heightRange){
                    if (!matchFound) {
                        matchFound = true;
                    } else {
                        errorBoundingBoxList.push(prediction);
                    }
                }
            })

            if (!matchFound) {
                errorBoundingBoxList.push(gt);
            }
        })

        //Add rest of predictions
        return errorBoundingBoxList = errorBoundingBoxList.concat(predictionBoundingBoxes);
    };


    updateSelectedTag = (datumClass: any) => {
        datumClass.selected = !datumClass.selected;
        this.updateAllSelectedAndNoneSelectedTags();
        this.prepareBoxes();
    };

    selectAllTags = () => {
        for (let aDatumClass of this.allDatumClasses) {
            aDatumClass.selected = true;
        }
        for (let aDatumClass of this.predictionDatumClasses) {
            aDatumClass.selected = true;
        }
        for (let aDatumClass of this.groundTruthClasses) {
            aDatumClass.selected = true;
        }
        this.updateCurrentDatumClasses();
        this.updateAllSelectedAndNoneSelectedTags();
        this.prepareBoxes();
    };

    deselectAllTags = () => {
        for (let aDatumClass of this.allDatumClasses) {
            aDatumClass.selected = false;
        }
        for (let aDatumClass of this.predictionDatumClasses) {
            aDatumClass.selected = false;
        }
        for (let aDatumClass of this.groundTruthClasses) {
            aDatumClass.selected = false;
        }
        this.updateCurrentDatumClasses();
        this.updateAllSelectedAndNoneSelectedTags();
        this.prepareBoxes();
    };

    updateAllSelectedAndNoneSelectedTags = () => {
        let amountOfSelected = this.currentDatumClasses.filter((aClass: any) => aClass.selected).length;
        this.allTagsSelected = amountOfSelected === this.currentDatumClasses.length;
        this.noTagSelected = amountOfSelected === 0;
    };

    closeModal() {
        this.activeModal.close();
    };

    // When selection of prediction/ground truth changes, update the current datum class list.
    updateCurrentDatumClasses = () => {
        if (this.predictionSelected && this.groundTruthSelected) {
            this.currentDatumClasses = this.allDatumClasses;
        } else if (this.predictionSelected) {
            this.currentDatumClasses = this.predictionDatumClasses;
        } else if (this.groundTruthSelected) {
            this.currentDatumClasses = this.groundTruthClasses;
            this.currentDatumClasses = this.groundTruthClasses;
        } else {
            this.currentDatumClasses = [];
        }
    };

    prepareDatumClasses = () => {
        // Prepare tags for prediction, groundTruth and both
        this.predictionDatumClasses = this.datumViewWrapper.predictionClasses;
        this.groundTruthClasses = this.datumViewWrapper.groundTruthClasses;
        this.allDatumClasses = this.predictionDatumClasses.concat(this.groundTruthClasses);

        // set selected to true and remove duplicates
        this.allDatumClasses = this.allDatumClasses.reduce((total: any, currentDatumClass: any) => {
            currentDatumClass.selected = true;

            if (total.length === 0) {
                total = [currentDatumClass];
            } else if (total.filter((aDatumClass: DatumClass) => aDatumClass.name === currentDatumClass.name).length === 0) {
                total.push(currentDatumClass);
            }
            return total;
        }, []);
    };

    nextImage = () => {
        this.imageLoading = true;

        if (this.index === this.datumViewWrappers.length - 1) {
            this.index = 0;
        } else {
            this.index++;
        }

        this.datumViewWrapper = this.datumViewWrappers[this.index];

        this.prepareDatumClassAndRenderImage();
    };

    previousImage = () => {
        this.imageLoading = true;

        if (this.index === 0) {
            this.index = this.datumViewWrappers.length - 1;
        } else {
            this.index--;
        }

        this.datumViewWrapper = this.datumViewWrappers[this.index];

        this.prepareDatumClassAndRenderImage();
    };

    prepareDatumClassAndRenderImage = () => {
        this.prepareDatumClasses();
        this.updateCurrentDatumClasses();

        setTimeout(() => {
            this.drawImageOnCanvas();
        }, 200);
    }

    toggleSelection = () => {
        setTimeout(() => {
            this.updateCurrentDatumClasses();
            this.prepareBoxes();
        }, 0);
    };
}



