<div id="object-detection-misclassified-modal" class="modal-content">
    <div class="modal-head">
        <h2 class="modal-title">
            <span>{{ 'ai-studio.object-detection.mis-classification' | translate }}</span>
            <a class="float-right">
                <i class="icon icon-times" (click)="closeModal()"></i>
            </a>
        </h2>
    </div>

    <div class="modal-body rest-height process-image-modal">
        <div class="row height100">
            <div class="col-sm-3 height100 leftPanel">
                <div class="form-check">
                    <div style="display: flex">
                        <checkbox id="prediction-checkbox"
                                  (onChange)="predictionSelected=$event; toggleSelection()"
                                  [checked]="predictionSelected">
                        </checkbox>
                        <label for="prediction-checkbox">
                            {{'ai-studio.object-detection.prediction' | translate}}
                        </label>
                    </div>
                    <div style="display: flex">
                        <checkbox id="ground-truth-checkbox"
                                  (onChange)=" groundTruthSelected=$event;toggleSelection()"
                                  [checked]="groundTruthSelected">
                        </checkbox>
                        <label for="ground-truth-checkbox">
                            {{'ai-studio.object-detection.what-you-define' | translate}}
                        </label>
                    </div>
                </div>

                <div *ngIf="currentDatumClasses.length > 0" style="padding: 15px 0;">
                    <p style="color: black; margin-bottom: 5px;">{{'ai-studio.object-detection.show-bounding-box' | translate}}</p>
                    <div class="bounding-box-selection">
                        <button-primary
                            text="{{'ai-studio.object-detection.all' | translate}}"
                            class="button-small"
                            (click)="selectAllTags()"
                            [ngClass]="{ 'active': allTagsSelected }"
                            style="margin-bottom: 7px; margin-left: 0px">
                        </button-primary>
                        |
                        <button-primary
                            text="{{'ai-studio.object-detection.none' | translate}}"
                            class="button-small"
                            (click)="deselectAllTags()"
                            [ngClass]="{ 'active': noTagSelected }"
                            style="margin-bottom: 7px; margin-left: 0px">
                        </button-primary>
                    </div>
                    <div class="tags">
                        <div *ngFor="let datumClass of currentDatumClasses" (click)="updateSelectedTag(datumClass)"  >
                            <ai-tag
                                style="cursor: pointer; padding-right: 10px;"
                                [name]="datumClass.name"
                                [color]="datumClass.color"
                                [ngStyle]="!datumClass.selected && { 'opacity' : 0.4 }">
                            </ai-tag>
                        </div>
                    </div>
                </div>

                <div class="form-check">
                    <div style="display: flex">
                        <checkbox id="object-detection-modal-highlight-error"
                                  (onChange)="showError=$event; prepareBoxes()"
                                  [checked]="showError">
                        </checkbox>
                        <label for="object-detection-modal-highlight-error">
                            {{'ai-studio.object-detection.highlight-errors' | translate}}
                        </label>
                    </div>
                </div>

                <div class="bottomPanel">
                    <div class="buttonBar">
                        <div class="btn-group pull-right" role="group">
                            <button
                                class="btn btn-default"
                                (click)="previousImage();"
                                ngbTooltip="{{'ai-studio.object-detection.pre-img' | translate}}">
                                <i class="icon icon-chevron-left"></i>
                            </button>
                            <button
                                class="btn btn-default"
                                (click)="nextImage();"
                                ngbTooltip="{{'ai-studio.object-detection.next-img' | translate}}">
                                <i class="icon icon-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-9 height100 rightPanel">
                <!-- Loading Image Icon -->
                <div class="height100 text-center" *ngIf="imageLoading">
                    <svg class="frontline-loading-animation" style="position: relative; top: 46%;"><use xlink:href="#frontline"></use></svg>
                </div>

                <!-- Broken Image Icon -->
                <div class="height100 text-center" *ngIf="imageBroken">
                    <i class="icon icon-broken-image" style="position: relative; font-size: 450px; top: 50%; margin-top: -225px;"></i>
                </div>
                <canvas
                    id="canvas_{{ datumViewWrapper.id }}_modal"
                    class="lazyload mis-classified-canvas">
                </canvas>
            </div>
        </div>
    </div>
</div>
