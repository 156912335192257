<div class="user-info-wrapper clear-fix">
    <!-- ToDo:  Show Avatar if required -->
<!--    <div class="float-left">
        <img ng-if="$ctrl.authenticationService.isInitialized()" class="img-avatar" ng-src="{{avatarurl}}" alt="Avatar" />
    </div>-->
    <div class="float-left">
        <strong>{{username}}</strong><br>
        <!-- ToDo:  Add Avatar if required -->
        <!--<a href="" ng-click="$ctrl.changeAvatar()">{{ 'component.profile.change-avatar' | translate }}</a>-->
    </div>
</div>

<div>
    <ul class="">
        <li *ngIf="!hasFrontlineRole"><a (click)="editProfile()">{{ 'ai-studio.user-profile.edit-user-profile' | translate }}</a></li>
        <li *ngIf="!hasFrontlineRole"><a (click)="editPassword()">{{ 'ai-studio.user-profile.change-password' | translate }}</a></li>
        <li><a href="">{{selectedLanguage!.displayName}} <i class="icon icon-angle-right float-right"></i></a>
            <ul>
                <li *ngFor="let l of supportedLocales | orderBy : 'displayName'">
                    <a (click)="changeLanguage(l)">{{l.displayName}}</a>
                </li>
            </ul>
        </li>
        <!-- ToDo: Add Help page -->
<!--        <li>
            <a *ngIf="!helpUrl" href="help.html">{{ 'component.profile.help' | translate }}</a>
            <a *ngIf="helpUrl" href="{{helpUrl}}" target="_blank" >{{ 'component.profile.help' | translate }}</a>
        </li>-->
        <li>
            <a (click)="logout()">{{ 'component.profile.logout' | translate }}</a>
        </li>
    </ul>
    <ul class="user-deletion">
        <li *ngIf="!hasFrontlineRole"><a (click)="openDeleteAccountDialog()">{{ 'ai-studio.user-profile.delete-user-profile' | translate }}</a></li>
    </ul>
    <div class="user-info">
        <span class="legal-link"><a href="https://www.teamviewer.com/en/eula/" target="_blank">Agreements</a></span>
        <span class="legal-link"><a href="https://www.teamviewer.com/en/privacy-policy/" target="_blank">Product Policy</a></span>
        <span class="legal-link"><a href="https://www.teamviewer.com/en/address/" target="_blank">Imprint</a></span>
        <!-- ToDo: Add LastLogin -->
<!--        <span [hidden]="lastlogin === 'never'">{{ "component.profile.last-login" | translate}} {{lastlogin}}<br></span>
        {{"component.profile.version" | translate}} {{version}}-->
    </div>
    <div>
        <span class="app-version" *ngIf="hasInternalDevRole">{{appVersion}}</span>
    </div>
</div>
