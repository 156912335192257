import {Component, HostBinding, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {UserService} from "services/aiStudio/user.service";
import {AuthenticationService} from "services/authentication.service";
import {NotificationService} from "services/notification.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
    selector: 'user-deletion-dialog',
    templateUrl: './user-deletion-dialog.component.html',
    styleUrls: ['./user-deletion-dialog.component.sass']
})

export class UserDeletionDialogComponent implements OnInit {

    @HostBinding('class') class = 'modal-content';

    conformationText: string = "";
    readyForDeletion: boolean = false;


    constructor(
        public activeModal: NgbActiveModal,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private notificationService: NotificationService,
        private translateService: TranslateService
    ) {
        //super();
    }

    ngOnInit() {

    }

    checkForValidInput() {
        this.conformationText === "DELETE" ? this.readyForDeletion = true : this.readyForDeletion = false;
    }

    deleteAccount() {
        if(this.readyForDeletion) {
            this.notificationService.info(this.translateService.instant('ai-studio.feedback.delete-user-account'));

            this.userService.deleteUser().then((resp: any) => {
                this.authenticationService.logout()
            }).catch((err: any) => {
                this.notificationService.error(this.translateService.instant('ai-studio.feedback.delete-user-account-failed'));
            });
        }
    }

    closeModal() {
        this.activeModal.close();
    }

}
