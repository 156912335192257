import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from 'global/config';
import {DetailedError, HttpRequest, HttpResponse, Upload} from 'tus-js-client';
import {UploadItem} from 'components/uploader/upload.item';
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {TagViewService} from "services/aiStudio/tag-view.service";
import {AuthenticationService} from "services/authentication.service";
import Cookies from "js-cookie";

@Injectable()
export class FileUploadService {
    constructor(private http: HttpClient, private config: Config, private aiProjectService: AiProjectService, private tagViewService: TagViewService, private authenticationService: AuthenticationService) {
    }

    uploadFile(
        uploadItem: UploadItem,
        onComplete: () => void,
        onError: () => void,
        onProgress?: (bytesSent: number, bytesTotal: number) => void,
        onAfterResponse?: (req: HttpRequest, res: HttpResponse) => void
    ): UploadItem | null {
        let tags: any[] = [];
        tags = tags.concat(this.tagViewService.selectedTagViewsForUpload);
        tags = tags.map(t => encodeURIComponent(t));
        let uploadContent = uploadItem.blob ? uploadItem.blob : uploadItem.file;
        if (uploadContent){
            let headers : { [key: string]: string; } = {
                "Authorization": "Bearer "+ this.authenticationService.getToken(),
                "isDevMode": `${this.config.baseUrl.endsWith('api')}`,
                "x-datum-width": uploadItem.width.toString(),
                "x-datum-height": uploadItem.height.toString(),
                "x-datum-tags": JSON.stringify(tags)
            }
            let csrfToken = Cookies.get("X-CSRF-TOKEN");
            if(csrfToken) {
                headers["X-CSRF-TOKEN"] = csrfToken;
            }
            const annotationString: string = uploadItem.annotation ? JSON.stringify(uploadItem.annotation) : '';

            uploadItem.upload = new Upload(uploadContent, {
                endpoint: `${this.config.baseUrl}/aiStorage/`+ this.aiProjectService.getAiProject().projectUuid + `/datum/upload`,
                retryDelays: [0, 3000, 6000, 12000, 24000],
                // Be carefull with chunk size. Hard limit from azure to 4mb
                chunkSize: 1000000,
                headers: headers,
                metadata: {
                    filename: uploadItem.name,
                    filetype: uploadContent.type,
                    annotations: annotationString
                },
                overridePatchMethod: true, // I had issues with PATCH so this will just use POST instead
                onError,
                onShouldRetry: function (err, retryAttempt, options) {
                    if (err instanceof DetailedError) {
                        var status = err.originalResponse ? err.originalResponse.getStatus() : 0
                        return !(status >= 400 && status < 400 + 100) || status === 409 || status === 423 || status === 429
                    }
                    return false
                },
                onSuccess: onComplete,
                onProgress,
                onAfterResponse
            })

            uploadItem.upload.start();

            return uploadItem;
        }
        return null;
    }
}
