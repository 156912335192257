<div id="classification-dialog-modal" class="modal-content">
    <div class="modal-head">
        <h2 class="modal-title">
            <span>{{ title }}</span>
            <a class="float-right" >
                <i class="icon icon-times" (click)="closeModal()"></i></a>
        </h2>
    </div>

    <div class="modal-body rest-height process-image-modal">
        <img
            class="img-fluid lazyload text-center"
            style="width:100%; height:100%; object-fit: contain;"
            [src]="baseUrl+'/aiStorage/'+projectUuid+'/datum/'+datum.id+'/image?size='+imgQuality | authImage | async"/>
    </div>
 </div>
