import {BoundingBox, Datum, DatumClass, DatumLabel} from "@teamviewer/aistudioapi-common-angular";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Config} from "global/config";
import {
    BACKGROUND,
    BOUNDING_BOX,
    brokenImage,
    DATUM_LABEL,
    IMAGE_SIZE_SMALL,
    imageSizeList,
    INFERENCE_PAGE_TAB,
    WELL_SET_TAB,
} from "utils/project-utils";
import {TagViewService} from "./tag-view.service";
import {TagView} from "@teamviewer/aistudioapi-aistudio-angular";
import {LoadingService} from "services/loading.service";
import {AuthImagePipe} from "pipes/authImage.pipe";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";

@Injectable()
export class DatumService {
    loadingPaginatedDatums: boolean = false;

    datums: Datum[] = [];
    inferenceDatums: Datum[] = [];

    totalPages: number = 0;
    totalDatums: number = 0;
    paginationPageSizeInitial = [50, 100, 200];
    paginationPageSize = this.paginationPageSizeInitial;
    minPageSize = 50;
    pageSize: number = this.paginationPageSize[0];

    minZoomLevel = 2;
    maxZoomLevel = 6;
    zoomLevel = 4;

    confidenceLevel = 75;

    selectedSortingSetting: string = 'lastCreatedFirst';

    imagesLoaded: boolean = true;

    constructor(private http: HttpClient,
                private config: Config,
                private aiProjectService: AiProjectService,
                private tagViewService: TagViewService,
                private loadingService: LoadingService,
                private authImagePipe: AuthImagePipe,
                private aiStudioObservables: AiStudioObservables) {}

    getDatums(): Datum[] {
        return this.datums;
    };

    findDatumById(id:string) {
        let datums = this.aiProjectService.getAllDatumsFromProject()
        let datum = datums.filter((aDatum: Datum) => aDatum.id === id)[0]
        return datum
    }

    getInferenceDatums() {
        return this.inferenceDatums;
    };


    setDatums(newDatums: any) {
        this.datums = newDatums;
    };

    deleteDatums(datumsToBeDeleted: Datum[]) {
        let projectId = this.aiProjectService.getAiProject().projectUuid
        const endpoint = `${this.config.baseUrl}/aiDataset/${projectId}/datums`
        const options: any = {
            body: datumsToBeDeleted
        }
        return this.http.delete(endpoint, options).toPromise()
    };

    updateDatums(datums: Datum[]) {
        let projectId = this.aiProjectService.getAiProject().projectUuid
        const endpoint = `${this.config.baseUrl}/aiDataset/${projectId}/datums`
        return this.http.put(endpoint, datums).toPromise()
    }

    setSorting(sorting: string) {
        this.selectedSortingSetting = sorting;
    }

    async renderMisclassifiedOnCanvas(misclassified: any, classes: DatumClass[]){
        let imgQuality = IMAGE_SIZE_SMALL;

        for (let mis of misclassified) {

            //prediction canvas
            const pCanvas = document.getElementById('canvas_prediction_' + mis.id);

            //groundtruth canvas
            const gCanvas = document.getElementById('canvas_groundtruth_' + mis.id);

            if (gCanvas == null || pCanvas == null) {
                continue;
            }

            let dualCanvas = [pCanvas, gCanvas]
            for( let canvas of dualCanvas ){
                let isPredictionCanvas = canvas.id.includes('canvas_prediction');
                const ctx: any = (canvas as HTMLCanvasElement).getContext('2d');
                this.fitCanvasToContainer(canvas as HTMLCanvasElement);

                let imageObj: any = new Image();
                this.authImagePipe.transform(this.config.baseUrl +'/aiStorage/'+  this.aiProjectService.getAiProject().projectUuid +'/datum/'+ mis.id + '/image?size=' + imgQuality).then(
                    url => { imageObj.src = url;}
                );

                imageObj.onerror = () => {
                    this.drawBrokenImageOnCanvas(ctx, canvas as HTMLCanvasElement);
                };

                imageObj.onload = () => {
                    let posInfo = this.getImagePosInfoInCanvas(imageObj, canvas, false);

                    if(ctx){
                        ctx.drawImage(imageObj, posInfo.xOffset, posInfo.yOffset, posInfo.imgWidth, posInfo.imgHeight);

                        let scaleX = posInfo.imgWidth / mis.trainingImgWidth;
                        let scaleY = posInfo.imgHeight/ mis.trainingImgHeight;

                        ctx.lineWidth = 2;
                        ctx.setLineDash([]);

                        let boxes = isPredictionCanvas ? mis.prediction : mis.groundTruth;

                        for (let boundingBoxObject of boxes) {
                            let color = classes.filter((aClass: DatumClass) => aClass.name === boundingBoxObject.label)[0].color;
                            ctx.strokeStyle = this.hexToRgba(color);
                            let width = boundingBoxObject.box[2] -  boundingBoxObject.box[0];
                            let boxScaledWidth = width * scaleX;
                            let height = boundingBoxObject.box[3] - boundingBoxObject.box[1];
                            let boxScaledHeight = height * scaleY;

                            // Draw bounding box with X, Y offset.
                            let x = boundingBoxObject.box[0]*scaleX + posInfo.xOffset;
                            let y = boundingBoxObject.box[1]*scaleY + posInfo.yOffset;

                            ctx.strokeRect(x, y, boxScaledWidth, boxScaledHeight);
                        }
                    }
                };
            }
        }
    }

    async renderImagesOnCanvas(datums: any, fitCenterCanvas: boolean, imgQuality: any) {
        //ToDo: One possible way to show skeleton of images

        this.imagesLoaded = false;

        let promiseStack = [];

        if (!imgQuality || !imageSizeList.includes(imgQuality)) {
            imgQuality = IMAGE_SIZE_SMALL;
        }
        for (let datum of datums) {
            const canvas = document.getElementById('canvas_' + datum.id);
            if (canvas == null) {
                continue;
            }

            const ctx = (canvas as HTMLCanvasElement).getContext('2d');
            this.fitCanvasToContainer(canvas as HTMLCanvasElement);

            let imageObj: any = new Image();

            let createdPromise = this.authImagePipe.transform(this.config.baseUrl +'/aiStorage/'+ this.aiProjectService.getAiProject().projectUuid +'/datum/'+ datum.id + '/image?size=' + imgQuality).then(
                url => { imageObj.src = url;}
            );

            promiseStack.push(createdPromise)

            imageObj.onerror = () => {
                this.drawBrokenImageOnCanvas(ctx, canvas as HTMLCanvasElement);
            };

            imageObj.onload = () => {
                let posInfo = this.getImagePosInfoInCanvas(imageObj, canvas, fitCenterCanvas);

                if (ctx) {
                    ctx.drawImage(imageObj, posInfo.xOffset, posInfo.yOffset, posInfo.imgWidth, posInfo.imgHeight);
                    if (this.aiProjectService.isObjectDetectionProject()) {
                            this.drawBoundingBoxesInCanvas(ctx, datum, posInfo.xOffset, posInfo.yOffset, posInfo.imgWidth, posInfo.imgHeight, false, false, false);
                    }
                }
            };
        }
        //ToDo: One possible way to show skeleton of images
        Promise.all(promiseStack).then(() => this.imagesLoaded = true);
    };

    drawBrokenImageOnCanvas(ctx: any, canvas: HTMLCanvasElement) {
        let image: HTMLImageElement = document.createElement('img');
        image.src = brokenImage;

        let posInfo = this.getImagePosInfoInCanvas(image, canvas, true);
        ctx.drawImage(image, posInfo.xOffset, posInfo.yOffset, posInfo.imgWidth, posInfo.imgHeight);
    };

    hexToRgba(hex: any, transparency?: number) {
        if (hex) {
            let r = parseInt(hex.slice(1, 3), 16);
            let g = parseInt(hex.slice(3, 5), 16);
            let b = parseInt(hex.slice(5, 7), 16);

            if (!transparency) {
                transparency = .8;
            }

            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + transparency + ')';
        } else return;
    };

    fitCanvasToContainer(canvas: HTMLCanvasElement) {
        canvas.style.width = '100%';
        canvas.style.height = '100%';
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
    };

    drawBoundingBoxesInCanvas(ctx: any, datum: Datum, xOffset: number, yOffset: number, imgWidth: number, imgHeight: number, boldLine: boolean, dashedLine: boolean, filled: boolean) {
        let scaleX = (datum.originWidth||0) / imgWidth;
        let scaleY = (datum.originHeight||0) / imgHeight;
        if (boldLine) {
            ctx.lineWidth = 4;
        } else {
            ctx.lineWidth = 2;
        }

        if (dashedLine) {
            ctx.setLineDash([10]);
        } else {
            ctx.setLineDash([]);
        }

        //If its an dataset Image and the image has bounding boxes
        for (let datumLabel of datum.datumLabels||[]) {
            if (datumLabel.datumClass && datumLabel.datumClass.color) {
                let boundingBox = datumLabel as BoundingBox
                ctx.strokeStyle = this.hexToRgba(boundingBox.datumClass?.color);

                // Scale the bounding box according to the original size of the image
                let boxScaledWidth = (boundingBox.width||0) / scaleX;
                let boxScaledHeight = (boundingBox.height||0) / scaleY;
                let boxScaledCenterPosX = (boundingBox.centerPosX||0) / scaleX;
                let boxScaledCenterPosY = (boundingBox.centerPosY||0) / scaleY;

                // Draw bounding box with X, Y offset.
                let x = boxScaledCenterPosX - boxScaledWidth / 2 + xOffset;
                let y = boxScaledCenterPosY - boxScaledHeight / 2 + yOffset;

                ctx.strokeRect(x, y, boxScaledWidth, boxScaledHeight);

                if (filled) {
                    ctx.fillStyle = this.hexToRgba(boundingBox.datumClass?.color, 0.4);
                    ctx.fillRect(x, y, boxScaledWidth, boxScaledHeight);
                }
            }
        }

        if(datum.inferenceImage) {
            ctx.setLineDash([10]);
            //If its an inference Image and the image has recognitions
            for(let recBb of datum.recognitions||[]) {
                if((recBb.recognitionInfo) && recBb.datumClass && recBb.datumClass.color) {
                    ctx.strokeStyle = this.hexToRgba(recBb.datumClass.color);

                    let recogData = JSON.parse(recBb.recognitionInfo);

                    let boxScaledWidth = recogData.width / scaleX;
                    let boxScaledHeight = recogData.height / scaleY;
                    let boxScaledCenterPosX = recogData.centerPosX / scaleX;
                    let boxScaledCenterPosY = recogData.centerPosY / scaleY;

                    // Draw bounding box with X, Y offset.
                    let x = boxScaledCenterPosX - boxScaledWidth / 2 + xOffset;
                    let y = boxScaledCenterPosY - boxScaledHeight / 2 + yOffset;

                    ctx.strokeRect(x, y, boxScaledWidth, boxScaledHeight);

                    if (filled) {
                        ctx.fillStyle = this.hexToRgba(recBb.datumClass.color, 0.4);
                        ctx.fillRect(x, y, boxScaledWidth, boxScaledHeight);
                    }
                }

            }
        }
        this.loadingPaginatedDatums = false;

    };

    getImagePosInfoInCanvas(img: HTMLImageElement, canvas: any, fitCenterCanvas: any) {
        // Draw image in the canvas center crop.
        let imgWrh = img.width / img.height;
        let canvasWrh = canvas.width / canvas.height;

        let imgNewWidth;
        let imgNewHeight;
        let xOffset;
        let yOffset;

        if (fitCenterCanvas) {
            if (imgWrh < canvasWrh) {
                imgNewHeight = canvas.height;
                imgNewWidth = imgNewHeight * imgWrh;
                xOffset = Math.abs((canvas.width - imgNewWidth) / 2);
                yOffset = 0;
            } else {
                imgNewWidth = canvas.width;
                imgNewHeight = imgNewWidth / imgWrh;
                xOffset = 0;
                yOffset = Math.abs((canvas.height - imgNewHeight) / 2);
            }
        } else { // Otherwise make it fit-scale
            if (imgWrh < canvasWrh) {
                imgNewWidth = canvas.width;
                imgNewHeight = imgNewWidth / imgWrh;
                xOffset = 0;
                yOffset = imgNewHeight > canvas.height ? ((canvas.height - imgNewHeight) / 2) : 0;
            } else {
                imgNewHeight = canvas.height;
                imgNewWidth = imgNewHeight * imgWrh;
                xOffset = imgNewWidth > canvas.width ? ((canvas.width - imgNewWidth) / 2) : 0;
                yOffset = 0;
            }
        }

        return {
            xOffset: xOffset,
            yOffset: yOffset,
            imgWidth: imgNewWidth,
            imgHeight: imgNewHeight
        };
    };

    getPaginatedDatums(page: number, sort: string, wellSet: boolean, isInference: boolean, confidenceLevel: number, size?: number) {
        let classes: any = [];
        if(!isInference) {
            if(wellSet) {
                if(this.aiProjectService.isObjectDetectionProject()){
                    //ObjectDetection - Well Set
                    classes = this.tagViewService.getWellSetTagViews().filter((tagView: TagView) => tagView.selected == true).map(aClass => aClass.tagName);
                } else {
                    //Classification - Tagged
                    classes = this.tagViewService.getTaggedTagViews().filter((tagView: TagView) => tagView.selected == true).map(aClass => aClass.tagName);
                }
            } else {
                if(this.aiProjectService.isObjectDetectionProject()){
                    //ObjectDetection - Not Ready
                    classes = this.tagViewService.getNotReadyTagViews().filter((tagView: TagView) => tagView.selected == true).map(aClass => aClass.tagName);
                }
            }
        } else {
            classes = this.tagViewService.getInferenceTagViews().filter((tagView: TagView) => tagView.selected == true).map(aClass => aClass.tagName);
            if(classes.length === 0) {
                classes = this.tagViewService.getInferenceTagViews().map(tagView => tagView.tagName);
            }
        }

        const projectUuid = this.aiProjectService.getAiProject().projectUuid;
        const params: any =  {
            classes: classes.join(','),
            projectUuid,
            page: page - 1,
            size: size !== 0 ? size : this.minPageSize,
            sort,
            isReady: wellSet,
            isInference,
            minimumConfidence:  confidenceLevel/100
        }

        const endpoint = this.config.baseUrl + '/aiDataset/' + projectUuid + '/datums'

        return this.http.get<Datum[]>(endpoint, {params}).toPromise().then(response => {
            return response;
        });
    };

    selectPage(selectedPage: number, isInferenceImage: boolean, sorting: any, wellSet: boolean) {
        //if project is loaded
        this.loadingPaginatedDatums = true;

        if (this.aiProjectService.getAiProject()) {
            this.getPaginatedDatums(selectedPage, sorting, wellSet, isInferenceImage, this.confidenceLevel, this.pageSize).then((response: any) => {
                this.totalPages = response.totalPages;
                this.totalDatums = response.totalElements;
                this.paginationPageSize = this.paginationPageSizeInitial;

                if (this.paginationPageSize.length === 0) {
                    this.paginationPageSize = [this.totalDatums]
                }

                this.loadPage(response, isInferenceImage)
                //Sets accessibility for Data Optimization
                if (this.totalDatums > 0) {
                    this.aiStudioObservables.updateStatusDatumsInProject$(true);
                } else {
                    this.aiStudioObservables.updateStatusDatumsInProject$(false);
                }
            })
        }
        this.loadingPaginatedDatums = false;
    };

    loadPage(response: any, inference?: any) {
        if (inference) {
            this.inferenceDatums = response.content;
        } else {
            this.datums = response.content;
        }

        this.loadingService.hide();

        setTimeout(() => {
            this.renderImagesOnCanvas(inference ? this.inferenceDatums : this.datums, false, false);
        }, 0);

    };

    datumIsObjectDetectionReady(datum: any) {
        if (datum && datum.datumLabels) {
            if (datum.datumLabels.length === 0) {
                return false;
            } else if (datum.datumLabels.filter((label: any) => label.datumClass.name === BACKGROUND).length > 0) {
                //Is background
                return true;
            } else {
                //classes without boundingboxes
                let classes = datum.datumLabels.filter((label: DatumLabel) => label.type === DATUM_LABEL);
                let boundingBoxes = datum.datumLabels.filter((label: DatumLabel) => label.type === BOUNDING_BOX)
                let notFound = false;

                classes.map((aClass: DatumLabel) => {
                    notFound = boundingBoxes.filter((bb: DatumLabel) => {
                        bb?.datumClass?.id === aClass.id
                    }).length === 0
                })

                return !notFound
            }
        } else return false;
    }


    onPagination(currentPage: number, selectedTagTab: string, pageSize?: number) {
        if (pageSize) {
            this.pageSize = pageSize
        }
        this.selectPage(currentPage, selectedTagTab === INFERENCE_PAGE_TAB , "", selectedTagTab === WELL_SET_TAB);
        // this.currentPage = currentPage;
    }

    //ToDo when there's user preferences
    updateImageSizeWhenZoom() {
        // Update user preference

    }

    //Fetch a datum for each class
    getDatumWithClass(aClassName: string): Promise<any> {
        const projectUuid = this.aiProjectService.getAiProject().projectUuid;
        const params: any = {
            classes: aClassName,
            projectUuid,
            page: 0,
            size: 1,
            isReady: true
        }
        const endpoint = this.config.baseUrl + '/aiDataset/' + projectUuid + '/datums'
        return this.http.get(endpoint, {params}).toPromise();

    }

    getDatumImage(projectUuid: any): Promise<any> {
        const params: any = {
            projectUuid,
            page: 0,
            size: 1,
            isReady: true
        }
        const endpoint = this.config.baseUrl + '/aiDataset/' + projectUuid + '/datums'
        return this.http.get(endpoint, {params}).toPromise();
    }
}
