import {Component, ViewEncapsulation} from "@angular/core";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {AuthenticationService} from "services/authentication.service";
import {DatasetService} from "services/aiStudio/dataset.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ImportVideoComponent} from "components/modals/import-video/import-video.component";
import {LoadingService} from "services/loading.service";
import {DialogConfirm} from "dialogs/dialog-confirm";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'ai-video-indicator',
    templateUrl: 'ai-video-indicator.component.html',
    styleUrls: ['ai-video-indicator.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class AiVideoIndicatorComponent {

    numOpenVideos: number = 0;

    constructor(private aiProjectService: AiProjectService,
                private authenticationService: AuthenticationService,
                private modalService: NgbModal,
                private datasetService: DatasetService,
                private loadingService: LoadingService,
                private dialogConfirm: DialogConfirm,
                private translateService: TranslateService) {
        datasetService.countDataCollectionVideos(this.aiProjectService.getAiProject().projectUuid || "").then((res) => this.numOpenVideos=res)
    }

    processNextVideo() {
        this.loadingService.show("ai-studio.feedback.uploader.loading-video")
        this.datasetService.getDataCollectionVideo(this.aiProjectService.getAiProject().projectUuid || "").then((datum) => {
            this.loadingService.hide();
            let modalInstance = this.modalService.open(ImportVideoComponent,  {
                animation: true,
                size: 'col-6',
                backdrop: 'static',
            })
            modalInstance.componentInstance.datum = datum;

            modalInstance.result.then(() => {
                this.datasetService.countDataCollectionVideos(this.aiProjectService.getAiProject().projectUuid || "").then((res) => {
                    this.numOpenVideos=res;
                    if(this.numOpenVideos > 0) {
                        this.dialogConfirm.open(this.translateService.instant('ai-studio.review-video-dialog.load-next'), this.translateService.instant('wf-editor.alert.yes'), this.translateService.instant('no-cancel')).then(() => this.processNextVideo())
                    }
                })
            })
        }).catch((err: any) => {
            console.log(err)
            this.loadingService.hide();
        })
    }

    getFilenameFromHeader(dispositionHeader: string): string | undefined {
        if (dispositionHeader && dispositionHeader.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(dispositionHeader);
            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }
        return undefined
    }
}
