import {Component, Input, ViewEncapsulation} from "@angular/core";
import {TagView} from "@teamviewer/aistudioapi-aistudio-angular";
import {BACKGROUND, INFERENCE, MINIMUM_IMAGES, OrderBy, UNTAGGED} from "utils/project-utils";
import {DatumService} from "services/aiStudio/datum.service";

@Component({
    selector: 'tag-view',
    templateUrl: 'tag-view.component.html',
    styleUrls: ['tag-view.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class TagViewComponent {
    /** @type {TagView} */
    @Input() backgroundTagView: TagView | undefined;
    /** @type {TagView[]} */
    @Input() positiveTagViews: TagView[] = [];
    /** @type {boolean} */
    @Input() showIcons?: boolean;
    /** @type {string} */
    @Input() tabSelected?: string;

    UNTAGGED = UNTAGGED;
    BACKGROUND = BACKGROUND;
    MINIMUM_IMAGES = MINIMUM_IMAGES;

    OrderBy = OrderBy
    constructor(private datumService: DatumService) {
    }

    updateViewImages(tab: string) {
        //Important: There are three possible cases to fetch Images
        //1. dataset-wellset
        //2. dataset-not-ready
        //3. inference

        let wellSet = tab === "dataset-wellset";
        let isInference = tab === INFERENCE;

        this.datumService.selectPage(1, isInference, this.datumService.selectedSortingSetting, wellSet);
    }
}
