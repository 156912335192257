import {AiProjectService} from "services/aiStudio/ai-project.service";
import {TagViewService} from "services/aiStudio/tag-view.service";
import {Injectable} from "@angular/core";
import {UNTAGGED} from "utils/project-utils";
import {HttpClient} from "@angular/common/http";
import {Config} from "global/config";

@Injectable()
export class DatasetService {
    constructor(private aiProjectService: AiProjectService, private tagViewService: TagViewService, private http: HttpClient, private config: Config) {}

    allClassesHaveImage(): boolean {
        if (this.aiProjectService.getAiProject()) {
            let tagsCount = this.tagViewService.tagViews.filter((tag: any) => tag.tagName !== UNTAGGED).map((aClass: any) => aClass.count);
            if(tagsCount.includes(0)){
                return false
            } else return true

        } else return false
    }

    async countPictures(projectUuid: string): Promise<any> {
        let endpoint = this.config.baseUrl + '/aiDataset/' + projectUuid + '/datums?' + 'page=0&size=1&isReady=true';
        return this.http.get<any>(endpoint).toPromise().then(response => {
            return response.totalElements
        })
    }

    countDataCollectionVideos(projectUuid: string) {
        let endpoint = this.config.baseUrl + '/aiDataset/' + projectUuid + '/videos' ;
        return this.http.get<any>(endpoint).toPromise();
    }

    getDataCollectionVideo(projectUuid: string) {
        let endpoint = this.config.baseUrl + '/aiDataset/' + projectUuid + '/video' ;
        return this.http.get<any>(endpoint).toPromise();
    }

    loadDataCollectionVideo(projectUuid: string, videoUuid: string) {
        const endpoint = this.config.baseUrl + '/aiStorage/' + projectUuid + '/video/' + videoUuid;
        return this.http.get(endpoint, {responseType: "blob", observe: "response", headers: { 'Accept': 'application/octet-stream'}}).toPromise();
    }

    releaseVideo(projectUuid: string, filename: string) {
        filename = filename.replace(".mp4","")
        const endpoint = this.config.baseUrl + '/aiStorage/' + projectUuid + '/video/' + filename;
        return this.http.delete(endpoint, {responseType: "blob", observe: "response", headers: { 'Accept': 'application/octet-stream'}}).toPromise();
    }
}
