import {Pipe, PipeTransform} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "services/authentication.service";
import {brokenImage} from "utils/project-utils";

@Pipe({
    name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

    constructor(
        private http: HttpClient,
        private auth: AuthenticationService, // our service that provides us with the authorization token
    ) {}

    async transform(src: string): Promise<string> {
        const token = this.auth.getToken();
        const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
        try {
            const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onloadend = () => resolve(reader.result as string);
                reader.readAsDataURL(<Blob>imageBlob);
            });
        } catch (e) {
            return brokenImage;
        }
    }
}
