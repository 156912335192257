import $ from 'jquery';
import {Directive, ElementRef, HostListener} from '@angular/core';
import ScrollEvent = JQuery.ScrollEvent;

@Directive({
    selector: '[tableScroll]'
})
export class TableScrollDirective {

    @HostListener('scroll', ['$event'])
    scrollingAction(event: ScrollEvent) {
        const el = event.target;
        if (el.scrollTop > 70) {
            $('.page-header').addClass('sticky-sub-menu');
        }
        else if (el.scrollTop < 70) {
            $('.page-header').removeClass('sticky-sub-menu');
        }

        if (el.scrollTop > 125) {
            $('.ag-header').addClass('fixed-table-header');
        }
        else if (el.scrollTop < 125) {
            $('.ag-header').removeClass('fixed-table-header');
        }
    }
}
