import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {NotificationService} from "services/notification.service";

@Injectable({providedIn: "root"})
export class ProjectGuard implements CanActivate {

    constructor(private router: Router, private notificationService: NotificationService, private aiprojectService: AiProjectService) {
    }



    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if(this.aiprojectService.getAiProject() !== null) {

            return true;
        } else {
            this.router.navigate(['home']);
            return false;
        }

    }


}
