<div [hidden]="!status_no_free_project" style="position: relative; height: 100%;">
    <div id="no-free-project-overlay" class="fullScreenOverlay">
        <div class="container">
            <div class="row">
                <div class="col-sm-5 text-right top-buffer">
                    <i class="icon icon-info" aria-hidden="true" style="font-size: 12em"></i>&nbsp;
                </div>

                <div class="col-sm-6 text-left overlay-content top-buffer" id="no-available-overlay-content">
                    <h1 class="">
                        {{ 'ai-studio.overlay.no-free-project.title' | translate }}
                    </h1>
                    <p class="lead">{{ 'ai-studio.overlay.no-free-project.lead' | translate }}</p>
                    <button-primary text="{{ 'ai-studio.init.create_new_project' | translate }}"
                                    (click)="showWizard();">
                    </button-primary>
                </div>
            </div>
        </div>
    </div>
</div>
