import {Pipe, PipeTransform} from '@angular/core';
import _ from 'lodash';

@Pipe({
    name: 'uniqueValues'
})
export class UniqueValuesPipe implements PipeTransform {

    transform(array: any, field: any): any {
        return _.uniqBy(array, field);
    }

}
