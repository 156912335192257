import {Component, ElementRef, HostBinding, Injectable, Input, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: 'dialog-confirm.html'
})
export class DialogConfirmComponent {
    @Input() public message?: string;
    @Input() public primaryButton?: string;
    @Input() public secondaryButton?: string;
    @Input() public template?: string;

    @HostBinding('class') class = 'modal-content';

    constructor(
        private activeModal: NgbActiveModal
    ) {
    }

    dismiss(): void {
        this.activeModal.dismiss();
    }

    close(): void {
        this.activeModal.close();
    }
}

@Injectable()
export class DialogConfirm {
    constructor(
        private modalService: NgbModal
    ) {}


    open(message: string, primaryButton: string, secondaryButton: string, template?: string): Promise<any> {
        const modal = this.modalService.open(DialogConfirmComponent, { size: 'col-3' });
        modal.componentInstance.message = message;
        modal.componentInstance.primaryButton = primaryButton;
        modal.componentInstance.secondaryButton = secondaryButton;
        if (template) {
            modal.componentInstance.template = template;
        }
        return modal.result;
    }
}
