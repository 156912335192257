import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

@Injectable()
export class Config {
    baseUrl = environment.APIHOSTPREFIX;

    readonly isProd = environment.production;

    properties: any = {};

    readonly urlPrefix: string;

    getServerSettingsFailed = false;
    constructor() {
        if(this.isProd) {
            this.urlPrefix = '/';
        }

        this.urlPrefix = '';
    }
}
