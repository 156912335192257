import {NgModule} from '@angular/core';
import {ErrorDirective} from './error.directive';
import {ValidatePipe} from './validate.pipe';
import {DisplayErrorComponent} from './display-error/display-error.component';
import { VALIDATION_MESSAGES } from './validation-messages';
import {FormsModule} from '@angular/forms';
import {
    DuplicatedClassValidatorModule
} from './validators/project-wizard/duplicated-class.validator'
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        FormsModule,
        DuplicatedClassValidatorModule,
        TranslateModule,
    ],
    declarations: [
        ErrorDirective,
        ValidatePipe,
        DisplayErrorComponent
    ],
    exports: [
        ErrorDirective,
        ValidatePipe,
        DisplayErrorComponent,
        DuplicatedClassValidatorModule,
    ],
    providers: [
        {
            provide: VALIDATION_MESSAGES,
            useValue: {
                required: () => 'ai-studio.feedback.validation.field-required',
                email: () => 'ai-studio.feedback.validation.valid-email',
                minlength: (details: any) =>
                    `This field must have a minimum length of ${details.requiredLength}`,
                maxlength: (details: any) =>
                    `This field must have a maximum length of ${details.requiredLength}`,
                pattern: (details: any) => {
                    return 'ai-studio.feedback.validation.invalid-input';
                }

            },
            multi: true,
        }
    ]
})
export class ValidationModule {

}
