import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Config} from "global/config";
import {UserPreferences} from "@teamviewer/aistudioapi-common-angular";

@Injectable()
export class UserService {
    userPreferences: UserPreferences = {};

    constructor(private http: HttpClient,
                private config: Config) {}

    async getUserPreference(): Promise<UserPreferences> {
        const endpoint = `${this.config.baseUrl}/user/preference`;
        return await this.http.get(endpoint).toPromise().then((resp: any) => {
            this.userPreferences = resp;
            return this.userPreferences;
        }).catch((err: any) => {
            console.log("Couldn't fetch user preferences. Creating a new blank one")
            this.userPreferences.zoomLevel = 4;
            this.userPreferences.getStartedCollapse = false;
            this.userPreferences.showTrainingDetails = true;
            return this.userPreferences;
        })
    }

    async saveUserPreference(): Promise<UserPreferences> {
        const endpoint = `${this.config.baseUrl}/user/preference`;
        return await this.http.put(endpoint, this.userPreferences).toPromise().then((resp: any) => {
            this.userPreferences = resp;
            return this.userPreferences;
        })
    };

    deleteUser(): Promise<any> {
        const endpoint = `${this.config.baseUrl}/user`;
        return this.http.delete(endpoint).toPromise()
    }
}
