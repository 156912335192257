import {Component, Input} from '@angular/core';
import {AbstractControl, NgForm} from '@angular/forms';

@Component({
    templateUrl: 'display-error.component.html'
})
export class DisplayErrorComponent {
    @Input() control!: AbstractControl;
    @Input() form?: NgForm;
}
