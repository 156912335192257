import {Component, HostBinding, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {DatumService} from "services/aiStudio/datum.service";
import {TranslateService} from "@ngx-translate/core";
import {UploadItem} from "components/uploader/upload.item";
import {UploaderComponent} from "components/uploader/uploader.component";

var MP4Box = require('mp4box/dist/mp4box.all.js');

const TINDERMODE = true

@Component({
    selector: 'video-review',
    templateUrl: 'video-review.component.html',
    styleUrls: ['video-review.component.sass']
})

export class VideoReviewComponent implements OnInit {
    @Input() videofile : File | Blob | null = null;
    @Input() uploader: UploaderComponent | undefined;
    @HostBinding('class') class = 'modal-content';

    showLoadingAnimation = false;
    canvas: any;                             // The Canvas
    availableHeight: number = 0;                    // available height = modal height - header and padding
    availableWidth: number = 0;                     // available width = modal width - padding and margins

    mp4boxfile = MP4Box.createFile();
    // @ts-ignore
    private decoder: VideoDecoder | undefined;
    frameDisplayed = false;
    trackRotation: number = 0;
    started: boolean = false;
    acceptAll: boolean = false;
    samplingFinished: boolean = false;
    samplePickRate: number = 3;
    private numSamples: number = 0;
    private curSample: number = 0;
    private highestFrame: number = -1;
    // @ts-ignore
    frames: VideoFrame[] = []

    constructor(private aiProjectService: AiProjectService,private datumService: DatumService, private translateService: TranslateService, private activeModal: NgbActiveModal){
    }

    ngOnInit(){
        this.modalRendered();
        // @ts-ignore
        this.decoder = new VideoDecoder({
            // @ts-ignore
            output: (frame: VideoFrame) => {
                if(frame.timestamp === 0 || ((frame.timestamp % this.samplePickRate) == 0)) {
                    this.curSample = frame.timestamp
                    if(TINDERMODE) {
                        let ctx = this.canvas.getContext("2d")
                        ctx.clearRect(0,0,this.canvas.width,this.canvas.height);
                        let x = this.canvas.width / 2;
                        let y = this.canvas.height / 2;
                        let width = this.canvas.width;
                        let height = this.canvas.height;
                        let angleInRadians = this.trackRotation*Math.PI/180;
                        ctx.translate(x, y);
                        ctx.rotate(angleInRadians);
                        if(this.trackRotation === 0 || this.trackRotation === 180) {
                            ctx.drawImage(frame, -width / 2, -height / 2, width, height);
                        }else {
                            ctx.drawImage(frame, -height/2, -width/2, height, width);
                        }
                        ctx.rotate(-angleInRadians);
                        ctx.translate(-x, -y);
                        this.frameDisplayed = true;
                        this.showLoadingAnimation = false;
                        this.highestFrame = Math.max(this.highestFrame, frame.timestamp)
                        if(this.acceptAll) {
                            this.addImage(frame.timestamp);
                        }
                    }else {
                        this.frames.push(frame)
                    }
                }
                if(TINDERMODE) {
                    frame.close();
                }
            },
            error: (error: DOMException) => {
                console.log("Error")
            }
        });

        const reader = new FileReader();
        reader.onload = () => {
            this.mp4boxfile = MP4Box.createFile();
            this.mp4boxfile.onReady = (info: any) => {
                console.log("MP4 ready", info)
                const track = info.videoTracks[0];

                this.trackRotation = this.determineRotation(track.matrix)
                this.decoder?.configure({
                    codec: track.codec,
                    codedHeight: track.video.height,
                    codedWidth: track.video.width,
                    description: this.description(this.mp4boxfile.getTrackById(track.id)),
                })
                this.numSamples = track.nb_samples
                this.samplePickRate = Math.ceil(track.nb_samples/(track.duration/track.timescale))

                // Start demuxing.
                this.mp4boxfile.setExtractionOptions(track.id, undefined, { nbSamples: 1 });

                this.setCanvasDimensions(track);
                if(!TINDERMODE) {
                    this.showFrame(0)
                }
            };
            this.mp4boxfile.onMoovStart = (info: any) => {
                console.log("Starting to receive File Information");
                reader.abort();
            }
            this.mp4boxfile.onSamples = (id: any, user: any, samples: any) => {
                let sample = samples[0]
                if(TINDERMODE && !this.acceptAll && (sample.number % this.samplePickRate) == 1) {
                    this.mp4boxfile.stop()
                }
                // @ts-ignore
                let chunk = new EncodedVideoChunk({
                    type: sample.is_sync?"key":"delta",
                    timestamp: sample.number,
                    duration: 1,
                    data: sample.data.slice(0)
                })
                this.decoder?.decode(chunk)
                if(sample.number === this.numSamples-1) {
                    this.samplingFinished = true
                }
                this.mp4boxfile.releaseUsedSamples(1,sample.number)
            }
            let arraybuffer = reader.result as any
            arraybuffer.fileStart = 0;

            this.mp4boxfile.appendBuffer(arraybuffer);
            this.mp4boxfile.flush();
        };
        if (this.videofile) {
            reader.readAsArrayBuffer(this.videofile);
        }
    }

    description(track: any) {
        for (const entry of track.mdia.minf.stbl.stsd.entries) {
            if (entry.avcC || entry.hvcC) {
                const stream = new MP4Box.DataStream(undefined, 0, MP4Box.DataStream.BIG_ENDIAN);
                if (entry.avcC) {
                    entry.avcC.write(stream);
                } else {
                    entry.hvcC.write(stream);
                }
                return new Uint8Array(stream.buffer, 8);  // Remove the box header.
            }
        }
        throw "avcC or hvcC not found";
    }

    modalRendered() {
        this.canvas = document.getElementById("videoImporterCanvas");
        window.addEventListener('resize', () => this.resizeCanvas, false);

        this.canvas.uniScaleTransform = true;

        this.setCanvasDimensions(null);
    }

    setCanvasDimensions(track: any) {
        if(this.canvas) {
            let canvasContainer = document.getElementById('canvasContainer');
            if(canvasContainer) {
                this.availableHeight = canvasContainer.clientHeight;
                this.availableWidth = canvasContainer.clientWidth - 30;
            }
            let videoWidth = 0;
            let videoHeight = 0;
            if(track) {
                if(this.trackRotation === 90 || this.trackRotation === 270) {
                    videoHeight = track.video.width;
                    videoWidth = track.video.height;
                }else {
                    videoHeight = track.video.height;
                    videoWidth = track.video.width;
                }
            }
            let scaling = Math.min((this.availableHeight/videoHeight),(this.availableWidth/videoWidth))
            this.canvas.height = videoHeight * scaling;
            this.canvas.width = videoWidth * scaling;
            if(canvasContainer) {
                canvasContainer.style.paddingLeft = ((this.availableWidth-this.canvas.width)/2)+"px"
            }
        }
    }

    resizeCanvas() {
        this.setCanvasDimensions(null);
        this.canvas.renderAll();
    }

    showFrame(index: number) {
            let frame = this.frames[index]
            // @ts-ignore
            let canvas = document.getElementById("videoImporterCanvas")
            // @ts-ignore
            canvas.getContext("2d").drawImage(frame, 0, 0, frame.displayWidth, frame.displayHeight);
            this.curSample = index
    }

    startSampling() {
        this.showLoadingAnimation = true
        this.started = true
        this.mp4boxfile.start()
        let ensureDisplayInterval = setInterval(() => {
            if(!this.frameDisplayed) {
                this.mp4boxfile.start()
            }else {
                clearInterval(ensureDisplayInterval)
            }
        },1000)
    }

    skipImage() {
        if(TINDERMODE) {
            this.mp4boxfile.start();
            if ((this.curSample + this.samplePickRate >= this.numSamples) || (this.samplingFinished && this.decoder?.decodeQueueSize === 0 && this.highestFrame === this.curSample)) {
                this.closeModal();
            }
        }else {
            if(this.curSample>0) {
                this.showFrame(this.curSample - 1)
            }
        }
    };

    addImage(sampleNum: number | undefined){
        if(TINDERMODE) {
            this.showLoadingAnimation = true;
            let content = this.canvas.toDataURL("image/jpeg");
            this.canvas.toBlob((blob: Blob) => {
                let anUploadItem: UploadItem = {
                    name: "VideoImport_" + (sampleNum || this.curSample) + ".jpg",
                    file: null,
                    blob: blob,
                    upload: null,
                    hasError: false,
                    isUploaded: false,
                    isDuplicate: false,
                    maxSizeExceeded: false,
                    content: content,
                    width: this.canvas.width,
                    height: this.canvas.height,
                    annotation: null,
                    annotationError: false
                };
                this.uploader?.files.push(anUploadItem);
                if (((sampleNum || this.curSample) + this.samplePickRate >= this.numSamples) || (this.samplingFinished && this.decoder?.decodeQueueSize === 0 && this.highestFrame === (sampleNum || this.curSample))) {
                    this.closeModal();
                } else if(!this.acceptAll) {
                    this.mp4boxfile.start();
                }
            })
        }else {
            this.showFrame(this.curSample+1)
        }
    }

    triggerAcceptAll() {
        this.acceptAll = true;
        this.started = true
        this.mp4boxfile.start();
    }

    closeModal() {
        this.decoder?.close();
        delete this.mp4boxfile;
        this.activeModal.close();
    };

    // Determine rotation from track header matrix
    // https://itecnote.com/tecnote/the-3-x-3-matrix-for-a-rotation-of-180-degrees/
    private determineRotation(matrix: Int32Array) {
        if(matrix[0] > 0) return 0;
        if(matrix[1] > 0) return 90;
        if(matrix[0] < 0) return 180;
        return 270;
    }
}



