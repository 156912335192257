import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import _ from "lodash";
import {projectContextOptions} from "utils/project-utils";

@Component({
    selector: 'project-context',
    templateUrl: 'project-context.component.html',
    styleUrls: ['project-context.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class ProjectContextComponent implements OnInit {
    @Input() currentWizardStep: number = 1;
    @Input() wizardContextStepsCount: number = 7;
    @Input() model: any = {};

    projectContextOptions: any = projectContextOptions;
    @Input() currentContextStep = 1;

    locationCheckBox: any = {};
    distanceCheckBox: any = {};
    perspectiveCheckBox: any = {};
    lightTypeCheckBox: any = {};
    lightConditionCheckBox: any = {};

    constructor(){}

    ngOnInit(){
        // Get all the project context options (for selections)
        /* Object.keys(this.projectContextOptions).map((option: any) => { */
            //ToDo: improve
            let locationContexts = this.projectContextOptions['location'];
            _.forEach(locationContexts, ((location) => {
                this.locationCheckBox[location] = this.model.context.location.indexOf(location) !== -1;
            })) ;

            let distanceContexts = this.projectContextOptions['distance'];
            _.forEach(distanceContexts, ((distance) => {
                this.distanceCheckBox[distance] = this.model.context.distance.indexOf(distance) !== -1;
            }));

            let perspectiveContexts = this.projectContextOptions['perspective'];
            _.forEach(perspectiveContexts, ((perspective) => {
                this.perspectiveCheckBox[perspective] = this.model.context.perspective.indexOf(perspective) !== -1;
            }));

            let lightTypeContexts = this.projectContextOptions['light_type'];
            _.forEach(lightTypeContexts, ((lightType) => {
                this.lightTypeCheckBox[lightType] = this.model.context.light_type.indexOf(lightType) !== -1;
            }));

            let lightConditionContexts = this.projectContextOptions['light_condition'];
            _.forEach(lightConditionContexts, ((lightCondition) => {
                this.lightConditionCheckBox[lightCondition] = this.model.context.light_condition.indexOf(lightCondition) !== -1;
            }));
        /* }); */
    }

    // -------- Project Location Context
    updateLocation = (location: string) => {
        if (this.locationCheckBox[location]) {
            this.model.context.location = this.model.context.location.filter((loc: any) => loc !== location)
        } else {
            this.model.context.location.push(location);
        }
        this.locationCheckBox[location] = !this.locationCheckBox[location]
    };

    // -------- Project Distance Context
    updateDistance = (distance: string) => {
        if (this.distanceCheckBox[distance]) {
            this.model.context.distance = this.model.context.distance.filter((dist: any) => dist !== distance)
        } else {
            this.model.context.distance.push(distance);
        }
        this.distanceCheckBox[distance] = !this.distanceCheckBox[distance]
    };

    // -------- Project Light Type Context
    updateLightType = (lightType: any) => {
         if (this.lightTypeCheckBox[lightType]) {
            this.model.context.light_type = this.model.context.light_type.filter((light: any) => light !== lightType)
        } else {
            this.model.context.light_type.push(lightType);
        }
        this.lightTypeCheckBox[lightType] = !this.lightTypeCheckBox[lightType]
    };

    // -------- Project Light Condition Context
    updateLightCondition = (lightCondition: string) => {
        if (this.lightConditionCheckBox[lightCondition]) {
            this.model.context.light_condition = this.model.context.light_condition.filter((light: any) => light !== lightCondition)
        } else {
            this.model.context.light_condition.push(lightCondition);
        }
        this.lightConditionCheckBox[lightCondition] = !this.lightConditionCheckBox[lightCondition]

    };

    // -------- Project Perspective Context
    updatePerspective = (perspective: string) => {
        if (this.perspectiveCheckBox[perspective]) {
            this.model.context.perspective = this.model.context.perspective.filter((light: any) => light !== perspective)
        } else {
            this.model.context.perspective.push(perspective);
        }
        this.perspectiveCheckBox[perspective] = !this.perspectiveCheckBox[perspective]
    };
}
