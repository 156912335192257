import {HttpClient} from "@angular/common/http";
import {Config} from "global/config";
import {TagView} from "@teamviewer/aistudioapi-aistudio-angular";
import {Injectable} from "@angular/core";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {BACKGROUND, UNTAGGED} from "utils/project-utils";

@Injectable()
export class TagViewService {
    public backgroundTag: any = BACKGROUND;

    // Tag views for image classification
    public tagViews: TagView[] = [];
    public backgroundTagView = {};
    public untaggedTagView = {};
    public taggedTagViews: TagView[] = [];
    public positiveTaggedTagViews: TagView[] = [];

    public targetTag: string = "";
    public classes: any = [];

    // Tag views for object detection
    public wellSetTagViews: TagView[] = [];
    public backgroundWellSetTagView = {};
    public positiveWellSetTagViews: TagView[] = [];
    public notReadyTagViews: TagView[] = [];
    public untaggedNotReadyTagView = {};
    public taggedNotReadyTagViews: TagView[] = [];

    // Tag views for inference images
    public inferenceTagViews: TagView[] = [];
    public inferenceBackgroundTagView = {};
    public inferencePositiveTagViews: TagView[] = [];
    public inferenceUntaggedTagView = {};

    //Selected Tag views in the upload dialog
    public selectedTagViewsForUpload: any[] = [];


    constructor(private http: HttpClient, private config: Config,
                private aiProjectService: AiProjectService,
                private aiStudioObservables: AiStudioObservables
    ) {}

    getWellSetTagViews(): TagView[] {
        return this.wellSetTagViews;
    };

    getUnbalancedWellSetTagViews(): TagView[] {
        return this.wellSetTagViews.filter((tagView: TagView) => !tagView.balanced);
    };

    getPositiveWellSetTagViews(): TagView[] {
        return this.positiveWellSetTagViews;
    };

    getBackgroundWellSetTagView(): TagView {
        return this.backgroundWellSetTagView;
    };

    getNotReadyTagViews(): TagView[] {
        return this.notReadyTagViews;
    };

    getUntaggedNotReadyTagView(): TagView{
        return this.untaggedNotReadyTagView;
    };

    getTaggedNotReadyTagViews(): TagView[]  {
        return this.taggedNotReadyTagViews;
    };

    getTagViews(): TagView[]  {
        return this.tagViews;
    };

    getBackgroundTagView(): TagView {
        return this.backgroundTagView;
    };

    getUntaggedTagView(): TagView {
        return this.untaggedTagView || {count: 0};
    };

    getTaggedTagViews(): TagView[]  {
        return this.taggedTagViews;
    };

    getPositiveTaggedTagViews(): TagView[]  {
        return this.positiveTaggedTagViews;
    };

    //TODO: inference: check if necessary
    getInferenceTagViews(): TagView[]  {
        return this.inferenceTagViews;
    };

    getBackgroundInferenceTagView(): TagView {
        return this.inferenceBackgroundTagView;
    };

    getPositiveInferenceTagViews(): TagView[]  {
        return this.inferencePositiveTagViews;
    };

    //TODO: inference: check if necessary
    getSelectedInferenceTagViews(): TagView[] {
        return this.inferenceTagViews.filter((tagView: TagView) => tagView.selected);
    }

    getUnbalancedAndTaggedTagViews(): TagView[] {
        return this.tagViews.filter((tagView: TagView) => (tagView.tagName !== UNTAGGED && !tagView.balanced));
    };

    getBelowMinimumAndTaggedTagViews(): TagView[] {
        return this.tagViews.filter((tagView: TagView)  => (tagView.tagName !== UNTAGGED && !tagView.aboveMinimumAmount));
    };

    getBelowMinimumAndWellSetTagViews(): TagView[] {
        return this.wellSetTagViews.filter((tagView: TagView) => !tagView.aboveMinimumAmount);
    };

    //Return tags based on project type
    getTagView(tagName: string): TagView | null {
        let targetTagViews = null;

        if (this.aiProjectService.isObjectDetectionProject()) {
            targetTagViews = this.wellSetTagViews.filter((tagView: TagView) => tagView.tagName === tagName)
        } else {
            targetTagViews = this.tagViews.filter((tagView: TagView) => tagView.tagName === tagName);
        }

        if (targetTagViews && targetTagViews.length === 1) {
            return targetTagViews[0];
        } else {
            return null;
        }
    };

    // Depends on the project type, different tag views will be fetched from the server and they will be updated.
    fetchTagViews(): Promise<TagView[]> {
        return new Promise((resolve, reject ) => {
            if(!this.aiProjectService.isObjectDetectionProject()) {
                this.fetchClassificationTagViews().then((response) => {
                    resolve(response);
                }, (reason) => {
                    reject(reason);
                });
            } else if (this.aiProjectService.isObjectDetectionProject()) {
                this.fetchNotReadyTagViews();
                this.fetchWellSetTagViews().then((response) => {
                    resolve(response);
                }, (reason) => {
                    reject(reason);
                });
            }
        });
    };

    //Fetching All tags for Classification projects
    async fetchClassificationTagViews(): Promise<TagView[]> {
        const endpoint = `/aiProject/` +  this.aiProjectService.getAiProject()?.projectUuid + `/tagView?tagView=ALL`;
        return this.http.get<TagView[]>(`${this.config.baseUrl}${endpoint}`)
            .toPromise()
            .then(response => {
                if (response && response.length > 0) {
                    for (let newTagView of response) {
                        for (let oldTagView of this.tagViews) {
                            if (newTagView.tagName === oldTagView.tagName) {
                                newTagView.selected = oldTagView.selected;
                                break;
                            }
                        }
                    }
                }
                this.tagViews = response || [];
                this.backgroundTagView = this.tagViews.filter(tagView => tagView.tagName === BACKGROUND)[0];
                this.untaggedTagView = this.tagViews.filter(tagView => tagView.tagName === UNTAGGED)[0];
                this.taggedTagViews = this.tagViews.filter(tagView => tagView.tagName !== UNTAGGED);
                this.positiveTaggedTagViews = this.tagViews.filter(tagView => tagView.tagName !== BACKGROUND && tagView.tagName !== UNTAGGED);

                //TODO: for guidance
                this.aiStudioObservables.updateGuidanceStep$();
                return this.tagViews;
            });
    };

    //Object Detection Well Set
    async fetchWellSetTagViews(): Promise<TagView[]> {
        const endpoint = `/aiProject/` + this.aiProjectService.getAiProject()?.projectUuid + `/tagView?tagView=READY`;
        return this.http.get<TagView[]>(`${this.config.baseUrl}${endpoint}`)
            .toPromise()
            .then(response => {
                if (response && response.length > 0) {
                    for (let newTagView of response) {
                        for (let oldTagView of this.wellSetTagViews) {
                            if (newTagView.tagName === oldTagView.tagName) {
                                newTagView.selected = oldTagView.selected;
                                break;
                            }
                        }
                    }
                }
                this.wellSetTagViews = response || [];
                //this.wellSetTagViews = this.$filter('orderBy')(response.data, '-tagName', true);
                this.positiveWellSetTagViews = this.wellSetTagViews.filter(tagView => tagView.tagName !== BACKGROUND);
                this.backgroundWellSetTagView = this.wellSetTagViews.filter(tagView => tagView.tagName === BACKGROUND)[0];
                this.aiStudioObservables.updateGuidanceStep$();
                return this.wellSetTagViews;
            });
    };

    //Object Detection Not Ready
    async fetchNotReadyTagViews(): Promise<TagView[]> {
        const endpoint = `/aiProject/` + this.aiProjectService.getAiProject()?.projectUuid + `/tagView?tagView=NOTREADY`;
        return this.http.get<TagView[]>(`${this.config.baseUrl}${endpoint}`)
            .toPromise()
            .then(response => {
                if (response) {
                    for (let newTagView of response) {
                        for (let oldTagView of this.notReadyTagViews) {
                            if (newTagView.tagName === oldTagView.tagName) {
                                newTagView.selected = oldTagView.selected;
                                break;
                            }
                        }
                    }
                }
                //this.notReadyTagViews = this.$filter('orderBy')(response.data, '-tagName', true);
                this.notReadyTagViews = response || [];
                this.taggedNotReadyTagViews = this.notReadyTagViews.filter(tagView => tagView.tagName !== UNTAGGED);
                this.untaggedNotReadyTagView = this.notReadyTagViews.filter(tagView => tagView.tagName === UNTAGGED)[0];
                return this.notReadyTagViews;
            })
    };

    //TODO: inference: check if necessary
    async fetchInferenceTagViews(confidenceLevel?: number) {
        if(confidenceLevel) {
            confidenceLevel = confidenceLevel/100;
        } else {
            confidenceLevel = 0.75;
        }
        const endpoint = `/aiProject/` + this.aiProjectService.getAiProject()?.projectUuid + `/tagView?tagView=ALL&minimumConfidence=` + confidenceLevel +`&isInference=true`;
        return this.http.get<TagView[]>(`${this.config.baseUrl}${endpoint}`)
            .toPromise()
            .then(response => {
                if (response && response) {
                    for (let newTagView of response) {
                        for (let oldTagView of this.inferenceTagViews) {
                            if (newTagView.tagName === oldTagView.tagName) {
                                newTagView.selected = oldTagView.selected;
                                break;
                            }
                        }
                    }
                }
                this.inferenceTagViews = response || [];
                //this.inferenceTagViews = this.$filter('orderBy')(response.data, '-tagName', true);
                this.inferencePositiveTagViews = this.inferenceTagViews.filter(tagView => tagView.tagName !== BACKGROUND);
                this.inferenceBackgroundTagView = this.inferenceTagViews.filter(tagView => tagView.tagName === BACKGROUND)[0];

                return this.inferenceTagViews;
            })
    }
}
