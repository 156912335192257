import {Component, ViewEncapsulation} from "@angular/core";
import {AiStudioObservables} from "services/aiStudio/ai-studio.observables";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {DatasetService} from "services/aiStudio/dataset.service";
import {NotificationService} from "services/notification.service";
import {AugmentationSettingsComponent} from "components/modals/augmentation-settings/augmentation-settings.component";
import {LoadingService} from "services/loading.service";
import {saveAs} from "file-saver";
import {Model} from "@teamviewer/aistudioapi-common-angular";
import {TranslateService} from "@ngx-translate/core";
import {DialogConfirm} from "dialogs/dialog-confirm";
import {AuthenticationService} from "services/authentication.service";
import {ProjectWizardComponent} from "components/modals/project-wizard/project-wizard.component";
import {TrainingService} from "services/aiStudio/training.service";
import {EventService} from "services/event.service";

@Component({
    selector: 'ai-toolbar',
    templateUrl: 'ai-toolbar.component.html',
    styleUrls: ['ai-toolbar.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class AiToolbarComponent {
    STATUS_PROJECT_INIT: boolean = false;
    STATUS_PROJECT_LOADED: boolean = false;
    STATUS_DATUMS_IN_PROJECT: boolean = false;
    STATUS_PUBLISHED_MODEL_EXIST: boolean = false;

    hasFrontlineRole: boolean;
    linkFrontline: string | undefined;
    linkFrontlineAiStudioOvewerview: string | undefined;

    showfrontlineIcon: boolean = true;

    publishedModel: Model | undefined;

    constructor(private aiStudioObservables: AiStudioObservables, private modalService: NgbModal, public aiProjectService: AiProjectService, private datasetService: DatasetService, private notificationService: NotificationService, private loadingService: LoadingService, private translateService: TranslateService, private dialogConfirm: DialogConfirm, private authenticationService: AuthenticationService, private trainingService: TrainingService, private eventService: EventService) {

        this.aiStudioObservables.STATUS_PROJECT_INIT$.subscribe((value: any) => this.STATUS_PROJECT_INIT = value);
        this.aiStudioObservables.STATUS_PROJECT_LOADED$.subscribe((value: any) => this.STATUS_PROJECT_LOADED = value);
        this.aiStudioObservables.STATUS_DATUMS_IN_PROJECT$.subscribe((value: any) => this.STATUS_DATUMS_IN_PROJECT = value);
        this.aiStudioObservables.STATUS_PUBLISHED_MODEL_HAS_CHANGED$.subscribe((model: Model) => {

            this.publishedModel = model;
            this.STATUS_PUBLISHED_MODEL_EXIST = !!(this.publishedModel && this.publishedModel.published);
        })

        this.hasFrontlineRole = this.authenticationService.hasRole(("Frontline_AI_User"));
        if(this.hasFrontlineRole) {
            this.linkFrontline = this.authenticationService.getFrontlineBaseUrl() + "/home.html";
            this.linkFrontlineAiStudioOvewerview = this.authenticationService.getFrontlineBaseUrl() + "/#/admin/aistudio";
        }
    }

    openAugmentationSettings = () => {
        if (this.STATUS_PROJECT_LOADED) {
            if(!this.datasetService.allClassesHaveImage()) {
                this.notificationService.info(this.translateService.instant('ai-studio.feedback.augmentation-tag-amount-warning'));
            } else {
                let modalInstance = this.modalService.open(AugmentationSettingsComponent,  {
                    animation: true,
                    size: 'col-6',
                    backdrop: 'static',
                    keyboard: false
                });

                modalInstance.componentInstance.fromTraining = false;
            }
        }
    };

    exportPublishedModel = () => {
        let model: Model = this.aiProjectService.getPublishedTrainingModel();
        if (model !== undefined) {
            this.loadingService.show(this.translateService.instant('ai-studio.feedback.augmentation-exporting-public-model'));

            let fileName = model.modelUuid + "_" + model.name + ".zip";
            this.aiProjectService.getPublishedModelAsFile()
            .then(response => {
                if(response?.headers.has("Content-Disposition")) {
                    fileName = this.getFilenameFromHeader(response.headers.get("Content-Disposition")||"") || fileName
                }
                if (response?.body) {
                    saveAs(response.body, fileName);
                }else {
                    this.notificationService.error(this.translateService.instant('ai-studio.feedback.exporting-public-model-failed'))
                }
            })
            .catch(err => {
                    console.error("Caught error: ", err)
                    this.notificationService.error(this.translateService.instant('ai-studio.feedback.exporting-public-model-failed'))
                }
            ).finally(() => this.loadingService.hide())
        } else {
            this.notificationService.info(this.translateService.instant('ai-studio.feedback.augmentation-no-public-model'));
        }
    };

    editProjectInWizard = () => {
        if (this.STATUS_PROJECT_LOADED) {
            let modalInstance = this.modalService.open(ProjectWizardComponent, {
                animation: true,
                size: "col-8",
                backdrop: 'static',
                keyboard: false,
            })
            modalInstance.componentInstance.fromToolbar = true;
            modalInstance.componentInstance.aiProject = this.aiProjectService.getAiProject();
        }
    };

    getFilenameFromHeader(dispositionHeader: string): string | undefined {
        if (dispositionHeader && dispositionHeader.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(dispositionHeader);
            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }
        return undefined
    }
}
