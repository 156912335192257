<div [hidden]="!status_project_init" style="position: relative; height: 100%;">
    <div id="no-workflow-overlay" class="fullScreenOverlay">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center overlay-content" style="margin-top:-100px;">
                    <div class="mb-3"><img src="../../../assets/images/email/TV_Frontline-AiStudio_grey_RGB.png" height="60px" width="auto" ></div>
                    <h1 class="">
                        {{ 'ai-studio.overlay.no-project.title' | translate }}
                    </h1>
                    <br/>
                    <button-primary text="{{ 'ai-studio.init.create_new_project' | translate }}"
                                    (click)="showWizard()" style="margin-bottom: 10px;"></button-primary>
                </div>
            </div>
        </div>
    </div>
</div>
