import {Component} from '@angular/core';
import {Config} from 'global/config';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "services/authentication.service";

@Component({
    selector: 'app-root',
    templateUrl: 'error.component.html',
    styleUrls: ['error.component.sass']
})
export class ErrorComponent {
    redirectUrl = this.redirectPrefixUrl();
    status = '';
    message = '';

    clickable = false;

    constructor(private config: Config, private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthenticationService) {
        this.activatedRoute.queryParams.subscribe((params: any) => {
            this.status = params.status;
            this.message = this.buildErrorMessage();
        });
    }

    buildErrorMessage(): string {
        this.clickable = false;
        switch (this.status) {
            case '401':
                return '' +
                    '<span id="txt">' +
                    '   You need to be logged-in to AiStudio.' +
                    '</span>' +
                    '<p>Please <a href="' + this.config.baseUrl + '">login</a> and try again.</p>';
            case '403':
                return '' +
                    '<span id="txt">' +
                    '   You do not have the required permissions for this!' +
                    '</span>' +
                    '<p>Please contact support if you believe this to be an error!</p>';
            case '404':
                return '' +
                    '<span id="txt">' +
                    '   The page you are looking for does not exist!' +
                    '</span>' +
                    '<p>Go to <a href="' + this.redirectUrl + '">Home</a>.</p>';
            case '-1':
                return '' +
                    '<span id="txt">' +
                    '   Could not connect to AiStudio at ' + this.config.baseUrl +
                    '</span>';
            default:
                return '' +
                    '<span id="txt">' +
                    '   There was an error!' +
                    '</span>' +
                    '<p>Go to <a href="' + this.redirectUrl + '">Home</a>.</p>';
        }
    }

    private redirectPrefixUrl(): string {
        if(this.config.isProd) {
            return `${this.config.baseUrl.endsWith("/") ? this.config.baseUrl : this.config.baseUrl +"/"}#/home`
        }
        return '#'
    }

    public async redirectToLogin(){
        console.log("clicked");
        this.authService.logout();
        this.router.navigate(["home"]);
    }
}
