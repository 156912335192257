import {Injectable} from '@angular/core';
import {GridOptions} from 'ag-grid-community';
import $ from 'jquery';

@Injectable()
export class GridGlobalConfig {

    regex = {
        password: /^(?=.*[A-Za-z])(?=.*\d)\S{8,}$/, //at least one letter, at least one number, any other character except for whitespace
        phoneNumber:  /^\+[1-9]\d{1,14}$/, // E.164 conform regex to detect only numbers which start with +[countrcode][telephonenumber], as that is the only twilio supported standard.
        email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    };

    agGridBaseOptions = {
        accentedSort: true,
        defaultColDef: { filter: false, cellClass: "ellipsize-text" },
        headerHeight: 50,
        icons: {
            menu: '<i class="icon icon-filter"/>',
            filter: '<i class="icon icon-search"/>',
            sortAscending: '<i class="icon icon-sort-alpha-asc"/>',
            sortDescending: '<i class="icon icon-sort-alpha-desc"/>'
        },
        overlayLoadingTemplate: '<span class="grid-loading"><svg class="frontline-loading-animation frontline-visual theme-color"><use xlink:href="#frontline"></use></svg></span>',
        overlayNoRowsTemplate: '<span class="row empty-state">Your search result is empty. Reset the filter or search.</span>',
        rowHeight: 40,
        rowSelection: 'multiple',
        suppressCellFocus: true,
        suppressDragLeaveHidesColumns: true,
        suppressMultiSort: true,
    };

    caseInsensitiveComparator(valueA: any, valueB: any) {
        if (!valueA) {
            return -1;
        }
        if (!valueB) {
            return 1;
        }
        return valueA.localeCompare(valueB, 'en', {'sensitivity': 'base'});
    }

    setGridHeight(tag: string, gridOptions: GridOptions) {
        const rowHeight = gridOptions.rowHeight || 0;
        const rowNumber = gridOptions.api?.getDisplayedRowCount() || 0;
        let eGridDiv = $(tag);
        eGridDiv.height(rowHeight * (rowNumber + 3) + 50);
    }

    agGridBaseColumnDefs = {
        checkbox: {
            headerName: "",
            checkboxSelection: true,
            suppressMenu: true,
            headerCheckboxSelectionFilteredOnly: true,
            sortable: false,
            resizable: false,
            cellStyle: { 'text-align': 'center' },
            headerClass: "headercenter",
            minWidth: 45,
            maxWidth: 45,
        },
        description: {
            headerName: 'Description',
            field: 'description',
            sortable: false
        },
        baseName: {
            headerName: 'Name',
            field: 'name',
            comparator: (a: any, b: any) => a.localeCompare(b, 'en', {'sensitivity': 'base'}),
            sortable: true
        }
    }

}
