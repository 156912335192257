import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {Injectable} from '@angular/core';
import {I18nService} from 'services/i18n.service';

@Injectable()
export class DayJsService {
    // making it a service might be overkill right now but will probably move all formatting calls here in the future
    dayjs = dayjs;

    constructor(private i18nService: I18nService) {
        this.dayjs.extend(localizedFormat);
        i18nService.languageChange.subscribe((lang: string) => this.changeLocale(lang));
    }

    changeLocale(locale: string) {
        // Verify the locale code:
        let code = locale.toLowerCase();

        import("dayjs/locale/" + code).then(() => {
            console.info('Loaded locale', code);
            dayjs.locale(code);
        }).catch( () => {
            // Falls back to the first 2 letters (if of format 'xx-zzd')
            code = code.split('-')[0];
            import("dayjs/locale/" + code).then(() => {
                console.info('Loaded locale', code);
                dayjs.locale(code);
            }).catch(error => console.log('Could not load locale', error.message))
        });
    }
}
