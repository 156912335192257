import {
    Directive,
    ComponentFactoryResolver,
    AfterViewInit,
    ViewContainerRef,
    Optional,
} from '@angular/core';
import { NgControl, NgForm, NgModelGroup } from '@angular/forms';
import {DisplayErrorComponent} from "./display-error/display-error.component";

@Directive({
    selector: '[ngModel], [ngModelGroup]',
})
export class ErrorDirective implements AfterViewInit {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        @Optional() private ngModel: NgControl,
        @Optional() private ngModelGroup: NgModelGroup,
        @Optional() private ngForm: NgForm,
    ) {

    }

    ngAfterViewInit() {
        setTimeout(() => {
            const control = this.ngModel?.control ?? this.ngModelGroup?.control;
            if (control) {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                    DisplayErrorComponent
                );
                const errorContainer = this.viewContainerRef.createComponent(
                    componentFactory
                );
                errorContainer.instance.control = control;
                errorContainer.instance.form = this.ngForm;
            }
        });
    }
}
