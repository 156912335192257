import {Component, HostBinding, Input, OnInit, ViewChild} from "@angular/core";
import {AiProjectService} from "services/aiStudio/ai-project.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Config} from "global/config";
import {DatumService} from "services/aiStudio/datum.service";
import {TranslateService} from "@ngx-translate/core";
import {MINIMUM_IMAGES} from "utils/project-utils";
import {TagViewService} from "services/aiStudio/tag-view.service";
import _ from "lodash";
import {TagView} from "@teamviewer/aistudioapi-aistudio-angular";
import {UploaderComponent} from "components/uploader/uploader.component";
import {EventService} from "services/event.service";
import {VideoReviewComponent} from "apps/aiStudio/dataset/video-review/video-review.component";
import {DatasetService} from "services/aiStudio/dataset.service";
import {Datum} from "@teamviewer/aistudioapi-common-angular";
import {LoadingService} from "services/loading.service";

@Component({
    selector: 'import-video',
    templateUrl: 'import-video.component.html',
    styleUrls: ['import-video.component.sass']
})

export class ImportVideoComponent implements OnInit {
    @HostBinding('class') class = 'modal-content';
    @ViewChild(UploaderComponent) uploader!: UploaderComponent
    @Input() datum : Datum = {};
    uploaderLoaded: boolean = false;
    tagViews: any = [];
    selectedTagViews: string = "xdg";
    //detectionTags: any = []
    MINIMUM_IMAGES = MINIMUM_IMAGES;


    constructor(public aiProjectService: AiProjectService,
                public tagViewService: TagViewService,
                private modalService: NgbModal,
                private config: Config,
                private datumService: DatumService,
                private datasetService: DatasetService,
                private translateService: TranslateService,
                private activeModal: NgbActiveModal,
                private loadingService: LoadingService,
                private eventService: EventService
    ) {

    }
    async ngOnInit(){
        this.loadingService.show("ai-studio.feedback.uploader.loading-video")
        this.tagViews = [];

        let backgroundTag: TagView = {};
        let positiveTags: TagView[] = [];

        this.selectedTagViews = this.datum.datumLabels?.map(label => label.datumClass?.name)[0]||"";
        this.tagViewService.selectedTagViewsForUpload = [this.selectedTagViews];

        if (this.aiProjectService.isSingleLabelProject()) {
            backgroundTag = _.cloneDeep(this.tagViewService.getBackgroundTagView());
            positiveTags = _.cloneDeep(this.tagViewService.getPositiveTaggedTagViews());
        } else if (this.aiProjectService.isObjectDetectionProject()) {
            // By default no tag selected
            backgroundTag = _.cloneDeep(this.tagViewService.getBackgroundWellSetTagView());
            positiveTags = _.cloneDeep(this.tagViewService.getPositiveWellSetTagViews());
        }
        for (let positiveTag of positiveTags) {
            positiveTag.selected = false;
        }

        this.tagViews = this.tagViews.concat(positiveTags);
        if(backgroundTag) {
            backgroundTag.selected = false;
            this.tagViews.push(backgroundTag);
        }
    }

    ngAfterViewInit() {
        this.uploaderLoaded = true;

        this.uploader.onUploadComplete = (report) => {
            console.log(report);
            if(report.errors === 0) {
                this.datasetService.releaseVideo(this.aiProjectService.getAiProject().projectUuid || "", this.datum.id || "");
            }
        }

        this.datasetService.loadDataCollectionVideo(this.aiProjectService.getAiProject().projectUuid || "", this.datum.id || "").then(response => {
            this.loadingService.hide();
            let modalInstance = this.modalService.open(VideoReviewComponent, {
                animation: true,
                size: 'col-12 no-padding',
                backdrop: 'static',
            })

            modalInstance.componentInstance.videofile = response!.body;
            modalInstance.componentInstance.uploader = this.uploader;
        }).catch((err: any) => {
            console.log(err)
            this.loadingService.hide();
        })
    }

    checkForBackgroundTag($tag: any) {
        // if(Object.is($tag.tagName, this.tagViewService.backgroundTag)) {
        //     this.selectedTagViews = [];
        //     this.selectedTagViews = [$tag.tagName];
        // } else {
        //     if(this.selectedTagViews.includes(this.tagViewService.backgroundTag)) {
        //        this.selectedTagViews = this.selectedTagViews.filter((tagName: string) => !Object.is(tagName, this.tagViewService.backgroundTag));
        //     }
        // }
        // this.tagViewService.selectedTagViewsForUpload = this.selectedTagViews
    }

    removeTag($tag: any) {
        // this.tagViewService.selectedTagViewsForUpload = this.selectedTagViews.filter((tagName: string) => !Object.is(tagName, $tag));
    }

    closeModal() {
        this.activeModal.close();
    };

    sendCancelEvent() {
        const projectUuid = this.aiProjectService.getAiProject().projectUuid;
        const event = this.eventService.buildModel(`Event,ai-project,cancel-upload`, projectUuid || '', '');
        this.eventService.submitEvent(event);
    }

    calculateMaximumUploadNumber() {
        let max = 0;
        for (let tag of this.tagViews) {
            if((MINIMUM_IMAGES-tag.count) > max && this.selectedTagViews.includes(tag.tagName)) {
                max = MINIMUM_IMAGES-tag.count;
            }
        }
        return max;
    };

    changeTag() {
        console.log("changing tag")
        this.tagViewService.selectedTagViewsForUpload = [this.selectedTagViews]
    }

    async skipVideo() {
        await this.datasetService.releaseVideo(this.aiProjectService.getAiProject().projectUuid || "", this.datum.id || "");
        this.closeModal()
    }
}
