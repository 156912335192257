<div class="modal-head">
       <h2 class="modal-title">
            <span>{{ 'ai-studio.modals.user-deletion.headline' | translate }}</span>
            <a class="float-right">
                <i class="icon icon-times" (click)="closeModal()"></i>
            </a>
       </h2>
</div>
<div class="modal-body modal-confirm">
    <div class="modal-confirm-content">
        <div class="row mb-4" style="align-items: center;">
            <div class="col-8 pl-0 ">
                <p class="m-0 warning">{{ 'ai-studio.modals.user-deletion.warning-paragraph' | translate}}</p>
            </div>
            <div class="col-4 p-0">
                <img src="../../../src/assets/images/robo/robo-delete-account.png" width="100%" height="auto">
            </div>
        </div>
        <form-field-text-input
            class="col col-sm-12"
            [label]="'ai-studio.modals.user-deletion.confirmation-label' | translate"
            [elementId]="'delete-account'"
            [(ngModel)]="conformationText"
            (ngModelChange)="checkForValidInput()">
        </form-field-text-input>
    </div>
</div>
<div class="modal-footer">
    <button-secondary text="{{ 'ai-studio.modals.cancel' | translate }}"
                      (click)="closeModal()">
    </button-secondary>
    <button-primary
        text="{{ 'ai-studio.modals.confirm' | translate }}"
        class="pull-left mark-red"
        (click)="deleteAccount()"
        [disabled]="!this.readyForDeletion">
    </button-primary>
</div>

